import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class LeadStatus  {
    _originalTypeClassName = "zPMO.BO.LeadStatus";
constructor() {
this.Id = null;
this.Name = null;
this.Index = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Index:any;
LeadType:any;
Validation:any;
static setLeadType = ($this, value) => {
var oldValue = $this.LeadType;
$this.LeadType = value;
if (value != null) {
if (oldValue != null) {
Domain.LeadType.removeStatuses(oldValue, $this);
}
Domain.LeadType.addStatuses(value, $this);
}
}
static setValidation = ($this, value) => {
var oldValue = $this.Validation;
$this.Validation = value;
if (value != null) {
if (oldValue != null && oldValue.Status != null) { oldValue.Status = null; }
if ($this.Validation.Status !== $this) {
Domain.LeadStatusActivationValidation.setStatus(value, $this);
}
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}