import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class Domain_LocalResourcesDefinition  {
    _originalTypeClassName = "zPMO.BO.Domain_LocalResourcesDefinition";
constructor() {
this.DTOs_Res_ASSOC_FiscalYear_Dashobard_FiscalYear = null;
this.ProjectBO_Res_ASSOC_Client_Project_Client = null;
this.ProjectBO_Res_ASSOC_Company_Payment_Company = null;
this.ProjectBO_Res_ASSOC_Product_Project_Product = null;
this.ProjectBO_Res_ASSOC_FileData_ProjectDocument_FileData = null;
this.ProjectBO_Res_ASSOC_Company_Project_Company = null;
this.ProjectBO_Res_ASSOC_FiscalYear_Project_FiscalYear = null;
this.ProjectBO_Res_ASSOC_Employee_ResourceUtilization_Resource = null;
this.Res_ENUM_ProjectStatus_Lead = null;
this.Res_ENUM_ProjectStatus_RequiresAction = null;
this.Res_ENUM_ProjectStatus_Accepted = null;
this.Res_ENUM_ProjectStatus_Completed = null;
this.Res_ENUM_ProjectStatus_Rejected = null;
this.Res_ENUM_ProjectStatus_PendingPayment = null;
this.CoreBO_Res_ASSOC_Company_Invoice_Issuer = null;
this.CoreBO_Res_ASSOC_Client_Invoice_Client = null;
this.CoreBO_Res_ASSOC_Currency_Invoice_Currency = null;
this.CoreBO_Res_ASSOC_Currency_Company_Currency = null;
this.CoreBO_Res_ASSOC_Tag_Client_Tags = null;
this.Res_ENUM_InvoiceTemplate_Micronoesis = null;
this.Res_ENUM_InvoiceTemplate_CLMSHellas = null;
this.Res_ENUM_InvoiceTemplate_CLMSUK = null;
this.Res_ENUM_InvoiceStatus_Temp = null;
this.Res_ENUM_InvoiceStatus_Cancelled = null;
this.Res_ENUM_InvoiceStatus_Issued = null;
this.Res_ENUM_InvoiceStatus_Payed = null;
this.Res_ENUM_CompanyTaxRuleType_FlatRate = null;
this.Res_ENUM_CompanyTaxRuleType_Scaled = null;
this.MonthyReportBO_Res_ASSOC_Client_MonthyReport_Client = null;
this.MonthyReportBO_Res_ASSOC_Project_MonthyReport_Project = null;
this.Expenses_Res_ASSOC_FiscalYear_Expense_FiscalYear = null;
this.Expenses_Res_ASSOC_ExpsenseCategory_ExpenseRule_Category = null;
this.Expenses_Res_ASSOC_ExpsenseCategory_Expense_Category = null;
this.Expenses_Res_ASSOC_Company_Expense_Company = null;
this.Expenses_Res_ASSOC_Company_ExpenseRule_Company = null;
this.LeadBO_Res_ASSOC_LeadType_Lead_LeadType = null;
this.LeadBO_Res_ASSOC_LeadStatus_Lead_LeadStatus = null;
this.LeadBO_Res_ASSOC_Client_Lead_Account = null;
this.LeadBO_Res_ASSOC_Product_LeadProductLine_Product = null;
this.LeadBO_Res_ASSOC_PMOUser_Lead_Owner = null;
this.LeadBO_Res_ASSOC_PMOUser_LeadJournalEntry_PMOUser = null;
this.LeadBO_Res_ASSOC_ClientContact_Lead_ClientContact = null;
this.LeadBO_Res_ASSOC_DynamicField_LeadTypeDynamicField_DynamicField = null;
this.LeadBO_Res_ASSOC_DynamicField_LeadDynamicFieldValue_DynamicField = null;
this.LeadBO_Res_ASSOC_Tag_Lead_Tags = null;
this.LeadBO_Res_ASSOC_LeadStatus_Validation_LeadStatusActivationValidation_Status = null;
this.LeadBO_Res_ASSOC_FileData_LeadUpload_Contents = null;
this.LeadBO_Res_ASSOC_LeadAutomationAction_LeadActionExecution_Action = null;
this.LeadBO_Res_ASSOC_DynamicServiceEndpoint_LeadAutomationAction_Endpoint = null;
this.Res_ENUM_LeadJournalEntryType_Created = null;
this.Res_ENUM_LeadJournalEntryType_StatusChange = null;
this.Res_ENUM_LeadJournalEntryType_FileUploaded = null;
this.Res_ENUM_LeadJournalEntryType_UpdateAction = null;
this.Res_ENUM_LeadJournalEntryType_Terminated = null;
this.Res_ENUM_LeadJournalEntryType_TaskAdded = null;
this.Res_ENUM_LeadAutomationActionType_NotifyUser = null;
this.Res_ENUM_LeadAutomationActionType_Clone = null;
this.Res_ENUM_LeadAutomationActionType_TransformToProject = null;
this.Res_ENUM_LeadAutomationActionType_InvokeEndpoint = null;
this.Res_ENUM_LeadAutomationTerminalStatuses_All = null;
this.Res_ENUM_LeadAutomationTerminalStatuses_Won = null;
this.Res_ENUM_LeadAutomationTerminalStatuses_Lost = null;
this.Res_ENUM_LeadAutomationTerminalStatuses_Pending = null;
this.Res_ENUM_LeadAutomationNotifyUserType_Owner = null;
this.Res_ENUM_LeadAutomationNotifyUserType_SpecificAccounts = null;
this.DynamicFieldBO_Res_ASSOC_DynamicFieldGroup_Fields_DynamicField_Group = null;
this.DynamicFieldBO_Res_ASSOC_DynamicField_DynamicFieldConditionLine_Operand1 = null;
this.Res_ENUM_DynamicFieldDatatype_Int = null;
this.Res_ENUM_DynamicFieldDatatype_Decimal = null;
this.Res_ENUM_DynamicFieldDatatype_Text = null;
this.Res_ENUM_DynamicFieldDatatype_RichText = null;
this.Res_ENUM_DynamicFieldDatatype_BigText = null;
this.Res_ENUM_DynamicFieldDatatype_Date = null;
this.Res_ENUM_DynamicFieldDatatype_DateTime = null;
this.Res_ENUM_DynamicFieldDatatype_Options = null;
this.Res_ENUM_DynamicFieldDatatype_MultiOptions = null;
this.Res_ENUM_DynamicFieldDatatype_Boolean = null;
this.Res_ENUM_DynamicFieldConditionLineOperator_Equals = null;
this.Res_ENUM_DynamicFieldConditionLineOperator_NotEquals = null;
this.Res_ENUM_DynamicFieldConditionLineOperator_HasValue = null;
this.Res_ENUM_DynamicFieldConditionOperator_AND = null;
this.Res_ENUM_DynamicFieldConditionOperator_OR = null;
this.Res_ENUM_DynamicFieldDatatype_Link = null;
this.Res_ENUM_LeadFiltersStatus_All = null;
this.Res_ENUM_LeadFiltersStatus_Pending = null;
this.Res_ENUM_LeadFiltersStatus_Won = null;
this.Res_ENUM_LeadFiltersStatus_Lost = null;
this.LeadActionTypeBO_Res_ASSOC_LeadActionType_LeadAction_LeadActionType = null;
this.LeadActionTypeBO_Res_ASSOC_PMOUser_LeadAction_PMOUser = null;
this.LeadActionTypeBO_Res_ASSOC_ClientContact_LeadAction_ClientContact = null;
this.TagsBO_Res_ASSOC_Tag_TagConditionLine_Tags = null;
this.Res_ENUM_TagType_TagType1 = null;
this.Res_ENUM_TagType_TagType2 = null;
this.Res_ENUM_TagConditionOperator_AND = null;
this.Res_ENUM_TagConditionOperator_OR = null;
this.CRMDashboardBO_Res_ASSOC_LeadType_CRMStats_LeadType = null;
this.Res_ENUM_CRMStatsDateFilter_CurrentMonth = null;
this.Res_ENUM_CRMStatsDateFilter_CurrentQuarter = null;
this.Res_ENUM_CRMStatsDateFilter_CurrentYear = null;
this.Res_ENUM_CRMStatsDateFilter_Custom = null;
this.NotificationBO_Res_ASSOC_PMOUser_Notification_User = null;
this.Res_ENUM_DynamicServiceEndpointVERB_GET = null;
this.Res_ENUM_DynamicServiceEndpointVERB_POST = null;
this.Res_ENUM_DynamicServiceEndpointVERB_PUT = null;
}

_key: any;
DTOs_Res_ASSOC_FiscalYear_Dashobard_FiscalYear:any;
ProjectBO_Res_ASSOC_Client_Project_Client:any;
ProjectBO_Res_ASSOC_Company_Payment_Company:any;
ProjectBO_Res_ASSOC_Product_Project_Product:any;
ProjectBO_Res_ASSOC_FileData_ProjectDocument_FileData:any;
ProjectBO_Res_ASSOC_Company_Project_Company:any;
ProjectBO_Res_ASSOC_FiscalYear_Project_FiscalYear:any;
ProjectBO_Res_ASSOC_Employee_ResourceUtilization_Resource:any;
Res_ENUM_ProjectStatus_Lead:any;
Res_ENUM_ProjectStatus_RequiresAction:any;
Res_ENUM_ProjectStatus_Accepted:any;
Res_ENUM_ProjectStatus_Completed:any;
Res_ENUM_ProjectStatus_Rejected:any;
Res_ENUM_ProjectStatus_PendingPayment:any;
CoreBO_Res_ASSOC_Company_Invoice_Issuer:any;
CoreBO_Res_ASSOC_Client_Invoice_Client:any;
CoreBO_Res_ASSOC_Currency_Invoice_Currency:any;
CoreBO_Res_ASSOC_Currency_Company_Currency:any;
CoreBO_Res_ASSOC_Tag_Client_Tags:any;
Res_ENUM_InvoiceTemplate_Micronoesis:any;
Res_ENUM_InvoiceTemplate_CLMSHellas:any;
Res_ENUM_InvoiceTemplate_CLMSUK:any;
Res_ENUM_InvoiceStatus_Temp:any;
Res_ENUM_InvoiceStatus_Cancelled:any;
Res_ENUM_InvoiceStatus_Issued:any;
Res_ENUM_InvoiceStatus_Payed:any;
Res_ENUM_CompanyTaxRuleType_FlatRate:any;
Res_ENUM_CompanyTaxRuleType_Scaled:any;
MonthyReportBO_Res_ASSOC_Client_MonthyReport_Client:any;
MonthyReportBO_Res_ASSOC_Project_MonthyReport_Project:any;
Expenses_Res_ASSOC_FiscalYear_Expense_FiscalYear:any;
Expenses_Res_ASSOC_ExpsenseCategory_ExpenseRule_Category:any;
Expenses_Res_ASSOC_ExpsenseCategory_Expense_Category:any;
Expenses_Res_ASSOC_Company_Expense_Company:any;
Expenses_Res_ASSOC_Company_ExpenseRule_Company:any;
LeadBO_Res_ASSOC_LeadType_Lead_LeadType:any;
LeadBO_Res_ASSOC_LeadStatus_Lead_LeadStatus:any;
LeadBO_Res_ASSOC_Client_Lead_Account:any;
LeadBO_Res_ASSOC_Product_LeadProductLine_Product:any;
LeadBO_Res_ASSOC_PMOUser_Lead_Owner:any;
LeadBO_Res_ASSOC_PMOUser_LeadJournalEntry_PMOUser:any;
LeadBO_Res_ASSOC_ClientContact_Lead_ClientContact:any;
LeadBO_Res_ASSOC_DynamicField_LeadTypeDynamicField_DynamicField:any;
LeadBO_Res_ASSOC_DynamicField_LeadDynamicFieldValue_DynamicField:any;
LeadBO_Res_ASSOC_Tag_Lead_Tags:any;
LeadBO_Res_ASSOC_LeadStatus_Validation_LeadStatusActivationValidation_Status:any;
LeadBO_Res_ASSOC_FileData_LeadUpload_Contents:any;
LeadBO_Res_ASSOC_LeadAutomationAction_LeadActionExecution_Action:any;
LeadBO_Res_ASSOC_DynamicServiceEndpoint_LeadAutomationAction_Endpoint:any;
Res_ENUM_LeadJournalEntryType_Created:any;
Res_ENUM_LeadJournalEntryType_StatusChange:any;
Res_ENUM_LeadJournalEntryType_FileUploaded:any;
Res_ENUM_LeadJournalEntryType_UpdateAction:any;
Res_ENUM_LeadJournalEntryType_Terminated:any;
Res_ENUM_LeadJournalEntryType_TaskAdded:any;
Res_ENUM_LeadAutomationActionType_NotifyUser:any;
Res_ENUM_LeadAutomationActionType_Clone:any;
Res_ENUM_LeadAutomationActionType_TransformToProject:any;
Res_ENUM_LeadAutomationActionType_InvokeEndpoint:any;
Res_ENUM_LeadAutomationTerminalStatuses_All:any;
Res_ENUM_LeadAutomationTerminalStatuses_Won:any;
Res_ENUM_LeadAutomationTerminalStatuses_Lost:any;
Res_ENUM_LeadAutomationTerminalStatuses_Pending:any;
Res_ENUM_LeadAutomationNotifyUserType_Owner:any;
Res_ENUM_LeadAutomationNotifyUserType_SpecificAccounts:any;
DynamicFieldBO_Res_ASSOC_DynamicFieldGroup_Fields_DynamicField_Group:any;
DynamicFieldBO_Res_ASSOC_DynamicField_DynamicFieldConditionLine_Operand1:any;
Res_ENUM_DynamicFieldDatatype_Int:any;
Res_ENUM_DynamicFieldDatatype_Decimal:any;
Res_ENUM_DynamicFieldDatatype_Text:any;
Res_ENUM_DynamicFieldDatatype_RichText:any;
Res_ENUM_DynamicFieldDatatype_BigText:any;
Res_ENUM_DynamicFieldDatatype_Date:any;
Res_ENUM_DynamicFieldDatatype_DateTime:any;
Res_ENUM_DynamicFieldDatatype_Options:any;
Res_ENUM_DynamicFieldDatatype_MultiOptions:any;
Res_ENUM_DynamicFieldDatatype_Boolean:any;
Res_ENUM_DynamicFieldConditionLineOperator_Equals:any;
Res_ENUM_DynamicFieldConditionLineOperator_NotEquals:any;
Res_ENUM_DynamicFieldConditionLineOperator_HasValue:any;
Res_ENUM_DynamicFieldConditionOperator_AND:any;
Res_ENUM_DynamicFieldConditionOperator_OR:any;
Res_ENUM_DynamicFieldDatatype_Link:any;
Res_ENUM_LeadFiltersStatus_All:any;
Res_ENUM_LeadFiltersStatus_Pending:any;
Res_ENUM_LeadFiltersStatus_Won:any;
Res_ENUM_LeadFiltersStatus_Lost:any;
LeadActionTypeBO_Res_ASSOC_LeadActionType_LeadAction_LeadActionType:any;
LeadActionTypeBO_Res_ASSOC_PMOUser_LeadAction_PMOUser:any;
LeadActionTypeBO_Res_ASSOC_ClientContact_LeadAction_ClientContact:any;
TagsBO_Res_ASSOC_Tag_TagConditionLine_Tags:any;
Res_ENUM_TagType_TagType1:any;
Res_ENUM_TagType_TagType2:any;
Res_ENUM_TagConditionOperator_AND:any;
Res_ENUM_TagConditionOperator_OR:any;
CRMDashboardBO_Res_ASSOC_LeadType_CRMStats_LeadType:any;
Res_ENUM_CRMStatsDateFilter_CurrentMonth:any;
Res_ENUM_CRMStatsDateFilter_CurrentQuarter:any;
Res_ENUM_CRMStatsDateFilter_CurrentYear:any;
Res_ENUM_CRMStatsDateFilter_Custom:any;
NotificationBO_Res_ASSOC_PMOUser_Notification_User:any;
Res_ENUM_DynamicServiceEndpointVERB_GET:any;
Res_ENUM_DynamicServiceEndpointVERB_POST:any;
Res_ENUM_DynamicServiceEndpointVERB_PUT:any;




}