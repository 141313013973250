import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class DynamicFieldConditionLine  {
    _originalTypeClassName = "zPMO.BO.DynamicFieldConditionLine";
constructor() {
this.Id = null;
this.Operator = null;
this.LogicOperator = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Operator:any;
LogicOperator:any;
Operand1:any;
Operand2:any;
static setOperand1 = ($this, value) => {
var oldValue = $this.Operand1;
$this.Operand1 = value;
if (value != null) {
}
}
static setOperand2 = ($this, value) => {
var oldValue = $this.Operand2;
$this.Operand2 = value;
if (value != null) {
}
}

static AreEqual(expected: any,actual: any): any{return "AreEqual is not yet implemented";
}
static AreNotEqual(expected: any,actual: any): any{return "AreNotEqual is not yet implemented";
}
static HasValue(actual: any): any{if (actual != null && Domain.LeadDynamicFieldValue.GetObjectValue(actual) != null && Domain.LeadDynamicFieldValue.GetObjectValue(actual)?.toString() != "") {return null;}
return CLMS.Framework.String.Format("Field {0} should have a value", actual?.DynamicField?.Name);
}
static IsTrue($this: any, values: any): any{let itemValue = values?.linq?.where((v) => v?.DynamicField == $this?.Operand1)?.firstOrDefault(null);
switch ($this?.Operator) {case Domain.DynamicFieldConditionLineOperator.Equals:
{
return Domain.DynamicFieldConditionLine.AreEqual($this?.Operand2, itemValue);}
case Domain.DynamicFieldConditionLineOperator.NotEquals:
{
return Domain.DynamicFieldConditionLine.AreNotEqual($this?.Operand2, itemValue);}
case Domain.DynamicFieldConditionLineOperator.HasValue:
{
return Domain.DynamicFieldConditionLine.HasValue(itemValue);}
}
console.error("DynamicFieldConditionLineOperator " + $this?.Operator + " is not yet implemented");
return "DynamicFieldConditionLineOperator " + $this?.Operator + " is not yet implemented";
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}