import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class GlobalResourcesDefinition  {
    _originalTypeClassName = "zPMO.BO.GlobalResourcesDefinition";
constructor() {
this.RES_SITE_ApplicationTitle = null;
this.RES_SITE_ApplicationDescription = null;
this.RES_SITE_WindowTitle = null;
this.RES_SITE_PREFS_CancelButtonText = null;
this.RES_SITE_PREFS_OKButtonText = null;
this.RES_SITE_Changesnotsaved = null;
this.RES_SITE_SessionExpiredErrorMessage = null;
this.RES_SITE_SessionRefreshMessage = null;
this.RES_SITE_SessionRefreshedMessage = null;
this.RES_SITE_ExitConfirmation = null;
this.RES_SITE_ExitConfirmationMessage = null;
this.RES_SITE_AreYouSure = null;
this.RES_SITE_Confirm = null;
this.RES_SITE_CloseChildList = null;
this.RES_SITE_Exit = null;
this.RES_SITE_NullValue = null;
this.RES_SITE_POPUP_BLOCKED_TITLE = null;
this.RES_SITE_POPUP_BLOCKED_MESSAGE = null;
this.RES_SITE_StaleObjectStateExceptionTitle = null;
this.RES_SITE_StaleObjectStateExceptionMessage = null;
this.RES_CONTROLLER_ACTION_TimeoutErrorTitle = null;
this.RES_CONTROLLER_ACTION_TimeoutErrorMessage = null;
this.RES_CONTROLLER_ACTION_AbortErrorTitle = null;
this.RES_CONTROLLER_ACTION_AbortErrorMessage = null;
this.RES_CONTROLLER_ACTION_GenericErrorTitle = null;
this.RES_CONTROLLER_ACTION_GenericErrorMessage = null;
this.RES_CONTROLLER_ACTION_ParserErrorTitle = null;
this.RES_CONTROLLER_ACTION_ParserErrorMessage = null;
this.RES_BO_PrimaryKeyNotSet = null;
this.RES_BO_CannotDeleteNewObject = null;
this.RES_BO_UpdateFailedDueToConcurrency = null;
this.RES_BO_DeleteFailedDueToConcurrency = null;
this.RES_BO_Overflow = null;
this.RES_BO_InvalidPassword = null;
this.RES_BO_InvalidPassword_CapitalLettersRequired = null;
this.RES_BO_InvalidPassword_MinPasswordLength = null;
this.RES_BO_InvalidPassword_MinRequiredNumericDigits = null;
this.RES_BO_InvalidPassword_MinRequiredNonAlphanumeric = null;
this.RES_BO_NewPass_And_OldPass_MustNotMatch = null;
this.RES_BO_BR_InvalidEmail = null;
this.RES_LIST_ERROR_ErrorTitle = null;
this.RES_LIST_ERROR_CannotRemoveLastView = null;
this.RES_LIST_TOOLBAR_ExportButtonTooltip = null;
this.RES_LIST_TOOLBAR_FilterButtonTooltip = null;
this.RES_LIST_TOOLBAR_PreferencesButtonTooltip = null;
this.RES_LIST_TOOLBAR_RefreshButtonTooltip = null;
this.RES_LIST_TOOLBAR_RefreshResetButtonTooltip = null;
this.RES_LIST_PAGER_PageSize = null;
this.RES_LIST_PAGER_Page = null;
this.RES_LIST_PAGER_Of = null;
this.RES_LIST_PAGER_FirstPage = null;
this.RES_LIST_PAGER_PreviousPage = null;
this.RES_LIST_PAGER_NextPage = null;
this.RES_LIST_PAGER_LastPage = null;
this.RES_LIST_PAGER_TotalRecords = null;
this.RES_LIST_AGGREGATE_Summation = null;
this.RES_LIST_AGGREGATE_Average = null;
this.RES_LIST_AGGREGATE_Count = null;
this.RES_LIST_PAGER_PageAggregateTooltip = null;
this.RES_LIST_PAGER_GrandAggregateTooltip = null;
this.RES_LIST_EXPORT_DialogTitle = null;
this.RES_LIST_EXPORT_ExportRange_CurrentPage = null;
this.RES_LIST_EXPORT_ExportRange_Top100Pages = null;
this.RES_LIST_EXPORT_ExportRange_AllPages_10000_Restriction = null;
this.RES_LIST_EXPORT_ExportRange_AllPages = null;
this.RES_LIST_EXPORT_PrintRange = null;
this.RES_LIST_EXPORT_IncludeGridLines = null;
this.RES_LIST_EXPORT_ExportOK = null;
this.RES_LIST_EXPORT_ExportCancel = null;
this.RES_LIST_FILTERS_DialogTitle = null;
this.RES_LIST_FILTERS_RowOperator_Header = null;
this.RES_LIST_FILTERS_RowOperatorTypes_None = null;
this.RES_LIST_FILTERS_RowOperatorTypes_And = null;
this.RES_LIST_FILTERS_RowOperatorTypes_Or = null;
this.RES_LIST_FILTERS_Column_Header = null;
this.RES_LIST_FILTERS_Column_None = null;
this.RES_LIST_FILTERS_Operators_Header = null;
this.RES_LIST_FILTERS_Operators_None = null;
this.RES_LIST_FILTERS_Operators_EqualTo = null;
this.RES_LIST_FILTERS_Operators_NotEqualTo = null;
this.RES_LIST_FILTERS_Operators_LessThan = null;
this.RES_LIST_FILTERS_Operators_LessThanOrEqualTo = null;
this.RES_LIST_FILTERS_Operators_GreaterThan = null;
this.RES_LIST_FILTERS_Operators_GreaterThanOrEqualTo = null;
this.RES_LIST_FILTERS_Operators_Like = null;
this.RES_LIST_FILTERS_Operators_IsNull = null;
this.RES_LIST_FILTERS_Operators_NotNull = null;
this.RES_LIST_FILTERS_Operators_HasΝοValue = null;
this.RES_LIST_FILTERS_SelectAll = null;
this.RES_LIST_FILTERS_FilterValue_Header = null;
this.RES_LIST_FILTERS_AddFilter = null;
this.RES_LIST_FILTERS_FilterOK = null;
this.RES_LIST_FILTERS_FilterCancel = null;
this.RES_LIST_PREFERENCES_DialogTitle = null;
this.RES_LIST_PREFERENCES_Column_Header = null;
this.RES_LIST_PREFERENCES_Visible = null;
this.RES_LIST_PREFERENCES_Sorting = null;
this.RES_LIST_PREFERENCES_SortingType_None = null;
this.RES_LIST_PREFERENCES_SortingType_ASC = null;
this.RES_LIST_PREFERENCES_SortingType_DESC = null;
this.RES_LIST_PREFERENCES_SortOrder = null;
this.RES_LIST_PREFERENCES_MoveUp = null;
this.RES_LIST_PREFERENCES_MoveDown = null;
this.RES_LIST_PREFERENCES_PreferencesOK = null;
this.RES_LIST_PREFERENCES_PreferencesCancel = null;
this.RES_LIST_SelectCheckBox_Tooltip = null;
this.RES_LIST_SelectAllCheckBox_Tooltip = null;
this.RES_LIST_TOOLBAR_MoreActionsButton_Text = null;
this.RES_LISTFORM_SUCCESS_SuccessMessage = null;
this.RES_LISTFORM_SUCCESS_SuccessTitle = null;
this.RES_LIST_PAGER_SelectedRecords = null;
this.RES_LISTFORM_NORESULTS_NoResults = null;
this.RES_LIST_ERROR_UseOnlyEqualLike = null;
this.RES_LIST_ERROR_MultipleNavigationActions = null;
this.RES_LIST_ShowOnlyGroups_Expand = null;
this.RES_LIST_ShowOnlyGroups_Collapse = null;
this.RES_LIST_FILTERS_NoFilterDefined = null;
this.RES_LIST_EXPORT_ExportOnlyGroups = null;
this.RES_LIST_EXPORT_ExportTo = null;
this.RES_LIST_VIEWS_RemovePrompt = null;
this.RES_LIST_VIEWS_SaveHeader = null;
this.RES_LIST_VIEWS_Name = null;
this.RES_LIST_VIEWS_Ok = null;
this.RES_LIST_VIEWS_Cancel = null;
this.RES_LIST_VIEWS_MakeDefault = null;
this.RES_LIST_VIEWS_Remove = null;
this.RES_LIST_VIEWS_Save = null;
this.RES_LIST_PAGER_Items = null;
this.RES_LIST_PAGER_PerPage = null;
this.RES_LIST_PAGE_Pages = null;
this.RES_LIST_PREFERENCES_Rearrange = null;
this.RES_LIST_TOOLBAR_HideQuickFilters = null;
this.RES_LIST_TOOLBAR_PredefinedView = null;
this.RES_LIST_TOOLBAR_Search = null;
this.RES_LIST_TOOLBAR_ShowQuickFilters = null;
this.RES_LIST_VIEWS_IsDefault = null;
this.RES_LISTFORM_FiltersApplied = null;
this.RES_LIST_AGGREGATORS_GrandAverage = null;
this.RES_LIST_AGGREGATORS_GrandCount = null;
this.RES_LIST_AGGREGATORS_GrandTotal = null;
this.RES_LIST_AGGREGATORS_PageAverage = null;
this.RES_LIST_AGGREGATORS_PageCount = null;
this.RES_LIST_AGGREGATORS_PageTotal = null;
this.RES_LIST_TOOLBAR_GroupingOrder = null;
this.RES_LIST_TOOLBAR_Page = null;
this.RES_LIST_SortASC_Tooltip = null;
this.RES_LIST_SortDESC_Tooltip = null;
this.RES_LIST_UnSort_Tooltip = null;
this.RES_LIST_FILTERS_Operators_Range = null;
this.RES_LIST_FILTERS_Operators_HasNoValue = null;
this.RES_LIST_FILTERS_Operators_HasValue = null;
this.RES_LIST_AGGREGATORS_CalculateAverage = null;
this.RES_LIST_AGGREGATORS_CalculateCount = null;
this.RES_LIST_AGGREGATORS_CalculateSum = null;
this.RES_LIST_TOOLBAR_CommonActions = null;
this.RES_LIST_MoveColumnLeft_Tooltip = null;
this.RES_LIST_MoveColumnRight_Tooltip = null;
this.RES_LIST_PREFERENCES_Order = null;
this.RES_LIST_FILTERS_False = null;
this.RES_LIST_FILTERS_True = null;
this.RES_LIST_FILTERS_Apply = null;
this.RES_LIST_FILTERS_ClearAll = null;
this.RES_LIST_RequiredFiltersMissingMessage = null;
this.RES_LIST_RequiredFiltersMissingTitle = null;
this.RES_LIST_FILTERS_ApplyQuickFilter = null;
this.RES_LIST_FILTERS_ClearQuickFilter = null;
this.RES_LISTFORM_SubGroups = null;
this.RES_LIST_EXPORT_FileName = null;
this.RES_LIST_EXPORT_PortraitOrientation = null;
this.RES_LIST_EXPORT_SumHeader = null;
this.RES_LIST_EXPORT_AverageHeader = null;
this.RES_LIST_EXPORT_Visible = null;
this.RES_LIST_EXPORT_Column = null;
this.RES_LIST_EXPORT_DisplayColumns = null;
this.RES_LIST_EXPORT_GroupColor = null;
this.RES_LIST_EXPORT_HeaderColor = null;
this.RES_LIST_EXPORT_EvenColor = null;
this.RES_LIST_EXPORT_OddColor = null;
this.RES_LIST_EXPORT_AggregateColor = null;
this.RES_LIST_EXPORT_CountHeader = null;
this.RES_LIST_EXPORT_TotalNonGroupCount = null;
this.RES_LISTFORM_GetGroupsClosed = null;
this.RES_LISTFORM_DisplayGroupItems = null;
this.RES_LISTFORM_PrevStateTooltip = null;
this.RES_LIST_AGGREGATORS_ClearAll = null;
this.RES_LIST_Import_DownloadCsv = null;
this.RES_LIST_Import_Import = null;
this.RES_LIST_Import_UploadCsv = null;
this.RES_LIST_Import_ErrorDescription = null;
this.RES_LIST_Import_ErrorDetails = null;
this.RES_LIST_Import_ErrorMessage = null;
this.RES_LIST_Import_FailedRecords = null;
this.RES_LIST_Import_ImportedRecords = null;
this.RES_LIST_Import_ImportResults = null;
this.RES_LIST_Import_RowNumber = null;
this.RES_LIST_Import_PreferedEncoding = null;
this.RES_LIST_VIEWS_Delete = null;
this.RES_LIST_TOOLBAR_ResetConfrmation = null;
this.RES_LIST_VIEWS_MakeDefaultConfirmation = null;
this.RES_LIST_VIEWS_Overwrite = null;
this.RES_LIST_VIEWS_NameAlert = null;
this.RES_LIST_DeselectAllRecordsPromptText = null;
this.RES_LIST_DeselectAllPageRecordsText = null;
this.RES_DATALIST_Actions = null;
this.RES_DATALIST_SelectionActions = null;
this.RES_DATALIST_ClearSearch = null;
this.RES_DATALIST_Search = null;
this.RES_DATALIST_NoResults = null;
this.RES_DATALIST_Info = null;
this.RES_DATALIST_PageSize = null;
this.RES_DATALIST_Records = null;
this.RES_DATALIST_All = null;
this.RES_DATALIST_Loading = null;
this.RES_DATALIST_Processing = null;
this.RES_DATALIST_PAGER_First = null;
this.RES_DATALIST_PAGER_Last = null;
this.RES_DATALIST_PAGER_Next = null;
this.RES_DATALIST_PAGER_Previous = null;
this.RES_DATALIST_FILTERS_True = null;
this.RES_DATALIST_FILTERS_False = null;
this.RES_DATALIST_AGGREGATORS_CalculateAverage = null;
this.RES_DATALIST_AGGREGATORS_CalculateCount = null;
this.RES_DATALIST_AGGREGATORS_CalculateSum = null;
this.RES_DATALIST_BUTTONS_ColumnVisibility = null;
this.RES_DATALIST_BUTTONS_Copy = null;
this.RES_DATALIST_BUTTONS_CopyTitle = null;
this.RES_DATALIST_BUTTONS_CopySuccessSingle = null;
this.RES_DATALIST_BUTTONS_CopySuccessMulti = null;
this.RES_DATALIST_AGGREGATORS_GrandAverage = null;
this.RES_DATALIST_AGGREGATORS_GrandCount = null;
this.RES_DATALIST_AGGREGATORS_GrandTotal = null;
this.RES_DATALIST_AGGREGATORS_PageAverage = null;
this.RES_DATALIST_AGGREGATORS_PageCount = null;
this.RES_DATALIST_AGGREGATORS_PageTotal = null;
this.RES_DATALIST_SelectAllRecordsText = null;
this.RES_DATALIST_SelectAllPageRecordsText = null;
this.RES_DATALIST_DeselectAllRecordsText = null;
this.RES_DATALIST_DeselectAllPageRecordsText = null;
this.RES_DATALIST_ExportTitle = null;
this.RES_DATALIST_OneRowSelected = null;
this.RES_DATALIST_MulitpleRowsSelected = null;
this.RES_DATALIST_EXPORT_DialogTitle = null;
this.RES_DATALIST_EXPORT_ExportRange_CurrentPage = null;
this.RES_DATALIST_EXPORT_ExportRange_Top100Pages = null;
this.RES_DATALIST_EXPORT_ExportRange_AllPages_10000_Restriction = null;
this.RES_DATALIST_EXPORT_ExportRange_AllPages = null;
this.RES_DATALIST_EXPORT_PrintRange = null;
this.RES_DATALIST_EXPORT_IncludeGridLines = null;
this.RES_DATALIST_EXPORT_ExportOK = null;
this.RES_DATALIST_EXPORT_ExportCancel = null;
this.RES_DATALIST_EXPORT_ExportOnlyGroups = null;
this.RES_DATALIST_EXPORT_ExportTo = null;
this.RES_DATALIST_EXPORT_FileName = null;
this.RES_DATALIST_EXPORT_PortraitOrientation = null;
this.RES_DATALIST_EXPORT_SumHeader = null;
this.RES_DATALIST_EXPORT_AverageHeader = null;
this.RES_DATALIST_EXPORT_Visible = null;
this.RES_DATALIST_EXPORT_Column = null;
this.RES_DATALIST_EXPORT_DisplayColumns = null;
this.RES_DATALIST_EXPORT_GroupColor = null;
this.RES_DATALIST_EXPORT_HeaderColor = null;
this.RES_DATALIST_EXPORT_EvenColor = null;
this.RES_DATALIST_EXPORT_OddColor = null;
this.RES_DATALIST_EXPORT_AggregateColor = null;
this.RES_DATALIST_EXPORT_CountHeader = null;
this.RES_DATALIST_EXPORT_TotalNonGroupCount = null;
this.RES_DATALIST_Grouping = null;
this.RES_DATALIST_GroupingOrder = null;
this.RES_DATALIST_Column = null;
this.RES_DATALIST_GetGroupsClosed = null;
this.RES_DATALIST_MergedGroupLevels = null;
this.RES_DATALIST_VIEWS_ViewName = null;
this.RES_DATALIST_VIEWS_View = null;
this.RES_DATALIST_VIEWS_Views = null;
this.RES_DATALIST_VIEWS_DeleteView = null;
this.RES_DATALIST_VIEWS_SaveView = null;
this.RES_DATALIST_VIEWS_InvalidViewName = null;
this.RES_DATALIST_VIEWS_IsViewDefault = null;
this.RES_DATALIST_VIEWS_DeleteConfirmation = null;
this.RES_DATALIST_QuickFilters = null;
this.RES_DATALIST_Import = null;
this.RES_DATALIST_DownloadImportTemplate = null;
this.RES_DATALIST_UploadImportFile = null;
this.RES_DATALIST_ImportDataEncoding = null;
this.RES_DATALIST_ImportResult = null;
this.RES_DATALIST_ImportResultSuccess = null;
this.RES_DATALIST_ImportResultErrors = null;
this.RES_DATALIST_ImportResultErrorList = null;
this.RES_DATALIST_MaxSelectedRowsLimitationTitle = null;
this.RES_DATALIST_MaxSelectedRowsLimitationMessage = null;
this.RES_DATALIST_Refresh = null;
this.RES_DATALIST_Reset = null;
this.RES_DATALIST_FILTERS_Ok = null;
this.RES_DATALIST_FILTERS_Cancel = null;
this.RES_DATALIST_FILTERS_ClearAll = null;
this.RES_DATALIST_FILTERS_RequiredFiltersMissingMessage = null;
this.RES_DATALIST_FILTERS_DialogTitle = null;
this.RES_DATALIST_FILTERS_AddFilter = null;
this.RES_DATALIST_FILTERS_Operators_Header = null;
this.RES_DATALIST_FILTERS_FilterValue_Header = null;
this.RES_DATALIST_FILTERS_RowOperator_Header = null;
this.RES_DATALIST_FILTERS_RowOperatorTypes_Or = null;
this.RES_DATALIST_FILTERS_RowOperatorTypes_And = null;
this.RES_DATALIST_FILTERS_Operators_HasNoValue = null;
this.RES_DATALIST_FILTERS_Operators_HasValue = null;
this.RES_DATALIST_FILTERS_Operators_Range = null;
this.RES_DATALIST_FILTERS_Operators_EqualTo = null;
this.RES_DATALIST_FILTERS_Operators_Like = null;
this.RES_DATALIST_FILTERS_Operators_NotEqualTo = null;
this.RES_DATALIST_FILTERS_Operators_GreaterThan = null;
this.RES_DATALIST_FILTERS_Operators_GreaterThanOrEqualTo = null;
this.RES_DATALIST_FILTERS_Operators_LessThan = null;
this.RES_DATALIST_FILTERS_Operators_LessThanOrEqualTo = null;
this.RES_DATALIST_VIEWS_ActiveView = null;
this.RES_DATALIST_EXPORTV2_Totals = null;
this.RES_DATALIST_EXPORTV2_Aggregator_Count = null;
this.RES_DATALIST_EXPORTV2_Aggregator_Sum = null;
this.RES_DATALIST_EXPORTV2_Aggregator_Average = null;
this.RES_DATALIST_EXPORTV2_Aggregator_TotalCount = null;
this.RES_DATALIST_EXPORTV2_Aggregator_TotalSum = null;
this.RES_DATALIST_EXPORTV2_Aggregator_TotalAverage = null;
this.RES_WEBFORM_PAGER_Records = null;
this.RES_WEBFORM_PAGER_To = null;
this.RES_WEBFORM_PAGER_FirstPage = null;
this.RES_WEBFORM_PAGER_PreviousPage = null;
this.RES_WEBFORM_PAGER_NextPage = null;
this.RES_WEBFORM_PAGER_LastPage = null;
this.RES_WEBFORM_PAGER_TotalRecords = null;
this.RES_WEBFORM_PAGER_RecordsPerPage = null;
this.RES_WEBFORM_GRID_AddRow = null;
this.RES_WEBFORM_GRID_DeleteRow = null;
this.RES_WEBFORM_GRID_NoData = null;
this.RES_WEBFORM_GRID_NoMatches = null;
this.RES_WEBFORM_GRID_NoMoreMatches = null;
this.RES_WEBFORM_GRID_Result = null;
this.RES_WEBFORM_GRID_From = null;
this.RES_WEBFORM_FIELD_Required = null;
this.RES_WEBFORM_FIELD_RequiredMissing = null;
this.RES_WEBFORM_ERROR_ErrorTitle = null;
this.RES_WEBFORM_SUCCESS_SuccessTitle = null;
this.RES_WEBFORM_SUCCESS_SuccessMessage = null;
this.RES_WEBFORM_DirtyMessage = null;
this.RES_WEBFORM_NoAction = null;
this.RES_WEBFORM_NotAuthorizedAction = null;
this.RES_WEBFORM_FieldOverflow = null;
this.RES_WEBFORM_UNAUTHORIZED_PageTitle = null;
this.RES_WEBFORM_DeleteFailedDueToIntegrity = null;
this.RES_WEBFORM_ObjectWithIdNotFound = null;
this.RES_WEBFORM_ObjectNotFound = null;
this.RES_WEBFORM_QuickFilters = null;
this.RES_WEBFORM_NoRecordsFound_MessageTitle = null;
this.RES_WEBFORM_FIELD_RequiredMissingTitle = null;
this.RES_WEBFORM_FIELD_InvalidBindingsTitle = null;
this.RES_WEBFORM_GenericErrorTitle = null;
this.RES_WEBFORM_GenericErrorDescription = null;
this.RES_WEBFORM_GenericConfirmationQuestion = null;
this.RES_WEBFORM_GenericFormatError = null;
this.RES_WEBFORM_dv_Title = null;
this.RES_CHECKBOX_FALSE = null;
this.RES_CHECKBOX_TRUE = null;
this.RES_WEBFORM_VALIDATIONS_Error = null;
this.RES_WEBFORM_VALIDATIONS_Ignore = null;
this.RES_WEBFORM_VALIDATIONS_IgnoreAll = null;
this.RES_WEBFORM_VALIDATIONS_Information = null;
this.RES_WEBFORM_VALIDATIONS_Messages = null;
this.RES_WEBFORM_VALIDATIONS_RequireYourAttention = null;
this.RES_WEBFORM_VALIDATIONS_ShowIgnoredWarnings = null;
this.RES_WEBFORM_VALIDATIONS_Warning = null;
this.RES_WEBFORM_VALIDATIONS_Cancel = null;
this.RES_WEBFORM_VALIDATIONS_Proceed = null;
this.RES_WEBFORM_VALIDATIONS_RequireYourAttentionSingular = null;
this.RES_WEBFORM_VALIDATIONS_WarningConfirmation = null;
this.RES_WEBFORM_REQUIRED_Legend = null;
this.RES_WEBFORM_FileUploadTitle = null;
this.RES_WEBFORM_FileUploadSuccess = null;
this.RES_WEBFORM_FileUploadError = null;
this.RES_WEBFORM_MaxFileSizeExceededError = null;
this.RES_WEBFORM_NotAllowedFileExtensionError = null;
this.RES_WEBFORM_FileDragAndDropMessage = null;
this.RES_WEBFORM_FileNotFound = null;
this.RES_WEBFORM_FileUploadLoading = null;
this.RES_WEBFORM_LoadingMessage = null;
this.RES_WEBFORM_Picklist_ClearAllConfirmation = null;
this.RES_AUTH_ERROR_TITLE = null;
this.RES_AUTH_ERROR_SUBTITLE = null;
this.RES_AUTH_ERROR_FOOTER = null;
this.RES_AUTH_NOT_CREATED = null;
this.RES_SECURITY_ERROR_TITLE = null;
this.RES_SECURITY_ERROR_SUBTITLE = null;
this.RES_SECURITY_ERROR_FOOTER = null;
this.RES_WEBFORM_FileNameInvalidError = null;
this.RES_MENU_SEARCH_MOST_USED = null;
this.RES_MENU_SEARCH_FAVORITES = null;
this.RES_MENU_SEARCH_SEARCH_RESULTS = null;
this.RES_MENU_SEARCH_INDEX_RESULTS = null;
}

_key: any;
RES_SITE_ApplicationTitle:any;
RES_SITE_ApplicationDescription:any;
RES_SITE_WindowTitle:any;
RES_SITE_PREFS_CancelButtonText:any;
RES_SITE_PREFS_OKButtonText:any;
RES_SITE_Changesnotsaved:any;
RES_SITE_SessionExpiredErrorMessage:any;
RES_SITE_SessionRefreshMessage:any;
RES_SITE_SessionRefreshedMessage:any;
RES_SITE_ExitConfirmation:any;
RES_SITE_ExitConfirmationMessage:any;
RES_SITE_AreYouSure:any;
RES_SITE_Confirm:any;
RES_SITE_CloseChildList:any;
RES_SITE_Exit:any;
RES_SITE_NullValue:any;
RES_SITE_POPUP_BLOCKED_TITLE:any;
RES_SITE_POPUP_BLOCKED_MESSAGE:any;
RES_SITE_StaleObjectStateExceptionTitle:any;
RES_SITE_StaleObjectStateExceptionMessage:any;
RES_CONTROLLER_ACTION_TimeoutErrorTitle:any;
RES_CONTROLLER_ACTION_TimeoutErrorMessage:any;
RES_CONTROLLER_ACTION_AbortErrorTitle:any;
RES_CONTROLLER_ACTION_AbortErrorMessage:any;
RES_CONTROLLER_ACTION_GenericErrorTitle:any;
RES_CONTROLLER_ACTION_GenericErrorMessage:any;
RES_CONTROLLER_ACTION_ParserErrorTitle:any;
RES_CONTROLLER_ACTION_ParserErrorMessage:any;
RES_BO_PrimaryKeyNotSet:any;
RES_BO_CannotDeleteNewObject:any;
RES_BO_UpdateFailedDueToConcurrency:any;
RES_BO_DeleteFailedDueToConcurrency:any;
RES_BO_Overflow:any;
RES_BO_InvalidPassword:any;
RES_BO_InvalidPassword_CapitalLettersRequired:any;
RES_BO_InvalidPassword_MinPasswordLength:any;
RES_BO_InvalidPassword_MinRequiredNumericDigits:any;
RES_BO_InvalidPassword_MinRequiredNonAlphanumeric:any;
RES_BO_NewPass_And_OldPass_MustNotMatch:any;
RES_BO_BR_InvalidEmail:any;
RES_LIST_ERROR_ErrorTitle:any;
RES_LIST_ERROR_CannotRemoveLastView:any;
RES_LIST_TOOLBAR_ExportButtonTooltip:any;
RES_LIST_TOOLBAR_FilterButtonTooltip:any;
RES_LIST_TOOLBAR_PreferencesButtonTooltip:any;
RES_LIST_TOOLBAR_RefreshButtonTooltip:any;
RES_LIST_TOOLBAR_RefreshResetButtonTooltip:any;
RES_LIST_PAGER_PageSize:any;
RES_LIST_PAGER_Page:any;
RES_LIST_PAGER_Of:any;
RES_LIST_PAGER_FirstPage:any;
RES_LIST_PAGER_PreviousPage:any;
RES_LIST_PAGER_NextPage:any;
RES_LIST_PAGER_LastPage:any;
RES_LIST_PAGER_TotalRecords:any;
RES_LIST_AGGREGATE_Summation:any;
RES_LIST_AGGREGATE_Average:any;
RES_LIST_AGGREGATE_Count:any;
RES_LIST_PAGER_PageAggregateTooltip:any;
RES_LIST_PAGER_GrandAggregateTooltip:any;
RES_LIST_EXPORT_DialogTitle:any;
RES_LIST_EXPORT_ExportRange_CurrentPage:any;
RES_LIST_EXPORT_ExportRange_Top100Pages:any;
RES_LIST_EXPORT_ExportRange_AllPages_10000_Restriction:any;
RES_LIST_EXPORT_ExportRange_AllPages:any;
RES_LIST_EXPORT_PrintRange:any;
RES_LIST_EXPORT_IncludeGridLines:any;
RES_LIST_EXPORT_ExportOK:any;
RES_LIST_EXPORT_ExportCancel:any;
RES_LIST_FILTERS_DialogTitle:any;
RES_LIST_FILTERS_RowOperator_Header:any;
RES_LIST_FILTERS_RowOperatorTypes_None:any;
RES_LIST_FILTERS_RowOperatorTypes_And:any;
RES_LIST_FILTERS_RowOperatorTypes_Or:any;
RES_LIST_FILTERS_Column_Header:any;
RES_LIST_FILTERS_Column_None:any;
RES_LIST_FILTERS_Operators_Header:any;
RES_LIST_FILTERS_Operators_None:any;
RES_LIST_FILTERS_Operators_EqualTo:any;
RES_LIST_FILTERS_Operators_NotEqualTo:any;
RES_LIST_FILTERS_Operators_LessThan:any;
RES_LIST_FILTERS_Operators_LessThanOrEqualTo:any;
RES_LIST_FILTERS_Operators_GreaterThan:any;
RES_LIST_FILTERS_Operators_GreaterThanOrEqualTo:any;
RES_LIST_FILTERS_Operators_Like:any;
RES_LIST_FILTERS_Operators_IsNull:any;
RES_LIST_FILTERS_Operators_NotNull:any;
RES_LIST_FILTERS_Operators_HasΝοValue:any;
RES_LIST_FILTERS_SelectAll:any;
RES_LIST_FILTERS_FilterValue_Header:any;
RES_LIST_FILTERS_AddFilter:any;
RES_LIST_FILTERS_FilterOK:any;
RES_LIST_FILTERS_FilterCancel:any;
RES_LIST_PREFERENCES_DialogTitle:any;
RES_LIST_PREFERENCES_Column_Header:any;
RES_LIST_PREFERENCES_Visible:any;
RES_LIST_PREFERENCES_Sorting:any;
RES_LIST_PREFERENCES_SortingType_None:any;
RES_LIST_PREFERENCES_SortingType_ASC:any;
RES_LIST_PREFERENCES_SortingType_DESC:any;
RES_LIST_PREFERENCES_SortOrder:any;
RES_LIST_PREFERENCES_MoveUp:any;
RES_LIST_PREFERENCES_MoveDown:any;
RES_LIST_PREFERENCES_PreferencesOK:any;
RES_LIST_PREFERENCES_PreferencesCancel:any;
RES_LIST_SelectCheckBox_Tooltip:any;
RES_LIST_SelectAllCheckBox_Tooltip:any;
RES_LIST_TOOLBAR_MoreActionsButton_Text:any;
RES_LISTFORM_SUCCESS_SuccessMessage:any;
RES_LISTFORM_SUCCESS_SuccessTitle:any;
RES_LIST_PAGER_SelectedRecords:any;
RES_LISTFORM_NORESULTS_NoResults:any;
RES_LIST_ERROR_UseOnlyEqualLike:any;
RES_LIST_ERROR_MultipleNavigationActions:any;
RES_LIST_ShowOnlyGroups_Expand:any;
RES_LIST_ShowOnlyGroups_Collapse:any;
RES_LIST_FILTERS_NoFilterDefined:any;
RES_LIST_EXPORT_ExportOnlyGroups:any;
RES_LIST_EXPORT_ExportTo:any;
RES_LIST_VIEWS_RemovePrompt:any;
RES_LIST_VIEWS_SaveHeader:any;
RES_LIST_VIEWS_Name:any;
RES_LIST_VIEWS_Ok:any;
RES_LIST_VIEWS_Cancel:any;
RES_LIST_VIEWS_MakeDefault:any;
RES_LIST_VIEWS_Remove:any;
RES_LIST_VIEWS_Save:any;
RES_LIST_PAGER_Items:any;
RES_LIST_PAGER_PerPage:any;
RES_LIST_PAGE_Pages:any;
RES_LIST_PREFERENCES_Rearrange:any;
RES_LIST_TOOLBAR_HideQuickFilters:any;
RES_LIST_TOOLBAR_PredefinedView:any;
RES_LIST_TOOLBAR_Search:any;
RES_LIST_TOOLBAR_ShowQuickFilters:any;
RES_LIST_VIEWS_IsDefault:any;
RES_LISTFORM_FiltersApplied:any;
RES_LIST_AGGREGATORS_GrandAverage:any;
RES_LIST_AGGREGATORS_GrandCount:any;
RES_LIST_AGGREGATORS_GrandTotal:any;
RES_LIST_AGGREGATORS_PageAverage:any;
RES_LIST_AGGREGATORS_PageCount:any;
RES_LIST_AGGREGATORS_PageTotal:any;
RES_LIST_TOOLBAR_GroupingOrder:any;
RES_LIST_TOOLBAR_Page:any;
RES_LIST_SortASC_Tooltip:any;
RES_LIST_SortDESC_Tooltip:any;
RES_LIST_UnSort_Tooltip:any;
RES_LIST_FILTERS_Operators_Range:any;
RES_LIST_FILTERS_Operators_HasNoValue:any;
RES_LIST_FILTERS_Operators_HasValue:any;
RES_LIST_AGGREGATORS_CalculateAverage:any;
RES_LIST_AGGREGATORS_CalculateCount:any;
RES_LIST_AGGREGATORS_CalculateSum:any;
RES_LIST_TOOLBAR_CommonActions:any;
RES_LIST_MoveColumnLeft_Tooltip:any;
RES_LIST_MoveColumnRight_Tooltip:any;
RES_LIST_PREFERENCES_Order:any;
RES_LIST_FILTERS_False:any;
RES_LIST_FILTERS_True:any;
RES_LIST_FILTERS_Apply:any;
RES_LIST_FILTERS_ClearAll:any;
RES_LIST_RequiredFiltersMissingMessage:any;
RES_LIST_RequiredFiltersMissingTitle:any;
RES_LIST_FILTERS_ApplyQuickFilter:any;
RES_LIST_FILTERS_ClearQuickFilter:any;
RES_LISTFORM_SubGroups:any;
RES_LIST_EXPORT_FileName:any;
RES_LIST_EXPORT_PortraitOrientation:any;
RES_LIST_EXPORT_SumHeader:any;
RES_LIST_EXPORT_AverageHeader:any;
RES_LIST_EXPORT_Visible:any;
RES_LIST_EXPORT_Column:any;
RES_LIST_EXPORT_DisplayColumns:any;
RES_LIST_EXPORT_GroupColor:any;
RES_LIST_EXPORT_HeaderColor:any;
RES_LIST_EXPORT_EvenColor:any;
RES_LIST_EXPORT_OddColor:any;
RES_LIST_EXPORT_AggregateColor:any;
RES_LIST_EXPORT_CountHeader:any;
RES_LIST_EXPORT_TotalNonGroupCount:any;
RES_LISTFORM_GetGroupsClosed:any;
RES_LISTFORM_DisplayGroupItems:any;
RES_LISTFORM_PrevStateTooltip:any;
RES_LIST_AGGREGATORS_ClearAll:any;
RES_LIST_Import_DownloadCsv:any;
RES_LIST_Import_Import:any;
RES_LIST_Import_UploadCsv:any;
RES_LIST_Import_ErrorDescription:any;
RES_LIST_Import_ErrorDetails:any;
RES_LIST_Import_ErrorMessage:any;
RES_LIST_Import_FailedRecords:any;
RES_LIST_Import_ImportedRecords:any;
RES_LIST_Import_ImportResults:any;
RES_LIST_Import_RowNumber:any;
RES_LIST_Import_PreferedEncoding:any;
RES_LIST_VIEWS_Delete:any;
RES_LIST_TOOLBAR_ResetConfrmation:any;
RES_LIST_VIEWS_MakeDefaultConfirmation:any;
RES_LIST_VIEWS_Overwrite:any;
RES_LIST_VIEWS_NameAlert:any;
RES_LIST_DeselectAllRecordsPromptText:any;
RES_LIST_DeselectAllPageRecordsText:any;
RES_DATALIST_Actions:any;
RES_DATALIST_SelectionActions:any;
RES_DATALIST_ClearSearch:any;
RES_DATALIST_Search:any;
RES_DATALIST_NoResults:any;
RES_DATALIST_Info:any;
RES_DATALIST_PageSize:any;
RES_DATALIST_Records:any;
RES_DATALIST_All:any;
RES_DATALIST_Loading:any;
RES_DATALIST_Processing:any;
RES_DATALIST_PAGER_First:any;
RES_DATALIST_PAGER_Last:any;
RES_DATALIST_PAGER_Next:any;
RES_DATALIST_PAGER_Previous:any;
RES_DATALIST_FILTERS_True:any;
RES_DATALIST_FILTERS_False:any;
RES_DATALIST_AGGREGATORS_CalculateAverage:any;
RES_DATALIST_AGGREGATORS_CalculateCount:any;
RES_DATALIST_AGGREGATORS_CalculateSum:any;
RES_DATALIST_BUTTONS_ColumnVisibility:any;
RES_DATALIST_BUTTONS_Copy:any;
RES_DATALIST_BUTTONS_CopyTitle:any;
RES_DATALIST_BUTTONS_CopySuccessSingle:any;
RES_DATALIST_BUTTONS_CopySuccessMulti:any;
RES_DATALIST_AGGREGATORS_GrandAverage:any;
RES_DATALIST_AGGREGATORS_GrandCount:any;
RES_DATALIST_AGGREGATORS_GrandTotal:any;
RES_DATALIST_AGGREGATORS_PageAverage:any;
RES_DATALIST_AGGREGATORS_PageCount:any;
RES_DATALIST_AGGREGATORS_PageTotal:any;
RES_DATALIST_SelectAllRecordsText:any;
RES_DATALIST_SelectAllPageRecordsText:any;
RES_DATALIST_DeselectAllRecordsText:any;
RES_DATALIST_DeselectAllPageRecordsText:any;
RES_DATALIST_ExportTitle:any;
RES_DATALIST_OneRowSelected:any;
RES_DATALIST_MulitpleRowsSelected:any;
RES_DATALIST_EXPORT_DialogTitle:any;
RES_DATALIST_EXPORT_ExportRange_CurrentPage:any;
RES_DATALIST_EXPORT_ExportRange_Top100Pages:any;
RES_DATALIST_EXPORT_ExportRange_AllPages_10000_Restriction:any;
RES_DATALIST_EXPORT_ExportRange_AllPages:any;
RES_DATALIST_EXPORT_PrintRange:any;
RES_DATALIST_EXPORT_IncludeGridLines:any;
RES_DATALIST_EXPORT_ExportOK:any;
RES_DATALIST_EXPORT_ExportCancel:any;
RES_DATALIST_EXPORT_ExportOnlyGroups:any;
RES_DATALIST_EXPORT_ExportTo:any;
RES_DATALIST_EXPORT_FileName:any;
RES_DATALIST_EXPORT_PortraitOrientation:any;
RES_DATALIST_EXPORT_SumHeader:any;
RES_DATALIST_EXPORT_AverageHeader:any;
RES_DATALIST_EXPORT_Visible:any;
RES_DATALIST_EXPORT_Column:any;
RES_DATALIST_EXPORT_DisplayColumns:any;
RES_DATALIST_EXPORT_GroupColor:any;
RES_DATALIST_EXPORT_HeaderColor:any;
RES_DATALIST_EXPORT_EvenColor:any;
RES_DATALIST_EXPORT_OddColor:any;
RES_DATALIST_EXPORT_AggregateColor:any;
RES_DATALIST_EXPORT_CountHeader:any;
RES_DATALIST_EXPORT_TotalNonGroupCount:any;
RES_DATALIST_Grouping:any;
RES_DATALIST_GroupingOrder:any;
RES_DATALIST_Column:any;
RES_DATALIST_GetGroupsClosed:any;
RES_DATALIST_MergedGroupLevels:any;
RES_DATALIST_VIEWS_ViewName:any;
RES_DATALIST_VIEWS_View:any;
RES_DATALIST_VIEWS_Views:any;
RES_DATALIST_VIEWS_DeleteView:any;
RES_DATALIST_VIEWS_SaveView:any;
RES_DATALIST_VIEWS_InvalidViewName:any;
RES_DATALIST_VIEWS_IsViewDefault:any;
RES_DATALIST_VIEWS_DeleteConfirmation:any;
RES_DATALIST_QuickFilters:any;
RES_DATALIST_Import:any;
RES_DATALIST_DownloadImportTemplate:any;
RES_DATALIST_UploadImportFile:any;
RES_DATALIST_ImportDataEncoding:any;
RES_DATALIST_ImportResult:any;
RES_DATALIST_ImportResultSuccess:any;
RES_DATALIST_ImportResultErrors:any;
RES_DATALIST_ImportResultErrorList:any;
RES_DATALIST_MaxSelectedRowsLimitationTitle:any;
RES_DATALIST_MaxSelectedRowsLimitationMessage:any;
RES_DATALIST_Refresh:any;
RES_DATALIST_Reset:any;
RES_DATALIST_FILTERS_Ok:any;
RES_DATALIST_FILTERS_Cancel:any;
RES_DATALIST_FILTERS_ClearAll:any;
RES_DATALIST_FILTERS_RequiredFiltersMissingMessage:any;
RES_DATALIST_FILTERS_DialogTitle:any;
RES_DATALIST_FILTERS_AddFilter:any;
RES_DATALIST_FILTERS_Operators_Header:any;
RES_DATALIST_FILTERS_FilterValue_Header:any;
RES_DATALIST_FILTERS_RowOperator_Header:any;
RES_DATALIST_FILTERS_RowOperatorTypes_Or:any;
RES_DATALIST_FILTERS_RowOperatorTypes_And:any;
RES_DATALIST_FILTERS_Operators_HasNoValue:any;
RES_DATALIST_FILTERS_Operators_HasValue:any;
RES_DATALIST_FILTERS_Operators_Range:any;
RES_DATALIST_FILTERS_Operators_EqualTo:any;
RES_DATALIST_FILTERS_Operators_Like:any;
RES_DATALIST_FILTERS_Operators_NotEqualTo:any;
RES_DATALIST_FILTERS_Operators_GreaterThan:any;
RES_DATALIST_FILTERS_Operators_GreaterThanOrEqualTo:any;
RES_DATALIST_FILTERS_Operators_LessThan:any;
RES_DATALIST_FILTERS_Operators_LessThanOrEqualTo:any;
RES_DATALIST_VIEWS_ActiveView:any;
RES_DATALIST_EXPORTV2_Totals:any;
RES_DATALIST_EXPORTV2_Aggregator_Count:any;
RES_DATALIST_EXPORTV2_Aggregator_Sum:any;
RES_DATALIST_EXPORTV2_Aggregator_Average:any;
RES_DATALIST_EXPORTV2_Aggregator_TotalCount:any;
RES_DATALIST_EXPORTV2_Aggregator_TotalSum:any;
RES_DATALIST_EXPORTV2_Aggregator_TotalAverage:any;
RES_WEBFORM_PAGER_Records:any;
RES_WEBFORM_PAGER_To:any;
RES_WEBFORM_PAGER_FirstPage:any;
RES_WEBFORM_PAGER_PreviousPage:any;
RES_WEBFORM_PAGER_NextPage:any;
RES_WEBFORM_PAGER_LastPage:any;
RES_WEBFORM_PAGER_TotalRecords:any;
RES_WEBFORM_PAGER_RecordsPerPage:any;
RES_WEBFORM_GRID_AddRow:any;
RES_WEBFORM_GRID_DeleteRow:any;
RES_WEBFORM_GRID_NoData:any;
RES_WEBFORM_GRID_NoMatches:any;
RES_WEBFORM_GRID_NoMoreMatches:any;
RES_WEBFORM_GRID_Result:any;
RES_WEBFORM_GRID_From:any;
RES_WEBFORM_FIELD_Required:any;
RES_WEBFORM_FIELD_RequiredMissing:any;
RES_WEBFORM_ERROR_ErrorTitle:any;
RES_WEBFORM_SUCCESS_SuccessTitle:any;
RES_WEBFORM_SUCCESS_SuccessMessage:any;
RES_WEBFORM_DirtyMessage:any;
RES_WEBFORM_NoAction:any;
RES_WEBFORM_NotAuthorizedAction:any;
RES_WEBFORM_FieldOverflow:any;
RES_WEBFORM_UNAUTHORIZED_PageTitle:any;
RES_WEBFORM_DeleteFailedDueToIntegrity:any;
RES_WEBFORM_ObjectWithIdNotFound:any;
RES_WEBFORM_ObjectNotFound:any;
RES_WEBFORM_QuickFilters:any;
RES_WEBFORM_NoRecordsFound_MessageTitle:any;
RES_WEBFORM_FIELD_RequiredMissingTitle:any;
RES_WEBFORM_FIELD_InvalidBindingsTitle:any;
RES_WEBFORM_GenericErrorTitle:any;
RES_WEBFORM_GenericErrorDescription:any;
RES_WEBFORM_GenericConfirmationQuestion:any;
RES_WEBFORM_GenericFormatError:any;
RES_WEBFORM_dv_Title:any;
RES_CHECKBOX_FALSE:any;
RES_CHECKBOX_TRUE:any;
RES_WEBFORM_VALIDATIONS_Error:any;
RES_WEBFORM_VALIDATIONS_Ignore:any;
RES_WEBFORM_VALIDATIONS_IgnoreAll:any;
RES_WEBFORM_VALIDATIONS_Information:any;
RES_WEBFORM_VALIDATIONS_Messages:any;
RES_WEBFORM_VALIDATIONS_RequireYourAttention:any;
RES_WEBFORM_VALIDATIONS_ShowIgnoredWarnings:any;
RES_WEBFORM_VALIDATIONS_Warning:any;
RES_WEBFORM_VALIDATIONS_Cancel:any;
RES_WEBFORM_VALIDATIONS_Proceed:any;
RES_WEBFORM_VALIDATIONS_RequireYourAttentionSingular:any;
RES_WEBFORM_VALIDATIONS_WarningConfirmation:any;
RES_WEBFORM_REQUIRED_Legend:any;
RES_WEBFORM_FileUploadTitle:any;
RES_WEBFORM_FileUploadSuccess:any;
RES_WEBFORM_FileUploadError:any;
RES_WEBFORM_MaxFileSizeExceededError:any;
RES_WEBFORM_NotAllowedFileExtensionError:any;
RES_WEBFORM_FileDragAndDropMessage:any;
RES_WEBFORM_FileNotFound:any;
RES_WEBFORM_FileUploadLoading:any;
RES_WEBFORM_LoadingMessage:any;
RES_WEBFORM_Picklist_ClearAllConfirmation:any;
RES_AUTH_ERROR_TITLE:any;
RES_AUTH_ERROR_SUBTITLE:any;
RES_AUTH_ERROR_FOOTER:any;
RES_AUTH_NOT_CREATED:any;
RES_SECURITY_ERROR_TITLE:any;
RES_SECURITY_ERROR_SUBTITLE:any;
RES_SECURITY_ERROR_FOOTER:any;
RES_WEBFORM_FileNameInvalidError:any;
RES_MENU_SEARCH_MOST_USED:any;
RES_MENU_SEARCH_FAVORITES:any;
RES_MENU_SEARCH_SEARCH_RESULTS:any;
RES_MENU_SEARCH_INDEX_RESULTS:any;




}