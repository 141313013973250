import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'zapp-color-picker',
  templateUrl: './ColorPicker.component.html',
  styleUrls: ['./ColorPicker.component.less']
})
export class zAppDevColorPickerComponent implements OnInit {

  constructor() { }

  @Input() color: string;
  @Input() displayInput: boolean = false;
  @Output() colorChange = new EventEmitter<string>();

  ngOnInit(): void {
    this.color = this.color || '#000000';
  }

  onChange() {
    this.colorChange.emit(this.color);
  }

}
