import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class DynamiFieldGroupItemDTO  {
    _originalTypeClassName = "zPMO.BO.DynamiFieldGroupItemDTO";
constructor() {
this.Name = null;
this.Datatype = null;
this.FieldId = null;
this.BoolValue = false;
this.StringValue = null;
this.NumberValue = null;
this.DateValue = null;
this.DynamiFieldGroupItemDTOKey = null;
this._key = this.DynamiFieldGroupItemDTOKey = 0; 
}

_key: any;
Name:any;
Datatype:any;
FieldId:any;
BoolValue:any;
StringValue:any;
NumberValue:any;
DateValue:any;
private _DynamiFieldGroupItemDTOKey:any;
get DynamiFieldGroupItemDTOKey(): any {
    return this._DynamiFieldGroupItemDTOKey;
  }
set DynamiFieldGroupItemDTOKey(value: any) {
    this._DynamiFieldGroupItemDTOKey = value;
    this._key = value;
  }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.DynamiFieldGroupItemDTOKey == null || $this.DynamiFieldGroupItemDTOKey == 0;
            }


}