import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class InvoiceLine  {
    _originalTypeClassName = "zPMO.BO.InvoiceLine";
constructor() {
this.Id = null;
this.Quantity = 1;
this.Product = null;
this.Description = null;
this.TaxPercentage = null;
this.Tax = null;
this.Price = 0;
this.UnitPrice = null;
this.TotalPrice = null;
this.Discount = null;
this.DiscountPercent = null;
this.TotalNetDiscountedAmount = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Quantity:any;
Product:any;
Description:any;
TaxPercentage:any;
Tax:any;
Price:any;
UnitPrice:any;
TotalPrice:any;
Discount:any;
DiscountPercent:any;
TotalNetDiscountedAmount:any;

static RefreshTotalPrices($this: any): any{let discountFraction = (CLMS.Framework.Number.NotEqual(() => $this.DiscountPercent, () => null) && CLMS.Framework.Number.GreaterThan(() => $this.DiscountPercent, () => 0) ? Joove.Common.safeNumber($this?.DiscountPercent) / Joove.Common.safeNumber(100) : 0);
let vatFraction = (CLMS.Framework.Number.NotEqual(() => $this.TaxPercentage, () => null) && CLMS.Framework.Number.GreaterThan(() => $this.TaxPercentage, () => 0) ? Joove.Common.safeNumber($this?.TaxPercentage) / Joove.Common.safeNumber(100) : 0);
$this.Price = Joove.Common.safeNumber($this?.UnitPrice) * Joove.Common.safeNumber($this?.Quantity);
$this.TotalNetDiscountedAmount = Joove.Common.safeNumber($this?.Price) * Joove.Common.safeNumber((Joove.Common.safeNumber(1) - Joove.Common.safeNumber(discountFraction)));
$this.Tax = Joove.Common.safeNumber($this?.TotalNetDiscountedAmount) * Joove.Common.safeNumber(vatFraction);
$this.TotalPrice = Joove.Common.safeNumber($this?.TotalNetDiscountedAmount) + Joove.Common.safeNumber($this?.Tax);
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}