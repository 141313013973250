import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ExpenseRule  {
    _originalTypeClassName = "zPMO.BO.ExpenseRule";
constructor() {
this.Id = null;
this.Name = null;
this.ExpenseName = null;
this.Amount = null;
this.StartFrom = null;
this.RepeatMonth = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
ExpenseName:any;
Amount:any;
StartFrom:any;
RepeatMonth:any;
Category:any;
Company:any;
static setCategory = ($this, value) => {
var oldValue = $this.Category;
$this.Category = value;
if (value != null) {
}
}
static setCompany = ($this, value) => {
var oldValue = $this.Company;
$this.Company = value;
if (value != null) {
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}