import { OrderByInfo } from './order-by-info';
import { GroupByInfo } from './group-by-info';
import { FilterInfo } from './filter-info';

export class DatasourceRequest {
  startRow;
  pageSize: number;
  filters;
  orderBy: Array<OrderByInfo>;
  excludeKeys;
  groupBy: Array<GroupByInfo>;
  indexes: any[];
  aggregators;
  DtoProperties: Array<string>;

  constructor(// $control: JQuery,
    startRow,
    pageSize: number,
    filters?,
    orderBy?: Array<OrderByInfo>,
    excludeKeys?,
    groupBy?: Array<GroupByInfo>,
    aggregators?: any,
    indexes?: Array<number>) {
    this.startRow = startRow;
    this.pageSize = pageSize;
    this.filters = filters;
    this.orderBy = orderBy;
    this.excludeKeys = excludeKeys;
    this.groupBy = groupBy;
    this.aggregators = aggregators;
    this.indexes = indexes;
  }

  static initDefault(): DatasourceRequest {
    return new DatasourceRequest(
      0,
      20,
      null,
      [],
      null,
      [],
      []
    )
  }

  setExludeKeys(excludeKeys) {
    this.excludeKeys = excludeKeys;
  }
}
