import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevResponsiveTableComponent } from './ResponsiveTable.component';
import { zAppDevRowDirective } from "src/app/@framework/components/ResponsiveTable/ResponsiveRow.directive";
import { zAppDevColumnDirective } from "src/app/@framework/components/ResponsiveTable/ResponsiveColumn.directive";

@NgModule({
  declarations: [
    zAppDevResponsiveTableComponent,
    zAppDevRowDirective,
    zAppDevColumnDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    zAppDevResponsiveTableComponent,
    zAppDevRowDirective,
    zAppDevColumnDirective
  ]
})
export class zAppDevResponsiveTableModule {
  static forRoot(): ModuleWithProviders<zAppDevResponsiveTableModule> {
    return {
      ngModule: zAppDevResponsiveTableModule,
      providers: [ ],
    };
  }
}
