import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class DynamicServiceEndpoint  {
    _originalTypeClassName = "zPMO.BO.DynamicServiceEndpoint";
constructor() {
this.Id = null;
this.Name = null;
this.Body = null;
this.RelativePath = null;
this.Verb = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Body:any;
RelativePath:any;
Verb:any;
Service:any;
static setService = ($this, value) => {
var oldValue = $this.Service;
$this.Service = value;
if (value != null) {
if (oldValue != null) {
Domain.DynamicServiceIntegration.removeEndpoints(oldValue, $this);
}
Domain.DynamicServiceIntegration.addEndpoints(value, $this);
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}