import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class LeadActionExecution  {
    _originalTypeClassName = "zPMO.BO.LeadActionExecution";
constructor() {
this.Id = null;
this.DateExecuted = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
DateExecuted:any;
Lead:any;
Action:any;
static setLead = ($this, value) => {
var oldValue = $this.Lead;
$this.Lead = value;
if (value != null) {
if (oldValue != null) {
Domain.Lead.removeExecutedAutomations(oldValue, $this);
}
Domain.Lead.addExecutedAutomations(value, $this);
}
}
static setAction = ($this, value) => {
var oldValue = $this.Action;
$this.Action = value;
if (value != null) {
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}