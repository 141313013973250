import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class InteractionCustomFieldValueDTO  {
    _originalTypeClassName = "zPMO.BO.InteractionCustomFieldValueDTO";
constructor() {
this.FieldId = null;
this.Value = null;
this.InteractionCustomFieldValueDTOKey = null;
this._key = this.InteractionCustomFieldValueDTOKey = 0; 
}

_key: any;
FieldId:any;
Value:any;
private _InteractionCustomFieldValueDTOKey:any;
get InteractionCustomFieldValueDTOKey(): any {
    return this._InteractionCustomFieldValueDTOKey;
  }
set InteractionCustomFieldValueDTOKey(value: any) {
    this._InteractionCustomFieldValueDTOKey = value;
    this._key = value;
  }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.InteractionCustomFieldValueDTOKey == null || $this.InteractionCustomFieldValueDTOKey == 0;
            }


}