import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class MonthlyReportLine  {
    _originalTypeClassName = "zPMO.BO.MonthlyReportLine";
constructor() {
this.Id = null;
this.Project = null;
this.Date = null;
this.User = null;
this.Activity = null;
this.Issue = null;
this.Comment = null;
this.Hours = null;
this.ManDays = null;
this.Priority = null;
this.ResolutionHours = null;
this.CalculatedPriority = null;
this.OriginalIssue = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Project:any;
Date:any;
User:any;
Activity:any;
Issue:any;
Comment:any;
Hours:any;
ManDays:any;
Priority:any;
ResolutionHours:any;
CalculatedPriority:any;
OriginalIssue:any;

static GetCalculatedPriority($this: any): any{if (CLMS.Framework.Number.Equal(() => $this.ResolutionHours, () => null) || CLMS.Framework.Number.LessThanOrEqual(() => $this.ResolutionHours, () => 0)) {return $this?.Priority;}
return $this?.Priority + " (" + Joove.Common.safeNumber($this?.ResolutionHours) + "h)";
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}