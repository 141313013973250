import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class Lead  {
    _originalTypeClassName = "zPMO.BO.Lead";
constructor() {
this.Tasks = [];
this.Account = new Domain.Client();
this.ProductLines = [];
this.JournalEntries = [];
this.DynamicFields = [];
this.LeadActions = [];
this.Tags = [];
this.Uploads = [];
this.ExecutedAutomations = [];
this.CreatedInteractions = [];
this.Id = null;
this.Title = null;
this.DateCreated = null;
this.DateUpdated = null;
this.DateCompleted = null;
this.OfferAmount = null;
this.Notes = null;
this.IsWon = false;
this.IsLost = false;
this.DaysOpen = null;
this.HasPendingTasks = false;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Title:any;
DateCreated:any;
DateUpdated:any;
DateCompleted:any;
OfferAmount:any;
Notes:any;
IsWon:any;
IsLost:any;
DaysOpen:any;
HasPendingTasks:any;
Tasks:any;
LeadType:any;
LeadStatus:any;
Account:any;
ProductLines:any;
Owner:any;
JournalEntries:any;
ClientContact:any;
DynamicFields:any;
LeadActions:any;
Tags:any;
Terminal:any;
Uploads:any;
ExecutedAutomations:any;
CreatedInteractions:any;
InteractionFrom:any;
static setTasks = ($this, items) => {
                if (items == null) items = [];
                if ($this.Tasks == null) $this.Tasks = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Tasks.length > 0) {
                    Domain.Lead.removeTasks($this, $this.Tasks[0]);
                }

                Domain.Lead.addManyTasks($this, copyItems);
            }
static addManyTasks = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Lead.addTasks($this, items[i]);
                }
            }
static addTasks = ($this, item) => {
                if ($this.Tasks == null) $this.Tasks = [];
                if (item == null || $this.Tasks.indexOf(item) > -1) return;
                $this.Tasks.push(item);
if (item.RelatedLead != $this) {
Domain.TaskEntry.setRelatedLead(item, $this);
}
}
static addRangeTasks = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Lead.addTasks($this, item));
            }
static removeTasks = ($this, item) => {
                if ($this == null || $this.Tasks == null || $this.Tasks.indexOf(item) === -1) return;
                var _index = $this.Tasks.indexOf(item);
                $this.Tasks.splice(_index, 1);
item.RelatedLead = null;
}
static clearTasks = ($this) => { $this.Tasks = null; }
static setLeadType = ($this, value) => {
var oldValue = $this.LeadType;
$this.LeadType = value;
if (value != null) {
}
}
static setLeadStatus = ($this, value) => {
var oldValue = $this.LeadStatus;
$this.LeadStatus = value;
if (value != null) {
}
}
static setAccount = ($this, value) => {
var oldValue = $this.Account;
$this.Account = value;
if (value != null) {
}
}
static setProductLines = ($this, items) => {
                if (items == null) items = [];
                if ($this.ProductLines == null) $this.ProductLines = [];

                const copyItems = Common.cloneObject(items);

                while ($this.ProductLines.length > 0) {
                    Domain.Lead.removeProductLines($this, $this.ProductLines[0]);
                }

                Domain.Lead.addManyProductLines($this, copyItems);
            }
static addManyProductLines = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Lead.addProductLines($this, items[i]);
                }
            }
static addProductLines = ($this, item) => {
                if ($this.ProductLines == null) $this.ProductLines = [];
                if (item == null || $this.ProductLines.indexOf(item) > -1) return;
                $this.ProductLines.push(item);
}
static addRangeProductLines = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Lead.addProductLines($this, item));
            }
static removeProductLines = ($this, item) => {
                if ($this == null || $this.ProductLines == null || $this.ProductLines.indexOf(item) === -1) return;
                var _index = $this.ProductLines.indexOf(item);
                $this.ProductLines.splice(_index, 1);
}
static clearProductLines = ($this) => { $this.ProductLines = null; }
static setOwner = ($this, value) => {
var oldValue = $this.Owner;
$this.Owner = value;
if (value != null) {
}
}
static setJournalEntries = ($this, items) => {
                if (items == null) items = [];
                if ($this.JournalEntries == null) $this.JournalEntries = [];

                const copyItems = Common.cloneObject(items);

                while ($this.JournalEntries.length > 0) {
                    Domain.Lead.removeJournalEntries($this, $this.JournalEntries[0]);
                }

                Domain.Lead.addManyJournalEntries($this, copyItems);
            }
static addManyJournalEntries = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Lead.addJournalEntries($this, items[i]);
                }
            }
static addJournalEntries = ($this, item) => {
                if ($this.JournalEntries == null) $this.JournalEntries = [];
                if (item == null || $this.JournalEntries.indexOf(item) > -1) return;
                $this.JournalEntries.push(item);
if (item.Lead != $this) {
Domain.LeadJournalEntry.setLead(item, $this);
}
}
static addRangeJournalEntries = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Lead.addJournalEntries($this, item));
            }
static removeJournalEntries = ($this, item) => {
                if ($this == null || $this.JournalEntries == null || $this.JournalEntries.indexOf(item) === -1) return;
                var _index = $this.JournalEntries.indexOf(item);
                $this.JournalEntries.splice(_index, 1);
item.Lead = null;
}
static clearJournalEntries = ($this) => { $this.JournalEntries = null; }
static setClientContact = ($this, value) => {
var oldValue = $this.ClientContact;
$this.ClientContact = value;
if (value != null) {
}
}
static setDynamicFields = ($this, items) => {
                if (items == null) items = [];
                if ($this.DynamicFields == null) $this.DynamicFields = [];

                const copyItems = Common.cloneObject(items);

                while ($this.DynamicFields.length > 0) {
                    Domain.Lead.removeDynamicFields($this, $this.DynamicFields[0]);
                }

                Domain.Lead.addManyDynamicFields($this, copyItems);
            }
static addManyDynamicFields = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Lead.addDynamicFields($this, items[i]);
                }
            }
static addDynamicFields = ($this, item) => {
                if ($this.DynamicFields == null) $this.DynamicFields = [];
                if (item == null || $this.DynamicFields.indexOf(item) > -1) return;
                $this.DynamicFields.push(item);
if (item.Lead != $this) {
Domain.LeadDynamicFieldValue.setLead(item, $this);
}
}
static addRangeDynamicFields = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Lead.addDynamicFields($this, item));
            }
static removeDynamicFields = ($this, item) => {
                if ($this == null || $this.DynamicFields == null || $this.DynamicFields.indexOf(item) === -1) return;
                var _index = $this.DynamicFields.indexOf(item);
                $this.DynamicFields.splice(_index, 1);
item.Lead = null;
}
static clearDynamicFields = ($this) => { $this.DynamicFields = null; }
static setLeadActions = ($this, items) => {
                if (items == null) items = [];
                if ($this.LeadActions == null) $this.LeadActions = [];

                const copyItems = Common.cloneObject(items);

                while ($this.LeadActions.length > 0) {
                    Domain.Lead.removeLeadActions($this, $this.LeadActions[0]);
                }

                Domain.Lead.addManyLeadActions($this, copyItems);
            }
static addManyLeadActions = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Lead.addLeadActions($this, items[i]);
                }
            }
static addLeadActions = ($this, item) => {
                if ($this.LeadActions == null) $this.LeadActions = [];
                if (item == null || $this.LeadActions.indexOf(item) > -1) return;
                $this.LeadActions.push(item);
if (item.Lead != $this) {
Domain.LeadAction.setLead(item, $this);
}
}
static addRangeLeadActions = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Lead.addLeadActions($this, item));
            }
static removeLeadActions = ($this, item) => {
                if ($this == null || $this.LeadActions == null || $this.LeadActions.indexOf(item) === -1) return;
                var _index = $this.LeadActions.indexOf(item);
                $this.LeadActions.splice(_index, 1);
item.Lead = null;
}
static clearLeadActions = ($this) => { $this.LeadActions = null; }
static setTags = ($this, items) => {
                if (items == null) items = [];
                if ($this.Tags == null) $this.Tags = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Tags.length > 0) {
                    Domain.Lead.removeTags($this, $this.Tags[0]);
                }

                Domain.Lead.addManyTags($this, copyItems);
            }
static addManyTags = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Lead.addTags($this, items[i]);
                }
            }
static addTags = ($this, item) => {
                if ($this.Tags == null) $this.Tags = [];
                if (item == null || $this.Tags.indexOf(item) > -1) return;
                $this.Tags.push(item);
}
static addRangeTags = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Lead.addTags($this, item));
            }
static removeTags = ($this, item) => {
                if ($this == null || $this.Tags == null || $this.Tags.indexOf(item) === -1) return;
                var _index = $this.Tags.indexOf(item);
                $this.Tags.splice(_index, 1);
}
static clearTags = ($this) => { $this.Tags = null; }
static setTerminal = ($this, value) => {
var oldValue = $this.Terminal;
$this.Terminal = value;
if (value != null) {
}
}
static setUploads = ($this, items) => {
                if (items == null) items = [];
                if ($this.Uploads == null) $this.Uploads = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Uploads.length > 0) {
                    Domain.Lead.removeUploads($this, $this.Uploads[0]);
                }

                Domain.Lead.addManyUploads($this, copyItems);
            }
static addManyUploads = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Lead.addUploads($this, items[i]);
                }
            }
static addUploads = ($this, item) => {
                if ($this.Uploads == null) $this.Uploads = [];
                if (item == null || $this.Uploads.indexOf(item) > -1) return;
                $this.Uploads.push(item);
if (item.Lead != $this) {
Domain.LeadUpload.setLead(item, $this);
}
}
static addRangeUploads = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Lead.addUploads($this, item));
            }
static removeUploads = ($this, item) => {
                if ($this == null || $this.Uploads == null || $this.Uploads.indexOf(item) === -1) return;
                var _index = $this.Uploads.indexOf(item);
                $this.Uploads.splice(_index, 1);
item.Lead = null;
}
static clearUploads = ($this) => { $this.Uploads = null; }
static setExecutedAutomations = ($this, items) => {
                if (items == null) items = [];
                if ($this.ExecutedAutomations == null) $this.ExecutedAutomations = [];

                const copyItems = Common.cloneObject(items);

                while ($this.ExecutedAutomations.length > 0) {
                    Domain.Lead.removeExecutedAutomations($this, $this.ExecutedAutomations[0]);
                }

                Domain.Lead.addManyExecutedAutomations($this, copyItems);
            }
static addManyExecutedAutomations = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Lead.addExecutedAutomations($this, items[i]);
                }
            }
static addExecutedAutomations = ($this, item) => {
                if ($this.ExecutedAutomations == null) $this.ExecutedAutomations = [];
                if (item == null || $this.ExecutedAutomations.indexOf(item) > -1) return;
                $this.ExecutedAutomations.push(item);
if (item.Lead != $this) {
Domain.LeadActionExecution.setLead(item, $this);
}
}
static addRangeExecutedAutomations = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Lead.addExecutedAutomations($this, item));
            }
static removeExecutedAutomations = ($this, item) => {
                if ($this == null || $this.ExecutedAutomations == null || $this.ExecutedAutomations.indexOf(item) === -1) return;
                var _index = $this.ExecutedAutomations.indexOf(item);
                $this.ExecutedAutomations.splice(_index, 1);
item.Lead = null;
}
static clearExecutedAutomations = ($this) => { $this.ExecutedAutomations = null; }
static setCreatedInteractions = ($this, items) => {
                if (items == null) items = [];
                if ($this.CreatedInteractions == null) $this.CreatedInteractions = [];

                const copyItems = Common.cloneObject(items);

                while ($this.CreatedInteractions.length > 0) {
                    Domain.Lead.removeCreatedInteractions($this, $this.CreatedInteractions[0]);
                }

                Domain.Lead.addManyCreatedInteractions($this, copyItems);
            }
static addManyCreatedInteractions = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Lead.addCreatedInteractions($this, items[i]);
                }
            }
static addCreatedInteractions = ($this, item) => {
                if ($this.CreatedInteractions == null) $this.CreatedInteractions = [];
                if (item == null || $this.CreatedInteractions.indexOf(item) > -1) return;
                $this.CreatedInteractions.push(item);
if (item.InteractionFrom != $this) {
Domain.Lead.setInteractionFrom(item, $this);
}
}
static addRangeCreatedInteractions = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Lead.addCreatedInteractions($this, item));
            }
static removeCreatedInteractions = ($this, item) => {
                if ($this == null || $this.CreatedInteractions == null || $this.CreatedInteractions.indexOf(item) === -1) return;
                var _index = $this.CreatedInteractions.indexOf(item);
                $this.CreatedInteractions.splice(_index, 1);
item.InteractionFrom = null;
}
static clearCreatedInteractions = ($this) => { $this.CreatedInteractions = null; }
static setInteractionFrom = ($this, value) => {
var oldValue = $this.InteractionFrom;
$this.InteractionFrom = value;
if (value != null) {
if (oldValue != null) {
Domain.Lead.removeCreatedInteractions(oldValue, $this);
}
Domain.Lead.addCreatedInteractions(value, $this);
}
}

static AddJournalEntry($this: any, type: any,oldValue: any,newValue: any,oldId: any,newId: any): any{let user = Joove.Common.cast(window._context.currentUser, "Domain.PMOUser");
let je = new Domain.LeadJournalEntry();
je.Date = moment().toDate();
Domain.LeadJournalEntry.setPMOUser(je, (user != null ? user : $this?.Owner));
je.Type = type;
je.OldValue = oldValue;
je.OldId = oldId;
je.NewValue = newValue;
je.NewId = newId;
$this?.JournalEntries?.add(je);
}
static GetHasPendingTasks($this: any): any{return $this?.Tasks?.linq?.any((t) => !(t?.Completed));
}
static RefreshDynamicFields($this: any): any{if ($this?.LeadType == null) {return;}
for (let _idf = 0; _idf < ($this?.LeadType?.DynamicFields == null ? 0 : $this?.LeadType?.DynamicFields.length); _idf++) {let df = $this?.LeadType?.DynamicFields[_idf];if ($this?.DynamicFields?.linq?.any((d) => d?.DynamicField == df?.DynamicField)) {continue;}let dfv = new Domain.LeadDynamicFieldValue();Domain.LeadDynamicFieldValue.setDynamicField(dfv, df?.DynamicField);$this?.DynamicFields?.add(dfv);}
}
static UpdateDaysOpen($this: any): any{if ($this?.IsLost || $this?.IsWon) {$this.DaysOpen = Joove.Common.dateDiff($this?.DateCompleted, $this?.DateCreated)?.days;}else {$this.DaysOpen = Joove.Common.dateDiff(moment().toDate(), $this?.DateCreated)?.days;}
}
static UpdatePrices($this: any): any{for (let _ip = 0; _ip < ($this?.ProductLines == null ? 0 : $this?.ProductLines.length); _ip++) {let p = $this?.ProductLines[_ip];Domain.LeadProductLine.UpdateTotalPrice(p);}
$this.OfferAmount = $this?.ProductLines?.linq?.sum((p) => p?.TotalPrice);
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}