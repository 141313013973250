import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');
import { WorkflowContextBase } from './WorkflowContextBase';

export class SendTaskRemindersContext extends WorkflowContextBase {
    _originalTypeClassName = "zPMO.BO.SendTaskRemindersContext";
constructor() {
super();
this._key = this.Id = "00000000-0000-0000-0000-000000000000"; 
}

_key: any;


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == "00000000-0000-0000-0000-000000000000";
            }


}