import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ApplicationPermission  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.ApplicationPermission";
constructor() {
this.Users = [];
this.Roles = [];
this.Operations = [];
this.Id = null;
this.Name = null;
this.Description = null;
this.IsCustom = false;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Description:any;
IsCustom:any;
Users:any;
Roles:any;
Operations:any;
static setUsers = ($this, items) => {
                if (items == null) items = [];
                if ($this.Users == null) $this.Users = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Users.length > 0) {
                    Domain.ApplicationPermission.removeUsers($this, $this.Users[0]);
                }

                Domain.ApplicationPermission.addManyUsers($this, copyItems);
            }
static addManyUsers = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.ApplicationPermission.addUsers($this, items[i]);
                }
            }
static addUsers = ($this, item) => {
                if ($this.Users == null) $this.Users = [];
                if (item == null || $this.Users.indexOf(item) > -1) return;
                $this.Users.push(item);
Domain.ApplicationUser.addPermissions(item, $this);
}
static addRangeUsers = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.ApplicationPermission.addUsers($this, item));
            }
static removeUsers = ($this, item) => {
                if ($this == null || $this.Users == null || $this.Users.indexOf(item) === -1) return;
                var _index = $this.Users.indexOf(item);
                $this.Users.splice(_index, 1);
Domain.ApplicationUser.removePermissions(item, $this);
}
static clearUsers = ($this) => { $this.Users = null; }
static setRoles = ($this, items) => {
                if (items == null) items = [];
                if ($this.Roles == null) $this.Roles = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Roles.length > 0) {
                    Domain.ApplicationPermission.removeRoles($this, $this.Roles[0]);
                }

                Domain.ApplicationPermission.addManyRoles($this, copyItems);
            }
static addManyRoles = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.ApplicationPermission.addRoles($this, items[i]);
                }
            }
static addRoles = ($this, item) => {
                if ($this.Roles == null) $this.Roles = [];
                if (item == null || $this.Roles.indexOf(item) > -1) return;
                $this.Roles.push(item);
Domain.ApplicationRole.addPermissions(item, $this);
}
static addRangeRoles = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.ApplicationPermission.addRoles($this, item));
            }
static removeRoles = ($this, item) => {
                if ($this == null || $this.Roles == null || $this.Roles.indexOf(item) === -1) return;
                var _index = $this.Roles.indexOf(item);
                $this.Roles.splice(_index, 1);
Domain.ApplicationRole.removePermissions(item, $this);
}
static clearRoles = ($this) => { $this.Roles = null; }
static setOperations = ($this, items) => {
                if (items == null) items = [];
                if ($this.Operations == null) $this.Operations = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Operations.length > 0) {
                    Domain.ApplicationPermission.removeOperations($this, $this.Operations[0]);
                }

                Domain.ApplicationPermission.addManyOperations($this, copyItems);
            }
static addManyOperations = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.ApplicationPermission.addOperations($this, items[i]);
                }
            }
static addOperations = ($this, item) => {
                if ($this.Operations == null) $this.Operations = [];
                if (item == null || $this.Operations.indexOf(item) > -1) return;
                $this.Operations.push(item);
Domain.ApplicationOperation.addPermissions(item, $this);
}
static addRangeOperations = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.ApplicationPermission.addOperations($this, item));
            }
static removeOperations = ($this, item) => {
                if ($this == null || $this.Operations == null || $this.Operations.indexOf(item) === -1) return;
                var _index = $this.Operations.indexOf(item);
                $this.Operations.splice(_index, 1);
Domain.ApplicationOperation.removePermissions(item, $this);
}
static clearOperations = ($this) => { $this.Operations = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}