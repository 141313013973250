import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class CRMStats  {
    _originalTypeClassName = "zPMO.BO.CRMStats";
constructor() {
this.Activity = [];
this.TerminalMoney = [];
this.GroupByStatus = [];
this.WonAndLostItems = [];
this.UserStats = [];
this.PendingItems = null;
this.AverageCloseDate = null;
this.WinRatio = null;
this.TotalItems = null;
this.WonItems = null;
this.WonMoney = null;
this.DateFrom = null;
this.DateTo = null;
this.CRMStatsKey = null;
this._key = this.CRMStatsKey = 0; 
}

_key: any;
PendingItems:any;
AverageCloseDate:any;
WinRatio:any;
TotalItems:any;
WonItems:any;
WonMoney:any;
DateFrom:any;
DateTo:any;
Activity:any;
TerminalMoney:any;
GroupByStatus:any;
WonAndLostItems:any;
LeadType:any;
UserStats:any;
private _CRMStatsKey:any;
get CRMStatsKey(): any {
    return this._CRMStatsKey;
  }
set CRMStatsKey(value: any) {
    this._CRMStatsKey = value;
    this._key = value;
  }
static setActivity = ($this, items) => {
                if (items == null) items = [];
                if ($this.Activity == null) $this.Activity = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Activity.length > 0) {
                    Domain.CRMStats.removeActivity($this, $this.Activity[0]);
                }

                Domain.CRMStats.addManyActivity($this, copyItems);
            }
static addManyActivity = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.CRMStats.addActivity($this, items[i]);
                }
            }
static addActivity = ($this, item) => {
                if ($this.Activity == null) $this.Activity = [];
                if (item == null || $this.Activity.indexOf(item) > -1) return;
                $this.Activity.push(item);
}
static addRangeActivity = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.CRMStats.addActivity($this, item));
            }
static removeActivity = ($this, item) => {
                if ($this == null || $this.Activity == null || $this.Activity.indexOf(item) === -1) return;
                var _index = $this.Activity.indexOf(item);
                $this.Activity.splice(_index, 1);
}
static clearActivity = ($this) => { $this.Activity = null; }
static setTerminalMoney = ($this, items) => {
                if (items == null) items = [];
                if ($this.TerminalMoney == null) $this.TerminalMoney = [];

                const copyItems = Common.cloneObject(items);

                while ($this.TerminalMoney.length > 0) {
                    Domain.CRMStats.removeTerminalMoney($this, $this.TerminalMoney[0]);
                }

                Domain.CRMStats.addManyTerminalMoney($this, copyItems);
            }
static addManyTerminalMoney = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.CRMStats.addTerminalMoney($this, items[i]);
                }
            }
static addTerminalMoney = ($this, item) => {
                if ($this.TerminalMoney == null) $this.TerminalMoney = [];
                if (item == null || $this.TerminalMoney.indexOf(item) > -1) return;
                $this.TerminalMoney.push(item);
}
static addRangeTerminalMoney = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.CRMStats.addTerminalMoney($this, item));
            }
static removeTerminalMoney = ($this, item) => {
                if ($this == null || $this.TerminalMoney == null || $this.TerminalMoney.indexOf(item) === -1) return;
                var _index = $this.TerminalMoney.indexOf(item);
                $this.TerminalMoney.splice(_index, 1);
}
static clearTerminalMoney = ($this) => { $this.TerminalMoney = null; }
static setGroupByStatus = ($this, items) => {
                if (items == null) items = [];
                if ($this.GroupByStatus == null) $this.GroupByStatus = [];

                const copyItems = Common.cloneObject(items);

                while ($this.GroupByStatus.length > 0) {
                    Domain.CRMStats.removeGroupByStatus($this, $this.GroupByStatus[0]);
                }

                Domain.CRMStats.addManyGroupByStatus($this, copyItems);
            }
static addManyGroupByStatus = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.CRMStats.addGroupByStatus($this, items[i]);
                }
            }
static addGroupByStatus = ($this, item) => {
                if ($this.GroupByStatus == null) $this.GroupByStatus = [];
                if (item == null || $this.GroupByStatus.indexOf(item) > -1) return;
                $this.GroupByStatus.push(item);
}
static addRangeGroupByStatus = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.CRMStats.addGroupByStatus($this, item));
            }
static removeGroupByStatus = ($this, item) => {
                if ($this == null || $this.GroupByStatus == null || $this.GroupByStatus.indexOf(item) === -1) return;
                var _index = $this.GroupByStatus.indexOf(item);
                $this.GroupByStatus.splice(_index, 1);
}
static clearGroupByStatus = ($this) => { $this.GroupByStatus = null; }
static setWonAndLostItems = ($this, items) => {
                if (items == null) items = [];
                if ($this.WonAndLostItems == null) $this.WonAndLostItems = [];

                const copyItems = Common.cloneObject(items);

                while ($this.WonAndLostItems.length > 0) {
                    Domain.CRMStats.removeWonAndLostItems($this, $this.WonAndLostItems[0]);
                }

                Domain.CRMStats.addManyWonAndLostItems($this, copyItems);
            }
static addManyWonAndLostItems = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.CRMStats.addWonAndLostItems($this, items[i]);
                }
            }
static addWonAndLostItems = ($this, item) => {
                if ($this.WonAndLostItems == null) $this.WonAndLostItems = [];
                if (item == null || $this.WonAndLostItems.indexOf(item) > -1) return;
                $this.WonAndLostItems.push(item);
}
static addRangeWonAndLostItems = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.CRMStats.addWonAndLostItems($this, item));
            }
static removeWonAndLostItems = ($this, item) => {
                if ($this == null || $this.WonAndLostItems == null || $this.WonAndLostItems.indexOf(item) === -1) return;
                var _index = $this.WonAndLostItems.indexOf(item);
                $this.WonAndLostItems.splice(_index, 1);
}
static clearWonAndLostItems = ($this) => { $this.WonAndLostItems = null; }
static setLeadType = ($this, value) => {
var oldValue = $this.LeadType;
$this.LeadType = value;
if (value != null) {
}
}
static setUserStats = ($this, items) => {
                if (items == null) items = [];
                if ($this.UserStats == null) $this.UserStats = [];

                const copyItems = Common.cloneObject(items);

                while ($this.UserStats.length > 0) {
                    Domain.CRMStats.removeUserStats($this, $this.UserStats[0]);
                }

                Domain.CRMStats.addManyUserStats($this, copyItems);
            }
static addManyUserStats = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.CRMStats.addUserStats($this, items[i]);
                }
            }
static addUserStats = ($this, item) => {
                if ($this.UserStats == null) $this.UserStats = [];
                if (item == null || $this.UserStats.indexOf(item) > -1) return;
                $this.UserStats.push(item);
}
static addRangeUserStats = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.CRMStats.addUserStats($this, item));
            }
static removeUserStats = ($this, item) => {
                if ($this == null || $this.UserStats == null || $this.UserStats.indexOf(item) === -1) return;
                var _index = $this.UserStats.indexOf(item);
                $this.UserStats.splice(_index, 1);
}
static clearUserStats = ($this) => { $this.UserStats = null; }

static GetDates(type: any,from: any,to: any): any{let stats = new Domain.CRMStats();
if (type == Domain.CRMStatsDateFilter.CurrentMonth) {stats.DateTo = moment().toDate();stats.DateFrom = moment([moment(stats?.DateTo).year(), ((moment(stats?.DateTo).month() + 1)) - 1, 1]).toDate();}else if (type == Domain.CRMStatsDateFilter.CurrentYear) {stats.DateTo = moment().toDate();stats.DateFrom = moment([moment(stats?.DateTo).year(), (1) - 1, 1]).toDate();}else if (type == Domain.CRMStatsDateFilter.Custom) {stats.DateTo = to;stats.DateFrom = from;}
return stats;
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.CRMStatsKey == null || $this.CRMStatsKey == 0;
            }


}