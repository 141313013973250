import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class FileData  {
    _originalTypeClassName = "zPMO.BO.FileData";
constructor() {
this.Id = null;
this.FileName = null;
this.FolderPath = null;
this.MaxFileSize = null;
this.AllowedExtensions = null;
this.Blob = null;
this.StorageMedium = null;
this.UploadedBy = null;
this.UploadDateTime = null;
this.Description = null;
this._key = this.Id = "00000000-0000-0000-0000-000000000000"; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
FileName:any;
FolderPath:any;
MaxFileSize:any;
AllowedExtensions:any;
Blob:any;
StorageMedium:any;
UploadedBy:any;
UploadDateTime:any;
Description:any;


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == "00000000-0000-0000-0000-000000000000";
            }


}