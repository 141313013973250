import { Component, OnInit, Input, HostBinding, forwardRef, Output, EventEmitter, ElementRef } from '@angular/core';
import { zAppDevComponentStatus } from '../component-status';
import { zAppDevComponentSize } from '../component-size';
import * as options from './ProgressBarItem';
import * as ThemeOptions from "../../theme/interfaces/options";
import { IThemeSevice } from "../../theme/interfaces/theme.interface";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { zAppDevBaseComponent } from '../BaseComponent/base.component';
import { CF_COMPONENT } from '@framework/rule-engine/directives/condition-formatting.directive';

@Component({
  selector: 'zapp-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.less'],
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevProgressBarComponent }]
})

export class zAppDevProgressBarComponent extends zAppDevBaseComponent {
  
  options: options.OptionRange[] = [
    { stop: 33, color: 'danger' },
    { stop: 66, color: 'warning' },
    { stop: 100, color: 'success' },
  ];
  themeOptions: ThemeOptions.ProgressBarThemeOptions;
  color: string;
  class: string;

  @Input() 
  set value(valueInput: any) {
    if (!this.allowExceedMax) {
      if (this.max !== undefined && valueInput > this.max) {
        valueInput = this.max;
      }
      if (this.min !== undefined && valueInput < this.min) {
        valueInput = this.min;
      }
    }
    
    if(valueInput === "") {
      valueInput = this.min ?? 0;
    }
    let upperBound = this.max ? this.max : 100;
    let bottomBound = this.min ? this.min : 0;
    let range = upperBound - bottomBound;
    valueInput -= this.min;
    this._value = this.round((valueInput / range ) * 100);
    this.model = valueInput;
    this.refreshHostClasses();
  }
  _value: any;

  @Output() valueChange : EventEmitter<any> = new EventEmitter<any>();

  @Input() model: number;

  @Input() min: number;

  @Input() max: number;

  @Input() variation: string = "Standard";

  @Input() size: zAppDevComponentSize = '';

  @Input() status: zAppDevComponentStatus = 'global';

  @Input() rangeColor: boolean = false;

  @Input() invertColor: boolean = false;

  @Input() showProgress: boolean = false;

  @Input() allowExceedMax: boolean = false;

  constructor(private themeService: IThemeSevice, protected elementRef: ElementRef) {
    super(elementRef);
    this.themeOptions = this.themeService.getProgressBarThemeOptions();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refreshHostClasses();
  }

  refreshHostClasses() {
    this.hostClasses = [
      this.class,
      this.getRangeColor(this.model),
      this.statusClass(),
      this.sizeClass(),
      this.rootClass()
    ].join(' ');
  }

  change($event: any): void {
    this.valueChange.emit(this.model);
  }

  rootClass() {
    return (this.themeOptions ?? [])[this.variation]?.Classes?.Global ?? '';
  }

  statusClass() {
    const extraClasses = this.themeOptions[this.variation].Classes.Roles[
      this.status
    ];
    return extraClasses;
  }

  sizeClass() {
    const extraClasses = this.themeOptions[this.variation].Classes.Roles[
      this.size
    ];
    return extraClasses;
  }

  getRangeColor(width: number) {
    if (this.rangeColor) {
      let range = this.max - this.min;
      for (var i = 0; i < this.options.length; i++) {
        if ( (width / range) * 100 <= this.options[i].stop) {
          var colorvalue = "";
          if (!this.invertColor) {
            colorvalue = this.options[i].color;
          } else {
            colorvalue = this.options[this.options.length - (i + 1)].color;
          }
          return "progressbar-status-" + colorvalue;
        }
      }
    }
  }

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }
}
