import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class CompanyInfo  {
    _originalTypeClassName = "zPMO.BO.CompanyInfo";
constructor() {
this.Company = null;
this.Income = null;
this.Expenses = null;
this.Tax = null;
this.Balance = null;
this.BalanceAfterTax = null;
this.CompanyInfoKey = null;
this._key = this.CompanyInfoKey = 0; 
}

_key: any;
Company:any;
Income:any;
Expenses:any;
Tax:any;
Balance:any;
BalanceAfterTax:any;
private _CompanyInfoKey:any;
get CompanyInfoKey(): any {
    return this._CompanyInfoKey;
  }
set CompanyInfoKey(value: any) {
    this._CompanyInfoKey = value;
    this._key = value;
  }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.CompanyInfoKey == null || $this.CompanyInfoKey == 0;
            }


}