import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatasourceRequest } from '../models/infos/dataSource-request';
import { OrderByInfo } from '../models/infos/order-by-info';
import { ColumnInfo } from '../models/infos/column-info';
import { FilterInfo } from '../models/infos/filter-info';
import { AggregatorTypes } from '../models/enums/aggregator-types';
import { Datasource } from '../interfaces/datasource';
import { environment } from 'src/environments/environment';
import { UpdateInstanceDto } from '../models/infos/update-instance';
import { catchError, first, map, take } from 'rxjs/operators';
import { lastValueFrom, of } from 'rxjs';

const headers = new HttpHeaders()
  .set('Content-Type', 'application/zappdev;');

export class BackendDatasource<T> extends Datasource<T> {
  
  dsRequest: DatasourceRequest = DatasourceRequest.initDefault();
  _updateInstanceEndpoint = 'UpdateInstance';
  
  constructor(
    private _httpClient: HttpClient,
    private _controller: string,
    private _path: string,
    public name: string,
    private _serializeModel: (model: any) => any) {
      super();
      this.controllerName = _controller;
  }

  fetchData(datasourceRequest: DatasourceRequest, model?: any): Promise<any> {

    let aggregatorsRequest = this.getAggregatorsRequest(datasourceRequest);
    if (this._serializeModel != null) {
      model = this._serializeModel(model);
    }
    return lastValueFrom(this._httpClient
      .post(`${environment.appUrl}${this._controller}/${this._path}_Datasource`, { datasourceRequest, model, aggregatorsRequest }, { headers, observe: 'response' })
      .pipe(map(response => response.body), take(1), catchError(_ => of({error: _}))));
  }

  fetchAggregators(datasourceRequest: DatasourceRequest, aggregatorsRequest: any, model?: any): Promise<any> {
    if (this._serializeModel != null) {
      model = this._serializeModel(model);
    }
    return lastValueFrom(this._httpClient
      .post(`${environment.appUrl}${this._controller}/${this._path}_DatasourceAggregators`, { datasourceRequest, aggregatorsRequest, model }, { headers, observe: 'response' })
      .pipe(map((data: any) => {
        const responseDTO = data.body;
        this.originalCollection = responseDTO;
        return responseDTO;
      })).pipe(first()));
  }

  fetchFullRecordset(data: { dataType: string, keys: string, indexes: string, datasourceRequest: DatasourceRequest, model: any }): Promise<any> {

    return lastValueFrom(this._httpClient
      .post(`${environment.appUrl}${this._controller}/${this._path}_GetFullRecordset`, data, { headers, observe: 'response' })
      .pipe(map(response => response.body)).pipe(first()));
  }

  default(startRow: number, pageSize: number): Promise<any> {
    this.dsRequest = DatasourceRequest.initDefault();
    this.dsRequest.startRow = startRow;
    this.dsRequest.pageSize = pageSize;
    return this.fetchData(this.dsRequest);
  }

  sort(columnInfo: ColumnInfo, direction: number): Promise<any> {
    this.dsRequest.orderBy = [];
    const orderBy = new OrderByInfo(columnInfo, direction);
    this.dsRequest.orderBy.push(orderBy);

    return this.fetchData(this.dsRequest);
  }

  filter(searchTerm: string, columns: Array<ColumnInfo>): Promise<any> {
    this.dsRequest.filters = new Array<FilterInfo>();
    columns.forEach(element => {
      const filterInfo = new FilterInfo(element, searchTerm, 2, 7);
      this.dsRequest.filters.push(filterInfo);
    });
    return this.fetchData(this.dsRequest);
  }

  fillAggregator(columnName: string, aggregatorType: AggregatorTypes) {
    const aggregator = {
      column: columnName,
      type: aggregatorType,
      enabled: true
    }
    this.dsRequest.aggregators.push(aggregator);
  }

  getAggregatorsRequest(requestInfo: DatasourceRequest) {
    if (requestInfo == null) {
      return null;
    }

    if (requestInfo.aggregators == null) {
      return null;
    }

    return JSON.parse(JSON.stringify(requestInfo.aggregators));
  }

  aggregate(columnInfo: ColumnInfo, aggregatorType: AggregatorTypes): Promise<any> {
    this.fillAggregator(columnInfo.name, aggregatorType);
    const aggregatorsRequest = this.getAggregatorsRequest(this.dsRequest);
    return this.fetchAggregators(this.dsRequest, aggregatorsRequest);
  }

  raiseSortEvent(columnInfo: ColumnInfo): void {
    if(this.sortEventCallback !== undefined) {
      this.sortEventCallback(columnInfo)
    }
  }

  raiseApplyFilterEvent(): void {
    if(this.applyFilterCallback !== undefined) {
      this.applyFilterCallback();
    }
  }
  raiseClearFilterEvent(): void {
    if(this.raiseClearFilterEvent !== undefined) {
      this.clearFilterCallback();
    }
  }

  indexOf(item: T): number {
    return undefined;
  }

  originalElement(item: T): T {
    // tslint:disable-next-line
    return this.originalCollection.filter(x => x['_clientId'] === item['_clientId'])[0];
  }

  updateInstance(data: any) {

    if (data?.model != null && this._serializeModel != null) {
      data.model = this._serializeModel(data.model);
    }

    return this._httpClient
      .post(`${environment.appUrl}${this._controller}/${this._updateInstanceEndpoint}`, data, { headers, observe: 'response' });
  }
}
