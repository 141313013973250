import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class LeadDynamicFieldValue  {
    _originalTypeClassName = "zPMO.BO.LeadDynamicFieldValue";
constructor() {
this.Id = null;
this.NumberValue = null;
this.StringValue = null;
this.BoolValue = false;
this.DateValue = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
NumberValue:any;
StringValue:any;
BoolValue:any;
DateValue:any;
DynamicField:any;
Lead:any;
static setDynamicField = ($this, value) => {
var oldValue = $this.DynamicField;
$this.DynamicField = value;
if (value != null) {
}
}
static setLead = ($this, value) => {
var oldValue = $this.Lead;
$this.Lead = value;
if (value != null) {
if (oldValue != null) {
Domain.Lead.removeDynamicFields(oldValue, $this);
}
Domain.Lead.addDynamicFields(value, $this);
}
}

static GetObjectValue($this: any): any{switch ($this?.DynamicField?.Datatype) {case Domain.DynamicFieldDatatype.BigText:
case Domain.DynamicFieldDatatype.Text:
case Domain.DynamicFieldDatatype.Link:
case Domain.DynamicFieldDatatype.RichText:
case Domain.DynamicFieldDatatype.MultiOptions:
case Domain.DynamicFieldDatatype.Options:
{
return $this?.StringValue;}
case Domain.DynamicFieldDatatype.Boolean:
{
return $this?.BoolValue;}
case Domain.DynamicFieldDatatype.Date:
case Domain.DynamicFieldDatatype.DateTime:
{
return $this?.DateValue;}
case Domain.DynamicFieldDatatype.Int:
{
return parseInt(($this?.NumberValue) as any);}
case Domain.DynamicFieldDatatype.Decimal:
{
return $this?.NumberValue;}
}
console.error("GetObjectValue of datatype " + $this?.DynamicField?.Datatype + " is not yet implemented");
return null;
}
static TrySetValue($this: any, value: any): any{switch ($this?.DynamicField?.Datatype) {case Domain.DynamicFieldDatatype.BigText:
case Domain.DynamicFieldDatatype.Text:
case Domain.DynamicFieldDatatype.Link:
case Domain.DynamicFieldDatatype.RichText:
case Domain.DynamicFieldDatatype.MultiOptions:
case Domain.DynamicFieldDatatype.Options:
{
$this.StringValue = value;break;
}
case Domain.DynamicFieldDatatype.Boolean:
{
$this.BoolValue = value != null && (value?.toLowerCase() == "true" || value == "1");break;
}
case Domain.DynamicFieldDatatype.Date:
case Domain.DynamicFieldDatatype.DateTime:
{
$this.DateValue = moment(value).toDate();break;
}
case Domain.DynamicFieldDatatype.Int:
{
$this.NumberValue = CLMS.Framework.Integer.Parse(value);break;
}
case Domain.DynamicFieldDatatype.Decimal:
{
$this.NumberValue = CLMS.Framework.Decimal.Parse(value);break;
}
}
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}