import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class DateTimeFormat  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.DateTimeFormat";
constructor() {
this.ApplicationLanguage = new Domain.ApplicationLanguage();
this.Id = null;
this.LongDatePattern = null;
this.LongTimePattern = null;
this.MonthDayPattern = null;
this.RFC1123Pattern = null;
this.ShortDatePattern = null;
this.ShortTimePattern = null;
this.YearMonthPattern = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
LongDatePattern:any;
LongTimePattern:any;
MonthDayPattern:any;
RFC1123Pattern:any;
ShortDatePattern:any;
ShortTimePattern:any;
YearMonthPattern:any;
ApplicationLanguage:any;
static setApplicationLanguage = ($this, value) => {
var oldValue = $this.ApplicationLanguage;
$this.ApplicationLanguage = value;
if (value != null) {
if (oldValue != null && oldValue.DateTimeFormat != null) { oldValue.DateTimeFormat = null; }
if ($this.ApplicationLanguage.DateTimeFormat !== $this) {
Domain.ApplicationLanguage.setDateTimeFormat(value, $this);
}
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}