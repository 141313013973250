import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { zAppDevDropDownBoxComponent } from "./DropDownBox.component";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [zAppDevDropDownBoxComponent],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    MultiSelectModule
  ],
  exports: [zAppDevDropDownBoxComponent],
})
export class zAppDevDropDownBoxModule {
  static forRoot(): ModuleWithProviders<zAppDevDropDownBoxModule> {
    return {
      ngModule: zAppDevDropDownBoxModule,
      providers: [],
    };
  }
}
