import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class BankAccount  {
    _originalTypeClassName = "zPMO.BO.BankAccount";
constructor() {
this.Id = null;
this.Name = null;
this.Beneficiary = null;
this.BankName = null;
this.IBAN = null;
this.BIC = null;
this.Description = null;
this.AccountNumber = null;
this.AddressLine1 = null;
this.AddressLine2 = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Beneficiary:any;
BankName:any;
IBAN:any;
BIC:any;
Description:any;
AccountNumber:any;
AddressLine1:any;
AddressLine2:any;
Currency:any;
static setCurrency = ($this, value) => {
var oldValue = $this.Currency;
$this.Currency = value;
if (value != null) {
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}