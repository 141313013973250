import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class DynamicFieldGroup  {
    _originalTypeClassName = "zPMO.BO.DynamicFieldGroup";
constructor() {
this.Fields = [];
this.Id = null;
this.Name = null;
this.Description = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Description:any;
Fields:any;
static setFields = ($this, items) => {
                if (items == null) items = [];
                if ($this.Fields == null) $this.Fields = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Fields.length > 0) {
                    Domain.DynamicFieldGroup.removeFields($this, $this.Fields[0]);
                }

                Domain.DynamicFieldGroup.addManyFields($this, copyItems);
            }
static addManyFields = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.DynamicFieldGroup.addFields($this, items[i]);
                }
            }
static addFields = ($this, item) => {
                if ($this.Fields == null) $this.Fields = [];
                if (item == null || $this.Fields.indexOf(item) > -1) return;
                $this.Fields.push(item);
if (item.Group != $this) {
Domain.DynamicField.setGroup(item, $this);
}
}
static addRangeFields = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.DynamicFieldGroup.addFields($this, item));
            }
static removeFields = ($this, item) => {
                if ($this == null || $this.Fields == null || $this.Fields.indexOf(item) === -1) return;
                var _index = $this.Fields.indexOf(item);
                $this.Fields.splice(_index, 1);
item.Group = null;
}
static clearFields = ($this) => { $this.Fields = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}