import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevColorPickerComponent } from './ColorPicker.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  declarations: [
    zAppDevColorPickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    InputTextModule,
  ],
  exports: [
    zAppDevColorPickerComponent
  ]
})
export class zAppDevColorPickerModule {
  static forRoot(): ModuleWithProviders<zAppDevColorPickerModule> {
    return {
      ngModule: zAppDevColorPickerModule,
      providers: [ ],
    };
  }
}
