export enum DynamicFieldDatatype {
Int,
Decimal,
Text,
RichText,
BigText,
Date,
DateTime,
Options,
MultiOptions,
Boolean,
Link,
}
