import { HttpBackend, HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit, Input, HostBinding, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Joove } from '@framework/core/Joove';
import { TranslateService } from '@ngx-translate/core';
import { generateKeyPair } from 'node:crypto';
import { FileProgressEvent, FileUpload } from 'primeng/fileupload';
import { lastValueFrom, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClientCommandsService } from '../../../@core/services/ClientCommands.service';
import { CF_COMPONENT } from '../../rule-engine/directives/condition-formatting.directive';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';
import { zAppDevBaseComponent } from '../BaseComponent/base.component';

import { zAppDevComponentSize } from '../component-size';
import { zAppDevComponentStatus } from '../component-status';
import { FileAttachmentOptions } from './FileAttachment.classes';
import { project } from '@framework/common';

@Component({
  selector: 'zapp-fileattachemnt',
  templateUrl: './fileattachment.component.html',
  styleUrls: ['./fileattachment.component.less'],
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevFileAttachmentComponent }]

})
export class zAppDevFileAttachmentComponent extends zAppDevBaseComponent {

  stateClass: any;
  fileData: any;
  fileKey: string;

  @Input()
  set value(value: any) {
    if (value == this.fileData) {
      return;
    }
    if (this.isMultiple && (value == null || value.length == 0)) {
      this.fileData = [];
    }
    if (!this.isMultiple && value != null && Joove.Common.stringIsNullOrEmpty(value.FileName)) {
      return;
    }

    this.files = [];
    this.fileData = value;
    if (this.isMultiple) {
      if (this.fileData !== null && this.fileData.length > 0) {
        for (let i = 0; i < this.fileData.length; i++) {
          const fileData = this.fileData[i];
          let file = new File([], fileData.FileName);
          this.files.push(file);
          this.updateBindings(i).then((response: any) => {
            this.fileKey = response;
          });
        }
      }
    } else {
      if (this.fileData !== null) {
        let file = new File([], this.fileData.FileName);
        this.files.push(file);
        this.updateBindings().then((response: any) => {
          this.fileKey = response;
        });
      }
    }
  }

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() size: zAppDevComponentSize = '';

  @Input() status: zAppDevComponentStatus = 'default';

  @Input() options: FileAttachmentOptions;

  @Input() class: string = '';

  @Input() basicEndpoint: string;

  @Input() controller: string;

  @Input() useDnDBox: boolean = false;

  @Input() displayFileNameList: boolean = false;

  @Input() isMultiple: boolean = false;

  @Input() hideSuccessMessage: boolean = false;

  @Input() model: any;

  @Input() readonly: boolean = false;

  @Input() required: boolean = false;

  @Input() indexes: Array<any>;

  @Input() projectionSchema: any;

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @ViewChild("fileUpload", { static: false }) fileUpload: FileUpload;
  files: any[] = [];

  subscription: Subscription;

  @HostBinding('class.readonly')
  get readonlyClass() {
    return this.readonly === true || this.disabled === true;
  }

  private httpClient: HttpClient;
  constructor(
    private _httpClient: HttpClient,
    handler: HttpBackend,
    protected elementRef: ElementRef,
    protected translate: TranslateService,
    protected clientCommandsService: ClientCommandsService,
    protected themeservice: IThemeSevice
  ) {
    super(elementRef);
    this.options = themeservice.getFileAttachmentThemeOptions();

    this.httpClient = new HttpClient(handler);
  }

  ngOnInit() {
    super.ngOnInit();

  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    if (this.useDnDBox) {
      this.prepareFilesList($event);
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {

    if (this.fileUpload.progress > 0 && this.fileUpload.progress < 100) {
      console.log("Upload in progress.");
      return;
    }

    let data: any;
    if (index == null) {
      data = {
        model: this.model,
      };
    } else {
      data = {
        model: this.model,
        indexes: index
      };
    }

    this._httpClient
      .post(`${environment.appUrl}${this.basicEndpoint}_Remove`, data, {
        reportProgress: true,
        observe: "events",
      })
      .subscribe((events) => {
        if (events.type === HttpEventType.UploadProgress) {

        } else if (events.type === HttpEventType.Response) {
          let bodyResponse = events.body as any;
          this.fileData = bodyResponse;
          this.valueChange.emit(this.fileData);
        }
      });
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {

    if (this.isMultiple) {
      for (const item of files) {
        item.progress = 0;
        // this.fileData.push(<File>item);
        this.files.push(item);
      }
      this.uploadFiles(files);
    } else {
      let item = files[0];
      this.files = [];
      this.files.push(item);
      this.fileData = <File>item;
      this.uploadFile(item);
    }

    // this.fileDropEl.nativeElement.value = "";
    this.fileUpload.clear();
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append("files", file);

    formData.append('model', JSON.stringify(this.modelToProjection()));
    formData.append('indexes', this.indexes.join('_'));

    this.subscription = this._httpClient
      .post(`${environment.appUrl}${this.basicEndpoint}_Upload`, formData, {
        reportProgress: true,
        observe: "events",
      })
      .subscribe((events) => {
        if (events.type === HttpEventType.UploadProgress) {
        } else if (events.type === HttpEventType.Response) {
          let bodyResponse = events.body as any;
          this.fileData = bodyResponse.Data as any;
          file.progress = 100;
          this.fileKey = null;
          this.valueChange.emit(this.fileData);
          setTimeout(() => {
            this.updateBindings().then((response: any) => {
              this.fileKey = response;
            });
          }, 200);

          this.displaySuccessMessage();
        }
      }, (error) => {
        const uploadedFile = formData.get("files") as File;
        this.files = this.files.filter(f => f.name !== uploadedFile.name);
        if (error.error && error.error.Data) {
          const parsedData = JSON.parse(error.error.Data);
          if (parsedData.Title) {
            this.displayErrorMessage(parsedData.Title);
          } else {
            this.displayErrorMessage();
          }
        } else {
          this.displayErrorMessage();
        }
      });
  }

  uploadFiles(files: Array<any>) {
    const formData = new FormData();

    let fileToUploadExists = false;
    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
      fileToUploadExists = true;
    }

    if (!fileToUploadExists) {
      return;
    }

    formData.append('model', JSON.stringify(this.modelToProjection()));
    formData.append('indexes', this.indexes.join('_'));

    this.subscription = this._httpClient
      .post(`${environment.appUrl}${this.basicEndpoint}_Upload`, formData, {
        reportProgress: true,
        observe: "events",
      })
      .subscribe((events) => {
        if (events.type === HttpEventType.UploadProgress) {

          this.fileUpload.onProgress.emit({ originalEvent: events, progress: (events.loaded / events.total) * 100 });

        } else if (events.type === HttpEventType.Response) {
          let bodyResponse = events.body as any;
          this.fileData = bodyResponse.Data as any;
          this.fileKey = null;
          this.valueChange.emit(this.fileData);
          if (!this.isMultiple) {
            setTimeout(() => {
              this.updateBindings().then((response: any) => {
                this.fileKey = response;
              });
            }, 200);

            this.displaySuccessMessage();
          }
        }
      }, (error) => {
        const uploadedFiles = formData.getAll("files[]") as File[];
        this.files = this.files.filter(f => !uploadedFiles.map(x => x.name).includes(f.name));
        if (error.error && error.error.Data) {
          const parsedData = JSON.parse(error.error.Data);
          if (parsedData.Title) {
            this.displayErrorMessage(parsedData.Title);
          } else {
            this.displayErrorMessage();
          }
        } else {
          this.displayErrorMessage();
        }
      });
  }

  uploadHandler(files: { files: any[] }) {
    this.prepareFilesList(files.files);
  }

  updateBindings(index?: number): Promise<any> {
    let data: any;
    if (index == null) {
      data = {
        model: this.modelToProjection(),
        indexes: (this.indexes ?? []).join("_")
      };
    } else {
      data = {
        model: this.modelToProjection(),
        indexes: index
      };
    }

    return lastValueFrom(this.httpClient
      .post(`${environment.appUrl}${this.basicEndpoint}_Download`, data, {
        observe: "response",
        responseType: 'text'
      })
      .pipe(map(response => response.body)).pipe(first()));
    // .subscribe((response) => {
    //   let data = response.body as any;
    //   this.fileKey = data;
    // });
  }

  proccessDownloadFile(i) {
    if (this.isMultiple) {
      this.updateBindings(i).then((response: any) => {
        this.fileKey = response;
        this.downloadFile(i);
      });
    } else {
      if (this.fileKey == null) {
        return;
      }
      this.downloadFile();
    }
  }

  downloadFile(index?: number) {
    this.httpClient
      .get(Joove.Common.pathJoin(environment.appUrl, this.controller, 'DownloadFile'), {
        params: {
          id: this.fileKey,
        },
        responseType: 'blob'
      })
      .subscribe((response) => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(response)
        a.href = objectUrl
        a.download = index == null ? this.fileData.FileName : this.fileData[index].FileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  displaySuccessMessage() {
    if (this.hideSuccessMessage) {
      return;
    }

    const successMessage = this.translate.instant('RES_WEBFORM_FileUploadSuccess');
    this.clientCommandsService.execute([{ Command: "SHOW_MESSAGE", Params: [successMessage, Joove.MessageType.Success] }]);
  }

  displayErrorMessage(errorMessage?: string) {
    errorMessage = errorMessage ?? 'RES_WEBFORM_FileUploadError';
    errorMessage = this.translate.instant(errorMessage) ?? errorMessage;
    this.clientCommandsService.execute([{ Command: "SHOW_MESSAGE", Params: [errorMessage, Joove.MessageType.Error] }]);
  }

  progressReport($event: FileProgressEvent) {
    this.fileUpload.progress = $event.progress;

    if ($event.progress >= 100) {
      this.displaySuccessMessage();
    }
  }

  cancelUpload() {
    if (this.subscription == null) {
      return;
    }

    this.files = this.files.filter(f => f.progress == null || f.progress >= 100);

    this.subscription.unsubscribe();
    this.fileUpload.progress = 0;
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  private modelToProjection(): any {
    let model = this.model;
    if (this.projectionSchema != null) {
      model = project(this.model, this.projectionSchema);
    }
    if (model == null) {
      return {}
    }
    return model;
  }

}
