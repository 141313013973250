<p-contextMenu #headCellsContextMenu [model]="headCellItems"></p-contextMenu>
<p-contextMenu #cellsContextMenu [model]="cellItems"></p-contextMenu>

<div #listContainer class="data-list-control" *ngIf="!hidden" [class.stack-layout]="options.stackResponsiveLayout">
  <p-table *ngIf="state != null && initialized" id="dataTableElement" #dataTableElement [columns]="state?.columnInfo"
    [value]="data" [selectionMode]="selectionMode" [(selection)]="selection" dataKey="_key"
    (onRowSelect)="selectionChanged($event, true)" (onRowUnselect)="selectionChanged($event, false)"
    [reorderableColumns]="options.hasReorderableColumns" [resizableColumns]="options.hasResizableColumns" [lazy]="true"
    columnResizeMode="expand" (onLazyLoad)="fetchData($event)" [loading]="loading" [totalRecords]="totalRecords"
    [paginator]="options.infiniteScrolling === true ? false : options.hasPaging"
    [rows]="options.infiniteScrolling === true ? 100 : state.pageSize"
    [rowsPerPageOptions]="pageSizes == null || pageSizes.length == 1 ? null : pageSizes" [(first)]="first"
    [selectAll]="state.allKeysSelected" [showCurrentPageReport]="options.displayRecordsInfoRow"
    currentPageReportTemplate="{{ 'RES_DATALIST_Info' | translate: { START: first, END: last, TOTAL: totalRecords } }}"
    (onPage)="onPage($event)" [responsiveLayout]="options.stackResponsiveLayout ? 'stack' : 'scroll'" [breakpoint]="'960px'" [scrollable]="true" scrollHeight="flex"
    [styleClass]="styleClass" paginatorDropdownAppendTo="body" [editMode]="options.cellEdit ? 'cell' : 'row'"
    [virtualScrollItemSize]="virtualScrollItemSize" [virtualScroll]="virtualScroll" (onColReorder)="onColumnReorder($event)"
    (onEditInit)="onCellEditInit($event)" (onEditComplete)="onCellEditComplete($event)"
    (onEditCancel)="onCellEditCancel($event)">
    <ng-template pTemplate="caption">
      <div class="list-header">

        <!-- actions-->
        <div *ngIf="actions != null" class="actions">
          <ng-container *ngFor="let action of actions">
            <zapp-button [hidden]="!showActionButton(action)" [type]="'Button'" [status]="action.status"
              [size]="action.size" (buttonClicked)="actionButtonOnClick(action, $event)"
              [class.single]="action.show == 'single'" [class.multi]="action.show == 'multi'"
              [class.list-default-action]="action.isDefault" [zapphaspermission]="action.security"
              [btnDisabled]="action.btnDisabled != null ? action.btnDisabled() : undefined"
              [disabled]="action.disableCondition != null ? action.disableCondition(state.selectedKeys.length, selection.length, totalRecords, state.pageSize, excludedItemKeys?.length, this) : undefined"
              [zappCfconditionalFormatting]="{ rules: action.buttonFormatRules, model: model, trigger: changeCounter, controlName: action.buttonName, context: [] }">
              <label zappLabel [type]="''" [status]="action.labelStatus" [size]="action.labelSize">
                {{action.label | translate}}
              </label>
            </zapp-button>
          </ng-container>
        </div>

        <div class="buttons-container">
          <!-- filter -->
          <div *ngIf="options.isSearchable" class="filter" style="width: 20rem; margin-right: 0.5rem;">
            <span class="p-input-icon-right" style="width: 100%;">
              <i *ngIf="searchInput != null && searchInput.length > 0" class="pi pi-times"
                (click)="clearButtonOnClick($event)" style="cursor: pointer;"></i>
              <i class="pi pi-search" (click)="searchButtonOnClick($event)" style="cursor: pointer;"></i>
              <i class="pi pi-filter" (click)="filterButtonOnClick($event)" style="cursor: pointer;"></i>
              <zappTextbox [type]="'string'" [isDecimal]="false" [liveUpdate]="false" #exportPageTitle name="searchList"
                [size]="'med'" [value]="searchInput" (valueChange)="addGlobalSearch($event, true)"
                style="margin-right: 0.25rem;" [placeholder]="_resources.textResources.Search">
              </zappTextbox>
            </span>
          </div>
          <div class="settings">

            <div class="buttons">

              <!-- columns visibility -->
              <div class="columns-visibility-wrapper" *ngIf="VisibleColumnDataset != null">
                <zapp-dropdown #columnsVisibility style="flex: 1" [size]="'med'" [isMultiSelect]='true' [searchEverywhere]='true'
                                [dataset]="VisibleColumnDataset" [optionLabel]="'_text'" [optionValue]="'_value'"
                                [value]="visibleColumnNames" (valueChange)="visibleColumnsChanged($event, '', null, true)"
                                [displayChips]="false">
                </zapp-dropdown>

                <zapp-button [type]="'Button'" (buttonClicked)="showColumnsVisibility()"
                              title="{{ _resources.textResources.ColumnVisibility }}">
                  <zappIcon themeIcon="eye" [circularBackground]="false">
                  </zappIcon>
                </zapp-button>
              </div>

              <!-- refresh -->
              <zapp-button [type]="'Button'" (buttonClicked)="refresh($event)"
                            title="{{ _resources.textResources.Refresh }}">
                <zappIcon themeIcon="replay" [circularBackground]="false">
                </zappIcon>
              </zapp-button>

              <!-- reset -->
              <zapp-button [type]="'Button'" (buttonClicked)="reset($event)"
                            title="{{ _resources.textResources.Reset }}">
                <zappIcon themeIcon="refresh" [circularBackground]="false">
                </zappIcon>
              </zapp-button>

              <!-- filters -->
              <zapp-button *ngIf="options.structuredFiltering" [type]="'Button'"
                            (buttonClicked)="showFiltersModal($event)" title="{{ _resources.textResources.FiltersPopUpTitle }}">
                <zappIcon themeIcon="filter" [circularBackground]="false">
                </zappIcon>
              </zapp-button>

              <!-- grouping -->
              <zapp-button *ngIf="options.isGroupable" [type]="'Button'" (buttonClicked)="showGroupingModal($event)"
                            title="{{ _resources.textResources.Grouping }}">
                <zappIcon themeIcon="th-large" [circularBackground]="false">
                </zappIcon>
              </zapp-button>

              <!-- export -->
              <zapp-button *ngIf="options.isExportable" [type]="'Button'" (buttonClicked)="showExportModal($event)"
                            title="{{ _resources.textResources.Export }}">
                <zappIcon themeIcon="download" [circularBackground]="false">
                </zappIcon>
              </zapp-button>

              <!-- views -->
              <zapp-button *ngIf="options.saveViews" [type]="'Button'" (buttonClicked)="showViewsModal($event)"
                            title="{{ _resources.textResources.Views }}">
                <zappIcon themeIcon="save" [circularBackground]="false">
                </zappIcon>
              </zapp-button>

              <!-- import -->
              <zapp-button *ngIf="options.importData" [type]="'Button'" (buttonClicked)="showImportModal($event)"
                            title="{{ _resources.textResources.Import }}">
                <zappIcon themeIcon="upload" [circularBackground]="false">
                </zappIcon>
              </zapp-button>

            </div>

            <!-- Page Size -->
            <!--<div *ngIf="options.hasPaging" style="display: flex; align-items: center;">
              <label style="margin-right: 0.5rem;">{{_resources.textResources.PageSize.replace('_MENU_', '')}}</label>

              <div *ngIf="options.userCanSelectPageSize" style="margin-right: 0.5rem;">
                <zapp-dropdown [name]="'PageSizeDropdown'" [indexes]='[]' [isSearchable]='false'
                               [projectionSchema]='PageSizeDropdown_ProjectionScema' [dontAllowEmptyValue]='true' [searchEverywhere]='true'
                               [dataset]="PageSizeDataset" [optionLabel]="'_text'" [optionValue]="'_value'" [model]="model"
                               #PageSizeDropdown name="PageSizeDropdown" [size]="'med'"
                               [value]="getPageSize()" (valueChange)="pageSizeChanged($event, '', null, true)">
                </zapp-dropdown>
              </div>

              <label *ngIf="!options.userCanSelectPageSize" style="margin-right: 1rem;">{{getPageSizeLabel()}}</label>
            </div>-->
          </div>
        </div>

      </div>
    </ng-template>

    <ng-template *ngIf="options.infiniteScrolling" pTemplate="loadingbody" let-columns="columns">
      <tr style="height: 50px">
        <td *ngIf="options.showRowNumbers" style="width: 1%;"></td>
        <td *ngFor="let col of columns; let even = even" [hidden]="!col.visible">
          <p-skeleton [ngStyle]="{'width': even ? (col.field === 'year' ? '30%' : '40%') : '60%'}"></p-skeleton>
        </td>
        <td *ngIf="editable && !options.cellEdit"></td>
      </tr>
    </ng-template>

    <!-- header cell template -->
    <ng-template #listHeaderCellTemplate let-col="col">
      <div style="display: flex; justify-content: space-between; align-items: center">
        <div>
          <span>{{ col.caption | translate }}</span>
          <p-sortIcon *ngIf="col.orderable" [field]="col.name" style="margin-left: auto"></p-sortIcon>
        </div>
        <i *ngIf="columnHasFilter(col)" class="pi pi-filter" (click)="filterButtonOnClick($event)"
          style="cursor: pointer;"></i>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngIf="options.selectUsingCheckbox" style="width: 4rem" alignFrozen="left" pFrozenColumn>
          <!--<p-tableHeaderCheckbox></p-tableHeaderCheckbox>-->
          <p-checkbox (click)="toggleSelectAll($event)" [binary]="true" [ngModel]="allSelected"></p-checkbox>
        </th>
        <th *ngIf="options.showRowNumbers" style="width: 1%;" alignFrozen="left" pFrozenColumn></th>

        <ng-container *ngFor="let col of columns">

          <th *ngIf="!allowColumnsFreeze || col.frozen == null || col.frozen.length <= 0" pReorderableColumn
            [hidden]="!col.visible"
            [style]="col.width > 0 ? 'flex: 0 0 ' + col.width + 'px; width: ' + col.width + 'px; max-width: ' + col.width + 'px; min-width: ' + col.width + 'px' : undefined"
            [class]="col.classes != null ? col.classes : ''"
            [pReorderableColumnDisabled]="!options.hasReorderableColumns" pResizableColumn
            [pResizableColumnDisabled]="!options.hasResizableColumns"
            [pSortableColumn]="col.orderable ? col.name : null" (contextmenu)="onHeadRowRightClick($event, col)">

            <ng-container *ngTemplateOutlet="listHeaderCellTemplate;  context: {col: col}"></ng-container>

          </th>

          <th *ngIf="allowColumnsFreeze && col.frozen != null && col.frozen.length > 0" pReorderableColumn
            [hidden]="!col.visible"
            [style]="col.width > 0 ? 'flex: 0 0 ' + col.width + 'px; width: ' + col.width + 'px; max-width: ' + col.width + 'px; min-width: ' + col.width + 'px' : undefined"
            [class]="col.classes != null ? col.classes : ''"
            [pReorderableColumnDisabled]="!options.hasReorderableColumns" pResizableColumn
            [pResizableColumnDisabled]="!options.hasResizableColumns"
            [pSortableColumn]="col.orderable ? col.name : null" (contextmenu)="onHeadRowRightClick($event, col)"
            [alignFrozen]="col.frozen" pFrozenColumn>

            <ng-container *ngTemplateOutlet="listHeaderCellTemplate;  context: {col: col}"></ng-container>

          </th>

        </ng-container>

        <!--edit row buttons column-->
        <th *ngIf="editable && !options.cellEdit" alignFrozen="right" pFrozenColumn></th>
      </tr>

      <!-- column filters cell template -->
      <ng-template #listColumnFiltersCellTemplate let-col="col">
        <p-columnFilter style="flex: 1" type="text" [showMenu]="false" [field]="col.name" [showClearButton]="false">
          <ng-template style="flex: 1" pTemplate="filter" let-value let-filter="filterCallback">

            <zapp-dropdown *ngIf="col.mambaDataTypeIsEnum" style="flex: 1" [size]="'med'" [isMultiSelect]='false'
              [searchEverywhere]='true' [dataset]="col.quickFilterData.dataset" [optionLabel]="'_text'"
              [optionValue]="'_value'" [value]="col.quickFilterData.search" (valueChange)="addQuickFilter(col, $event)">
            </zapp-dropdown>

            <zapp-datetime *ngIf="col.dataType == 'DateTime'" [dateTimeBoxOptions]="col.quickFilterData.dateConfig"
              [size]="'med'" [value]="col.quickFilterData.search" (valueChange)="addQuickFilter(col, $event)">
            </zapp-datetime>

            <span *ngIf="!col.mambaDataTypeIsEnum && col.dataType != 'DateTime'" class="p-input-icon-right"
              style="width: 100%;">
              <i *ngIf="col.quickFilterData.search != null && col.quickFilterData.search.length > 0 "
                class="pi pi-times" (click)="removeQuickFilterFromColumn(col, true)" style="cursor: pointer;"></i>
              <i class="pi pi-search" (click)="addQuickFilter(col)" style="cursor: pointer;"></i>
              <zappTextbox [type]="'string'" [isDecimal]="false" [liveUpdate]="false" #exportPageTitle [size]="'med'"
                [value]="col.quickFilterData.search" (valueChange)="addQuickFilter(col, $event)">
              </zappTextbox>
            </span>

          </ng-template>
        </p-columnFilter>
      </ng-template>

      <tr *ngIf="options.isSearchable && quickFilters">
        <th *ngIf="options.selectUsingCheckbox" alignFrozen="left" pFrozenColumn></th>
        <th *ngIf="options.showRowNumbers" style="width: 1%;" alignFrozen="left" pFrozenColumn></th>

        <ng-container *ngFor="let col of columns">
          <th *ngIf="!allowColumnsFreeze || col.frozen == null || col.frozen.length <= 0" [hidden]="!col.visible"
            [class]="col.classes != null ? col.classes : ''">
            <ng-container *ngTemplateOutlet="listColumnFiltersCellTemplate;  context: {col: col}"></ng-container>
          </th>
          <th *ngIf="allowColumnsFreeze && col.frozen != null && col.frozen.length > 0" [hidden]="!col.visible"
            [class]="col.classes != null ? col.classes : ''" [alignFrozen]="col.frozen" pFrozenColumn>
            <ng-container *ngTemplateOutlet="listColumnFiltersCellTemplate;  context: {col: col}"></ng-container>
          </th>
        </ng-container>

        <!--edit row buttons column-->
        <th *ngIf="editable && !options.cellEdit" alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex" let-editing="editing">

      <ng-container *ngIf="rowData.$groupingInfo != null && rowData.$groupingInfo.groups.length > 0">
        <ng-container *ngFor="let group of rowData.$groupingInfo.groups">
          <!-- Grouping row -->
          <tr class="subheader-row level-{{group.level}}" [class.collapsed]="group.collapsed"
            [class.hidden]="group.hidden">
            <td *ngIf="group.mergedColumns == null || group.mergedColumns.length <= 0" class="compact"
              [attr.colspan]="columns.length + 10">
              <button type="button" pButton pRipple [pRowToggler]="rowData"
                class="p-button-text p-button-rounded p-button-plain mr-2"
                [icon]="group.collapsed ? 'pi pi-chevron-right' : 'pi pi-chevron-down'"
                (click)="toggleGroup(group)"></button>
              <span class="caption">{{group.columnName}}: </span>
              <span class="value">{{group.value}}</span>
            </td>
            <td *ngIf="group.mergedColumns != null && group.mergedColumns.length > 0"
              [class.compact]="!state.getGroupsClosed">
              <button *ngIf="!state.getGroupsClosed" type="button" pButton pRipple [pRowToggler]="rowData"
                class="p-button-text p-button-rounded p-button-plain mr-2"
                [icon]="group.collapsed ? 'pi pi-chevron-right' : 'pi pi-chevron-down'"
                (click)="toggleGroup(group)"></button>
              <span>{{_resources.textResources.MergedGroupLevels}}:&nbsp;</span>
              <span *ngFor="let mergedColumn of group.mergedColumns; let last = last">
                <b class="caption">
                  {{mergedColumn.columnName}}:
                </b>
                <b class="value">
                  {{mergedColumn.value}}
                </b>
                <span *ngIf="!last" class="separator">&nbsp;|&nbsp;</span>
              </span>
            </td>
          </tr>
          <!-- Aggregations row-->
          <tr *ngIf="group.columnAggregators != null && group.columnAggregators.length > 0" class="aggregations-row"
            [class.hidden]="group.hidden">
            <td *ngIf="options.showRowNumbers" style="width: 1%;"></td>
            <td *ngFor="let col of group.columnAggregators" [hidden]="!col.visible">
              <ul class="aggregations">
                <li *ngIf="col.countAggregator != null">
                  <span class="caption">{{_resources.textResources.CountHeader}}</span>
                  <span class="value">{{col.countAggregator.ValueFormatted}}</span>
                </li>
                <li *ngIf="col.sumAggregator != null">
                  <span class="caption">{{_resources.textResources.SumHeader}}</span>
                  <span class="value">{{col.sumAggregator.ValueFormatted}}</span>
                </li>
                <li *ngIf="col.averageAggregator != null">
                  <span class="caption">{{_resources.textResources.AverageHeader}}</span>
                  <span class="value">{{col.averageAggregator.ValueFormatted}}</span>
                </li>
              </ul>
            </td>
            <!--edit row buttons column-->
            <td *ngIf="editable && !options.cellEdit"></td>
          </tr>
        </ng-container>
      </ng-container>

      <tr *ngIf="rowData.$helperRow != true" [pEditableRow]="rowData" [pSelectableRow]="rowData"
        [pSelectableRowIndex]="rowIndex" [pSelectableRowDblClick]="rowData"
        (contextmenu)="onDataRowRightClick($event, rowData)"
        [class.hidden]="rowData.$groupingInfo != null && rowData.$groupingInfo.hidden"
        [class]="rowData?.$cfInfo?.rowClasses ?? ''" (dblclick)="onRowDoubleClick(rowData)">
        <td *ngIf="options.selectUsingCheckbox" alignFrozen="left" pFrozenColumn>
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td *ngIf="options.showRowNumbers" style="width: 1%;" alignFrozen="left" pFrozenColumn>{{rowIndex + 1}}</td>

        <ng-container *ngFor="let col of columns">
          <td *ngIf="!allowColumnsFreeze || col.frozen == null || col.frozen.length <= 0"
              [pEditableColumn]="col.editable ? rowData : undefined"
              [pEditableColumnField]="col.editable ? col.name : undefined"
              [pEditableColumnRowIndex]="col.editable ? rowIndex : undefined"
              [pEditableColumnDisabled]="!col.editable || !options.cellEdit"
              [class]="(rowData?.$cfInfo?.cells != null ? rowData?.$cfInfo?.cells[col.name]?.classes ?? '' : '') + ' ' + (col.classes != null ? col.classes : '')"
              [style]="col.width > 0 ? 'flex: 0 0 ' + col.width + 'px; width: ' + col.width + 'px; max-width: ' + col.width + 'px; min-width: ' + col.width + 'px' : undefined"
              [hidden]="!col.visible">

            @if (options.stackResponsiveLayout) {
              <span class="p-column-title">{{ col.caption | translate }}</span>
            }
            
            <!-- duplicate code below, 1st occurance -->
            <p-cellEditor class="progress-bar" *ngIf="col.editable && !hideRowEditButton" [class.progress-bar]="col.itemType == 6">
              <!-- edit mode -->
              <ng-template pTemplate="input">
                <input *ngIf="(col.itemType == 4 || col.itemType == 0 || col.itemType == 5 || col.itemType == 6) && !col.mambaDataTypeIsEnum && col.dataType != 'DateTime'"
                       pInputText type="text" [ngModel]="renderCellValue(rowData, col)"
                       (blur)="setCellValue($event.target.value, rowData, col)">

                <zappCheckbox *ngIf="col.itemType == 2" style="margin-bottom: 0" [hasClickEvent]="false"
                              [value]="renderCellValue(rowData, col)" (valueChange)="setCellValue($event, rowData, col)">
                </zappCheckbox>

                <zapp-datetime *ngIf="col.dataType == 'DateTime'" [dateTimeBoxOptions]="col.quickFilterData.dateConfig"
                               [size]="'med'" [value]="renderCellValue(rowData, col)"
                               (valueChange)="setCellValue($event, rowData, col)">
                </zapp-datetime>

                <zapp-dropdown *ngIf="col.mambaDataTypeIsEnum && col.dataType != 'DateTime'" style="flex: 1"
                               [size]="'med'" [isMultiSelect]='false' [searchEverywhere]='true'
                               [dataset]="col.quickFilterData.dataset" [optionLabel]="'_text'" [optionValue]="'_id'"
                               [value]="renderMambaEnumCellValue(rowData, col)" (valueChange)="setCellValue($event, rowData, col)">
                </zapp-dropdown>
                <p-image *ngIf="col.itemType == 3"
                         [src]="rowData._key | getListImageUrl : col : dataset.controllerName : name : !col.showFullImage"
                         alt="Image" [width]="col.thumbnailWidth" [height]="col.thumbnailHeight" [preview]="col.showFullImage"
                         [imageClass]="class"></p-image>
              </ng-template>
              <!-- view only mode -->
              <ng-template pTemplate="output">
                <span *ngIf="col.itemType == 4">
                  {{rowData | getColumnValue : col}}
                </span>
                <span *ngIf="col.itemType == 2">
                  <i style="opacity: 0.8"
                     [class]="(rowData | getColumnValue : col) ? 'pi pi-check-square' : 'pi pi-stop'"></i>
                </span>
                <span *ngIf="col.itemType == 0">
                  <a [href]="rowData | getColumnValue : col">{{ rowData | getColumnValue : col }}</a>
                </span>
                <zapp-progressbar *ngIf="col.itemType == 6" [min]="col.min" [max]="col.max" [rangeColor]="false"
                                  [showProgress]="true" [allowExceedMax]="true" [size]="'med'" [value]="rowData | getColumnValue : col">
                </zapp-progressbar>
                <p-image *ngIf="col.itemType == 3"
                         [src]="rowData._key | getListImageUrl : col : dataset.controllerName : name : !col.showFullImage"
                         alt="Image" [width]="col.thumbnailWidth" [height]="col.thumbnailHeight" [preview]="col.showFullImage"
                         [imageClass]="class"></p-image>
                <span *ngIf="col.itemType == 5" [innerHTML]="rowData | getColumnValue : col"></span>
                <span *ngIf="col.itemType == 1">
                  ITEM TYPE NOT_IMPLEMENTED
                </span>
              </ng-template>
            </p-cellEditor>

            <ng-container *ngIf="!col.editable || hideRowEditButton">
              <span *ngIf="col.itemType == 4">
                {{rowData | getColumnValue : col}}
              </span>
              <span *ngIf="col.itemType == 2">
                <i style="opacity: 0.8"
                   [class]="(rowData | getColumnValue : col) ? 'pi pi-check-square' : 'pi pi-stop'"></i>
              </span>
              <span *ngIf="col.itemType == 0">
                <a [href]="rowData | getColumnValue : col">{{ rowData | getColumnValue : col }}</a>
              </span>
              <zapp-progressbar *ngIf="col.itemType == 6" [min]="col.min" [max]="col.max" [rangeColor]="false"
                                [showProgress]="true" [allowExceedMax]="true" [size]="'med'" [value]="rowData | getColumnValue : col">
              </zapp-progressbar>
              <p-image *ngIf="col.itemType == 3"
                       [src]="rowData._key | getListImageUrl : col : dataset.controllerName : name : !col.showFullImage"
                       alt="Image" [width]="col.thumbnailWidth" [height]="col.thumbnailHeight" [preview]="col.showFullImage"
                       [imageClass]="class"></p-image>
              <span *ngIf="col.itemType == 5" [innerHTML]="rowData | getColumnValue : col"></span>
              <span *ngIf="col.itemType == 1">
                ITEM TYPE NOT_IMPLEMENTED
              </span>
            </ng-container>

          </td>
          <td *ngIf="allowColumnsFreeze && col.frozen != null && col.frozen.length > 0"
              [pEditableColumn]="col.editable ? rowData : undefined"
              [pEditableColumnField]="col.editable ? col.name : undefined"
              [pEditableColumnRowIndex]="col.editable ? rowIndex : undefined"
              [pEditableColumnDisabled]="!col.editable || !options.cellEdit"
              [class]="(rowData?.$cfInfo?.cells != null ? rowData?.$cfInfo?.cells[col.name]?.classes ?? '' : '') + ' ' + (col.classes != null ? col.classes : '')"
              [style]="col.width > 0 ? 'flex: 0 0 ' + col.width + 'px; width: ' + col.width + 'px; max-width: ' + col.width + 'px; min-width: ' + col.width + 'px' : undefined"
              [hidden]="!col.visible" [alignFrozen]="col.frozen" pFrozenColumn>

            @if (options.stackResponsiveLayout) {
              <span class="p-column-title">{{ col.caption | translate }}</span>
            }

            <!-- duplicate code below, 2nd occurance -->
            <p-cellEditor *ngIf="col.editable && !hideRowEditButton" [class.progress-bar]="col.itemType == 6">
              <!-- edit mode -->
              <ng-template pTemplate="input">
                <input *ngIf="(col.itemType == 4 || col.itemType == 0 || col.itemType == 0) && !col.mambaDataTypeIsEnum && col.dataType != 'DateTime'"
                       pInputText type="text" [ngModel]="renderCellValue(rowData, col)"
                       (blur)="setCellValue($event.target.value, rowData, col)">

                <zappCheckbox *ngIf="col.itemType == 2" style="margin-bottom: 0" [hasClickEvent]="false"
                              [value]="renderCellValue(rowData, col)" (valueChange)="setCellValue($event, rowData, col)">
                </zappCheckbox>

                <zapp-datetime *ngIf="col.dataType == 'DateTime'" [dateTimeBoxOptions]="col.quickFilterData.dateConfig"
                               [size]="'med'" [value]="renderCellValue(rowData, col)"
                               (valueChange)="setCellValue($event, rowData, col)">
                </zapp-datetime>

                <zapp-dropdown *ngIf="col.mambaDataTypeIsEnum && col.dataType != 'DateTime'" style="flex: 1"
                               [size]="'med'" [isMultiSelect]='false' [searchEverywhere]='true'
                               [dataset]="col.quickFilterData.dataset" [optionLabel]="'_text'" [optionValue]="'_id'"
                               [value]="renderMambaEnumCellValue(rowData, col)" (valueChange)="setCellValue($event, rowData, col)">
                </zapp-dropdown>
                <p-image *ngIf="col.itemType == 3"
                         [src]="rowData._key | getListImageUrl : col : dataset.controllerName : name : !col.showFullImage"
                         alt="Image" [width]="col.thumbnailWidth" [height]="col.thumbnailHeight" [preview]="col.showFullImage"
                         [imageClass]="class"></p-image>
              </ng-template>
              <!-- view only mode -->
              <ng-template pTemplate="output">
                <span *ngIf="col.itemType == 4">
                  {{rowData | getColumnValue : col}}
                </span>
                <span *ngIf="col.itemType == 2">
                  <i style="opacity: 0.8"
                     [class]="(rowData | getColumnValue : col) ? 'pi pi-check-square' : 'pi pi-stop'"></i>
                </span>
                <span *ngIf="col.itemType == 0">
                  <a [href]="rowData | getColumnValue : col">{{ rowData | getColumnValue : col }}</a>
                </span>
                <zapp-progressbar *ngIf="col.itemType == 6" [min]="col.min" [max]="col.max" [rangeColor]="false"
                                  [showProgress]="true" [allowExceedMax]="true" [size]="'med'" [value]="rowData | getColumnValue : col">
                </zapp-progressbar>
                <p-image *ngIf="col.itemType == 3"
                         [src]="rowData._key | getListImageUrl : col : dataset.controllerName : name : !col.showFullImage"
                         alt="Image" [width]="col.thumbnailWidth" [height]="col.thumbnailHeight" [preview]="col.showFullImage"
                         [imageClass]="class"></p-image>
                <span *ngIf="col.itemType == 5" [innerHTML]="rowData | getColumnValue : col"></span>
                <span *ngIf="col.itemType == 1">
                  ITEM TYPE NOT_IMPLEMENTED
                </span>
              </ng-template>
            </p-cellEditor>

            <ng-container *ngIf="!col.editable || hideRowEditButton">
              <span *ngIf="col.itemType == 4">
                {{rowData | getColumnValue : col}}
              </span>
              <span *ngIf="col.itemType == 2">
                <i style="opacity: 0.8"
                   [class]="(rowData | getColumnValue : col) ? 'pi pi-check-square' : 'pi pi-stop'"></i>
              </span>
              <span *ngIf="col.itemType == 0">
                <a [href]="rowData | getColumnValue : col">{{ rowData | getColumnValue : col }}</a>
              </span>
              <zapp-progressbar *ngIf="col.itemType == 6" [min]="col.min" [max]="col.max" [rangeColor]="false"
                                [showProgress]="true" [allowExceedMax]="true" [size]="'med'" [value]="rowData | getColumnValue : col">
              </zapp-progressbar>
              <p-image *ngIf="col.itemType == 3"
                       [src]="rowData._key | getListImageUrl : col : dataset.controllerName : name : !col.showFullImage"
                       alt="Image" [width]="col.thumbnailWidth" [height]="col.thumbnailHeight" [preview]="col.showFullImage"
                       [imageClass]="class"></p-image>
              <span *ngIf="col.itemType == 5" [innerHTML]="rowData | getColumnValue : col"></span>
              <span *ngIf="col.itemType == 1">
                ITEM TYPE NOT_IMPLEMENTED
              </span>
            </ng-container>

          </td>
        </ng-container>

        <td *ngIf="editable && !options.cellEdit" alignFrozen="right" pFrozenColumn>
          <div class="flex align-items-center justify-content-center gap-2">
            <button *ngIf="!editing && !hideRowEditButton" pButton pRipple type="button" pInitEditableRow
              icon="pi pi-pencil" (click)="rowEditInit(rowData)" class="p-button-rounded p-button-text"></button>
            <button *ngIf="editing && !hideRowEditButton" pButton pRipple type="button" pSaveEditableRow
              icon="pi pi-check" (click)="rowEditSave(rowData)"
              class="p-button-rounded p-button-text p-button-success mr-2"></button>
            <button *ngIf="editing && !hideRowEditButton" pButton pRipple type="button" pCancelEditableRow
              icon="pi pi-times" (click)="rowEditCancel(rowData, rowIndex)"
              class="p-button-rounded p-button-text p-button-danger"></button>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
      <tr *ngIf="displayAggregationsFooter()">
        <td *ngIf="options.showRowNumbers" style="width: 1%;"></td>
        <td *ngFor="let col of columns" [hidden]="!col.visible">
          <ul class="aggregations">
            <li *ngIf="col.countAggregator != null">
              <span class="caption">{{_resources.textResources.GrandCount}}</span>
              <span class="value">{{col.countAggregator.ValueFormatted}}</span>
            </li>
            <li *ngIf="col.sumAggregator != null">
              <span class="caption">{{_resources.textResources.GrandTotal}}</span>
              <span class="value">{{col.sumAggregator.ValueFormatted}}</span>
            </li>
            <li *ngIf="col.averageAggregator != null">
              <span class="caption">{{_resources.textResources.GrandAverage}}</span>
              <span class="value">{{col.averageAggregator.ValueFormatted}}</span>
            </li>
          </ul>
        </td>
        <!--edit row buttons column-->
        <td *ngIf="editable && !options.cellEdit"></td>
      </tr>
    </ng-template>

    <ng-template *ngIf="(options.isPickList || options.displayRecordsInfoRow) && options.hasMultiSelect"
      pTemplate="summary">
      <div style="text-align: left">
        <span *ngIf="state.allKeysSelected">
          {{ 'RES_LIST_PAGER_SelectedRecords' | translate }}: {{totalRecords}} of {{totalRecords}}
        </span>
        <span
          *ngIf="options.hasMultiSelect && !state.allKeysSelected && state.selectedKeys != null && state.selectedKeys.length > 0">
          {{
          'RES_LIST_PAGER_SelectedRecords'
          |
          translate
          }} {{state.selectedKeys.length}} {{_resources.textResources.Of}} {{totalRecords}}
        </span>

        <ng-container *ngIf="options.hasMultiSelect && !options.isPickList">
          <zapp-button [type]="'Button'" (buttonClicked)="selectAll()"
            title="{{ _resources.textResources.SelectAllRecordsText }}" style="margin-left: 2rem;"
            [disabled]="allSelected">
            {{_resources.textResources.SelectAllRecordsText}}
          </zapp-button>

          <ng-container *ngIf="moreThanOnePageExist">
            <zapp-button [type]="'Button'" (buttonClicked)="selectCurrentPage()"
              title="{{ _resources.textResources.SelectAllPageRecordsText }}" [disabled]="currentPageAllSelected">
              {{_resources.textResources.SelectAllPageRecordsText}}
            </zapp-button>
          </ng-container>

          <zapp-button [type]="'Button'" (buttonClicked)="deselectAll()"
            title="{{ _resources.textResources.DeselectAllRecordsText }}" [disabled]="nothingSelected">
            {{_resources.textResources.DeselectAllRecordsText}}
          </zapp-button>

          <ng-container *ngIf="moreThanOnePageExist">
            <zapp-button [type]="'Button'" (buttonClicked)="deselectCurrentPage()"
              title="{{ _resources.textResources.DeselectAllPageRecordsText }}" [disabled]="currentPageNothingSelected">
              {{_resources.textResources.DeselectAllPageRecordsText}}
            </zapp-button>
          </ng-container>

        </ng-container>

      </div>
    </ng-template>

  </p-table>
</div>

<!-- Grouping Modal -->
<zapp-modal #GroupingModal [header]="groupingHeaderModal" [body]="groupingBodyModal" [footer]="groupingFooterModal">
  <ng-template #groupingHeaderModal>
    <label>
      {{_resources.textResources.Grouping}}
    </label>
    <!--<zappIcon themeIcon="remove" [circularBackground]="false" #Icon style='float: right;' [size]="'lg'" (click)="IconOnClick($event )">
    </zappIcon>-->

  </ng-template>

  <ng-template #groupingBodyModal>
    <div class='datalist-grouping p-grid'>

      <div class='p-col-6 group-left'>

        <zappFormGroup [label]="GroupingColumnlabelTpl" [input]="GroupingColumninputTpl"
                       [description]="GroupingColumndescriptionTpl">
          <ng-template #GroupingColumnlabelTpl>
            <div zappLabelContainer>
              <label zappLabel jb-type="Label" class="jb-control ">{{_resources.textResources.Column}}</label>
            </div>
          </ng-template>

          <ng-template #GroupingColumninputTpl>
            <div zappInoutContainer>
              <select class='available-columns p-inputtext' multiple='multiple' [(ngModel)]="leftSelectedGroupingColumns">
                <option *ngFor="let column of leftGroupingColumns" [value]="column.name" style="padding: 0.25rem 0.5rem;">
                  {{ column.caption | translate }}
                </option>
              </select>
              <div class="btn-group btn-group-sm groupingButtons">
                <zapp-button [type]="'Button'" #Button1 [size]="'med'" (buttonClicked)="addGroups($event)"
                             style="margin-bottom: 0.5rem;">
                  <zappIcon themeIcon="arrow-right" [circularBackground]="false"></zappIcon>
                </zapp-button>
                <zapp-button [type]="'Button'" #Button1 [size]="'med'" (buttonClicked)="removeGroups($event)">
                  <zappIcon themeIcon="arrow-left" [circularBackground]="false"></zappIcon>
                </zapp-button>
              </div>
            </div>
          </ng-template>

          <ng-template #GroupingColumndescriptionTpl>
            <div zappDescriptionContainer>

            </div>
          </ng-template>
        </zappFormGroup>

        <br />

        <!-- Group data only -->
        <zappCheckbox [hasClickEvent]="false" #MergedGroupingCheckBox [size]="'med'" [value]="getGroupsClosed"
                      (valueChange)="getGroupsClosedChanged($event, '', model, false)">
          <label zappLabel [type]="''" #PDFPortraitOrientationLabel>
            {{_resources.textResources.GetGroupsClosed}}
          </label>
        </zappCheckbox>
      </div>


      <div class='p-col-6 group-right'>

        <zappFormGroup [label]="GroupingOrderColumnlabelTpl" [input]="GroupingOrderColumninputTpl"
                       [description]="GroupingOrderColumndescriptionTpl">
          <ng-template #GroupingOrderColumnlabelTpl>
            <div zappLabelContainer>
              <label zappLabel jb-type="Label" class="jb-control ">{{_resources.textResources.GroupingOrder}}</label>
            </div>
          </ng-template>

          <ng-template #GroupingOrderColumninputTpl>
            <div zappInoutContainer>
              <select class='grouping-columns p-inputtext' multiple='multiple' [(ngModel)]="rightSelectedGroupingColumns">
                <option *ngFor="let column of rightGroupingColumns" [value]="column.name"
                        style="padding: 0.25rem 0.5rem;">
                  {{ column.caption | translate }}
                </option>
              </select>
              <div class='btn-group btn-group-sm groupingButtons'>
                <zapp-button [type]="'Button'" #Button1 [size]="'med'" (buttonClicked)="moveGroupUp($event)"
                             style="margin-bottom: 0.5rem;">
                  <zappIcon themeIcon="arrow-up" [circularBackground]="false"></zappIcon>
                </zapp-button>
                <zapp-button [type]="'Button'" #Button1 [size]="'med'" (buttonClicked)="moveGroupDown($event)">
                  <zappIcon themeIcon="arrow-down" [circularBackground]="false"></zappIcon>
                </zapp-button>
              </div>
            </div>
          </ng-template>

          <ng-template #GroupingOrderColumndescriptionTpl>
            <div zappDescriptionContainer>

            </div>
          </ng-template>
        </zappFormGroup>

        <br />
        <!-- Merged Grouping -->
        <zappCheckbox [hasClickEvent]="false" #MergedGroupingLevelsCheckBox [size]="'med'" [value]="mergeGroupLevels"
                      (valueChange)="mergeGroupLevelsChanged($event, '', model, false)">
          <label zappLabel [type]="''" #PDFPortraitOrientationLabel>
            {{_resources.textResources.MergedGroupLevels}}
          </label>
        </zappCheckbox>
      </div>
    </div>
  </ng-template>

  <!--(buttonClicked)="ButtonOnClick($event )"-->
  <ng-template #groupingFooterModal>
    <zapp-button [type]="'Button'" #GroupingOKButton [class]="'jb-modal-ok-button'" [status]="'primary'" [size]="'med'"
                  (buttonClicked)="GroupingModalOK($event )">
      <label>OK</label>
    </zapp-button>
    <zapp-button [type]="'Button'" #GroupingCancelButton [class]="'jb-modal-ok-button'" [size]="'med'"
                  (buttonClicked)="GroupingModalCancel($event )">
      <label>Cancel</label>
    </zapp-button>
  </ng-template>

</zapp-modal>

<!-- Export Modal -->
<zapp-modal #ExportModal [header]="exportHeaderModal" [body]="exportBodyModal" [footer]="exportFooterModal">
  <ng-template #exportHeaderModal>
    <label>
      {{_resources.textResources.Export}}
    </label>
    <!--<zappIcon themeIcon="remove" [circularBackground]="false" #Icon style='float: right;' [size]="'lg'" (click)="IconOnClick($event )">
    </zappIcon>-->

  </ng-template>

  <ng-template #exportBodyModal>



    <zapp-responsive-table>
      <div zappRow>
        <div zappCell [columns]="5">

          <!-- Export Type -->
          <zappFormGroup [label]="ExportTypelabelTpl" [input]="ExportTypeinputTpl"
                         [description]="ExportTypedescriptionTpl">
            <ng-template #ExportTypelabelTpl>
              <div zappLabelContainer>
                <label zappLabel jb-type="Label" class="jb-control ">{{_resources.textResources.ExportType}}</label>
              </div>
            </ng-template>

            <ng-template #ExportTypeinputTpl>
              <div zappInoutContainer>
                <zapp-dropdown [name]="'ExportTypeDropdown'" [indexes]='[]' [isSearchable]='false'
                               [projectionSchema]='ExportTypeDropdown_ProjectionScema' [dontAllowEmptyValue]='true'
                               [searchEverywhere]='true' [dataset]="ExportTypeDataset" [optionLabel]="'_text'" [optionValue]="'_value'"
                               [model]="model" #ExportTypeDropdown name="ExportTypeDropdown" [size]="'med'"
                               [value]="selectedExportType" (valueChange)="exportTypeChanged($event, '', model, true)">
                </zapp-dropdown>
              </div>
            </ng-template>

            <ng-template #ExportTypedescriptionTpl>
              <div zappDescriptionContainer>

              </div>
            </ng-template>
          </zappFormGroup>

          <br />

          <!-- Page Title -->
          <zappFormGroup [label]="PageTitlelabelTpl" [input]="PageTitleinputTpl" [description]="PageTitledescriptionTpl">
            <ng-template #PageTitlelabelTpl>
              <div zappLabelContainer>
                <label zappLabel jb-type="Label" class="jb-control ">{{_resources.textResources.ExportTitle}}</label>
              </div>
            </ng-template>

            <ng-template #PageTitleinputTpl>
              <div zappInoutContainer>
                <zappTextbox [type]="'string'" [isDecimal]="false" [liveUpdate]="false" #exportPageTitle
                             name="exportPageTitle" [size]="'med'" [value]="dataset.controllerName"
                             (valueChange)="exportPageTitleChanged($event, '', model, false)">
                </zappTextbox>
              </div>
            </ng-template>

            <ng-template #PageTitledescriptionTpl>
              <div zappDescriptionContainer>

              </div>
            </ng-template>
          </zappFormGroup>

          <br />

          <!-- Print Range -->
          <zappFormGroup [label]="PrintRangelabelTpl" [input]="PrintRangeinputTpl"
                         [description]="PrintRangedescriptionTpl">
            <ng-template #PrintRangelabelTpl>
              <div zappLabelContainer>
                <label zappLabel jb-type="Label" class="jb-control ">{{_resources.textResources.ExportRange}}</label>
              </div>
            </ng-template>

            <ng-template #PrintRangeinputTpl>
              <div zappInoutContainer>
                <zapp-dropdown [name]="'PrintRangeDropdown'" [indexes]='[]' [isSearchable]='false'
                               [projectionSchema]='PrintRangeDropdown_ProjectionScema' [dontAllowEmptyValue]='true'
                               [searchEverywhere]='true' [dataset]="PrintRangeDataset" [optionLabel]="'_text'" [optionValue]="'_value'"
                               [model]="model" #PrintRangeDropdown name="PrintRangeDropdown" [size]="'med'"
                               [value]="selectedPrintRange" (valueChange)="printRangeChanged($event, '', model, true)">
                </zapp-dropdown>
              </div>
            </ng-template>

            <ng-template #PrintRangedescriptionTpl>
              <div zappDescriptionContainer>

              </div>
            </ng-template>
          </zappFormGroup>

          <br />

          <!-- Filename -->
          <zappFormGroup [label]="FilenamelabelTpl" [input]="FilenameinputTpl" [description]="FilenamedescriptionTpl">
            <ng-template #FilenamelabelTpl>
              <div zappLabelContainer>
                <label zappLabel jb-type="Label" class="jb-control ">{{_resources.textResources.FileName}}</label>
              </div>
            </ng-template>

            <ng-template #FilenameinputTpl>
              <div zappInoutContainer>
                <zappTextbox [type]="'string'" [isDecimal]="false" [liveUpdate]="false" #exportFilename
                             name="exportFilename" [size]="'med'" [value]="getExportFilename()"
                             (valueChange)="exportFilenameChanged($event, '', model, false)">
                </zappTextbox>
              </div>
            </ng-template>

            <ng-template #FilenamedescriptionTpl>
              <div zappDescriptionContainer>

              </div>
            </ng-template>
          </zappFormGroup>

          <br />

          <!-- Options -->
          <zappFormGroup [label]="OptionslabelTpl" [input]="OptionsinputTpl" [description]="OptionsdescriptionTpl">
            <ng-template #OptionslabelTpl>
              <div zappLabelContainer>
              </div>
            </ng-template>

            <ng-template #OptionsinputTpl>
              <div zappInoutContainer>
                <!-- PDF Portrait orientation -->
                <zappCheckbox [hasClickEvent]="false" #PDFPortraitOrientationCheckBox [size]="'med'"
                              [value]="pdfPortraitOrientation" (valueChange)="pdfPortraitOrientationChanged($event, '', model, false)"
                              [readonly]="selectedExportType != 'PDF'">
                  <label zappLabel [type]="''" #PDFPortraitOrientationLabel>
                    {{_resources.textResources.PortraitOrientation}}
                  </label>
                </zappCheckbox>

                <br />

                <!-- Include Grid Lines -->
                <zappCheckbox [hasClickEvent]="false" #IncludeGridLinesCheckBox [size]="'med'" [value]="includeGridLines"
                              (valueChange)="includeGridLinesChanged($event, '', model, false)">
                  <label zappLabel [type]="''" #IncludeGridLinesLabel>
                    {{_resources.textResources.IncludeGridLines}}
                  </label>
                </zappCheckbox>
              </div>
            </ng-template>

            <ng-template #OptionsdescriptionTpl>
              <div zappDescriptionContainer>

              </div>
            </ng-template>
          </zappFormGroup>

        </div>
        <div zappCell [columns]="2"></div>
        <div zappCell [columns]="5">

          <div class="export-color-select-group">

            <!-- Colors -->
            <zappFormGroup [label]="ColorslabelTpl" [input]="ColorsinputTpl" [description]="ColorsdescriptionTpl">
              <ng-template #ColorslabelTpl>
                <div zappLabelContainer>
                  <label zappLabel [type]="''">Colors</label>
                </div>
              </ng-template>

              <ng-template #ColorsinputTpl>
                <div zappInoutContainer>
                  <div class="group-input">
                    <zapp-color-picker [(color)]="headerLinesColor" displayInput="true"></zapp-color-picker>
                    <label style="margin-left: 1rem">{{_resources.textResources.HeaderColor}}</label>
                  </div>
                  <br />
                  <div class="group-input">
                    <zapp-color-picker [(color)]="evenLinesColor" displayInput="true"></zapp-color-picker>
                    <label style="margin-left: 1rem">{{_resources.textResources.EvenColor}}</label>
                  </div>
                  <br />
                  <div class="group-input">
                    <zapp-color-picker [(color)]="oddLinesColor" displayInput="true"></zapp-color-picker>
                    <label style="margin-left: 1rem">{{_resources.textResources.OddColor}}</label>
                  </div>
                  <br />
                  <div class="group-input">
                    <zapp-color-picker [(color)]="aggregateLinesColor" displayInput="true"></zapp-color-picker>
                    <label style="margin-left: 1rem">{{_resources.textResources.AggregateColor}}</label>
                  </div>
                  <br />
                  <div class="group-input">
                    <zapp-color-picker [(color)]="groupLinesColor" displayInput="true"></zapp-color-picker>
                    <label style="margin-left: 1rem">{{_resources.textResources.GroupColor}}</label>
                  </div>
                </div>
              </ng-template>

              <ng-template #ColorsdescriptionTpl>
                <div zappDescriptionContainer>

                </div>
              </ng-template>
            </zappFormGroup>

          </div>

        </div>
      </div>
    </zapp-responsive-table>
  </ng-template>

  <!--(buttonClicked)="ButtonOnClick($event )"-->
  <ng-template #exportFooterModal>
    <zapp-button [type]="'Button'" #ExportOKButton [class]="'jb-modal-ok-button'" [status]="'primary'" [size]="'med'"
                 (buttonClicked)="ExportModalOK($event)">
      <label>OK</label>
    </zapp-button>
    <zapp-button [type]="'Button'" #ExportCancelButton [class]="'jb-modal-ok-button'" [size]="'med'"
                 (buttonClicked)="ExportModalCancel($event)">
      <label>Cancel</label>
    </zapp-button>
  </ng-template>

</zapp-modal>

<!-- Filters Modal -->
<zapp-modal #FiltersModal id="FiltersModal" [header]="filtersHeaderModal" [body]="filtersBodyModal" [footer]="filtersFooterModal"
            [style]="filtersModalStyle">
  <ng-template #filtersHeaderModal>
    <label>
      {{_resources.textResources.FiltersPopUpTitle}}
    </label>
    <!--<zappIcon themeIcon="remove" [circularBackground]="false" #Icon style='float: right;' [size]="'lg'" (click)="IconOnClick($event )">
    </zappIcon>-->

  </ng-template>

  <ng-template #filtersBodyModal>

    <table>
      <thead>
        <tr>
          <th style="width: 20rem;">{{_resources.textResources.Column}}</th>
          <th style="width: 15rem;">{{_resources.textResources.Operator}}</th>
          <th>{{_resources.textResources.Criteria}}</th>
          <th style="width: 10rem;">{{_resources.textResources.RowOperator}}</th>
          <th style="width: 5rem;"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let filter of filters">
          <td style="width: 20rem;">
            <!-- Column -->
            <zapp-dropdown [name]="'FiltersColumnDropdown'" [indexes]='[]' [isSearchable]='false'
                           [projectionSchema]='FilterColumnDropdown_ProjectionScema' [dontAllowEmptyValue]='true'
                           [searchEverywhere]='true' [dataset]="FiltersColumnDataset" [optionLabel]="'_text'" [model]="model"
                           #FiltersColumnDropdown name="FiltersColumnDropdown" [size]="'med'" [value]="getFilterColumn(filter)"
                           (valueChange)="filtersColumnChanged($event, '', filter, true)">
            </zapp-dropdown>
          </td>
          <td style="width: 15rem;">
            <!-- Operator -->
            <zapp-dropdown [name]="'FiltersOperatorDropdown'" [indexes]='[]' [isSearchable]='false'
                           [projectionSchema]='FilterOperatorDropdown_ProjectionScema' [dontAllowEmptyValue]='true'
                           [searchEverywhere]='true' [dataset]="FiltersOperatorDataset" [optionLabel]="'_text'" [model]="model"
                           #FiltersColumnDropdown name="FiltersOperatorDropdown" [size]="'med'" [value]="getFilterOperator(filter)"
                           (valueChange)="filtersOperatorChanged($event, '', filter, true)" [filter]="findCompatibleOperators(filter)">
            </zapp-dropdown>
          </td>
          <td>
            <!-- Criteria -->
            <zappTextbox *ngIf="filter.operator != 9 && filter.operator != 10 && filter.column != null && filter.column.dataType != 'bool' && filter.column.dataType != 'DateTime'"
                         [type]="'string'" [isDecimal]="false" [liveUpdate]="false" #exportFilename name="exportFilename"
                         [size]="'med'" [value]="filter.value" (valueChange)="rowValueChange($event, '', filter, false)">
            </zappTextbox>
            <zapp-dropdown *ngIf="filter.operator != 9 && filter.operator != 10 && filter.column != null && filter.column.dataType == 'bool'"
                           [name]="'FiltersBooleanDropdown'" [indexes]='[]' [isSearchable]='false'
                           [projectionSchema]='FilterBooleanDropdown_ProjectionScema' [dontAllowEmptyValue]='true'
                           [searchEverywhere]='true' [dataset]="BooleanDataset" [optionLabel]="'_text'" [model]="model"
                           #FiltersBooleanDropdown name="FiltersBooleanDropdown" [size]="'med'" [value]="getBooleanOperator(filter)"
                           (valueChange)="filtersBooleanOperatorChanged($event, '', filter, true)">
            </zapp-dropdown>
            <zapp-datetime *ngIf="filter.operator != 9 && filter.operator != 10 && filter.column != null && filter.column.dataType == 'DateTime'"
                           [dateTimeBoxOptions]="getFilterFormatValues(filter)" #filtersDatetime name="filtersDatetime" [size]="'med'"
                           [value]="filter?.value" (valueChange)="filterDateTimeChanged($event, '', filter, true)">
            </zapp-datetime>
            <label *ngIf="filter.operator == 8 && filter.column != null && filter.column.dataType != 'DateTime'">
              {{_resources.textResources.And}}
            </label>
            <zappTextbox *ngIf="filter.operator == 8 && filter.column != null && filter.column.dataType != 'DateTime'"
                         [type]="'string'" [isDecimal]="false" [liveUpdate]="false" #exportFilename name="exportFilename"
                         [size]="'med'" [value]="filter.secondValue" (valueChange)="rowSecondValueChange($event, '', filter, false)">
            </zappTextbox>
            <zapp-datetime *ngIf="filter.operator == 8 && filter.column != null && filter.column.dataType == 'DateTime'"
                           [dateTimeBoxOptions]="getFilterFormatValues(filter)" #filtersDatetime2 name="filtersDatetime2" [size]="'med'"
                           [value]="filter?.secondValue" (valueChange)="filterDateTimeSecondChanged($event, '', filter, true)">
            </zapp-datetime>
          </td>
          <td style="width: 10rem;">
            <!-- Row Operator -->
            <zapp-dropdown [name]="'FiltersRowOperatorDropdown'" [indexes]='[]' [isSearchable]='false'
                           [projectionSchema]='FilterRowOperatorDropdown_ProjectionScema' [dontAllowEmptyValue]='true'
                           [searchEverywhere]='true' [dataset]="FiltersRowOperatorDataset" [optionLabel]="'_text'" [model]="model"
                           #FiltersColumnDropdown name="FiltersRowOperatorDropdown" [size]="'med'"
                           [value]="getRowFilterOperator(filter)" (valueChange)="filtersRowOperatorChanged($event, '', filter, true)">
            </zapp-dropdown>
          </td>
          <td style="width: 5rem; text-align: center;">
            <zapp-button [type]="'Button'" #Button2 [size]="'med'" [status]="'danger'"
                         (buttonClicked)="removeFilter(filter)">
              <span class="pi pi-times"></span>
            </zapp-button>
          </td>
        </tr>
        <tr *ngIf="filtersHaveErrors">
          <td colspan="5">
            <label zappLabel [type]="''" #Label2 [size]="'lg'" [status]="'danger'">
              {{_resources.textResources.RequiredFiltersMissingMessage}}
            </label>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <!-- Add Filter Button -->
            <zapp-button [type]="'Button'" #Button2 [size]="'med'" [status]="'primary'" (buttonClicked)="addFilter()">
              <label>
                {{_resources.textResources.AddFilter}}
              </label>
            </zapp-button>
            <!-- Clear All Filters Button -->
            <zapp-button [type]="'Button'" #Button3 [size]="'med'" (buttonClicked)="clearAllFilter()">
              <label>
                {{_resources.textResources.ClearAll}}
              </label>
            </zapp-button>
          </td>
        </tr>
      </tfoot>
    </table>
  </ng-template>

  <!--(buttonClicked)="ButtonOnClick($event )"-->
  <ng-template #filtersFooterModal>
    <zapp-button [type]="'Button'" #FiltersOKButton [class]="'jb-modal-ok-button'" [status]="'primary'" [size]="'med'"
                 (buttonClicked)="FitersModalOK($event)">
      <label>OK</label>
    </zapp-button>
    <zapp-button [type]="'Button'" #FiltersCancelButton [class]="'jb-modal-ok-button'" [size]="'med'"
                 (buttonClicked)="FiltersModalCancel($event)">
      <label>Cancel</label>
    </zapp-button>
  </ng-template>

</zapp-modal>

<!-- Views Modal -->
<zapp-modal #ViewsModal [header]="viewsHeaderModal" [body]="viewsBodyModal" [footer]="viewsFooterModal">
  <ng-template #viewsHeaderModal>
    <label>
      {{_resources.textResources.Views}}
    </label>
    <!--<zappIcon themeIcon="remove" [circularBackground]="false" #Icon style='float: right;' [size]="'lg'" (click)="IconOnClick($event )">
    </zappIcon>-->

  </ng-template>

  <ng-template #viewsBodyModal>

    <zappFormGroup [label]="FormGroup2labelTpl" [input]="FormGroup2inputTpl">

      <ng-template #FormGroup2labelTpl>
        <div zappLabelContainer>
          <label zappLabel [type]="''" #Label2 [size]="'lg'">
            {{_resources.textResources.SaveCurrentView}}
          </label>

        </div>
      </ng-template>

      <ng-template #FormGroup2inputTpl>
        <div zappInoutContainer>

          <table style="width: 100%">
            <tbody>
              <tr>
                <td>
                  <zappTextbox [type]="'string'" [isDecimal]="false" [placeholder]="_resources.textResources.ViewName"
                               [liveUpdate]="false" #viewNameInput name="viewNameInput" [size]="'med'" [value]="viewName"
                               (valueChange)="viewNameChange($event, '', model, false)" style="display: inline-block; width: 20rem;">
                  </zappTextbox>
                </td>
                <td>
                  <zappCheckbox [hasClickEvent]="false" #IsDefaultCheckBox [size]="'med'" [value]="isDefaultView"
                                (valueChange)="isDefaultViewChanged($event, '', model, false)"
                                style="display: inline-block; margin-left: 1rem">
                    <label zappLabel [type]="''" #IsDefaultViewLabel>
                      {{_resources.textResources.IsDefault}}
                    </label>
                  </zappCheckbox>
                </td>
                <td style="width: 5rem;">
                  <zapp-button [type]="'Button'" #SaveViewButton [size]="'med'" (buttonClicked)="saveCurrentView()">
                    <span class="pi pi-save"></span>
                  </zapp-button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </ng-template>
    </zappFormGroup>

    <br />
    <br />

    <label zappLabel [type]="''" #Label2 [size]="'lg'">
      {{_resources.textResources.Views}}
    </label>

    <table style="width: 100%; text-align: left;">
      <thead>
        <tr>
          <th></th>
          <th>{{_resources.textResources.View}}</th>
          <th>{{_resources.textResources.IsDefault}}</th>
          <th style="width: 5rem;"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let view of views">
          <td>
            <zapp-button [type]="'Button'" #SelectViewButton [size]="'med'" (buttonClicked)="loadView(view)">
              <span>{{_resources.textResources.Apply}}</span>
            </zapp-button>
          </td>
          <td><span>{{view.ViewName}}</span></td>
          <td>
            <zappCheckbox [hasClickEvent]="false" #IsDefaultViewCheckBox [size]="'med'" [value]="view.IsDefault"
                          (valueChange)="isDefaultViewChanged($event, '', model, false)">
            </zappCheckbox>
          </td>
          <td style="width: 5rem;">
            <zapp-button [type]="'Button'" #DeleteViewButton [size]="'med'" (buttonClicked)="deleteView(view)">
              <span class="pi pi-trash"></span>
            </zapp-button>
          </td>
        </tr>
      </tbody>
      <tfoot>

      </tfoot>
    </table>
  </ng-template>

  <!--(buttonClicked)="ButtonOnClick($event )"-->
  <ng-template #viewsFooterModal>
    <zapp-button [type]="'Button'" #FiltersOKButton [class]="'jb-modal-ok-button'" [status]="'primary'" [size]="'med'"
                 (buttonClicked)="ViewsModalOK($event)">
      <label>OK</label>
    </zapp-button>
    <zapp-button [type]="'Button'" #FiltersCancelButton [class]="'jb-modal-ok-button'" [size]="'med'"
                 (buttonClicked)="ViewsModalCancel($event)">
      <label>Cancel</label>
    </zapp-button>
  </ng-template>

</zapp-modal>

<!-- Import Modal -->
<zapp-modal #ImportModal id="ImportModal" [header]="importHeaderModal" [body]="importBodyModal" [footer]="importFooterModal">
  <ng-template #importHeaderModal>
    <label>
      {{_resources.textResources.Import}}
    </label>

  </ng-template>

  <ng-template #importBodyModal>

    <div class='import-pop-up'>
      <div>
        <a href="javascript:void(0);" jb-type="Button" class='jb-control jb-link-btn btn btn-primary btn-csv-download'
           ui-role-color="primary" (click)="downloadCsvTemplate()">
          <label jb-type="Label" class="jb-control" style="cursor: pointer;">
            {{_resources.textResources.DownloadCsv}}
          </label>
        </a>
        <br />
        <div jb-type="FileAttachment" jb-attachment="" class="jb-control list-group file-attachment">
          <div class="box has-advanced-upload">
            <div class="box__input">
              <div (drop)="onFileDrop($event)" (dragover)="onFileDragOver($event)"
                   style="border: 2px dashed #ddd; padding: 1rem; margin-bottom: 1rem; border-radius: 0.25rem;">
                <svg xmlns="http://www.w3.org/2000/svg" class="" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     style="width: 10rem; margin: 0 auto; display: block;">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                </svg>
              </div>
              <input style="display: none" type="file" jb-id="FileUploadUploadButton" #csv_file id="csv_file"
                     class="box__file" (change)="onSelectFile($event)">

              <zapp-button [type]="'Button'" [class]="'jb-modal-ok-button'" [status]="'primary'" [size]="'med'">
                <label for="csv_file">
                  <span class="fileattachment_content">{{_resources.textResources.ImportUpload}}</span>
                  <span class="fileattachment_progress">
                    <span class="fileattachment_progress_inner"></span>
                  </span>
                </label>
              </zapp-button>


            </div>
          </div>
          <span class="encoding-notice">{{_resources.textResources.ImportEncoding}}</span>
        </div>
      </div>
      <div *ngIf="listImportResults != null" class='import-results-table'>
        <label jb-type="Label" ui-role-color="success status-heading">
          {{_resources.textResources.ImportResult}}
        </label>
        <table jb-type="Table" class="jb-control status-table" style="width: 100%;">
          <tr>
            <th>
              <i jb-type="Iconism" class='glyphicon glyphicon-ok jb-control' ui-role-color="success"></i>
              <span>{{_resources.textResources.ImportSuccess}}: </span>
              <span class='import-success-value' jb-type="Label"
                    ui-role-color="success">{{listImportResults.NumberImported}}</span>
            </th>
            <th>
              <i jb-type="Iconism" class='glyphicon glyphicon-remove jb-control' ui-role-color="danger"></i>
              <span>{{_resources.textResources.ImportError}}: </span>
              <span class='import-error-value' jb-type="Label"
                    ui-role-color="danger">{{listImportResults.NumberFailled}}</span>
            </th>
          </tr>
          <tr *ngIf="listImportResults.Errors.length > 0" class='on-error error-list-row'>
            <td colspan='2'>
              <a style='cursor: pointer; display: block; margin-top: 20px;' class='toggle-error-list'>
                {{_resources.textResources.ImportErrorList}}
              </a>
            <td>
          </tr>
          <tr class='on-error error-details-row'>
            <td colspan='2'>
              <span *ngFor="let error of listImportResults.Errors; index as i" class='error-row' style="display: block;">
                {{i + 1}}. {{error.ErrorMessage}} (row #{{error.RowNumber}})
              </span>
            <td>
          </tr>
        </table>
      </div>
    </div>
  </ng-template>

  <!--(buttonClicked)="ButtonOnClick($event )"-->
  <ng-template #importFooterModal>
    <zapp-button [type]="'Button'" #FiltersOKButton [class]="'jb-modal-ok-button'" [status]="'primary'" [size]="'med'"
                 (buttonClicked)="ImportModalOK($event)">
      <label>Done</label>
    </zapp-button>
    <!--<zapp-button [type]="'Button'" #FiltersCancelButton [class]="'jb-modal-ok-button'" [size]="'med'"
      (buttonClicked)="ImportModalCancel($event)">
      <label>Cancel</label>
    </zapp-button>-->
  </ng-template>

</zapp-modal>
