import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenubarModule} from 'primeng/menubar';
import {TranslateModule} from '@ngx-translate/core';
import {SecurityModule} from '../../security/security.module';
import {zAppSearchModalComponent} from './SearchModal.component';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {zAppDevIconismModule} from '@framework/components/Iconism/iconism.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ListboxModule} from "primeng/listbox";
import {FormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {zAppDevModalModule} from "@framework/components/Modal/modal.module";
import {zAppDevDataListModule} from "@framework/components/DataList/datalist.module";
import {zAppDevButtonModule} from "@framework/components/Button/button.module";
import {zAppDevTabContainerModule} from "@framework/components/TabContainer/tabcontainer.module";
import {zAppDevTextBoxModule} from "@framework/components/TextBox/textbox.module";
import {zAppDevCheckBoxModule} from "@framework/components/CheckBox/checkbox.module";
import {zAppDevGridModule} from "@framework/components/Grid/grid.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        SecurityModule,
        MenubarModule,
        TieredMenuModule,
        zAppDevIconismModule,
        AutoCompleteModule,
        OverlayPanelModule,
        ListboxModule,
        FormsModule,
        RippleModule,
        zAppDevModalModule,
        zAppDevDataListModule,
        zAppDevButtonModule,
        zAppDevTabContainerModule,
        zAppDevTextBoxModule,
        zAppDevCheckBoxModule,
        zAppDevGridModule
    ],
  declarations: [zAppSearchModalComponent],
  exports: [zAppSearchModalComponent],
  providers:[]
})
export class zAppDevBreadcrumbModule {
}
