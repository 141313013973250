export class DateTimeBoxOptions {
    readonly: boolean;
    required: boolean;
    doesNotMakeFormDirty: boolean;
    asMonthPicker: boolean;
    datePicker: boolean;
    timePicker: boolean;
    dateFormat: string;
    timeFormat: string;
    allowtyping: boolean;

    constructor(options){
        this.readonly = options.readonly,
        this.required = options.required,
        this.doesNotMakeFormDirty = options.doesNotMakeFormDirty,
        this.asMonthPicker = options.asMonthPicker,
        this.datePicker = options.datePicker,
        this.timePicker = options.timePicker,
        this.dateFormat = options.dateFormat,
        this.timeFormat = options.timeFormat
        this.allowtyping = options.allowtyping
    }
}
