import { Component, OnInit, Input, HostBinding, ViewChild, Output, EventEmitter, ElementRef, } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Dropdown } from "primeng/dropdown";

import { zAppDevComponentSize } from "../component-size";
import { zAppDevComponentStatus } from "../component-status";
import { IThemeSevice } from "../../theme/interfaces/theme.interface";
import * as ThemeOptions from "../../theme/interfaces/options";

import { DatasourceRequest } from "@framework/datasource/models/infos/dataSource-request";
import { ManuallyEnteredDatasource } from "@framework/datasource/types/manuallyentered-datasource";
import { Datasource } from "@framework/datasource/interfaces/datasource";
import { BackendDatasource } from "@framework/datasource/types/backend-datasource";

import { DefaultSelectionUpdateEvent, project, transformStyleToObject } from "../../common";
import { Joove } from "@framework/core/Joove";
import { CF_COMPONENT } from "../../rule-engine/directives/condition-formatting.directive";
import { zAppDevBaseComponent } from "../BaseComponent/base.component";
import { first } from "rxjs/operators";

import { Cycles } from "../../common/cycles";

@Component({
  selector: "zapp-dropdown",
  templateUrl: "./DropDownBox.component.html",
  styleUrls: ["./DropDownBox.component.less"],
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevDropDownBoxComponent }]
})
export class zAppDevDropDownBoxComponent extends zAppDevBaseComponent {
  protected options: ThemeOptions.DropDownBoxThemeOptions;

  iconClasses: string = "";
  placeholder: string = "";
  filterPlaceholder: string = "";
  showClear: boolean = true;
  globalClass: string;
  panelStyleClass = "";
  hasValue: boolean = false;

  @Input()
  set value(value: any) {
    this.hasValue = value != null;
    this.notFoundItemToSelect = null;

    if (!this.isMultiSelect) {
      if (this.areValuesSame(this.selectedValue, value)) {
        return;
      }
    } else {
      if (this.value != null && this.areArraysSame(this.selectedValue, value)) {
        return;
      }
    }

    if (this.items.length > 0) {
      this.writeValueToDropdown(value);
    } else {
      this.loadDataAndSetValue(value);
    }

    //if new value is not empty but selected value is empty
    //keep value in order to check on the next dataset refresh if value is included
    if (this.selectedValue == null && value != null) {
      this.notFoundItemToSelect = value;
    }
  }

  private async loadDataAndSetValue(value: any) {
    const fetchedData = await this.refresh();
    if (fetchedData) {
      this.writeValueToDropdown(value);
    }
  }
  selectedValue: any;
  notFoundItemToSelect: any;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() name: string;

  @Input() dtoName: string;

  @Input() dataset: Datasource<any>;

  @Input() items: any[];

  @Input() projectionSchema: any;

  @Input() model: any;

  @Input() selectedItems: any[];

  @Input() optionLabel: string;

  @Input() optionValue: string;

  @Input() isSearchable: boolean;

  @Input() searchEverywhere: boolean;

  @Input() dontAllowEmptyValue: boolean;

  @Input() variation: string = "Standard";

  @Input() size: zAppDevComponentSize = '';

  @Input() status: zAppDevComponentStatus = "global";

  @Input() class: string = "";

  @Input() formTranslation: string = "";

  @Input() filter: (item: any) => boolean;

  @Input() required: boolean;

  @Input() indexes: number[];

  @Input() readonly: boolean = false;

  @Input() isMultiSelect: boolean = false;

  @Input() displayChips: boolean = true;

  @Input()
  set dependencies(value: any) {

    if (!this.hasValue || this.selectedValue == null) {
      return;
    }

    this.dependenciesChanged();
  }

  async dependenciesChanged() {
    await this.refresh();
    const oldValue = this.selectedValue;
    this.writeValueToDropdown(this.selectedValue);
    if (oldValue != this.selectedValue) {
      this.setValue(this.selectedValue);
    }
  }

  @Output()
  selectedItemsChange = new EventEmitter<any>();

  _readonly: boolean = false;

  @ViewChild('dropdown') dropdown: Dropdown;

  constructor(protected readonly translate: TranslateService, protected themeservice: IThemeSevice, protected elementRef: ElementRef) {
    super(elementRef);
    this.options = themeservice.getDropDownBoxThemeOptions();
    this.items = [];
  }

  ngOnInit() {
    super.ngOnInit();
    this.iconClasses = this.required ? "pi pi-chevron-down dropdownbox-required" : "pi pi-chevron-down";
    this.globalClass = this.options[this.variation].Classes.Global;
    this.panelStyleClass = [this.statusClass(), this.sizeClass(), this.hostClasses].join(" ");

    if (this.formTranslation != null && this.formTranslation.trim().length > 0) {
      this.translate
        .get(`${this.formTranslation}_RES_PAGETITLE_Index`)
        .pipe(first())
        .subscribe(() => {
          this.placeholder = this.translate.instant(`${this.formTranslation}_RES_COMBO_${this.name}_PROMPT`);
        });
    }

    this.handleInitialSelectedValue();
    if (this.dontAllowEmptyValue) {
      this.showClear = false;
    }
  }

  private async handleInitialSelectedValue() {
    if (this.hasValue == true || this.dataset == null || !(this.dataset instanceof ManuallyEnteredDatasource)) {
      return;
    }

    //https://indepth.dev/posts/1001/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error#possible-fixes
    await this.sleep(10);
    await this.refresh();
    let selected = this.items.find(a => a.selected);
    if (selected != null) {
      this.selectedValue = selected[this.optionValue];
      let valueUpdateEvent: DefaultSelectionUpdateEvent = new DefaultSelectionUpdateEvent();
      valueUpdateEvent.makeDirty = false;
      valueUpdateEvent.value = this.selectedValue;
      this.valueChange.emit(valueUpdateEvent);
    }
  }

  private async sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  statusClass() {
    const extraClasses = this.options[this.variation].Classes.Roles[
      this.status
    ];
    return extraClasses;
  }

  sizeClass() {
    const extraClasses = this.options[this.variation].Classes.Roles[this.size];
    return extraClasses;
  }

  onFilter($event: any) {
    //console.log($event);
  }

  writeValueToDropdown(value: any) {
    if (value == null) {
      this.selectedValue = null;
      return;
    }

    if (this.dataset && this.dataset instanceof ManuallyEnteredDatasource) {
      this.selectedValue = value;
      return;
    }

    if (Joove.Common.stringIsNullOrEmpty(this.optionValue)) {
      this.selectedValue = this.isMultiSelect
        ? this.items.filter(item => value.some(v => item._key === v._key))
        : this.items.find(item => item._key === value._key);

      return;
    }

    this.selectedValue = this.isMultiSelect
      ? this.items.filter(a => value.some(v => a[this.optionValue] == v[this.optionValue])).map(a => a[this.optionValue])
      : this.items.find(a => a[this.optionValue] == value)[this.optionValue];
  }

  setValue(value: any) {

    if (value == null) {
      this.valueChange.emit(null);
      return;
    }

    if (typeof (value._key) !== "undefined" && value._key === this.selectedValue?._key) {
      return;
    }

    let needsUpdate = false;

    if (value != null && this.dataset && this.dataset instanceof BackendDatasource) {
      if (this.isMultiSelect) {
        needsUpdate = value.length > 0 && !value.some(v => typeof (v._key) === "undefined");
      } else {
        needsUpdate = typeof (value._key) !== "undefined";
      }
    }

    if (needsUpdate) {
      var backendDatasource = <BackendDatasource<any>>this.dataset;

      let data = {
        keys: this.isMultiSelect ? `${value.map(v => v._key).join(',')}` : `${value._key}`,
        dataType: this.dtoName,
        jbID: this.name,
        model: this.modelToProjection()
      };

      backendDatasource.updateInstance(data)
        .subscribe(
          (res: any) => {

            const updatedInstanceData = Cycles.reconstructObject(res.body);
            const resData = this.isMultiSelect ? [].concat(updatedInstanceData) : updatedInstanceData;

            this.value = resData;
            this.valueChange.emit(resData);
          }
        );
    } else {
      this.valueChange.emit(value);
    }

    this.writeValueToDropdown(value);

    const optionValue = this.optionValue ?? '_key';

    if (this.isMultiSelect) {
      const selectedKeys = this.items?.filter(it => value.some(v => v[optionValue] === it[optionValue]));
      this.selectedItemsChange.emit(selectedKeys ?? value);
    } else {
      const selectedKey = typeof value == 'object' ?
        this.items?.find(it => it[optionValue] == value[optionValue]) :
        this.items?.find(it => it[optionValue] == value)?._key;
      this.selectedItemsChange.emit([selectedKey ?? value]);
    }
  }

  private areValuesSame(value1, value2): boolean {
    if (value1 == value2) {
      return true;
    }

    if ((value1 == null && value2 != null) ||
      (value1 != null && value2 == null)) {
      return false;
    }

    if (this.optionValue == null) {
      return false;
    }

    if (typeof value1 !== 'object') {
      return value1 == value2;
    }

    return value1[this.optionValue] == value2[this.optionValue];
  }

  private areArraysSame(arr1: any[], arr2: any[]) {
    if ((arr1 == null && arr2 != null) ||
      (arr1 != null && arr2 == null)) {
      return false;
    }

    if (arr1 == null && arr2 == null) {
      return true;
    }

    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  onValueChange($event: any) {
    this.setValue($event.value);
  }

  onFocus($event: any) {
  }

  onShow($event: any) {
    this.refresh();
  }

  show() {
    this.dropdown.show();
  }

  async refresh(): Promise<boolean> {
    if (this.dataset != null) {
      const datasourceRequestInfo = this.prepareDatasourceRequestInfo();
      const model = this.modelToProjection();
      const response = await this.dataset.fetchData(datasourceRequestInfo, model, this.indexes, this.name);

      this.items = this.filterItems(Cycles.reconstructObject(response.Data));

      if (this.notFoundItemToSelect != null && this.selectedValue == null) {
        this.writeValueToDropdown(this.notFoundItemToSelect);
      }

      return true;
    }

    return false;
  }

  // this was added to filter dropdowns added manually (ex. in the list.component.html)
  private filterItems(items: any[]) {
    if (this.filter == null) {
      return items;
    }

    return items.filter((i) => this.filter(i));
  }

  private prepareDatasourceRequestInfo(): DatasourceRequest {
    const request = new DatasourceRequest(
      0,
      10000,
      [],
      [],
      [],
    );

    request.indexes = this.indexes;

    return request;
  }

  private modelToProjection(): any {
    let model = this.model;
    if (this.projectionSchema != null) {
      model = project(this.model, this.projectionSchema);
    }
    if (model == null) {
      return {}
    }
    return model;
  }
}
