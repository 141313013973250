import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ApplicationTimezoneInfo  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.ApplicationTimezoneInfo";
constructor() {
this.Id = null;
this.DisplayName = null;
this.StandardName = null;
this._key = this.Id = ""; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
DisplayName:any;
StandardName:any;


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == "";
            }


}