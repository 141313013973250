import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class Invoice  {
    _originalTypeClassName = "zPMO.BO.Invoice";
constructor() {
this.Payments = [];
this.Lines = [];
this.Client = new Domain.Client();
this.RelatedDocuments = [];
this.Id = null;
this.Date = null;
this.NetAmount = null;
this.Amount = null;
this.TaxPercentage = null;
this.TaxAmount = null;
this.Details = null;
this.InvoiceNumber = null;
this.ReferenceNumber = null;
this.Template = null;
this.DateCreated = null;
this.Status = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Date:any;
NetAmount:any;
Amount:any;
TaxPercentage:any;
TaxAmount:any;
Details:any;
InvoiceNumber:any;
ReferenceNumber:any;
Template:any;
DateCreated:any;
Status:any;
Payments:any;
Project:any;
Issuer:any;
PDF:any;
Lines:any;
Client:any;
Currency:any;
PaymentBank:any;
RelatedDocuments:any;
static setPayments = ($this, items) => {
                if (items == null) items = [];
                if ($this.Payments == null) $this.Payments = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Payments.length > 0) {
                    Domain.Invoice.removePayments($this, $this.Payments[0]);
                }

                Domain.Invoice.addManyPayments($this, copyItems);
            }
static addManyPayments = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Invoice.addPayments($this, items[i]);
                }
            }
static addPayments = ($this, item) => {
                if ($this.Payments == null) $this.Payments = [];
                if (item == null || $this.Payments.indexOf(item) > -1) return;
                $this.Payments.push(item);
if (item.Invoice != $this) {
Domain.Payment.setInvoice(item, $this);
}
}
static addRangePayments = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Invoice.addPayments($this, item));
            }
static removePayments = ($this, item) => {
                if ($this == null || $this.Payments == null || $this.Payments.indexOf(item) === -1) return;
                var _index = $this.Payments.indexOf(item);
                $this.Payments.splice(_index, 1);
item.Invoice = null;
}
static clearPayments = ($this) => { $this.Payments = null; }
static setProject = ($this, value) => {
var oldValue = $this.Project;
$this.Project = value;
if (value != null) {
if (oldValue != null) {
Domain.Project.removeInvoices(oldValue, $this);
}
Domain.Project.addInvoices(value, $this);
}
}
static setIssuer = ($this, value) => {
var oldValue = $this.Issuer;
$this.Issuer = value;
if (value != null) {
}
}
static setPDF = ($this, value) => {
var oldValue = $this.PDF;
$this.PDF = value;
if (value != null) {
}
}
static setLines = ($this, items) => {
                if (items == null) items = [];
                if ($this.Lines == null) $this.Lines = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Lines.length > 0) {
                    Domain.Invoice.removeLines($this, $this.Lines[0]);
                }

                Domain.Invoice.addManyLines($this, copyItems);
            }
static addManyLines = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Invoice.addLines($this, items[i]);
                }
            }
static addLines = ($this, item) => {
                if ($this.Lines == null) $this.Lines = [];
                if (item == null || $this.Lines.indexOf(item) > -1) return;
                $this.Lines.push(item);
}
static addRangeLines = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Invoice.addLines($this, item));
            }
static removeLines = ($this, item) => {
                if ($this == null || $this.Lines == null || $this.Lines.indexOf(item) === -1) return;
                var _index = $this.Lines.indexOf(item);
                $this.Lines.splice(_index, 1);
}
static clearLines = ($this) => { $this.Lines = null; }
static setClient = ($this, value) => {
var oldValue = $this.Client;
$this.Client = value;
if (value != null) {
}
}
static setCurrency = ($this, value) => {
var oldValue = $this.Currency;
$this.Currency = value;
if (value != null) {
}
}
static setPaymentBank = ($this, value) => {
var oldValue = $this.PaymentBank;
$this.PaymentBank = value;
if (value != null) {
}
}
static setRelatedDocuments = ($this, items) => {
                if (items == null) items = [];
                if ($this.RelatedDocuments == null) $this.RelatedDocuments = [];

                const copyItems = Common.cloneObject(items);

                while ($this.RelatedDocuments.length > 0) {
                    Domain.Invoice.removeRelatedDocuments($this, $this.RelatedDocuments[0]);
                }

                Domain.Invoice.addManyRelatedDocuments($this, copyItems);
            }
static addManyRelatedDocuments = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Invoice.addRelatedDocuments($this, items[i]);
                }
            }
static addRelatedDocuments = ($this, item) => {
                if ($this.RelatedDocuments == null) $this.RelatedDocuments = [];
                if (item == null || $this.RelatedDocuments.indexOf(item) > -1) return;
                $this.RelatedDocuments.push(item);
}
static addRangeRelatedDocuments = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Invoice.addRelatedDocuments($this, item));
            }
static removeRelatedDocuments = ($this, item) => {
                if ($this == null || $this.RelatedDocuments == null || $this.RelatedDocuments.indexOf(item) === -1) return;
                var _index = $this.RelatedDocuments.indexOf(item);
                $this.RelatedDocuments.splice(_index, 1);
}
static clearRelatedDocuments = ($this) => { $this.RelatedDocuments = null; }

static GetFileName($this: any): any{return "SALES - " + $this?.Issuer?.ShortName + " " + $this?.Client?.ShortName + " INVOICE " + $this?.InvoiceNumber + ".pdf";
}
static RefreshTotalPrices($this: any): any{if (CLMS.Framework.Number.LessThanOrEqual(() => $this.Lines.length, () => 0)) {return;}
for (let _iline = 0; _iline < ($this?.Lines == null ? 0 : $this?.Lines.length); _iline++) {let line = $this?.Lines[_iline];Domain.InvoiceLine.RefreshTotalPrices(line);}
$this.NetAmount = $this?.Lines?.linq?.sum((l) => l?.Price);
$this.TaxPercentage = $this?.Lines?.linq?.where()?.firstOrDefault(null)?.TaxPercentage;
$this.TaxAmount = $this?.Lines?.linq?.sum((l) => l?.Tax);
$this.Amount = $this?.Lines?.linq?.sum((l) => l?.TotalPrice);
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}