import { Datasource } from '../interfaces/datasource';
import { DatasourceRequest } from '../models/infos/dataSource-request';
import { OrderByInfo } from '../models/infos/order-by-info';
import { ColumnInfo } from '../models/infos/column-info';
import { FilterInfo } from '../models/infos/filter-info';
import { AggregatorTypes } from '../models/enums/aggregator-types';
import { BehaviorSubject } from 'rxjs';
import { GroupByInfo } from '../models/infos/group-by-info';
import { ValueFormat } from '../../core/clms.fw.angular';
import { OrderByDirections } from '../models/enums/order-by-directions';
import { FilterOperators } from '../models/enums/filter-operators';
import { RowOperators } from '../models/enums/row-operators';

export interface DatasourceResultData {
  Data: any[];
  TotalRows: number;
}

export class ViewModelDatasource<T> extends Datasource<T> {
  collection: Array<T> = [];
  modelCallback: any;
  path: string;
  dsRequest: DatasourceRequest;
  collectionCallback: any;
  filterCallback: any;
  data: BehaviorSubject<DatasourceResultData> = new BehaviorSubject(null);

  constructor(filterCallback: any, private _controller: string, private _initialSorting: { direction: OrderByDirections, column: string }[] = []) {
    super();
    this.controllerName = _controller;
    this.dsRequest = DatasourceRequest.initDefault();
    this.filterCallback = filterCallback;
  }

  setModelCallback(callback) {
    this.modelCallback = callback;
  }

  setCollectionCallback(callback) {
    this.collectionCallback = callback;
  };

  fetchData(datasourceRequest: DatasourceRequest, formModel?: any, indexes?: any[], controlName?: string): Promise<any> {
    let returnCollection = [];
    let model = this.modelCallback();

    if (this.filterCallback != null || (datasourceRequest.filters && datasourceRequest.filters.length > 0)) {
      let filterBy = this.GetFilterBy(formModel, indexes, controlName, datasourceRequest);
      returnCollection = this.collectionCallback(datasourceRequest).filter(filterBy);
    } else {
      returnCollection = this.collectionCallback(datasourceRequest);
    }

    const totalRows = returnCollection.length;
    returnCollection = returnCollection.slice(datasourceRequest.startRow, datasourceRequest.startRow + datasourceRequest.pageSize);

    if (datasourceRequest.filters != null) {
      // datasourceRequest.filters.forEach((element: any) => {
      //     returnCollection = this.filterColumn(returnCollection, element);
      // });
    }

    if (datasourceRequest.orderBy == null ||
      datasourceRequest.orderBy.length == 0) {
      datasourceRequest.orderBy = this._initialSorting?.map(s => ({ direction: s.direction, column: { name: s.column } as any })) ?? [];
    }

    if (datasourceRequest.orderBy) {
      datasourceRequest.orderBy.forEach(element => {
        if (element.direction === OrderByDirections.ASC) {
          returnCollection.sort((a, b) => (a[element.column.name] > b[element.column.name]) ? 1 : -1);
        } else {
          returnCollection.sort((a, b) => (a[element.column.name] > b[element.column.name]) ? -1 : 1);
        }
      });
    }

    let result: any;
    if ((datasourceRequest.aggregators ?? []).length <= 0) {
      result = {
        Data: returnCollection,
        TotalRows: totalRows,
        Groups: this.groupBy(returnCollection, datasourceRequest),
        RuleEvaluations: null,
      };
    } else {
      result = this.groupBy(returnCollection, datasourceRequest, (datasourceRequest.aggregators ?? []));
    }

    this.data.next(result);

    return new Promise((resolve, reject) => {
      resolve(result);
    });
  }

  fetchFullRecordset(data: { dataType: string, keys: string, indexes: string, datasourceRequest: DatasourceRequest, model: any }): Promise<any> {

    data.datasourceRequest.startRow = 0;
    data.datasourceRequest.pageSize = 10000;

    return new Promise(async (resolve, reject) => {
      const result = await this.fetchData(data.datasourceRequest);
      resolve(result.Data);
    });
  }

  filterColumn(data: Array<any>, filter: FilterInfo): Array<any> {
    throw new Error('Method not implemented.');
  }

  sort(columnInfo: ColumnInfo, direction: number): Promise<any> {
    this.dsRequest.orderBy = [];
    const orderBy = new OrderByInfo(columnInfo, direction);
    this.dsRequest.orderBy.push(orderBy);

    return this.fetchData(this.dsRequest);
  }

  filter(searchTerm: string, columns: any[]): Promise<any> {
    this.dsRequest.filters = new Array<FilterInfo>();
    columns.forEach(element => {
      const filterInfo = new FilterInfo(element, searchTerm, 2, 7);
      this.dsRequest.filters.push(filterInfo);
    });
    return this.fetchData(this.dsRequest);
  }

  default(startRow: number, pageSize: number): Promise<any> {
    this.dsRequest.startRow = startRow;
    this.dsRequest.pageSize = pageSize;
    return this.fetchData(this.dsRequest);
  }

  update(data: Array<T>): Promise<any> {
    this.originalCollection = data;
    this.collection = [...data];//JSON.parse(JSON.stringify(data));

    return this.fetchData(this.dsRequest);
  }

  indexOf(item: T) {
    // tslint:disable-next-line
    return this.originalCollection.findIndex((x) => x['_clientId'] === item['_clientId']);
  }

  fetchAggregators(datasourceRequest: DatasourceRequest, aggregatorsRequest: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  aggregate(columnInfo: ColumnInfo, aggregatorType: AggregatorTypes): Promise<any> {
    throw new Error('Method not implemented.');
  }

  raiseSortEvent(columnInfo: ColumnInfo): void {
    if (this.sortEventCallback !== undefined) {
      this.sortEventCallback(columnInfo)
    }
  }

  raiseApplyFilterEvent(): void {
    if (this.applyFilterCallback !== undefined) {
      this.applyFilterCallback();
    }
  }
  raiseClearFilterEvent(): void {
    if (this.raiseClearFilterEvent !== undefined) {
      this.clearFilterCallback();
    }
  }

  originalElement(item: T): T {
    // tslint:disable-next-line
    return this.originalCollection.filter(x => x['_clientId'] === item['_clientId'])[0];
  }

  chartMapper(x: string, y: Array<string>, title: Array<string>, r: Array<string> = null) {
    return (item) => {
      return {
        Item: item,
        Radius: (r != null) ? r.map(name => item[name]) : [],
        Label: item[x],
        Values: y.map(name => item[name]),
        ValueLabels: title
      }
    };
  }

  chartMapperAggregation(x: string, y: Array<string>, labels?, dynamic = true) {
    let valueLabels = null;

    return (item) => {
      const group = {
        Item: item,
        Label: item.KeyFormatted,
        Radius: [],
        Values: [],
        ValueLabels: valueLabels || []
      };

      if (dynamic) {
        item.SubGroups.forEach((subGroup) => {
          if (subGroup.Aggregates.length > 0) {
            group.ValueLabels.push(subGroup.KeyFormatted);
            subGroup.Aggregates.forEach((col, index) => {
              if (index == 0) {
                group.Values[group.ValueLabels.indexOf(subGroup.KeyFormatted)] = col.Value;
              } else {
                group.Radius[group.ValueLabels.indexOf(subGroup.KeyFormatted)] = col.Value;
              }

            });
          }
        });
      } else {
        item.Aggregates.forEach((col) => {
          group.Values.push(col.Value);
          group.ValueLabels.push(labels[col.Column.toLowerCase()]);
        });
      }

      if (valueLabels == null) {
        valueLabels = group.ValueLabels;
      }

      return group;
    };
  }

  private groupBy(items: Array<T>, requestInfo: DatasourceRequest, aggregatorsInfo: Array<any> = []): any {
    if (!requestInfo.groupBy || requestInfo.groupBy.length <= 0) {
      if (aggregatorsInfo.length > 0) {
        return aggregatorsInfo.map(info => this.calculateAggregation(items, info));
      } else {
        return null;
      }
    }

    const root = this.getGroup("", "ROOT", new GroupByInfo(new ColumnInfo("ROOT", "object", null), 0, 0, 0));
    root.SubGroups = this.retrieveGroup(items, root, requestInfo.groupBy, "", 0, aggregatorsInfo);

    if (aggregatorsInfo.length > 0) {
      root.Aggregates = aggregatorsInfo.map(info => this.calculateAggregation(items, info));

      return {
        Data: null,
        TotalRows: 0,
        Groups: root,
        RuleEvaluations: null,
      };
    } else {
      return root;
    }
  }

  private calculateAggregation(items: Array<T>, aggregatorsInfo: any) {
    const result = {
      Column: aggregatorsInfo.column,
      Type: String(aggregatorsInfo.type),
      Value: null,
      ValueFormatted: null
    };

    let mapper = function recursiveParsePath(obj) {
      var parts = result.Column.split('.');
      for (var i = 0; i < parts.length; i++) {
        if (obj == null)
          return null;
        obj = obj[parts[i]];
      }
      return obj;
    }

    if (aggregatorsInfo.type == AggregatorTypes.COUNT) {
      mapper = c => 1;
    }

    result.Value = items
      .map(mapper)
      .linq
      .aggregate((c1, c2) => c1 + c2);

    if (aggregatorsInfo.type == AggregatorTypes.AVERAGE) {
      result.Value = result.Value / items.length;
    }

    if (aggregatorsInfo.formatting != null) {
      var valueFormat = new ValueFormat(aggregatorsInfo.formatting as any);
      result.ValueFormatted = valueFormat.format(result.Value);
    } else {
      result.ValueFormatted = String(result.Value);
    }

    return result;
  }

  private getGroup(id: string, key: string, group: GroupByInfo): any {
    return {
      Aggregates: [],
      Column: {
        Formatting: group.column.formatting,
        IsEncrypted: group.column.isEncrypted,
        MambaDataType: group.column.mambaDataType,
        Name: group.column.name,
      },
      Identifier: id,
      Key: key,
      KeyFormatted: key,
      StateType: 0,
      SubGroups: [],
      UniqueItemKeys: null
    }
  }

  private retrieveGroup(items: Array<T>, root: any, groupBys: Array<GroupByInfo>, prefix: string, index: number, aggregatorsInfo: Array<any> = []) {
    const groupBy = groupBys[index];
    const name = groupBy.column.name;
    index++;
    root.SubGroups = items.linq
      .groupBy(c => c[name])
      .select(i => {
        const groupKey = `${name}/---/${String(i.key).replace(' ', '___')}`;
        const group = this.getGroup(`${prefix}___${groupKey}`, i.key, groupBy);
        if (index < groupBys.length) {
          group.SubGroups = this.retrieveGroup(i['source'].toArray(), group, groupBys, groupKey, index, aggregatorsInfo);
        } else {
          group.UniqueItemKeys = i['source'].linq.select(c => c.Id).toArray();
        }
        group.Aggregates = aggregatorsInfo.map(info => this.calculateAggregation(i['source'].toArray(), info));
        group.Items = i['source'].toArray();
        return group;
      }).toArray();

    return root.SubGroups;
  }

  private GetFilterBy(formModel: any, indexes: any[], controlName: string, requestInfo: DatasourceRequest): any {
    let filterBy = this.filterCallback(formModel, indexes, controlName) as any;

    if (requestInfo.filters && requestInfo.filters.length > 0) {
      let q: { condition: string, operator: string }[] = [];

      for (let i = 0; i < requestInfo.filters.length; i++) {
        const filter = requestInfo.filters[i];

        const name = filter.column.name.replace(/\./g, '?.');
        const operator = filter.operator;
        const rowOperator = filter.rowOperator;
        const value = filter.value;

        let queryItem: { condition: string, operator: string } = { condition: '', operator: '' };
        switch (operator) {
          case FilterOperators.EQUAL_TO:
            queryItem.condition = `DataItem.${name} === '${value}'`;
            break;
          case FilterOperators.LIKE:
            queryItem.condition = `String(DataItem.${name}).toUpperCase().includes(String('${value}').toUpperCase())`;
            break;
          default:
            break;
        }

        switch (rowOperator) {
          case RowOperators.AND:
            queryItem.operator = `&&`;
            break;
          case RowOperators.OR:
            queryItem.operator = `||`;
            break;
          default:
            break;
        }

        if (queryItem.condition != null && queryItem.condition.length > 0) {
          q.push(queryItem);
        }
      }

      const datasetFilterBy = filterBy;
      
      const qArr = [];
      if (q.length > 0) {
        const lastItem = q.pop();
        for (let qItem of q) {
          qArr.push(`${qItem.condition} ${qItem.operator}`);
        }
        qArr.push(`${lastItem.condition}`);
      }
      
      const qs = qArr.join(` `);

      filterBy = (DataItem, index, items) => {
        return datasetFilterBy(DataItem, index, items) && eval(qs);
      };
    }

    return filterBy;
  }
}
