import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class WorkflowSchedule  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Workflow.WorkflowSchedule";
constructor() {
this.Workflow = null;
this.Description = null;
this.StartDateTime = null;
this.ExpireOn = null;
this.CronExpression = null;
this.LastExecution = null;
this.LastExecutionMessage = null;
this.IsLastExecutionSuccess = false;
this.Active = false;
this.HumanReadableExpression = null;
this.NextExecutionTime = null;
this._key = this.Workflow = ""; 
}

_key: any;
private _Workflow:any;
get Workflow(): any {
    return this._Workflow;
  }
set Workflow(value: any) {
    this._Workflow = value;
    this._key = value;
  }
Description:any;
StartDateTime:any;
ExpireOn:any;
CronExpression:any;
LastExecution:any;
LastExecutionMessage:any;
IsLastExecutionSuccess:any;
Active:any;
HumanReadableExpression:any;
NextExecutionTime:any;


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Workflow == null || $this.Workflow == "";
            }


}