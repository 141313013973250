export enum FilterOperators {
    NONE,
    EQUAL_TO,
    NOT_EQUAL_TO,
    LESS_THAN,
    GREATER_THAN,
    LESS_THAN_OR_EQUAL_TO,
    GREATER_THAN_OR_EQUAL_TO,
    LIKE,
    RANGE,
    HAS_VALUE,
    HAS_NO_VALUE
}
