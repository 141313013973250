import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class LeadAutomationAction  {
    _originalTypeClassName = "zPMO.BO.LeadAutomationAction";
constructor() {
this.SpecificAccounts = [];
this.EnpointResponseHandling = [];
this.Id = null;
this.Type = null;
this.NotificationType = null;
this.SendEmail = false;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Type:any;
NotificationType:any;
SendEmail:any;
Automation:any;
SpecificAccounts:any;
NewLeadFlow:any;
Endpoint:any;
EnpointResponseHandling:any;
static setAutomation = ($this, value) => {
var oldValue = $this.Automation;
$this.Automation = value;
if (value != null) {
if (oldValue != null) {
Domain.LeadAutomation.removeActions(oldValue, $this);
}
Domain.LeadAutomation.addActions(value, $this);
}
}
static setSpecificAccounts = ($this, items) => {
                if (items == null) items = [];
                if ($this.SpecificAccounts == null) $this.SpecificAccounts = [];

                const copyItems = Common.cloneObject(items);

                while ($this.SpecificAccounts.length > 0) {
                    Domain.LeadAutomationAction.removeSpecificAccounts($this, $this.SpecificAccounts[0]);
                }

                Domain.LeadAutomationAction.addManySpecificAccounts($this, copyItems);
            }
static addManySpecificAccounts = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.LeadAutomationAction.addSpecificAccounts($this, items[i]);
                }
            }
static addSpecificAccounts = ($this, item) => {
                if ($this.SpecificAccounts == null) $this.SpecificAccounts = [];
                if (item == null || $this.SpecificAccounts.indexOf(item) > -1) return;
                $this.SpecificAccounts.push(item);
}
static addRangeSpecificAccounts = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.LeadAutomationAction.addSpecificAccounts($this, item));
            }
static removeSpecificAccounts = ($this, item) => {
                if ($this == null || $this.SpecificAccounts == null || $this.SpecificAccounts.indexOf(item) === -1) return;
                var _index = $this.SpecificAccounts.indexOf(item);
                $this.SpecificAccounts.splice(_index, 1);
}
static clearSpecificAccounts = ($this) => { $this.SpecificAccounts = null; }
static setNewLeadFlow = ($this, value) => {
var oldValue = $this.NewLeadFlow;
$this.NewLeadFlow = value;
if (value != null) {
}
}
static setEndpoint = ($this, value) => {
var oldValue = $this.Endpoint;
$this.Endpoint = value;
if (value != null) {
}
}
static setEnpointResponseHandling = ($this, items) => {
                if (items == null) items = [];
                if ($this.EnpointResponseHandling == null) $this.EnpointResponseHandling = [];

                const copyItems = Common.cloneObject(items);

                while ($this.EnpointResponseHandling.length > 0) {
                    Domain.LeadAutomationAction.removeEnpointResponseHandling($this, $this.EnpointResponseHandling[0]);
                }

                Domain.LeadAutomationAction.addManyEnpointResponseHandling($this, copyItems);
            }
static addManyEnpointResponseHandling = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.LeadAutomationAction.addEnpointResponseHandling($this, items[i]);
                }
            }
static addEnpointResponseHandling = ($this, item) => {
                if ($this.EnpointResponseHandling == null) $this.EnpointResponseHandling = [];
                if (item == null || $this.EnpointResponseHandling.indexOf(item) > -1) return;
                $this.EnpointResponseHandling.push(item);
}
static addRangeEnpointResponseHandling = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.LeadAutomationAction.addEnpointResponseHandling($this, item));
            }
static removeEnpointResponseHandling = ($this, item) => {
                if ($this == null || $this.EnpointResponseHandling == null || $this.EnpointResponseHandling.indexOf(item) === -1) return;
                var _index = $this.EnpointResponseHandling.indexOf(item);
                $this.EnpointResponseHandling.splice(_index, 1);
}
static clearEnpointResponseHandling = ($this) => { $this.EnpointResponseHandling = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}