import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class WorkflowExecutionResult  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Workflow.WorkflowExecutionResult";
constructor() {
this.Status = null;
this.WorkflowExecutionResultKey = null;
this._key = this.WorkflowExecutionResultKey = 0; 
}

_key: any;
Status:any;
Context:any;
private _WorkflowExecutionResultKey:any;
get WorkflowExecutionResultKey(): any {
    return this._WorkflowExecutionResultKey;
  }
set WorkflowExecutionResultKey(value: any) {
    this._WorkflowExecutionResultKey = value;
    this._key = value;
  }
static setContext = ($this, value) => {
var oldValue = $this.Context;
$this.Context = value;
if (value != null) {
if (oldValue != null && oldValue.Result != null) { oldValue.Result = null; }
if ($this.Context.Result !== $this) {
Domain.WorkflowContextBase.setResult(value, $this);
}
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.WorkflowExecutionResultKey == null || $this.WorkflowExecutionResultKey == 0;
            }


}