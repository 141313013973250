import { Directive, Input, HostBinding, OnInit } from '@angular/core';
import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';

@Directive({
  selector: '[zappCell]'
})
export class zAppDevColumnDirective implements OnInit {
  @HostBinding('class') cellClass: string;

  @Input() columns: number;
  @Input() columnsMd: number;
  @Input() columnsLg: number;
  @Input() columnsXl: number;
  options: ThemeOptions.LayoutThemeOptions;

  constructor(private themeService: IThemeSevice) {
  }

  ngOnInit(): void {
    this.options = this.themeService.getLayoutThemeOptions();
    this.cellClass = this.options.ColumnClass(this.columns, this.columnsMd, this.columnsLg, this.columnsXl);
  }
}
