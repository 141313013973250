import { Datasource } from "../interfaces/datasource";
import { AggregatorTypes } from "../models/enums/aggregator-types";
import { ColumnInfo } from "../models/infos/column-info";
import { DatasourceRequest } from "../models/infos/dataSource-request";

export class ManuallyEnteredDatasource<T> extends Datasource<T> {
  
  private collection: Array<T> = [];

  constructor(collection: any) {
    super();
    this.collection = collection
  }

  fetchData(datasourceRequest: DatasourceRequest, model?: any): Promise<any> {
    let returnCollection = this.collection;

    return new Promise((resolve, reject) => {
      resolve({ Data: returnCollection, TotalRows: returnCollection.length });
    });
  }

  fetchAggregators(datasourceRequest: DatasourceRequest, aggregatorsRequest: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

  sort(columnInfo: ColumnInfo, direction: number): Promise<any> {
    throw new Error("Method not implemented.");
  }

  filter(searchTerm: string, columns: any[]): Promise<any> {
    throw new Error("Method not implemented.");
  }

  default(startRow: number, pageSize: number): Promise<any> {
    throw new Error("Method not implemented.");
  }

  aggregate(columnInfo: ColumnInfo, aggregatorType: AggregatorTypes): Promise<any> {
    throw new Error("Method not implemented.");
  }

  raiseSortEvent(): void {
    throw new Error("Method not implemented.");
  }

  raiseApplyFilterEvent(): void {
    throw new Error("Method not implemented.");
  }
  raiseClearFilterEvent(): void {
    throw new Error("Method not implemented.");
  }

  indexOf(item: any): number {
    throw new Error("Method not implemented.");
  }

  originalElement(item: T): T {
    throw new Error("Method not implemented.");
  }
}
