import { Directive, OnInit, HostBinding } from '@angular/core';
import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';

@Directive({
  selector: '[zappRow]',
})

export class zAppDevRowDirective implements OnInit {
  @HostBinding('class') rowClass: string;

  options: ThemeOptions.LayoutThemeOptions;
  constructor(private themeService: IThemeSevice) {
  }

  ngOnInit() {
    this.options = this.themeService.getLayoutThemeOptions();
    this.rowClass = `grid ${this.options.RowClass}`;
  }
}
