<dp-day-calendar #dayCalendar
                 (onGoToCurrent)="onGoToCurrent.emit()"
                 (onLeftNav)="onLeftNavClick($event)"
                 (onRightNav)="onRightNavClick($event)"
                 (onSelect)="dateSelected($event)"
                 [config]="componentConfig"
                 [displayDate]="displayDate"
                 [ngModel]="_selected"
                 [theme]="theme">
</dp-day-calendar>
<dp-time-select #timeSelect
                (onChange)="timeChange($event)"
                [config]="componentConfig"
                [ngModel]="_selected"
                [theme]="theme">
</dp-time-select>
