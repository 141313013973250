import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class Project  {
    _originalTypeClassName = "zPMO.BO.Project";
constructor() {
this.Client = new Domain.Client();
this.Payment = [];
this.Comments = [];
this.Documents = [];
this.Resources = [];
this.Invoices = [];
this.Tasks = [];
this.Id = null;
this.Name = null;
this.Description = null;
this.DateStarted = null;
this.DateEnded = null;
this.Budget = null;
this.RemainingAmount = null;
this.Status = null;
this.IssueTrackerURL = null;
this.FileSystemURL = null;
this.CustomerAssignmentId = null;
this.ResourceCost = null;
this.NumberOfLicences = null;
this.ApprovedSupportHours = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Description:any;
DateStarted:any;
DateEnded:any;
Budget:any;
RemainingAmount:any;
Status:any;
IssueTrackerURL:any;
FileSystemURL:any;
CustomerAssignmentId:any;
ResourceCost:any;
NumberOfLicences:any;
ApprovedSupportHours:any;
Client:any;
Payment:any;
Comments:any;
Product:any;
Documents:any;
Company:any;
FiscalYear:any;
Resources:any;
Invoices:any;
OriginatingLead:any;
Tasks:any;
static setClient = ($this, value) => {
var oldValue = $this.Client;
$this.Client = value;
if (value != null) {
}
}
static setPayment = ($this, items) => {
                if (items == null) items = [];
                if ($this.Payment == null) $this.Payment = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Payment.length > 0) {
                    Domain.Project.removePayment($this, $this.Payment[0]);
                }

                Domain.Project.addManyPayment($this, copyItems);
            }
static addManyPayment = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Project.addPayment($this, items[i]);
                }
            }
static addPayment = ($this, item) => {
                if ($this.Payment == null) $this.Payment = [];
                if (item == null || $this.Payment.indexOf(item) > -1) return;
                $this.Payment.push(item);
}
static addRangePayment = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Project.addPayment($this, item));
            }
static removePayment = ($this, item) => {
                if ($this == null || $this.Payment == null || $this.Payment.indexOf(item) === -1) return;
                var _index = $this.Payment.indexOf(item);
                $this.Payment.splice(_index, 1);
}
static clearPayment = ($this) => { $this.Payment = null; }
static setComments = ($this, items) => {
                if (items == null) items = [];
                if ($this.Comments == null) $this.Comments = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Comments.length > 0) {
                    Domain.Project.removeComments($this, $this.Comments[0]);
                }

                Domain.Project.addManyComments($this, copyItems);
            }
static addManyComments = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Project.addComments($this, items[i]);
                }
            }
static addComments = ($this, item) => {
                if ($this.Comments == null) $this.Comments = [];
                if (item == null || $this.Comments.indexOf(item) > -1) return;
                $this.Comments.push(item);
}
static addRangeComments = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Project.addComments($this, item));
            }
static removeComments = ($this, item) => {
                if ($this == null || $this.Comments == null || $this.Comments.indexOf(item) === -1) return;
                var _index = $this.Comments.indexOf(item);
                $this.Comments.splice(_index, 1);
}
static clearComments = ($this) => { $this.Comments = null; }
static setProduct = ($this, value) => {
var oldValue = $this.Product;
$this.Product = value;
if (value != null) {
}
}
static setDocuments = ($this, items) => {
                if (items == null) items = [];
                if ($this.Documents == null) $this.Documents = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Documents.length > 0) {
                    Domain.Project.removeDocuments($this, $this.Documents[0]);
                }

                Domain.Project.addManyDocuments($this, copyItems);
            }
static addManyDocuments = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Project.addDocuments($this, items[i]);
                }
            }
static addDocuments = ($this, item) => {
                if ($this.Documents == null) $this.Documents = [];
                if (item == null || $this.Documents.indexOf(item) > -1) return;
                $this.Documents.push(item);
}
static addRangeDocuments = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Project.addDocuments($this, item));
            }
static removeDocuments = ($this, item) => {
                if ($this == null || $this.Documents == null || $this.Documents.indexOf(item) === -1) return;
                var _index = $this.Documents.indexOf(item);
                $this.Documents.splice(_index, 1);
}
static clearDocuments = ($this) => { $this.Documents = null; }
static setCompany = ($this, value) => {
var oldValue = $this.Company;
$this.Company = value;
if (value != null) {
}
}
static setFiscalYear = ($this, value) => {
var oldValue = $this.FiscalYear;
$this.FiscalYear = value;
if (value != null) {
}
}
static setResources = ($this, items) => {
                if (items == null) items = [];
                if ($this.Resources == null) $this.Resources = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Resources.length > 0) {
                    Domain.Project.removeResources($this, $this.Resources[0]);
                }

                Domain.Project.addManyResources($this, copyItems);
            }
static addManyResources = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Project.addResources($this, items[i]);
                }
            }
static addResources = ($this, item) => {
                if ($this.Resources == null) $this.Resources = [];
                if (item == null || $this.Resources.indexOf(item) > -1) return;
                $this.Resources.push(item);
}
static addRangeResources = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Project.addResources($this, item));
            }
static removeResources = ($this, item) => {
                if ($this == null || $this.Resources == null || $this.Resources.indexOf(item) === -1) return;
                var _index = $this.Resources.indexOf(item);
                $this.Resources.splice(_index, 1);
}
static clearResources = ($this) => { $this.Resources = null; }
static setInvoices = ($this, items) => {
                if (items == null) items = [];
                if ($this.Invoices == null) $this.Invoices = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Invoices.length > 0) {
                    Domain.Project.removeInvoices($this, $this.Invoices[0]);
                }

                Domain.Project.addManyInvoices($this, copyItems);
            }
static addManyInvoices = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Project.addInvoices($this, items[i]);
                }
            }
static addInvoices = ($this, item) => {
                if ($this.Invoices == null) $this.Invoices = [];
                if (item == null || $this.Invoices.indexOf(item) > -1) return;
                $this.Invoices.push(item);
if (item.Project != $this) {
Domain.Invoice.setProject(item, $this);
}
}
static addRangeInvoices = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Project.addInvoices($this, item));
            }
static removeInvoices = ($this, item) => {
                if ($this == null || $this.Invoices == null || $this.Invoices.indexOf(item) === -1) return;
                var _index = $this.Invoices.indexOf(item);
                $this.Invoices.splice(_index, 1);
item.Project = null;
}
static clearInvoices = ($this) => { $this.Invoices = null; }
static setOriginatingLead = ($this, value) => {
var oldValue = $this.OriginatingLead;
$this.OriginatingLead = value;
if (value != null) {
}
}
static setTasks = ($this, items) => {
                if (items == null) items = [];
                if ($this.Tasks == null) $this.Tasks = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Tasks.length > 0) {
                    Domain.Project.removeTasks($this, $this.Tasks[0]);
                }

                Domain.Project.addManyTasks($this, copyItems);
            }
static addManyTasks = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Project.addTasks($this, items[i]);
                }
            }
static addTasks = ($this, item) => {
                if ($this.Tasks == null) $this.Tasks = [];
                if (item == null || $this.Tasks.indexOf(item) > -1) return;
                $this.Tasks.push(item);
if (item.RelatedProject != $this) {
Domain.TaskEntry.setRelatedProject(item, $this);
}
}
static addRangeTasks = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Project.addTasks($this, item));
            }
static removeTasks = ($this, item) => {
                if ($this == null || $this.Tasks == null || $this.Tasks.indexOf(item) === -1) return;
                var _index = $this.Tasks.indexOf(item);
                $this.Tasks.splice(_index, 1);
item.RelatedProject = null;
}
static clearTasks = ($this) => { $this.Tasks = null; }

static RecalculateCosts($this: any): any{for (let _ientry = 0; _ientry < ($this?.Resources == null ? 0 : $this?.Resources.length); _ientry++) {let entry = $this?.Resources[_ientry];entry.Cost = Joove.Common.safeNumber((Joove.Common.cast(entry?.Time, "number"))) * Joove.Common.safeNumber(entry?.Resource?.Rates?.linq?.lastOrDefault()?.Rate);}
$this.ResourceCost = $this?.Resources?.linq?.sum((r) => r?.Cost);
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}