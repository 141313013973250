import { Pipe, PipeTransform } from '@angular/core';
import { DataListColumnInfo } from './helpers/List.helper.classes';

@Pipe({
  name: 'visibleColumns',
  pure: false
})
export class VisibleColumnsPipe implements PipeTransform {
  transform(items: DataListColumnInfo[]): any {
    if (!items) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(i => i.visible != false);
  }
}
