import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ClientContact  {
    _originalTypeClassName = "zPMO.BO.ClientContact";
constructor() {
this.Client = new Domain.Client();
this.Id = null;
this.Position = null;
this.Name = null;
this.Phone = null;
this.AlternativePhone = null;
this.Email = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Position:any;
Name:any;
Phone:any;
AlternativePhone:any;
Email:any;
Client:any;
static setClient = ($this, value) => {
var oldValue = $this.Client;
$this.Client = value;
if (value != null) {
if (oldValue != null) {
Domain.Client.removeContacts(oldValue, $this);
}
Domain.Client.addContacts(value, $this);
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}