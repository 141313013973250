import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class MenuItem  {
    _originalTypeClassName = "zPMO.BO.MenuItem";
constructor() {
this.children = [];
this.label = null;
this.icon = null;
this.command = null;
this.seperator = false;
this.MenuItemKey = null;
this._key = this.MenuItemKey = 0; 
}

_key: any;
label:any;
icon:any;
command:any;
seperator:any;
children:any;
private _MenuItemKey:any;
get MenuItemKey(): any {
    return this._MenuItemKey;
  }
set MenuItemKey(value: any) {
    this._MenuItemKey = value;
    this._key = value;
  }
static setchildren = ($this, items) => {
                if (items == null) items = [];
                if ($this.children == null) $this.children = [];

                const copyItems = Common.cloneObject(items);

                while ($this.children.length > 0) {
                    Domain.MenuItem.removechildren($this, $this.children[0]);
                }

                Domain.MenuItem.addManychildren($this, copyItems);
            }
static addManychildren = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.MenuItem.addchildren($this, items[i]);
                }
            }
static addchildren = ($this, item) => {
                if ($this.children == null) $this.children = [];
                if (item == null || $this.children.indexOf(item) > -1) return;
                $this.children.push(item);
}
static addRangechildren = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.MenuItem.addchildren($this, item));
            }
static removechildren = ($this, item) => {
                if ($this == null || $this.children == null || $this.children.indexOf(item) === -1) return;
                var _index = $this.children.indexOf(item);
                $this.children.splice(_index, 1);
}
static clearchildren = ($this) => { $this.children = null; }

static CreateOperation(op: any): any{let mi = new Domain.MenuItem();
mi.label = op?.Name;
mi.icon = "pi pi-fw pi-star";
return mi;
}
static CreatePermission(perm: any): any{let mi = new Domain.MenuItem();
mi.label = perm?.Name;
mi.icon = "pi pi-calendar-plus";
for (let _iop = 0; _iop < (perm?.Operations == null ? 0 : perm?.Operations.length); _iop++) {let op = perm?.Operations[_iop];mi?.children?.add(Domain.MenuItem.CreateOperation(op));}
return mi;
}
static CreateRole(role: any): any{let mi = new Domain.MenuItem();
mi.label = role?.Name;
mi.icon = "pi pi-user";
for (let _ip = 0; _ip < (role?.Permissions == null ? 0 : role?.Permissions.length); _ip++) {let p = role?.Permissions[_ip];mi?.children?.add(Domain.MenuItem.CreatePermission(p));}
return mi;
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.MenuItemKey == null || $this.MenuItemKey == 0;
            }


}