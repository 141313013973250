import { Component, HostBinding, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { zAppDevComponentSize } from '../component-size';
import { zAppDevComponentStatus } from '../component-status';
import { DateTimeBoxMode } from './DateTimeBox.mode';
import { DateTimeBoxOptions } from './DateTimeBox.model';

import { IThemeSevice } from '../../theme/interfaces/theme.interface';
import * as ThemeOptions from '../../theme/interfaces/options';
import * as moment from 'moment';
import { Joove } from '@framework/core/Joove';
import { CF_COMPONENT } from '../../rule-engine/directives/condition-formatting.directive';
import { zAppDevBaseComponent } from '../BaseComponent/base.component';
import { takeUntil } from 'rxjs/operators';
import { ContextService } from '../../../@core/services/Context.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'zapp-datetime',
  templateUrl: './DateTimeBox.component.html',
  styleUrls: ["./DateTimeBox.component.less"],
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevDateTimeBoxComponent }]
})
export class zAppDevDateTimeBoxComponent extends zAppDevBaseComponent {
  protected options: ThemeOptions.DateTimeBoxThemeOptions;

  mode: DateTimeBoxMode = 'day';
  inputClass = "";
  view: 'month' | 'date' = 'date';
  dateFormat: string = '';
  timeFormat: string = '';
  primeNgDateFormat: string = 'mm/dd/yy';
  panelStyleClass: string = '';

  @Input()
  set value(value: any) {
    if (value == null) {
      this._value = null;
    } else if (moment(value).isValid()) {
      this._value = moment(value).toDate();
    }
  }
  _value: any;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() name: string;

  @Input() dateTimeBoxOptions: DateTimeBoxOptions;

  @Input() size: zAppDevComponentSize = 'med';

  @Input() status: zAppDevComponentStatus = 'global';

  @Input() variation: string = 'Standard';

  @Input() stepHour: number = 1;

  @Input() stepMinute: number = 1;

  @Input()
  set minDate(value: Date | string | undefined) {
    if (value == null) {
      this._minDate = undefined;
      return;
    }

    this._minDate = new Date(value);
  }
  _minDate: Date | undefined;

  @Input()
  set maxDate(value: Date | string | undefined) {
    if (value == null) {
      this._maxDate = undefined;
      return;
    }

    this._maxDate = new Date(value);
  }
  _maxDate: Date | undefined;

  hidden: boolean = false;

  disabled: boolean = false;

  @Input() readonly: boolean = false;

  @HostBinding('class.zapp-required')
  get requiredDatetimebox() {
    return this.required === true;
  }

  private primeNgDateFormatDictionary: { [id: string]: string; } = {
    "dddd, D MMMM YYYY": "DD, d MM yy",
    "ddd, D MMMM YYYY": "D, d MM yy",
    "DD MMMM YYYY": "dd MM yy",
    "D-MMM-YYYY": "d-M-yy",
    "D/M/YYYY": "d/m/yy",
    "D/MM/YYYY": "d/mm/yy",
    "DD/MM/YYYY": "dd/mm/yy",
    "D-MM-YYYY": "d-mm-yy",
    "MM/DD/YYYY": "mm/dd/yy",
    "MM-DD-YYYY": "mm-dd-yy",
    "YYYY/MM/DD": "yy/mm/dd",
    "YYYY-MM-DD": "yy-mm-dd",
    "D/M": "d/m",
    "D/M/YY": "d/m/y",
    "DD/MM/YY": "dd/mm/y",
    "D-MMM": "d-M",
    "YYYY": "yy",
    "MMMM YYYY": "MM yy",
    "MMM-YYYY": "M-yy",
    "MMM-YY": "M-y",
    "M/YYYY": "m/yy",
    "MM/YYYY": "mm/yy",
    "YYYY/M": "yy/m",
    "YYYY/MM": "yy/mm",
    "M/YY": "m/y",
    "MM/YY": "mm/y",
    "YY/M": "y/m",
    "YY/MM": "y/mm",
    "H:mm": ""
  }

  isTouchDevice: boolean = false;
  get timeOnly() {
    return this.dateTimeBoxOptions.timePicker && !this.dateTimeBoxOptions.datePicker;
  }

  constructor(
    protected themeservice: IThemeSevice,
    protected elementRef: ElementRef,
    protected contextService: ContextService,
    protected deviceService: DeviceDetectorService,
  ) {
    super(elementRef);
    this.options = themeservice.getDateTimeBoxThemeOptions();

    this.isTouchDevice = this.deviceService.isMobile() || this.deviceService.isTablet();

    this.statusSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.getInputClass();
    });

    this.contextService.context$.pipe(takeUntil(this.destroy$)).subscribe((context: Joove.IContext) => {
      this.refreshDateFormat();
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getInputClass();

    this.view = this.dateTimeBoxOptions.asMonthPicker ? 'month' : 'date';

    if (this.dateTimeBoxOptions.asMonthPicker) {
      this.mode = "month";
    } else if (this.dateTimeBoxOptions.timePicker && !this.dateTimeBoxOptions.datePicker) {
      this.mode = "time";
    } else if (this.dateTimeBoxOptions.timePicker && this.dateTimeBoxOptions.datePicker) {
      this.mode = "daytime";
    }

    this.refreshDateFormat();
  }

  refreshDateFormat() {
    if (this.dateTimeBoxOptions.asMonthPicker === true) {
      this.view = 'month';
      this.dateFormat = window._context.currentLocale.monthDayPattern;
      this.primeNgDateFormat = 'mm/yy';
    }

    if (this.dateTimeBoxOptions.dateFormat != null && this.dateTimeBoxOptions.dateFormat.trim().length > 0) {
      this.dateFormat = this.dateTimeBoxOptions.dateFormat;
      this.primeNgDateFormat = this.primeNgDateFormatDictionary[this.dateFormat];
    } else if (this.dateTimeBoxOptions.asMonthPicker !== true) {

      if (this.mode === 'time') {
        this.dateFormat = Joove.Common.changeDateTimeFormat(window._context.currentLocale.shortTimePattern);
      } else if (this.mode === 'daytime') {
        this.dateFormat = Joove.Common.changeDateTimeFormat(`${window._context.currentLocale.shortDatePattern}`);
        this.timeFormat = Joove.Common.changeDateTimeFormat(`${window._context.currentLocale.shortTimePattern}`)
      } else {
        //apply default format...
        this.dateFormat = Joove.Common.changeDateTimeFormat(window._context.currentLocale.shortDatePattern);
      }

      const globalFormat = this.primeNgDateFormatDictionary[this.dateFormat];
      if (globalFormat != null) {
        this.primeNgDateFormat = globalFormat;
      }
      
    }

    if (this.dateTimeBoxOptions.timePicker && this.dateTimeBoxOptions.timeFormat != null && this.dateTimeBoxOptions.timeFormat.trim().length > 0) {
      this.timeFormat = this.dateTimeBoxOptions.timeFormat;
    }
  }

  onSelect($event) {
    this.setValue($event);
  }

  onMonthChange(event) {
    clearTimeout(this.onInputBlurTimeout);
  }

  private onInputBlurTimeout;
  onInputBlur($event) {
    this.onInputBlurTimeout = setTimeout(() => {
      this.setValue($event.target.value);
    }, 100);
  }

  setValue(value) {
    clearTimeout(this.onInputBlurTimeout);

    if (value == null || (typeof value === 'string' && value.trim().length <= 0)) {
      this.valueChange.emit(null);
      return;
    }

    const validDate = this.isValidDate(value);

    if (!validDate && this.value == null) {
      return;
    }

    // ex. value = '10:05', do not affect date part (date part changes to today fix) - happens only on blur event
    if (this.timeOnly && this.isTimeOnly(value) && this._value != null) {
      const timeArr = value.split(':');
      const hours = parseInt(timeArr[0]);
      const minutes = parseInt(timeArr[1]);
      value = moment(this._value).hours(hours).minutes(minutes);
    }

    if (validDate) {
      const momentDate = moment(value, `${this.dateFormat}${this.timeFormat}`);

      if (this.dateTimeBoxOptions.timePicker == false) {
        momentDate.set('hours', 12);
      }

      this.valueChange.emit(momentDate.format("YYYY-MM-DDTHH:mm:ssZ"));
    } else {
      this.valueChange.emit(value);
    }
  }

  getInputClass() {
    this.inputClass = `${this.options[this.variation].Input.Classes.Roles[this.status]} ${this.options[this.variation].Input.Classes.Roles[this.size]}`;

    if (this.required) {
      this.inputClass += ' zapp-required';
    }
  }

  statusClass() {
    const extraClasses = this.options[this.variation].Container.Classes.Roles[this.status];
    this.panelStyleClass = `zapp-datetimebox ${extraClasses}`;
    return this.panelStyleClass;
  }

  sizeClass() {
    const extraClasses = this.options[this.variation].Input.Classes.Roles[this.size];
    return extraClasses;
  }

  private isValidDate(d) {

    return moment(d, `${this.dateFormat}${this.timeFormat}`, false).isValid();

    //return moment(d, 'D DD/M MM/Y', false).isValid()
    //  || moment(d, 'M MM/D DD/Y', false).isValid()
    //  || moment(d, 'Y/M MM/D DD', false).isValid();

    //var timestamp = Date.parse(d);

    //return isNaN(timestamp) == false;
  }

  private isTimeOnly(d): boolean {
    const timeRegEx = /^([0-9]|0[0-9]|1?[0-9]|2[0-3]):[0-5]?[0-9]$/;
    return timeRegEx.test(d);
  }

}
