import { type } from "os";
import { AggregatorTypes } from "../../../datasource/models/enums/aggregator-types";
import { FilterOperators } from "../../../datasource/models/enums/filter-operators";
import { RowOperators } from "../../../datasource/models/enums/row-operators";
import { ColumnInfo } from "../../../datasource/models/infos/column-info";
import { FilterInfo } from "../../../datasource/models/infos/filter-info";
import { GroupByInfo } from "../../../datasource/models/infos/group-by-info";
import { OrderByInfo } from "../../../datasource/models/infos/order-by-info";
import { AggregatorInfo } from "../../DataList/DataList.component";

/*   Datalist Configuration Options
 *
 *   Used only for initialization and configuration. They do not change
 *   their values in runtime
 */
export interface IDataListOptions {
    isStandAlone?: boolean;
    stackResponsiveLayout?: boolean;
    isPickList?: boolean;
    waitForPredefinedFilters?: boolean;
    showRowNumbers?: boolean;
    hasPaging?: boolean;
    displayRecordsInfoRow?: boolean;
    hasMultiSelect?: boolean;
    selectUsingCheckbox?: boolean;
    hasReorderableColumns?: boolean;
    hasResizableColumns?: boolean;
    canHideShowColumns?: boolean;
    canCopyToClipboard?: boolean;
    showRefreshButton?: boolean;
    isExportable?: boolean;
    isGroupable?: boolean;
    isSearchable?: boolean;
    importData?: boolean;
    saveViews?: boolean;
    structuredFiltering?: boolean;
    rememberSelectedItems?: boolean;
    rememberLastState?: boolean;
    predefinedGroups?: Array<any>;
    predefinedAggregators?: Array<any>;
    onUpdateAction?: any;
    cellEdit?: boolean;
    pageSizes?: Array<number>;
    userCanSelectPageSize?: boolean;
    maxSelectedRows?: number;
    minColumnWidth?: number;
    useExportV2?: boolean;
    useCustomModal?: boolean;
    parentForm?: string,
    isMaster?: boolean,
    alternateRowsColor?: boolean,
    compactLines?: boolean,
    infiniteScrolling?: boolean;
    allowColumnsFreeze?: boolean;
}

/*   Datalist Status
 *
 *   This reflects the current state of the list. Changes its state
 *   and values with each action
 */
export interface IDataListStatus {
    startRow: number;
    pageSize: number;
    columnInfo: Array<DataListColumnInfo>; //This contains the column information in the order as
    filters: Array<DataListFilterInfo>;
    orderBy: Array<OrderByInfo>;
    aggregators: Array<DataListAggregatorInfo>;
    groupBy: Array<DataListGroupByInfo>;
    getGroupsClosed: boolean;
    mergeGroupLevels: boolean;
    excludedKeys: Array<any>;
    selectedKeys: Array<any>;
    selectedItems: Array<any>;
    indexes: Array<number>;
    allKeysSelected: boolean;
    projectionSchemaCallback: any;
    exportSettings: {
        type: string;
        range: string;
        fileName: string;
        exportTitle: string;
        includeGridLines: boolean;
        portraitOrientation: boolean;
        columnInfo: Array<DataListColumnInfo>;
        groupInfo: Array<DataListGroupByInfo>;
        headerColor: string;
        evenColor: string;
        oddColor: string;
        groupColor: string;
        aggregateColor: string;
    }
}

/*   Column Info
 *
 *   The configuration options for each column of the DataList control
 */
export class DataListColumnInfo extends ColumnInfo {
  constructor(options) {
    var name = options.name == null ? null : options.name.split("\\.").join("_");
    super(name, options.dataType, options.formatting);
    this.mambaDataType = options.dataType;
    this.mambaDataTypeIsEnum = options.dataTypeIsEnum;
    this.dataType = options.dataType;
    this.caption = options.caption;
    this.visible = options.visible;
    this.groupable = options.groupable;
    this.searchable = options.searchable;
    this.orderable = options.orderable;
    this.editable = options.editable;
    this.style = options.style;
    this.classes = options.classes;
    this.itemType = options.itemType;
    this.importable = options.importable;
    this.supportsAggregators = options.supportsAggregators;
    this.width = options.width || null;
    this.minWidth = options.minWidth || null;
    this.customWidth = options.customWidth || null;
    this.thumbnailWidth = options.thumbnailWidth || null;
    this.thumbnailHeight = options.thumbnailHeight || null;
    this.length = options.length || null;
    this.css = options.css || "";
    this.isEncrypted = options.isEncrypted;
    this.showFullImage = options.showFullImage;
    this.quickFilterData = options.quickFilterData;
    this.frozen = options.frozen ?? '';
    this.min = options.min;
    this.max = options.max;
  }
  mambaDataType;
  mambaDataTypeIsEnum;
  caption;
  dataType;
  visible;
  groupable;
  searchable;
  orderable;
  editable;
  style;
  classes;
  itemType;
  importable;
  supportsAggregators;
  width;
  minWidth;
  customWidth;
  thumbnailWidth: number;
  thumbnailHeight: number;
  length: number;
  css: string;
  showFullImage: boolean;
  quickFilterData: ColumnQuickfilterData;
  countAggregator: AggregatorInfo;
  sumAggregator: AggregatorInfo;
  averageAggregator: AggregatorInfo;
  frozen?: 'left' | 'right' | '';
  min: number; // ProgressBar
  max: number; // ProgressBar
}

export interface ColumnQuickfilterData {
  search: string;
  dataset?: any;
  dateConfig?: any;
}

/*   Filter Info
 *
 *   Helper class used in the list status that describes filtering information
 */
export class DataListFilterInfo extends FilterInfo {
    constructor(columnInfo: ColumnInfo, value: any, rowOp: RowOperators, op: FilterOperators, secondValue: any, type: DataListFilterType, controlId?: string) {
        super(columnInfo, value, rowOp, op, secondValue);

        this.type = type;
        this.controlId = controlId;
    }

    type: DataListFilterType;
    controlId: string;
}

/*   Aggregator Info
 *
 *   Helper class used in the list status that describes aggregator information
 */
export class DataListAggregatorInfo {

  constructor(column, type, formatting?, enabled?: boolean) {
        this.column = column;
        this.type = type;
        this.formatting = formatting;
        this.enabled = enabled;
    }

    formatting: any;
    column: string;
    type: AggregatorTypes;
    enabled: boolean;
    encrypted: boolean;
}

/*   Group By Info
 *
 *   Helper class used in the list status that describes group by information
 */
export class DataListGroupByInfo extends GroupByInfo {
    constructor(column, initialState?, getGroupsClosed?: boolean, inactive?: boolean) {
        super(column, initialState, inactive, getGroupsClosed);
    }
}

export type ListExportColorsConfiguration = {
  headerColor?: string,
  evenColor?: string,
  oddColor?: string,
  groupColor?: string,
  aggregateColor?: string
}

export enum DataListColumnItemType {
    HYPERLINK = 0,
    DOWNLOADLINK,
    CHECKBOX,
    IMAGEBOX,
    TEXTBOX,
    HTML,
    PROGRESSBAR
}

export enum DataListFilterType {
    Quick,
    Global,
    Custom
}
