import { setKeyAsClientKey, keyHasDefaultValue } from './utils';

export const objectsAreEqual = (objA: any, objB: any, typeCheck?: boolean): boolean => {
  setKeyAsClientKey(objA);
  setKeyAsClientKey(objB);

  // Type Check
  if (typeCheck === true && objA != null && objB != null && objA._typeHash != objB._typeHash) return false;

  // Simple value equality
  if (objA === objB) return true;

  //handle case where one is null and other is undefined
  if ((objA == null || objA == undefined) && (objB == null || objB == undefined)) return true;

  // Client Key equality
  if ((objA != null && objB != null) &&
    (objA._clientKey || objB._clientKey) &&
    objB._clientKey === objA._clientKey) return true;

  // One of objects null, other not null
  if (objA == null || objB == null) return false;

  // Key equality
  if (typeof (objB._key) != "undefined" &&
    keyHasDefaultValue(objA._key) === false &&
    objB._key === objA._key) return true;

  // Angular $$haskey equality
  if (typeof (objB.$$hashKey) != "undefined" && objB.$$hashKey === objA.$$hashKey) return true;

  return false;
}
