import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevMultiSelectComponent } from './MultiSelect.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    zAppDevMultiSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MultiSelectModule
  ],
  exports: [
    zAppDevMultiSelectComponent
  ]
})
export class zAppDevMultiSelectModule {
  static forRoot(): ModuleWithProviders<zAppDevMultiSelectModule> {
    return {
      ngModule: zAppDevMultiSelectModule,
      providers: [ ],
    };
  }
}
