import { Directive, Input, HostBinding, OnInit } from '@angular/core';
import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';

@Directive({
  selector: '[zappInputContainer]'
})
export class zappInputContainerDirective implements OnInit {
  @HostBinding('class') inputContainerClass: string;

  constructor(private themeService: IThemeSevice) {
  }

  ngOnInit(): void {
    this.inputContainerClass = 'form-group-input zapp-formgroupinputcontainer';
  }
}
