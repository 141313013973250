import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class LeadFilters  {
    _originalTypeClassName = "zPMO.BO.LeadFilters";
constructor() {
this.Id = null;
this.OnlyMine = false;
this.FinalStatus = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
OnlyMine:any;
FinalStatus:any;
Type:any;
Status:any;
Terminal:any;
static setType = ($this, value) => {
var oldValue = $this.Type;
$this.Type = value;
if (value != null) {
}
}
static setStatus = ($this, value) => {
var oldValue = $this.Status;
$this.Status = value;
if (value != null) {
}
}
static setTerminal = ($this, value) => {
var oldValue = $this.Terminal;
$this.Terminal = value;
if (value != null) {
}
}

static Init($this: any): any{$this.OnlyMine = true;
$this.FinalStatus = Domain.LeadFiltersStatus.Pending;
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}