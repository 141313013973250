import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class LeadAction  {
    _originalTypeClassName = "zPMO.BO.LeadAction";
constructor() {
this.Id = null;
this.Date = null;
this.Notes = null;
this.IsCompleted = false;
this.IsCanceled = false;
this.ReminderDate = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Date:any;
Notes:any;
IsCompleted:any;
IsCanceled:any;
ReminderDate:any;
TaskEntry:any;
Lead:any;
LeadActionType:any;
PMOUser:any;
ClientContact:any;
static setTaskEntry = ($this, value) => {
var oldValue = $this.TaskEntry;
$this.TaskEntry = value;
if (value != null) {
if (oldValue != null && oldValue.RelatedLeadAction != null) { oldValue.RelatedLeadAction = null; }
if ($this.TaskEntry.RelatedLeadAction !== $this) {
Domain.TaskEntry.setRelatedLeadAction(value, $this);
}
}
}
static setLead = ($this, value) => {
var oldValue = $this.Lead;
$this.Lead = value;
if (value != null) {
if (oldValue != null) {
Domain.Lead.removeLeadActions(oldValue, $this);
}
Domain.Lead.addLeadActions(value, $this);
}
}
static setLeadActionType = ($this, value) => {
var oldValue = $this.LeadActionType;
$this.LeadActionType = value;
if (value != null) {
}
}
static setPMOUser = ($this, value) => {
var oldValue = $this.PMOUser;
$this.PMOUser = value;
if (value != null) {
}
}
static setClientContact = ($this, value) => {
var oldValue = $this.ClientContact;
$this.ClientContact = value;
if (value != null) {
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}