import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class AuditLogEntry  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Auditing.Model.AuditLogEntry";
constructor() {
this.Id = null;
this.UserName = null;
this.IPAddress = null;
this.EntityFullName = null;
this.EntityShortName = null;
this.EntityId = null;
this.Timestamp = null;
this.EntryTypeId = null;
this.ActionTypeId = null;
this.OldValue = null;
this.NewValue = null;
this.PropertyName = null;
this.ExtraField1 = null;
this.ExtraField2 = null;
this.ExtraField3 = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
UserName:any;
IPAddress:any;
EntityFullName:any;
EntityShortName:any;
EntityId:any;
Timestamp:any;
EntryTypeId:any;
ActionTypeId:any;
OldValue:any;
NewValue:any;
PropertyName:any;
ExtraField1:any;
ExtraField2:any;
ExtraField3:any;


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}