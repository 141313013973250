import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class TagConditionLine  {
    _originalTypeClassName = "zPMO.BO.TagConditionLine";
constructor() {
this.Tags = [];
this.Id = null;
this.JoinOperator = null;
this.NextLineOperator = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
JoinOperator:any;
NextLineOperator:any;
Tags:any;
static setTags = ($this, items) => {
                if (items == null) items = [];
                if ($this.Tags == null) $this.Tags = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Tags.length > 0) {
                    Domain.TagConditionLine.removeTags($this, $this.Tags[0]);
                }

                Domain.TagConditionLine.addManyTags($this, copyItems);
            }
static addManyTags = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.TagConditionLine.addTags($this, items[i]);
                }
            }
static addTags = ($this, item) => {
                if ($this.Tags == null) $this.Tags = [];
                if (item == null || $this.Tags.indexOf(item) > -1) return;
                $this.Tags.push(item);
}
static addRangeTags = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.TagConditionLine.addTags($this, item));
            }
static removeTags = ($this, item) => {
                if ($this == null || $this.Tags == null || $this.Tags.indexOf(item) === -1) return;
                var _index = $this.Tags.indexOf(item);
                $this.Tags.splice(_index, 1);
}
static clearTags = ($this) => { $this.Tags = null; }

static IsMatch($this: any, selectedTags: any): any{let result = true;
for (let _itag = 0; _itag < ($this?.Tags == null ? 0 : $this?.Tags.length); _itag++) {let tag = $this?.Tags[_itag];let lineResult = selectedTags?.linq?.contains(tag);if ($this?.JoinOperator == Domain.TagConditionOperator.OR) {result = result || lineResult;}else {result = result && lineResult;if (!(result)) {break;}}}
return result;
}
static Matches(conditions: any,selectedTags: any): any{let result = true;
let prevOperator = Domain.TagConditionOperator.AND;
for (let _iline = 0; _iline < (conditions == null ? 0 : conditions.length); _iline++) {let line = conditions[_iline];let lineResult = Domain.TagConditionLine.IsMatch(line, selectedTags);if (prevOperator == Domain.TagConditionOperator.OR) {result = result || lineResult;}else {result = result && lineResult;if (!(result)) {break;}}prevOperator = line?.NextLineOperator;}
return result;
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}