import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ApplicationClient  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.ApplicationClient";
constructor() {
this.User = new Domain.ApplicationUser();
this.Id = null;
this.ClientKey = null;
this.IPAddress = null;
this.SessionId = null;
this.ConnectedOn = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
ClientKey:any;
IPAddress:any;
SessionId:any;
ConnectedOn:any;
User:any;
static setUser = ($this, value) => {
var oldValue = $this.User;
$this.User = value;
if (value != null) {
if (oldValue != null) {
Domain.ApplicationUser.removeClients(oldValue, $this);
}
Domain.ApplicationUser.addClients(value, $this);
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}