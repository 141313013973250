import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Datasource } from '@framework/datasource/interfaces/datasource';
import { zAppDevBaseComponent } from '@framework/components/BaseComponent/base.component';
import { TranslateService } from '@ngx-translate/core';
import { IThemeSevice } from '@framework/theme/interfaces/theme.interface';
import { DatasourceRequest } from '@framework/datasource/models/infos/dataSource-request';
import { DefaultSelectionUpdateEvent, project, transformStyleToObject } from "../../common";

@Component({
  selector: 'zapp-multiselect',
  templateUrl: './MultiSelect.component.html',
  styleUrls: ['./MultiSelect.component.less']
})
export class zAppDevMultiSelectComponent extends zAppDevBaseComponent implements OnInit {

  constructor(protected readonly translate: TranslateService, protected themeservice: IThemeSevice, protected elementRef: ElementRef) {
    super(elementRef);
  }

  @Input() selectedOptions: any[];

  @Input() name: string;

  @Input() dataset: Datasource<any>;

  @Output() selectedOptionsChange = new EventEmitter<any[]>();

  options: any[];

  @Input() indexes: number[];

  @Input() projectionSchema: any;

  @Input() model: any;

  @Input() optionLabel: string;

  @Input() valueProperty: string;


  ngOnInit(): void {
    super.ngOnInit();

    this.fetchOptions();
  }

  onChange() {
    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  private fetchOptions() {
    const datasourceRequestInfo = this.prepareDatasourceRequestInfo();
    const model = this.modelToProjection();

    this.dataset && this.dataset.fetchData(datasourceRequestInfo, model).then((response: any) => {
      this.options = response.Data;
      let selected = this.options.find(a => a.selected);
      if (selected != null) {
        this.selectedOptionsChange.emit(selected);
      }
    });
  }

  private prepareDatasourceRequestInfo(): DatasourceRequest {
    const request = new DatasourceRequest(
      0,
      100,
      [],
      [],
      [],
    );

    request.indexes = this.indexes;

    return request;
  }

  private modelToProjection(): any {
    let model = this.model;
    if (this.projectionSchema != null) {
      model = project(this.model, this.projectionSchema);
    }
    if (model == null) {
      return {}
    }
    return model;
  }

}
