import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'zapp-responsive-table',
  templateUrl: './ResponsiveTable.component.html',
  styleUrls: ['./ResponsiveTable.component.less']
})
export class zAppDevResponsiveTableComponent implements OnInit {

  @HostBinding("class") hostClasses: string = 'zapp-Layout';

  constructor() { }

  ngOnInit(): void {

  }

}
