import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class Employee  {
    _originalTypeClassName = "zPMO.BO.Employee";
constructor() {
this.Rates = [];
this.Id = null;
this.Name = null;
this.IsActive = false;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
IsActive:any;
Rates:any;
static setRates = ($this, items) => {
                if (items == null) items = [];
                if ($this.Rates == null) $this.Rates = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Rates.length > 0) {
                    Domain.Employee.removeRates($this, $this.Rates[0]);
                }

                Domain.Employee.addManyRates($this, copyItems);
            }
static addManyRates = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Employee.addRates($this, items[i]);
                }
            }
static addRates = ($this, item) => {
                if ($this.Rates == null) $this.Rates = [];
                if (item == null || $this.Rates.indexOf(item) > -1) return;
                $this.Rates.push(item);
}
static addRangeRates = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Employee.addRates($this, item));
            }
static removeRates = ($this, item) => {
                if ($this == null || $this.Rates == null || $this.Rates.indexOf(item) === -1) return;
                var _index = $this.Rates.indexOf(item);
                $this.Rates.splice(_index, 1);
}
static clearRates = ($this) => { $this.Rates = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}