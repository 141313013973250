import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');
import { ApplicationUser } from './ApplicationUser';

export class PMOUser extends ApplicationUser {
    _originalTypeClassName = "zPMO.BO.PMOUser";
constructor() {
super();
this._key = this.UserName = ""; 
}

_key: any;


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.UserName == null || $this.UserName == "";
            }


}