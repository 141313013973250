<!--<div class="container" zappFileattachment (fileDropped)="onFileDropped($event)">
  <input *ngIf="isMultiple" type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" [disabled]="disabled || readonly" />
  <input *ngIf="!isMultiple" type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" [disabled]="disabled || readonly" />
  <img src="assets/img/dnd/ic-upload-file.svg" alt="">
  <div *ngIf="useDnDBox">
    <h3>Drag and drop file here</h3>
    <h3>or</h3>
    <label for="fileDropRef">Browse for file</label>
  </div>
</div>
<div class="files-list" *ngIf="displayFileNameList">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div>
      <i class="fa fa-file" (click)="proccessDownloadFile(i)" style="cursor: pointer;">
        <span class="name">{{ file?.name }}</span>-->
<!-- <app-progress [progress]="file?.progress"></app-progress> -->
<!--</i>
    </div>
    <i class="fa fa-trash delete" (click)="deleteFile(i)"></i>
  </div>
</div>-->

<p-fileUpload #fileUpload name="fileUpload"
              [customUpload]="true"
              (uploadHandler)="uploadHandler($event)"
              (onProgress)="progressReport($event)"
              [mode]="useDnDBox ? 'advanced' : 'basic'"
              [multiple]="isMultiple"
              chooseLabel="{{'RES_WEBFORM_FileUploadTitle' | translate}}"
              chooseIcon="pi pi-upload"
              [auto]="true"
              [disabled]="disabled || readonly"
              [maxFileSize]="options.maxFileSize"
              invalidFileSizeMessageSummary="{{'RES_WEBFORM_MaxFileSizeExceededError' | translate}}"
              [accept]="options.allowedExtensions"
              invalidFileTypeMessageSummary="{{'RES_WEBFORM_NotAllowedFileExtensionError' | translate}}">
  <ng-template pTemplate="content">
    <p class="content-message">{{'RES_WEBFORM_FileDragAndDropMessage' | translate}}</p>
  </ng-template>
  <ng-template pTemplate="toolbar">
    <p-button label="{{'RES_SITE_PREFS_CancelButtonText' | translate}}" icon="pi pi-times" (click)="cancelUpload()" [disabled]="fileUpload?.progress <= 0 || fileUpload?.progress >= 100"></p-button>
  </ng-template>
</p-fileUpload>

<br />

<zapp-progressbar *ngIf="fileUpload?.progress > 0 && fileUpload?.progress < 100" [min]="0" [max]="100" [rangeColor]="false" [showProgress]="false" #ProgressBar [size]="'med'" [value]="fileUpload?.progress">
</zapp-progressbar>

<div class="files-list" *ngIf="displayFileNameList">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div class="flex flex-wrap align-content-center align-items-center">

      <zappIcon *ngIf="!disabled && !readonly" themeIcon="remove" class="remove" [size]="'med'" (click)="deleteFile(i)">
      </zappIcon>

      <i class="fa fa-file" (click)="proccessDownloadFile(i)" style="cursor: pointer;">
        <span class="name">{{ file?.name }}</span>
      </i>

    </div>
    <!--<i class="pi pi-times delete" (click)="deleteFile(i)"></i>-->
  </div>
</div>
