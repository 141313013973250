import { Component, OnInit, Input, HostBinding, ElementRef, TemplateRef } from '@angular/core';
import { CF_COMPONENT } from '../../rule-engine/directives/condition-formatting.directive';
import { zAppDevBaseComponent } from '../BaseComponent/base.component';
import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';

@Component({
  selector: "zappFormGroup",
  templateUrl: './FormGroup.component.html',
  styleUrls: ['./FormGroup.component.less'],
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevFormGroupComponent }]
})
export class zAppDevFormGroupComponent extends zAppDevBaseComponent {

  @Input() horizontalAlign: boolean;

  @Input('label')
  labelTpl: TemplateRef<any>;

  @Input('input')
  inputTpl: TemplateRef<any>;

  @Input('description')
  descriptionTpl: TemplateRef<any>;

  options: ThemeOptions.FormGroupThemeOptions;

  constructor(protected elementRef: ElementRef, private themeService: IThemeSevice) {
    super(elementRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.options = this.themeService.getFormGroupThemeOptions();
  }

  @HostBinding('class.horizontal')
  get warning() {
    return this.horizontalAlign === true;
  }

  // override base component
  @HostBinding('class')
  get hostClasses(): string {
    return this.hostClassesArray.concat(this.styleClassesArray).join(' ');
  }

}
