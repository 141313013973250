import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class Client  {
    _originalTypeClassName = "zPMO.BO.Client";
constructor() {
this.Contacts = [];
this.Tags = [];
this.MarketSegments = [];
this.Id = null;
this.Company = null;
this.ShortName = null;
this.VAT = null;
this.Individual = false;
this.Country = null;
this.AddressLine1 = null;
this.AddressLine2 = null;
this.ContactPerson = null;
this.ContactEmail = null;
this.ContactPhone = null;
this.Profession = null;
this.TaxOffice = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Company:any;
ShortName:any;
VAT:any;
Individual:any;
Country:any;
AddressLine1:any;
AddressLine2:any;
ContactPerson:any;
ContactEmail:any;
ContactPhone:any;
Profession:any;
TaxOffice:any;
InvoiceInfo:any;
Contacts:any;
Tags:any;
MarketSegments:any;
static setInvoiceInfo = ($this, value) => {
var oldValue = $this.InvoiceInfo;
$this.InvoiceInfo = value;
if (value != null) {
}
}
static setContacts = ($this, items) => {
                if (items == null) items = [];
                if ($this.Contacts == null) $this.Contacts = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Contacts.length > 0) {
                    Domain.Client.removeContacts($this, $this.Contacts[0]);
                }

                Domain.Client.addManyContacts($this, copyItems);
            }
static addManyContacts = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Client.addContacts($this, items[i]);
                }
            }
static addContacts = ($this, item) => {
                if ($this.Contacts == null) $this.Contacts = [];
                if (item == null || $this.Contacts.indexOf(item) > -1) return;
                $this.Contacts.push(item);
if (item.Client != $this) {
Domain.ClientContact.setClient(item, $this);
}
}
static addRangeContacts = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Client.addContacts($this, item));
            }
static removeContacts = ($this, item) => {
                if ($this == null || $this.Contacts == null || $this.Contacts.indexOf(item) === -1) return;
                var _index = $this.Contacts.indexOf(item);
                $this.Contacts.splice(_index, 1);
item.Client = null;
}
static clearContacts = ($this) => { $this.Contacts = null; }
static setTags = ($this, items) => {
                if (items == null) items = [];
                if ($this.Tags == null) $this.Tags = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Tags.length > 0) {
                    Domain.Client.removeTags($this, $this.Tags[0]);
                }

                Domain.Client.addManyTags($this, copyItems);
            }
static addManyTags = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Client.addTags($this, items[i]);
                }
            }
static addTags = ($this, item) => {
                if ($this.Tags == null) $this.Tags = [];
                if (item == null || $this.Tags.indexOf(item) > -1) return;
                $this.Tags.push(item);
}
static addRangeTags = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Client.addTags($this, item));
            }
static removeTags = ($this, item) => {
                if ($this == null || $this.Tags == null || $this.Tags.indexOf(item) === -1) return;
                var _index = $this.Tags.indexOf(item);
                $this.Tags.splice(_index, 1);
}
static clearTags = ($this) => { $this.Tags = null; }
static setMarketSegments = ($this, items) => {
                if (items == null) items = [];
                if ($this.MarketSegments == null) $this.MarketSegments = [];

                const copyItems = Common.cloneObject(items);

                while ($this.MarketSegments.length > 0) {
                    Domain.Client.removeMarketSegments($this, $this.MarketSegments[0]);
                }

                Domain.Client.addManyMarketSegments($this, copyItems);
            }
static addManyMarketSegments = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Client.addMarketSegments($this, items[i]);
                }
            }
static addMarketSegments = ($this, item) => {
                if ($this.MarketSegments == null) $this.MarketSegments = [];
                if (item == null || $this.MarketSegments.indexOf(item) > -1) return;
                $this.MarketSegments.push(item);
Domain.MarketSegment.addAccounts(item, $this);
}
static addRangeMarketSegments = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Client.addMarketSegments($this, item));
            }
static removeMarketSegments = ($this, item) => {
                if ($this == null || $this.MarketSegments == null || $this.MarketSegments.indexOf(item) === -1) return;
                var _index = $this.MarketSegments.indexOf(item);
                $this.MarketSegments.splice(_index, 1);
Domain.MarketSegment.removeAccounts(item, $this);
}
static clearMarketSegments = ($this) => { $this.MarketSegments = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}