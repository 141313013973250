import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class AuditPropertyConfiguration  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Auditing.Model.AuditPropertyConfiguration";
constructor() {
this.Entity = new Domain.AuditEntityConfiguration();
this.Id = null;
this.Name = null;
this.DataType = null;
this.IsAuditable = false;
this.IsComplex = false;
this.IsCollection = false;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
DataType:any;
IsAuditable:any;
IsComplex:any;
IsCollection:any;
Entity:any;
static setEntity = ($this, value) => {
var oldValue = $this.Entity;
$this.Entity = value;
if (value != null) {
if (oldValue != null) {
Domain.AuditEntityConfiguration.removeProperties(oldValue, $this);
}
Domain.AuditEntityConfiguration.addProperties(value, $this);
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}