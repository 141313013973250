import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ReportRow  {
    _originalTypeClassName = "zPMO.BO.ReportRow";
constructor() {
this.IsExpense = false;
this.Amount = null;
this.Name = null;
this.ClientName = null;
this.Category = null;
this.Date = null;
this.Status = null;
this.StatusString = null;
this.CompanyName = null;
this.ReportRowKey = null;
this._key = this.ReportRowKey = 0; 
}

_key: any;
IsExpense:any;
Amount:any;
Name:any;
ClientName:any;
Category:any;
Date:any;
Status:any;
StatusString:any;
CompanyName:any;
private _ReportRowKey:any;
get ReportRowKey(): any {
    return this._ReportRowKey;
  }
set ReportRowKey(value: any) {
    this._ReportRowKey = value;
    this._key = value;
  }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.ReportRowKey == null || $this.ReportRowKey == 0;
            }


}