import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class LeadAutomation  {
    _originalTypeClassName = "zPMO.BO.LeadAutomation";
constructor() {
this.Actions = [];
this.Conditions = [];
this.Id = null;
this.Name = null;
this.Description = null;
this.ConditionTerminalStatus = null;
this.Index = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Description:any;
ConditionTerminalStatus:any;
Index:any;
LeadType:any;
Actions:any;
Conditions:any;
ConditionStatus:any;
ConditionTerminal:any;
static setLeadType = ($this, value) => {
var oldValue = $this.LeadType;
$this.LeadType = value;
if (value != null) {
if (oldValue != null) {
Domain.LeadType.removeAutomations(oldValue, $this);
}
Domain.LeadType.addAutomations(value, $this);
}
}
static setActions = ($this, items) => {
                if (items == null) items = [];
                if ($this.Actions == null) $this.Actions = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Actions.length > 0) {
                    Domain.LeadAutomation.removeActions($this, $this.Actions[0]);
                }

                Domain.LeadAutomation.addManyActions($this, copyItems);
            }
static addManyActions = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.LeadAutomation.addActions($this, items[i]);
                }
            }
static addActions = ($this, item) => {
                if ($this.Actions == null) $this.Actions = [];
                if (item == null || $this.Actions.indexOf(item) > -1) return;
                $this.Actions.push(item);
if (item.Automation != $this) {
Domain.LeadAutomationAction.setAutomation(item, $this);
}
}
static addRangeActions = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.LeadAutomation.addActions($this, item));
            }
static removeActions = ($this, item) => {
                if ($this == null || $this.Actions == null || $this.Actions.indexOf(item) === -1) return;
                var _index = $this.Actions.indexOf(item);
                $this.Actions.splice(_index, 1);
item.Automation = null;
}
static clearActions = ($this) => { $this.Actions = null; }
static setConditions = ($this, items) => {
                if (items == null) items = [];
                if ($this.Conditions == null) $this.Conditions = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Conditions.length > 0) {
                    Domain.LeadAutomation.removeConditions($this, $this.Conditions[0]);
                }

                Domain.LeadAutomation.addManyConditions($this, copyItems);
            }
static addManyConditions = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.LeadAutomation.addConditions($this, items[i]);
                }
            }
static addConditions = ($this, item) => {
                if ($this.Conditions == null) $this.Conditions = [];
                if (item == null || $this.Conditions.indexOf(item) > -1) return;
                $this.Conditions.push(item);
}
static addRangeConditions = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.LeadAutomation.addConditions($this, item));
            }
static removeConditions = ($this, item) => {
                if ($this == null || $this.Conditions == null || $this.Conditions.indexOf(item) === -1) return;
                var _index = $this.Conditions.indexOf(item);
                $this.Conditions.splice(_index, 1);
}
static clearConditions = ($this) => { $this.Conditions = null; }
static setConditionStatus = ($this, value) => {
var oldValue = $this.ConditionStatus;
$this.ConditionStatus = value;
if (value != null) {
}
}
static setConditionTerminal = ($this, value) => {
var oldValue = $this.ConditionTerminal;
$this.ConditionTerminal = value;
if (value != null) {
}
}

static EvaluateConditions($this: any, lead: any): any{if ($this?.ConditionTerminalStatus == Domain.LeadAutomationTerminalStatuses.Lost && !(lead?.IsLost)) {return false;}
if ($this?.ConditionTerminalStatus == Domain.LeadAutomationTerminalStatuses.Won && !(lead?.IsWon)) {return false;}
if ($this?.ConditionTerminalStatus == Domain.LeadAutomationTerminalStatuses.Pending && lead?.Terminal != null) {return false;}
if (($this?.ConditionTerminalStatus == Domain.LeadAutomationTerminalStatuses.Lost || $this?.ConditionTerminalStatus == Domain.LeadAutomationTerminalStatuses.Won) && $this?.ConditionTerminal != lead?.Terminal) {return false;}
if (($this?.ConditionStatus != null && lead?.LeadStatus != $this?.ConditionStatus)) {return false;}
return true;
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}