import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ApplicationRole  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.ApplicationRole";
constructor() {
this.Users = [];
this.Permissions = [];
this.Id = null;
this.Name = null;
this.Description = null;
this.IsCustom = false;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Description:any;
IsCustom:any;
Users:any;
Permissions:any;
static setUsers = ($this, items) => {
                if (items == null) items = [];
                if ($this.Users == null) $this.Users = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Users.length > 0) {
                    Domain.ApplicationRole.removeUsers($this, $this.Users[0]);
                }

                Domain.ApplicationRole.addManyUsers($this, copyItems);
            }
static addManyUsers = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.ApplicationRole.addUsers($this, items[i]);
                }
            }
static addUsers = ($this, item) => {
                if ($this.Users == null) $this.Users = [];
                if (item == null || $this.Users.indexOf(item) > -1) return;
                $this.Users.push(item);
Domain.ApplicationUser.addRoles(item, $this);
}
static addRangeUsers = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.ApplicationRole.addUsers($this, item));
            }
static removeUsers = ($this, item) => {
                if ($this == null || $this.Users == null || $this.Users.indexOf(item) === -1) return;
                var _index = $this.Users.indexOf(item);
                $this.Users.splice(_index, 1);
Domain.ApplicationUser.removeRoles(item, $this);
}
static clearUsers = ($this) => { $this.Users = null; }
static setPermissions = ($this, items) => {
                if (items == null) items = [];
                if ($this.Permissions == null) $this.Permissions = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Permissions.length > 0) {
                    Domain.ApplicationRole.removePermissions($this, $this.Permissions[0]);
                }

                Domain.ApplicationRole.addManyPermissions($this, copyItems);
            }
static addManyPermissions = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.ApplicationRole.addPermissions($this, items[i]);
                }
            }
static addPermissions = ($this, item) => {
                if ($this.Permissions == null) $this.Permissions = [];
                if (item == null || $this.Permissions.indexOf(item) > -1) return;
                $this.Permissions.push(item);
Domain.ApplicationPermission.addRoles(item, $this);
}
static addRangePermissions = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.ApplicationRole.addPermissions($this, item));
            }
static removePermissions = ($this, item) => {
                if ($this == null || $this.Permissions == null || $this.Permissions.indexOf(item) === -1) return;
                var _index = $this.Permissions.indexOf(item);
                $this.Permissions.splice(_index, 1);
Domain.ApplicationPermission.removeRoles(item, $this);
}
static clearPermissions = ($this) => { $this.Permissions = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}