import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class TagCategory  {
    _originalTypeClassName = "zPMO.BO.TagCategory";
constructor() {
this.Tags = [];
this.Id = null;
this.Name = null;
this.Color = "#000000";
this.TextColor = "#ffffff";
this.AllowMultipleValues = false;
this.ColorHTML = null;
this.TextColorHTML = null;
this.Type = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Color:any;
TextColor:any;
AllowMultipleValues:any;
ColorHTML:any;
TextColorHTML:any;
Type:any;
Tags:any;
static setTags = ($this, items) => {
                if (items == null) items = [];
                if ($this.Tags == null) $this.Tags = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Tags.length > 0) {
                    Domain.TagCategory.removeTags($this, $this.Tags[0]);
                }

                Domain.TagCategory.addManyTags($this, copyItems);
            }
static addManyTags = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.TagCategory.addTags($this, items[i]);
                }
            }
static addTags = ($this, item) => {
                if ($this.Tags == null) $this.Tags = [];
                if (item == null || $this.Tags.indexOf(item) > -1) return;
                $this.Tags.push(item);
if (item.TagCategory != $this) {
Domain.Tag.setTagCategory(item, $this);
}
}
static addRangeTags = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.TagCategory.addTags($this, item));
            }
static removeTags = ($this, item) => {
                if ($this == null || $this.Tags == null || $this.Tags.indexOf(item) === -1) return;
                var _index = $this.Tags.indexOf(item);
                $this.Tags.splice(_index, 1);
item.TagCategory = null;
}
static clearTags = ($this) => { $this.Tags = null; }

static GetColor($this: any): any{return "<span class='bg-color-indicator' style='background: " + $this?.Color + "' ></span>";
}
static GetTextColor($this: any): any{return "<span class='bg-color-indicator' style='background: " + $this?.TextColor + "' ></span>";
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}