import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class AuditEntityConfiguration  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Auditing.Model.AuditEntityConfiguration";
constructor() {
this.Properties = [];
this.Id = null;
this.FullName = null;
this.ShortName = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
FullName:any;
ShortName:any;
Properties:any;
static setProperties = ($this, items) => {
                if (items == null) items = [];
                if ($this.Properties == null) $this.Properties = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Properties.length > 0) {
                    Domain.AuditEntityConfiguration.removeProperties($this, $this.Properties[0]);
                }

                Domain.AuditEntityConfiguration.addManyProperties($this, copyItems);
            }
static addManyProperties = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.AuditEntityConfiguration.addProperties($this, items[i]);
                }
            }
static addProperties = ($this, item) => {
                if ($this.Properties == null) $this.Properties = [];
                if (item == null || $this.Properties.indexOf(item) > -1) return;
                $this.Properties.push(item);
if (item.Entity != $this) {
Domain.AuditPropertyConfiguration.setEntity(item, $this);
}
}
static addRangeProperties = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.AuditEntityConfiguration.addProperties($this, item));
            }
static removeProperties = ($this, item) => {
                if ($this == null || $this.Properties == null || $this.Properties.indexOf(item) === -1) return;
                var _index = $this.Properties.indexOf(item);
                $this.Properties.splice(_index, 1);
item.Entity = null;
}
static clearProperties = ($this) => { $this.Properties = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}