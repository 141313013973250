import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ApplicationLanguage  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.ApplicationLanguage";
constructor() {
this.Id = null;
this.Name = null;
this.Code = null;
this.Icon = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Code:any;
Icon:any;
DateTimeFormat:any;
static setDateTimeFormat = ($this, value) => {
var oldValue = $this.DateTimeFormat;
$this.DateTimeFormat = value;
if (value != null) {
if (oldValue != null && oldValue.ApplicationLanguage != null) { oldValue.ApplicationLanguage = null; }
if ($this.DateTimeFormat.ApplicationLanguage !== $this) {
Domain.DateTimeFormat.setApplicationLanguage(value, $this);
}
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}