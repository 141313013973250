import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class DynamicField  {
    _originalTypeClassName = "zPMO.BO.DynamicField";
constructor() {
this.Options = [];
this.Id = null;
this.Name = null;
this.Datatype = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Datatype:any;
Group:any;
Options:any;
static setGroup = ($this, value) => {
var oldValue = $this.Group;
$this.Group = value;
if (value != null) {
if (oldValue != null) {
Domain.DynamicFieldGroup.removeFields(oldValue, $this);
}
Domain.DynamicFieldGroup.addFields(value, $this);
}
}
static setOptions = ($this, items) => {
                if (items == null) items = [];
                if ($this.Options == null) $this.Options = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Options.length > 0) {
                    Domain.DynamicField.removeOptions($this, $this.Options[0]);
                }

                Domain.DynamicField.addManyOptions($this, copyItems);
            }
static addManyOptions = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.DynamicField.addOptions($this, items[i]);
                }
            }
static addOptions = ($this, item) => {
                if ($this.Options == null) $this.Options = [];
                if (item == null || $this.Options.indexOf(item) > -1) return;
                $this.Options.push(item);
if (item.DynamicField != $this) {
Domain.DynamicFieldOption.setDynamicField(item, $this);
}
}
static addRangeOptions = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.DynamicField.addOptions($this, item));
            }
static removeOptions = ($this, item) => {
                if ($this == null || $this.Options == null || $this.Options.indexOf(item) === -1) return;
                var _index = $this.Options.indexOf(item);
                $this.Options.splice(_index, 1);
item.DynamicField = null;
}
static clearOptions = ($this) => { $this.Options = null; }

static CheckCondition(conditions: any,value: any): any{let result = false;
let latestError = null;
let prevOperator = Domain.DynamicFieldConditionOperator.OR;
for (let _iline = 0; _iline < (conditions == null ? 0 : conditions.length); _iline++) {let line = conditions[_iline];let lineResult = Domain.DynamicFieldConditionLine.IsTrue(line, value);let isLineOk = CLMS.Framework.String.IsNullOrEmpty((lineResult ?? '').trim());if (!(isLineOk)) {latestError = lineResult;}if (prevOperator == Domain.DynamicFieldConditionOperator.OR) {result = result || isLineOk;}else if (prevOperator == Domain.DynamicFieldConditionOperator.AND) {result = result && isLineOk;if (result == false) {return lineResult;}}prevOperator = line?.LogicOperator;}
return (result == true ? null : latestError);
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}