import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ApplicationOperation  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.ApplicationOperation";
constructor() {
this.Permissions = [];
this.Id = null;
this.Name = null;
this.ParentControllerName = null;
this.Type = null;
this.IsAvailableToAnonymous = false;
this.IsAvailableToAllAuthorizedUsers = false;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
ParentControllerName:any;
Type:any;
IsAvailableToAnonymous:any;
IsAvailableToAllAuthorizedUsers:any;
Permissions:any;
static setPermissions = ($this, items) => {
                if (items == null) items = [];
                if ($this.Permissions == null) $this.Permissions = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Permissions.length > 0) {
                    Domain.ApplicationOperation.removePermissions($this, $this.Permissions[0]);
                }

                Domain.ApplicationOperation.addManyPermissions($this, copyItems);
            }
static addManyPermissions = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.ApplicationOperation.addPermissions($this, items[i]);
                }
            }
static addPermissions = ($this, item) => {
                if ($this.Permissions == null) $this.Permissions = [];
                if (item == null || $this.Permissions.indexOf(item) > -1) return;
                $this.Permissions.push(item);
Domain.ApplicationPermission.addOperations(item, $this);
}
static addRangePermissions = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.ApplicationOperation.addPermissions($this, item));
            }
static removePermissions = ($this, item) => {
                if ($this == null || $this.Permissions == null || $this.Permissions.indexOf(item) === -1) return;
                var _index = $this.Permissions.indexOf(item);
                $this.Permissions.splice(_index, 1);
Domain.ApplicationPermission.removeOperations(item, $this);
}
static clearPermissions = ($this) => { $this.Permissions = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}