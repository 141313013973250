import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class CompanyTaxRule  {
    _originalTypeClassName = "zPMO.BO.CompanyTaxRule";
constructor() {
this.ScaleItems = [];
this.Id = null;
this.Type = null;
this.Rate = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Type:any;
Rate:any;
ScaleItems:any;
static setScaleItems = ($this, items) => {
                if (items == null) items = [];
                if ($this.ScaleItems == null) $this.ScaleItems = [];

                const copyItems = Common.cloneObject(items);

                while ($this.ScaleItems.length > 0) {
                    Domain.CompanyTaxRule.removeScaleItems($this, $this.ScaleItems[0]);
                }

                Domain.CompanyTaxRule.addManyScaleItems($this, copyItems);
            }
static addManyScaleItems = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.CompanyTaxRule.addScaleItems($this, items[i]);
                }
            }
static addScaleItems = ($this, item) => {
                if ($this.ScaleItems == null) $this.ScaleItems = [];
                if (item == null || $this.ScaleItems.indexOf(item) > -1) return;
                $this.ScaleItems.push(item);
}
static addRangeScaleItems = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.CompanyTaxRule.addScaleItems($this, item));
            }
static removeScaleItems = ($this, item) => {
                if ($this == null || $this.ScaleItems == null || $this.ScaleItems.indexOf(item) === -1) return;
                var _index = $this.ScaleItems.indexOf(item);
                $this.ScaleItems.splice(_index, 1);
}
static clearScaleItems = ($this) => { $this.ScaleItems = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}