import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class InterationDTO  {
    _originalTypeClassName = "zPMO.BO.InterationDTO";
constructor() {
this.CustomFields = [];
this.Id = null;
this.Title = null;
this.FlowId = null;
this.AccountId = null;
this.StatusId = null;
this.InterationDTOKey = null;
this._key = this.InterationDTOKey = 0; 
}

_key: any;
Id:any;
Title:any;
FlowId:any;
AccountId:any;
StatusId:any;
CustomFields:any;
private _InterationDTOKey:any;
get InterationDTOKey(): any {
    return this._InterationDTOKey;
  }
set InterationDTOKey(value: any) {
    this._InterationDTOKey = value;
    this._key = value;
  }
static setCustomFields = ($this, items) => {
                if (items == null) items = [];
                if ($this.CustomFields == null) $this.CustomFields = [];

                const copyItems = Common.cloneObject(items);

                while ($this.CustomFields.length > 0) {
                    Domain.InterationDTO.removeCustomFields($this, $this.CustomFields[0]);
                }

                Domain.InterationDTO.addManyCustomFields($this, copyItems);
            }
static addManyCustomFields = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.InterationDTO.addCustomFields($this, items[i]);
                }
            }
static addCustomFields = ($this, item) => {
                if ($this.CustomFields == null) $this.CustomFields = [];
                if (item == null || $this.CustomFields.indexOf(item) > -1) return;
                $this.CustomFields.push(item);
}
static addRangeCustomFields = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.InterationDTO.addCustomFields($this, item));
            }
static removeCustomFields = ($this, item) => {
                if ($this == null || $this.CustomFields == null || $this.CustomFields.indexOf(item) === -1) return;
                var _index = $this.CustomFields.indexOf(item);
                $this.CustomFields.splice(_index, 1);
}
static clearCustomFields = ($this) => { $this.CustomFields = null; }

static Fill(interaction: any): any{let dto = new Domain.InterationDTO();
dto.Id = interaction?.Id;
dto.Title = interaction?.Title;
dto.AccountId = interaction?.Account?.Id;
dto.FlowId = interaction?.LeadType?.Id;
dto.StatusId = interaction?.LeadStatus?.Id;
for (let _idf = 0; _idf < (interaction?.DynamicFields == null ? 0 : interaction?.DynamicFields.length); _idf++) {let df = interaction?.DynamicFields[_idf];let dfDTO = new Domain.InteractionCustomFieldValueDTO();dfDTO.FieldId = df?.DynamicField?.Id;dfDTO.Value = Domain.LeadDynamicFieldValue.GetObjectValue(df)?.toString();dto?.CustomFields?.add(dfDTO);}
return dto;
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.InterationDTOKey == null || $this.InterationDTOKey == 0;
            }


}