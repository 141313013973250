import { OrderByDirections } from '../enums/order-by-directions';
import { ColumnInfo } from './column-info';
export class OrderByInfo {
    column: ColumnInfo;
    direction: OrderByDirections;
    constructor(columnInfo: ColumnInfo, direction: OrderByDirections) {
        this.column = columnInfo;
        this.direction = direction;
    }
}
