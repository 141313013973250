import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevFormGroupComponent } from './FormGroup.component';
import { zappLabelContainerDirective } from '@framework/components/FormGroup/LabelContainer.directive';
import { zappInputContainerDirective } from '@framework/components/FormGroup/InputContainer.directive';
import { zappDescriptionContainerDirective } from '@framework/components/FormGroup/DescriptionContainer.directive';


@NgModule({
  declarations: [zAppDevFormGroupComponent, zappLabelContainerDirective, zappInputContainerDirective, zappDescriptionContainerDirective],
  imports: [
    CommonModule
  ],
  exports: [zAppDevFormGroupComponent, zappLabelContainerDirective, zappInputContainerDirective, zappDescriptionContainerDirective]
})
export class zAppDevFormGroupModule {
  static forRoot(): ModuleWithProviders<zAppDevFormGroupModule> {
    return {
      ngModule: zAppDevFormGroupModule,
      providers: [ ],
    };
  }
}
