import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { zAppDevFileAttachmentComponent } from "./fileattachment.component";
import { FileUploadModule } from 'primeng/fileupload';
import { FormsModule } from "@angular/forms";
import { SecurityModule } from "../../security/security.module";
import { TranslateModule } from '@ngx-translate/core';
import { zAppDevIconismModule } from '../Iconism/iconism.module';
import { zAppDevProgressBarModule } from "../ProgressBar/progressbar.module";
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [zAppDevFileAttachmentComponent],
  imports: [
    CommonModule,
    FormsModule,
    SecurityModule,
    TranslateModule,
    FileUploadModule,
    zAppDevIconismModule,
    zAppDevProgressBarModule,
    ButtonModule,
  ],
  exports: [zAppDevFileAttachmentComponent],
})
export class zAppDevFileAttachmentModule {
  static forRoot(): ModuleWithProviders<zAppDevFileAttachmentModule> {
    return {
      ngModule: zAppDevFileAttachmentModule,
      providers: [],
    };
  }
}
