import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class Company  {
    _originalTypeClassName = "zPMO.BO.Company";
constructor() {
this.BankAccounts = [];
this.Id = null;
this.ShortName = null;
this.Name = "New Company";
this.Activity = null;
this.VAT = null;
this.Country = null;
this.City = null;
this.Address = null;
this.PostalCode = null;
this.InvoiceLetter = null;
this.NextNumber = null;
this.FullName = null;
this.CompanyNumber = null;
this.DefaultTemplate = null;
this.Phonenumber = null;
this.TaxOffice = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
ShortName:any;
Name:any;
Activity:any;
VAT:any;
Country:any;
City:any;
Address:any;
PostalCode:any;
InvoiceLetter:any;
NextNumber:any;
FullName:any;
CompanyNumber:any;
DefaultTemplate:any;
Phonenumber:any;
TaxOffice:any;
Currency:any;
BankAccounts:any;
TaxRule:any;
static setCurrency = ($this, value) => {
var oldValue = $this.Currency;
$this.Currency = value;
if (value != null) {
}
}
static setBankAccounts = ($this, items) => {
                if (items == null) items = [];
                if ($this.BankAccounts == null) $this.BankAccounts = [];

                const copyItems = Common.cloneObject(items);

                while ($this.BankAccounts.length > 0) {
                    Domain.Company.removeBankAccounts($this, $this.BankAccounts[0]);
                }

                Domain.Company.addManyBankAccounts($this, copyItems);
            }
static addManyBankAccounts = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Company.addBankAccounts($this, items[i]);
                }
            }
static addBankAccounts = ($this, item) => {
                if ($this.BankAccounts == null) $this.BankAccounts = [];
                if (item == null || $this.BankAccounts.indexOf(item) > -1) return;
                $this.BankAccounts.push(item);
}
static addRangeBankAccounts = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Company.addBankAccounts($this, item));
            }
static removeBankAccounts = ($this, item) => {
                if ($this == null || $this.BankAccounts == null || $this.BankAccounts.indexOf(item) === -1) return;
                var _index = $this.BankAccounts.indexOf(item);
                $this.BankAccounts.splice(_index, 1);
}
static clearBankAccounts = ($this) => { $this.BankAccounts = null; }
static setTaxRule = ($this, value) => {
var oldValue = $this.TaxRule;
$this.TaxRule = value;
if (value != null) {
}
}

static CalculateTax($this: any, income: any,expenses: any): any{let kerdos = Joove.Common.safeNumber(income) - Joove.Common.safeNumber(expenses);
if (CLMS.Framework.Number.LessThan(() => kerdos, () => 0)) {return 0;}
if ($this?.TaxRule?.Type == Domain.CompanyTaxRuleType.FlatRate) {return Joove.Common.safeNumber(kerdos) * Joove.Common.safeNumber($this?.TaxRule?.Rate) / Joove.Common.safeNumber(100);}else {let tax = 0;let scale = $this?.TaxRule?.ScaleItems?.linq?.orderBy((o) => o?.Amount)?.toArray();let currentScale = scale?.linq?.where()?.firstOrDefault(null);let currentIndex = 0;let remaining = kerdos;while (CLMS.Framework.Number.GreaterThan(() => remaining, () => 0) && currentScale != null) {let next = scale[currentIndex];let ammoutToTax = remaining;if (next != null) {let scaleMax = Joove.Common.safeNumber(next?.Amount) - Joove.Common.safeNumber(currentScale?.Amount);if (CLMS.Framework.Number.GreaterThan(() => ammoutToTax, () => scaleMax)) {ammoutToTax = scaleMax;}}tax = Joove.Common.safeNumber(tax) + Joove.Common.safeNumber(ammoutToTax) * Joove.Common.safeNumber(currentScale?.Rate);currentScale = next;currentIndex = Joove.Common.safeNumber(currentIndex) + Joove.Common.safeNumber(1);}return tax;}
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}