import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class LeadStatusActivationValidation  {
    _originalTypeClassName = "zPMO.BO.LeadStatusActivationValidation";
constructor() {
this.Status = new Domain.LeadStatus();
this.Conditions = [];
this.Id = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Status:any;
Conditions:any;
LeadType:any;
static setStatus = ($this, value) => {
var oldValue = $this.Status;
$this.Status = value;
if (value != null) {
if (oldValue != null && oldValue.Validation != null) { oldValue.Validation = null; }
if ($this.Status.Validation !== $this) {
Domain.LeadStatus.setValidation(value, $this);
}
}
}
static setConditions = ($this, items) => {
                if (items == null) items = [];
                if ($this.Conditions == null) $this.Conditions = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Conditions.length > 0) {
                    Domain.LeadStatusActivationValidation.removeConditions($this, $this.Conditions[0]);
                }

                Domain.LeadStatusActivationValidation.addManyConditions($this, copyItems);
            }
static addManyConditions = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.LeadStatusActivationValidation.addConditions($this, items[i]);
                }
            }
static addConditions = ($this, item) => {
                if ($this.Conditions == null) $this.Conditions = [];
                if (item == null || $this.Conditions.indexOf(item) > -1) return;
                $this.Conditions.push(item);
}
static addRangeConditions = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.LeadStatusActivationValidation.addConditions($this, item));
            }
static removeConditions = ($this, item) => {
                if ($this == null || $this.Conditions == null || $this.Conditions.indexOf(item) === -1) return;
                var _index = $this.Conditions.indexOf(item);
                $this.Conditions.splice(_index, 1);
}
static clearConditions = ($this) => { $this.Conditions = null; }
static setLeadType = ($this, value) => {
var oldValue = $this.LeadType;
$this.LeadType = value;
if (value != null) {
if (oldValue != null) {
Domain.LeadType.removeStatusActivationValidations(oldValue, $this);
}
Domain.LeadType.addStatusActivationValidations(value, $this);
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}