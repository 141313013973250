import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class DynamicServiceIntegration  {
    _originalTypeClassName = "zPMO.BO.DynamicServiceIntegration";
constructor() {
this.Endpoints = [];
this.Id = null;
this.Name = null;
this.BaseURL = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
BaseURL:any;
Endpoints:any;
static setEndpoints = ($this, items) => {
                if (items == null) items = [];
                if ($this.Endpoints == null) $this.Endpoints = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Endpoints.length > 0) {
                    Domain.DynamicServiceIntegration.removeEndpoints($this, $this.Endpoints[0]);
                }

                Domain.DynamicServiceIntegration.addManyEndpoints($this, copyItems);
            }
static addManyEndpoints = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.DynamicServiceIntegration.addEndpoints($this, items[i]);
                }
            }
static addEndpoints = ($this, item) => {
                if ($this.Endpoints == null) $this.Endpoints = [];
                if (item == null || $this.Endpoints.indexOf(item) > -1) return;
                $this.Endpoints.push(item);
if (item.Service != $this) {
Domain.DynamicServiceEndpoint.setService(item, $this);
}
}
static addRangeEndpoints = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.DynamicServiceIntegration.addEndpoints($this, item));
            }
static removeEndpoints = ($this, item) => {
                if ($this == null || $this.Endpoints == null || $this.Endpoints.indexOf(item) === -1) return;
                var _index = $this.Endpoints.indexOf(item);
                $this.Endpoints.splice(_index, 1);
item.Service = null;
}
static clearEndpoints = ($this) => { $this.Endpoints = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}