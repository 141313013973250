<p-calendar *ngIf="!hidden" [ngModel]="_value"
            (onSelect)="onSelect($event)"
            (onBlur)="onInputBlur($event)"
            (onMonthChange)="onMonthChange($event)"
            [showTime]="dateTimeBoxOptions.timePicker"
            [readonlyInput]="!dateTimeBoxOptions.allowtyping || isTouchDevice"
            [touchUI]="isTouchDevice"
            [panelStyleClass]="panelStyleClass"
            [view]="view"
            [dateFormat]="primeNgDateFormat"
            [yearNavigator]="true"
            yearRange="1901:2100"
            appendTo="body"
            [minDate]="_minDate"
            [maxDate]="_maxDate"
            [inputStyleClass]="inputClass"
            [disabled]="disabled || readonly"
            [timeOnly]="dateTimeBoxOptions.timePicker && !dateTimeBoxOptions.datePicker"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute">
</p-calendar>
