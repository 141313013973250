import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class DynamiFieldGroupDTO  {
    _originalTypeClassName = "zPMO.BO.DynamiFieldGroupDTO";
constructor() {
this.Items = [];
this.GroupName = null;
this.GroupId = null;
this.DynamiFieldGroupDTOKey = null;
this._key = this.DynamiFieldGroupDTOKey = 0; 
}

_key: any;
GroupName:any;
GroupId:any;
Items:any;
private _DynamiFieldGroupDTOKey:any;
get DynamiFieldGroupDTOKey(): any {
    return this._DynamiFieldGroupDTOKey;
  }
set DynamiFieldGroupDTOKey(value: any) {
    this._DynamiFieldGroupDTOKey = value;
    this._key = value;
  }
static setItems = ($this, items) => {
                if (items == null) items = [];
                if ($this.Items == null) $this.Items = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Items.length > 0) {
                    Domain.DynamiFieldGroupDTO.removeItems($this, $this.Items[0]);
                }

                Domain.DynamiFieldGroupDTO.addManyItems($this, copyItems);
            }
static addManyItems = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.DynamiFieldGroupDTO.addItems($this, items[i]);
                }
            }
static addItems = ($this, item) => {
                if ($this.Items == null) $this.Items = [];
                if (item == null || $this.Items.indexOf(item) > -1) return;
                $this.Items.push(item);
}
static addRangeItems = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.DynamiFieldGroupDTO.addItems($this, item));
            }
static removeItems = ($this, item) => {
                if ($this == null || $this.Items == null || $this.Items.indexOf(item) === -1) return;
                var _index = $this.Items.indexOf(item);
                $this.Items.splice(_index, 1);
}
static clearItems = ($this) => { $this.Items = null; }

static Convert(lead: any): any{let data = [];
for (let _idf = 0; _idf < (lead?.DynamicFields == null ? 0 : lead?.DynamicFields.length); _idf++) {let df = lead?.DynamicFields[_idf];let group = data?.linq?.where((d) => CLMS.Framework.Number.Equal(() => d.GroupId, () => df.DynamicField.Group.Id))?.firstOrDefault(null);if (group == null) {group = new Domain.DynamiFieldGroupDTO();group.GroupId = df?.DynamicField?.Group?.Id;group.GroupName = df?.DynamicField?.Group?.Name;data?.add(group);}let existing = group?.Items?.linq?.where((i) => CLMS.Framework.Number.Equal(() => i.FieldId, () => df.DynamicField.Id))?.firstOrDefault(null);if (existing == null) {existing = new Domain.DynamiFieldGroupItemDTO();existing.FieldId = df?.DynamicField?.Id;existing.Name = df?.DynamicField?.Name;existing.Datatype = df?.DynamicField?.Datatype;group?.Items?.add(existing);}existing.StringValue = df?.StringValue;existing.NumberValue = df?.NumberValue;existing.DateValue = df?.DateValue;existing.BoolValue = df?.BoolValue;}
return data;
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.DynamiFieldGroupDTOKey == null || $this.DynamiFieldGroupDTOKey == 0;
            }


}