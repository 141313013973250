import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ResourceUtilization  {
    _originalTypeClassName = "zPMO.BO.ResourceUtilization";
constructor() {
this.Resource = new Domain.Employee();
this.Id = null;
this.Time = null;
this.Cost = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Time:any;
Cost:any;
Resource:any;
static setResource = ($this, value) => {
var oldValue = $this.Resource;
$this.Resource = value;
if (value != null) {
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}