import { Joove } from './Joove';
import { System } from './clms.fw.dotnet';
import * as moment from 'moment';
import { ClientCommandsService } from '../../@core/services/ClientCommands.service';
import { Injectable } from '@angular/core';

Date.prototype["addDays"] = function (days) {
    this.setDate(this.getDate() + days);
    return this;
};

if (!String.prototype["format"]) {
    String.prototype["format"] = function () {
        var args = arguments;
        return this.replace(/{(\d+)}/g,
            (match, number) => (typeof args[number] != "undefined"
                ? args[number]
                : match));
    };
}

if (!String.prototype["hashCode"]) {
    String.prototype["hashCode"] = function () {
        var h = 0, l = this.length, i = 0;
        if ( l > 0 )
          while (i < l)
            h = (h << 5) - h + this.charCodeAt(i++) | 0;
        return h;
    };
}

if (!Array.prototype["linq"]) {
    Object.defineProperty(Array.prototype, "linq", {
        get: function () {
            return System.Linq.Enumerable.from(this);
        },
        enumerable: false,
        configurable: true
    });
}

if (!Array.prototype["add"]) {
    Object.defineProperty(Array.prototype, "add", {    
        value: function (item: any) {
            if (this == null) return [item];
            return this.push(item);
        },
        enumerable: false,
        configurable: true
    });
}

if (!Array.prototype["toArray"]) {
    Object.defineProperty(Array.prototype, "toArray", {    
        value: function () {
            return this;
        },
        enumerable: false,
        configurable: true
    });
}

if (!Array.prototype["addManyNew"]) {
    Object.defineProperty(Array.prototype, "addManyNew", {    
        value: function (times: number, valueGetter: () => any): Array<any> {
            for (var index = 0; index < times; index++) {
                this.push(valueGetter());
            }
            return this;
        },
        enumerable: false,
        configurable: true
    });
}

if (!Array.prototype["clear"]) {
    Object.defineProperty(Array.prototype, "clear", {    
        value: function (): Array<any> {
            if (this == null) return this;
    
            this.splice(0, this.length);
            return this;
        },
        enumerable: false,
        configurable: true
    });
}

if (!Array.prototype["contains"]) {
    Object.defineProperty(Array.prototype, "contains", {    
        value: function (item: any): boolean {
            if (this == null) return false;
    
            const array = new System.Collections.List(this);
            return array.contains(item);
        },
        enumerable: false,
        configurable: true
    });
}

if (!Array.prototype["remove"]) {
    Object.defineProperty(Array.prototype, "remove", {    
        value: function (item: any): Array<any> {
            if (this == null) return;
    
            var indexToRemove = this.indexOf(item);
    
            if (indexToRemove == -1) return;
    
            this.splice(indexToRemove, 1);
    
            return this;
        },
        enumerable: false,
        configurable: true
    });
}

if (!Array.prototype["addRange"]) {
    Object.defineProperty(Array.prototype, "addRange", {    
        value: function (items: any): Array<any> {
            if (items == null || this == null) return;
            for (var i = 0; i < items.length; i++) {
                this.push(items[i]);
            }    
            return this;
        },
        enumerable: false,
        configurable: true
    });
}

if (!Array.prototype["insert"]) {
    Object.defineProperty(Array.prototype, "insert", {    
        value: function (pos, item: any): void {
            this.splice(pos, 0, item);
        },
        enumerable: false,
        configurable: true
    });
}

if (!Array.prototype["toCollection"]) {
    Object.defineProperty(Array.prototype, "toCollection", {    
        value: function (): System.Collections.List<any> {
            return new System.Collections.List<any>(this);
        },
        enumerable: false,
        configurable: true
    });
}

if (!Math.round10) {
    Math.round10 = (value, exp) => Joove.Common.decimalAdjust("round", value, exp);
}
// Decimal floor
if (!Math.floor10) {
    Math.floor10 = (value, exp) => Joove.Common.decimalAdjust("floor", value, exp);
}
// Decimal ceil
if (!Math.ceil10) {
    Math.ceil10 = (value, exp) => Joove.Common.decimalAdjust("ceil", value, exp);
}

if (!Math.sign) {
    Math.sign = (x) => (Number(x > 0) - Number(x < 0)) || +x;
}

const NULL = Object.freeze({}) as any;

export namespace CLMS.Framework {
    export var DomainModel: any;

    export namespace Timespan {
        export function Parse(value: string): System.Time.TimeSpan {
            let day;
            let milliseconds;
            let tokens = value.split(":");
            let [hours, minutes, seconds] = tokens; 
            
            if (hours.indexOf('.') !== -1) {
                [day, hours] = hours.split('.');
            }

            if (seconds.indexOf('.') !== -1) {
                [seconds, milliseconds] = seconds.split('.');
            }

            let tms = new System.Time.TimeSpan(0, System.Time.TimeUnit.Days);

            if (day != null) {
                tms = tms.addUnit(parseInt(day), System.Time.TimeUnit.Days);
            }
            
            if (hours != null) {
                tms = tms.addUnit(parseInt(hours), System.Time.TimeUnit.Hours);
            }

            if (minutes != null) {
                tms = tms.addUnit(parseInt(minutes), System.Time.TimeUnit.Minutes);
            }

            if (seconds != null) {
                tms = tms.addUnit(parseInt(seconds), System.Time.TimeUnit.Seconds);
            }

            if (milliseconds != null) {
                tms = tms.addUnit(parseInt(milliseconds), System.Time.TimeUnit.Milliseconds);
            }
            return tms;
        }
    }

    export namespace DateTime {
        export function ToString(value: any, format?: string, locale?: { Code: string }) {
            const datetime = moment(value);
            if (locale != null) datetime.locale(locale.Code);
            if (format == null) datetime.format();
            return datetime.format(Joove.Common.changeDateTimeFormat(format)).toUpperCase();
        }

        export function ParseExact(value: string, locale?: string, formats?: string | Array<string>): any {
            if (formats != null) {
                if (typeof formats === "string") {
                    formats = Joove.Common.changeDateTimeFormat(formats);
                } else {
                    formats = formats.map((format) => {
                        if (typeof format === "string") return Joove.Common.changeDateTimeFormat(format);
                        return format;
                    });
                }

                return moment(value, formats as any, locale);
            }

            return moment(value, locale || "en-US");
        }

        export function Compare(left: Date, right: Date) {
            const x = Joove.Common.dateDiff(left, right);
            return Math.sign(x.milliseconds);
        }
    }

    export namespace Boolean {
        export function Parse(value: string): boolean {
            return value == "true";
        }
    }

    export namespace Integer {
        export function Parse(value: any): number {
            if (value == null) return null;

            var parsed = parseInt(value);

            return isNaN(parsed) ? null : parsed;             
        }
    }

    export namespace Long {
        export function Parse(value: any): number {
            if (value == null) return null;

            var parsed = parseInt(value);

            return isNaN(parsed) ? null : parsed;   
        }
    }

    export namespace Float {
        export function Parse(value: any): number {
            if (value == null) return null;

            var parsed = parseFloat(value);

            return isNaN(parsed) ? null : parsed;   
        }
    }

    export namespace Decimal {
        export function Parse(value: any): number {
            if (value == null) return null;

            var parsed = parseFloat(value);

            return isNaN(parsed) ? null : parsed;  
        }
    }

    export namespace Double {
        export function Parse(value: any): number {
            if (value == null) return null;

            var parsed = parseFloat(value);

            return isNaN(parsed) ? null : parsed;  
        }
    }

    export namespace String {
        export const Empty = "";

        export function Compare(left: string, right: string, ignoreCase: boolean = false): number {
            if (ignoreCase === true) {
                left = left == null ? "" : left.toLocaleLowerCase();
                right = right == null ? "" : right.toLocaleLowerCase();
            }
            return left.localeCompare(right);
        }

        export function Join(sep: string, values: Array<string>): string {
            return values.join(sep);
        }

        export function IsSingular(value: string): boolean {
            throw new System.Exceptions.NotImplementedException(
                "CLMS.Framework.String.IsSingular: Not Implemented yet!"
            );
        }

        export function IsPlural(value: string): boolean {
            throw new System.Exceptions.NotImplementedException(
                "CLMS.Framework.String.IsPlural: Not Implemented yet!"
            );
        }

        export function Singularize(value: string): string {
            throw new System.Exceptions.NotImplementedException(
                "CLMS.Framework.String.Singularize: Not Implemented yet!"
            );
        }

        export function Pluralize(value: string): string {
            throw new System.Exceptions.NotImplementedException(
                "CLMS.Framework.String.Pluralize: Not Implemented yet!"
            );
        }

        export function Format(format: string, ...args: Array<any>): string {
            return (format as any).format(...args);
        }

        export function Concat(values: Array<String> | String, right?: String): String {
            if (values instanceof Array) {
                return values.join("");
            } else {
                return values.toString() + right;
            }
        }

        export function SplitCamelCase(input: string, pattern: RegExp, replacement: string): String {
            return input.replace(new RegExp(pattern), replacement);
        }

        export function IsNullOrEmpty(value: string): boolean {
            return value === "undefined" || Joove.Common.stringIsNullOrEmpty(value);
        }

        export function FillWith(value: string, rep: number): string {
            return Array(rep + 1).join(value);
        }
    }

    export namespace Utilities {

        //export function FocusOnFirstInputElementOfModal() {
        //    if (window._context.isModal === true) {
        //        setTimeout(() => {
        //            $("[jb-type='TextBox'], [jb-type='TextArea'], [jb-type='RichTextBox'], [jb-type='PasswordTextBox'], [jb-type='DateTimeBox'], [jb-type='PasswordTextBox']").eq(0).focus();
        //        }, 500);
        //    }
        //}   
    
        /*export function ExecuteRequest(req, args: Array<any>, cb: Function, err: Function): void {
            if (cb == NULL) cb = (response) => { };
            if (err == NULL) err = (error) => { };
            req(...args)
                .then(cb)
                .catch(err)
                .then(() => {
                    Joove.Core.applyScope(Joove.Common.getScope());
                });
        }*/

        export function ThrowException(name: string): any {
            throw new System.Exceptions.NotImplementedException(
                `${name}: Not Implemented yet!`
            );
        }

        /*export function OpenWindow(url: string, target: string = "_blank", showWarningIfBlocked: boolean = true): void {
            var newWindow =
                window.open(url, target);
            try {
                newWindow.focus();
            }
            catch (e) {
                if (showWarningIfBlocked == true) {
                    window._popUpManager.warning(window._resourcesManager.getPopupBlockedTitle(),
                        window._resourcesManager.getPopupBlockedMessage());
                }
            }
        }*/
        
        export function GetURIParameterValue(parameter: string): string {
            //Works for stuff like: http://localhost:56258/MemberList/GoTo?aNumber=500&astring=xaxa
            let urlParams = new URLSearchParams(location.search);
            if (urlParams && urlParams.has(parameter)) {
                return urlParams.get(parameter);
            }

            //Works for stuff like: http://localhost:56258/MemberList/GoTo/500/xaxa
            if (window._context && window._context.routeData && window._context.routeData.length && window._context.routeData.length>0) {
                for (var i = 0; i < window._context.routeData.length; i++) {
                    let record = window._context.routeData[i];
                    if (record["key"].toLowerCase() == parameter.toLowerCase()) {
                        return record["value"];
                    }
                }
            }

            return "";
        }
        
        export function SizeOf(object: any): number {
            var objects = [object];
            var size = 0;

            for (var index = 0; index < objects.length; index++) {

                switch (typeof objects[index]) {
                    case 'boolean': size += 4; break;
                    case 'number': size += 8; break;
                    case 'string': size += 2 * objects[index].length; break;
                    case 'object':
                        if (Object.prototype.toString.call(objects[index]) != '[object Array]') {
                            for (var key in objects[index]) size += 2 * key.length;
                        }

                        for (var key in objects[index]) {
                            var processed = false;
                            for (var search = 0; search < objects.length; search++) {
                                if (objects[search] === objects[index][key]) {
                                    processed = true;
                                    break;
                                }
                            }
                            if (!processed) objects.push(objects[index][key]);
                        }
                }
            }
            return size;
        }//end SizeOf()

      @Injectable({
        providedIn: 'root'
      })
      export class ValidationException {
        constructor(private clientCommandsService: ClientCommandsService) { }

        throw(message?: string) {
          this.clientCommandsService.execute([{ Command: "SHOW_MESSAGE", Params: [message, Joove.MessageType.Error] }]);
          throw new System.Exceptions.SystemException(message);
        }
      }

        export class MambaRuntimeType {

        }

        export class Path {
            static GetServerPhysicalPath: (path: string) => string;
            static GetDataPath: (path: string) => string;
            static GetUploadsPath: (path: string) => string;
            static ResolveClientURL: (path: string) => string;
            static GetAttatchmentLink: (path: string) => string;
            static GetAttatchmentPath: (path: string) => string;
        }

        export class Email {
            static SendMail(subject: EMailMessage | string,
                body?: boolean | string,
                to?: string,
                cc = "",
                bcc = "",
                fromAddress = "",
                attachments = null,
                sendAsync = false): void {

                if (subject instanceof EMailMessage) {
                } else {

                }
            }
        }

        export class Serializer<T> {
            ToJson(instance: T): string {
                return JSON.stringify(instance);
            }

            FromJson(data: string): T {
                return Joove.Common.toJson(data) as any;
            }

            ToXml(instance: T, tab?: string): string {
                /*  This work is licensed under Creative Commons GNU LGPL License.
                    License: http://creativecommons.org/licenses/LGPL/2.1/
                   Version: 0.9
                    Author:  Stefan Goessner/2006
                    Web:     http://goessner.net/ 
                */
                let toXml = (v, name, ind) => {
                    let xmlParsed = "";
                    if (v instanceof Array) {
                        for (var i = 0, n = v.length; i < n; i++) {
                            xmlParsed += ind + toXml(v[i], name, ind + "\t") + "\n";
                        }
                    } else if (typeof (v) == "object") {
                        let hasChild = false;
                        xmlParsed += ind + "<" + name;
                        for (var m in v) {
                            if (v.hasOwnProperty(m)) {
                                if (m.charAt(0) === "@")
                                    xmlParsed += ` ${m.substr(1)}="${v[m].toString()}"`;
                                else
                                    hasChild = true;
                            }
                        }
                        xmlParsed += hasChild ? ">" : "/>";
                        if (hasChild) {
                            for (var m in v) {
                                if (v.hasOwnProperty(m)) {
                                    if (m === "#text")
                                        xmlParsed += v[m];
                                    else if (m === "#cdata")
                                        xmlParsed += `<![CDATA[${v[m]}]]>`;
                                    else if (m.charAt(0) !== "@")
                                        xmlParsed += toXml(v[m], m, ind + "\t");
                                }
                            }
                            xmlParsed += (xmlParsed.charAt(xmlParsed.length - 1) === "\n"
                                ? ind : "") + "</" + name + ">";
                        }
                    } else {
                        xmlParsed += ind + "<" + name + ">" + v.toString() + "</" + name + ">";
                    }
                    return xmlParsed;
                }, xmlOutput = "";
                for (let m in instance) {
                    if (instance.hasOwnProperty(m)) {
                        xmlOutput += toXml(instance[m], m, "");
                    }
                }
                return tab ? xmlOutput.replace(/\t/g, tab) : xmlOutput.replace(/\t|\n/g, "");
            }

            //FromXml(data: string): T {
            //    return jQuery.parseXML(data) as any;
            //}

            ParseEnum(data: string): T {
                throw new System.Exceptions.NotImplementedException(
                    `${name}: Not Implemented yet!`
                );
            }
        }
        export class EMailMessage {
            constructor() {
                this.To = [];
                this.CC = [];
                this.Bcc = [];
            }

            From: string;
            Subject: string;
            Body: string;
            IsBodyHtml: boolean;
            To: string[];
            CC: string[];
            Bcc: string[];
        }

        export class DataAccessContext<T> {
            Filter: any;
            SortByColumnName: any;
            PageIndex: number;
            PageSize: number;
        }

        export class PagedResults<T> {
            Results: System.Collections.List<T>;
            TotalResults: number;
        }

        export class Common {
            static ConvertToDateTime(str: string, throwException = true): Date {
                return Joove.Common.convertToDateTime(str);
            }

            static ConvertToInt(str: string, throwException = true): number {
                return Joove.Common.convertToNumber(str);
            }

            static ConvertToDouble(str: string, throwException = true): number {
                return Joove.Common.convertToNumber(str);
            }

            static ConvertToGuid(str: string, throwException = true): System.Guid {
                return System.Guid.Parse(str);
            }

            static ConvertToDecimal(str: string, throwException = true): number {
                return Joove.Common.convertToNumber(str);
            }

            static ConvertToLong(str: string, throwException = true): number {
                return Joove.Common.convertToNumber(str);
            }

            static Base64Decode(base64EncodedData: string): string {
                return decodeURIComponent(window.escape(window.atob(base64EncodedData)));
            }

            static Base64Encode(base64EncodedData: string): string {
                return window.btoa(window.unescape(encodeURIComponent(base64EncodedData)));
            }

            static GetMD5Hash(data: string): string {
                throw new System.Exceptions.NotImplementedException(
                    "GetMD5: Not Implemented yet!"
                );
            }

            static IsTypePrimitiveOrSimple(data: any): boolean {
                throw new System.Exceptions.NotImplementedException(
                    "IsTypePrimitiveOrSimple: Not Implemented yet!"
                );
            }

            static IsTypeCollection(data: any): boolean {
                throw new System.Exceptions.NotImplementedException(
                    "IsTypeCollection: Not Implemented yet!"
                );
            }

            static IsPropertyPrimitiveOrSimple(data: any): boolean {
                throw new System.Exceptions.NotImplementedException(
                    "IsPropertyPrimitiveOrSimple: Not Implemented yet!"
                );
            }

            static IsPropertyCollection(data: any): boolean {
                throw new System.Exceptions.NotImplementedException(
                    "IsPropertyCollection: Not Implemented yet!"
                );
            }


        }
    }
    
    export namespace Data {
        export interface ISession {

        }

        export class DataManager {
            static IsPropertyDirty(obj: any, property: string): boolean {
                const session = MiniSessionManager.Instance.Session;
                throw new System.Exceptions.NotImplementedException(
                    "IsPropertyDirty: Not Implemented yet!"
                );
            }
        }

        export class MiniSessionManager {
            InstanceId: System.Guid;
            SingleUseSession: boolean;
            WillFlush: boolean;
            Session: ISession;
            LastAction: any;

            static Instance: MiniSessionManager;

            OpenSession(): any {
                throw new System.Exceptions.NotImplementedException(
                    "OpenSession: Not Implemented yet!"
                );
            };
            OpenSessionWithTransaction(): any {
                throw new System.Exceptions.NotImplementedException(
                    "OpenSessionWithTransaction: Not Implemented yet!"
                );
            }
            BeginTransaction(): any {
                throw new System.Exceptions.NotImplementedException(
                    "BeginTransaction: Not Implemented yet!"
                );
            }
            CommitChanges(exception = null): void {
                throw new System.Exceptions.NotImplementedException(
                    "CommitChanges: Not Implemented yet!"
                );
            }
            Dispose(): void {
                throw new System.Exceptions.NotImplementedException(
                    "BeginTransaction: Not Implemented yet!"
                );
            }
            ExecuteInTransaction<T>(func: System.Action<T> | System.Func<T>): void | T {
                throw new System.Exceptions.NotImplementedException(
                    "BeginTransaction: Not Implemented yet!"
                );
            }
        }
    }

    export namespace Number {
        export function Equal(left: () => number, right: () => number): boolean {
            var lvalue = Joove.Common.nullSafe<number>(left, null);
            var rvalue = Joove.Common.nullSafe<number>(right, null);

            return lvalue == rvalue;
        }

        export function NotEqual(left: () => number, right: () => number): boolean {
            var lvalue = Joove.Common.nullSafe<number>(left, null);
            var rvalue = Joove.Common.nullSafe<number>(right, null);

            return lvalue != rvalue;
        }

        export function LessThan(left: () => number, right: () => number): boolean {
            var lvalue = Joove.Common.nullSafe<number>(left, null);
            var rvalue = Joove.Common.nullSafe<number>(right, null);

            return lvalue < rvalue;
        }

        export function GreaterThan(left: () => number, right: () => number): boolean {
            var lvalue = Joove.Common.nullSafe<number>(left, null);
            var rvalue = Joove.Common.nullSafe<number>(right, null);

            return lvalue > rvalue;
        }

        export function LessThanOrEqual(left: () => number, right: () => number): boolean {
            var lvalue = Joove.Common.nullSafe<number>(left, null);
            var rvalue = Joove.Common.nullSafe<number>(right, null);

            return CLMS.Framework.Number.Equal(left, right) || lvalue <= rvalue;
        }

        export function GreaterThanOrEqual(left: () => number, right: () => number): boolean {
            var lvalue = Joove.Common.nullSafe<number>(left, null);
            var rvalue = Joove.Common.nullSafe<number>(right, null);

            return CLMS.Framework.Number.Equal(left, right) || lvalue >= rvalue;
        }
    }
}
