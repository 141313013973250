import { Directive, Input, HostBinding, OnInit } from '@angular/core';
import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';

@Directive({
  selector: '[zappLabelContainer]'
})
export class zappLabelContainerDirective implements OnInit {
  @HostBinding('class') lebelContainerClass: string;

  constructor(private themeService: IThemeSevice) {
  }

  ngOnInit(): void {
    this.lebelContainerClass = 'form-group-label zapp-formgrouplabelcontainer';
  }
}
