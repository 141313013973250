import { ValueFormat } from '../../../core/clms.fw.angular';

export class ColumnInfo {
  name: string;
  mambaDataType: string;
  formatting: ValueFormat;
  searchable: boolean;
  groupable: boolean;
  isEncrypted: boolean;
  caption: string;
  dataType;
  isVisible;
  orderable;
  width;
  minWidth;
  customWidth;
  supportsAggregators;

  constructor(name: string, dt: string, formatting?: ValueFormat, searchable?: boolean, isEncrypted: boolean = false) {
    this.name = name;
    this.mambaDataType = dt;
    this.formatting = formatting;
    this.searchable = searchable || true;
    this.caption = name;
    this.dataType = dt;
    this.isEncrypted = isEncrypted;
  }
}
