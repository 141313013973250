import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ApplicationUserAction  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.ApplicationUserAction";
constructor() {
this.Id = null;
this.UserName = null;
this.ActiveRoles = null;
this.ActivePermissions = null;
this.Action = null;
this.Controller = null;
this.Date = null;
this.ErrorMessage = null;
this.Success = false;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
UserName:any;
ActiveRoles:any;
ActivePermissions:any;
Action:any;
Controller:any;
Date:any;
ErrorMessage:any;
Success:any;


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}