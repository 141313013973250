import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class TaskEntry  {
    _originalTypeClassName = "zPMO.BO.TaskEntry";
constructor() {
this.Id = null;
this.Description = null;
this.CreatedOn = null;
this.DueDate = null;
this.Completed = false;
this.CompletedAt = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Description:any;
CreatedOn:any;
DueDate:any;
Completed:any;
CompletedAt:any;
Assignee:any;
CreatedBy:any;
RelatedProject:any;
RelatedLead:any;
RelatedLeadAction:any;
static setAssignee = ($this, value) => {
var oldValue = $this.Assignee;
$this.Assignee = value;
if (value != null) {
}
}
static setCreatedBy = ($this, value) => {
var oldValue = $this.CreatedBy;
$this.CreatedBy = value;
if (value != null) {
}
}
static setRelatedProject = ($this, value) => {
var oldValue = $this.RelatedProject;
$this.RelatedProject = value;
if (value != null) {
if (oldValue != null) {
Domain.Project.removeTasks(oldValue, $this);
}
Domain.Project.addTasks(value, $this);
}
}
static setRelatedLead = ($this, value) => {
var oldValue = $this.RelatedLead;
$this.RelatedLead = value;
if (value != null) {
if (oldValue != null) {
Domain.Lead.removeTasks(oldValue, $this);
}
Domain.Lead.addTasks(value, $this);
}
}
static setRelatedLeadAction = ($this, value) => {
var oldValue = $this.RelatedLeadAction;
$this.RelatedLeadAction = value;
if (value != null) {
if (oldValue != null && oldValue.TaskEntry != null) { oldValue.TaskEntry = null; }
if ($this.RelatedLeadAction.TaskEntry !== $this) {
Domain.LeadAction.setTaskEntry(value, $this);
}
}
}

static GetAutoDescription($this: any): any{let descrition = "";
if ($this?.RelatedLead != null) {descrition = "Lead " + $this?.RelatedLead?.Title;if ($this?.RelatedLeadAction != null) {descrition = descrition + ", Action: " + $this?.RelatedLeadAction?.LeadActionType?.Name;}}else if ($this?.RelatedProject != null) {descrition = "Project " + $this?.RelatedProject?.Name;}
return descrition;
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}