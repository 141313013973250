import { DatasourceRequest } from '../models/infos/dataSource-request';
import { ColumnInfo } from '../models/infos/column-info';
import { Subject } from 'rxjs';
import { AggregatorTypes } from '../models/enums/aggregator-types';

export abstract class Datasource<T> {

  originalCollection: Array<T> = []; // imutable
  sortEventCallback: any;
  applyFilterCallback: any;
  clearFilterCallback: any;
  customFilter: string;
  controllerName: string;

  abstract fetchData(datasourceRequest: DatasourceRequest, model?: any, indexes?: any[], controlName?: string): Promise<any>;

  abstract fetchAggregators(datasourceRequest: DatasourceRequest, aggregatorsRequest: any): Promise<any>;

  abstract sort(columnInfo: ColumnInfo, direction: number): Promise<any>;

  abstract filter(searchTerm: string, columns: Array<any>): Promise<any>;

  abstract default(startRow: number, pageSize: number): Promise<any>;

  abstract aggregate(columnInfo: ColumnInfo, aggregatorType: AggregatorTypes): Promise<any>;

  abstract raiseSortEvent(columnInfo: ColumnInfo): void;

  abstract raiseApplyFilterEvent(): void;

  abstract raiseClearFilterEvent(): void;

  abstract indexOf(item: T): number;

  abstract originalElement(item: T): T;
}
