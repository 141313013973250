import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ApplicationUser  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.ApplicationUser";
constructor() {
this.Permissions = [];
this.Roles = [];
this.Clients = [];
this.Logins = [];
this.Claims = [];
this.UserName = null;
this.PasswordHash = null;
this.SecurityStamp = null;
this.EmailConfirmed = false;
this.LockoutEnabled = false;
this.PhoneNumberConfirmed = false;
this.TwoFactorEnabled = false;
this.AccessFailedCount = null;
this.Name = null;
this.Email = null;
this.PhoneNumber = null;
this.LockoutEndDate = null;
this._key = this.UserName = ""; 
}

_key: any;
private _UserName:any;
get UserName(): any {
    return this._UserName;
  }
set UserName(value: any) {
    this._UserName = value;
    this._key = value;
  }
PasswordHash:any;
SecurityStamp:any;
EmailConfirmed:any;
LockoutEnabled:any;
PhoneNumberConfirmed:any;
TwoFactorEnabled:any;
AccessFailedCount:any;
Name:any;
Email:any;
PhoneNumber:any;
LockoutEndDate:any;
Permissions:any;
Roles:any;
Clients:any;
Logins:any;
Claims:any;
Profile:any;
static setPermissions = ($this, items) => {
                if (items == null) items = [];
                if ($this.Permissions == null) $this.Permissions = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Permissions.length > 0) {
                    Domain.ApplicationUser.removePermissions($this, $this.Permissions[0]);
                }

                Domain.ApplicationUser.addManyPermissions($this, copyItems);
            }
static addManyPermissions = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.ApplicationUser.addPermissions($this, items[i]);
                }
            }
static addPermissions = ($this, item) => {
                if ($this.Permissions == null) $this.Permissions = [];
                if (item == null || $this.Permissions.indexOf(item) > -1) return;
                $this.Permissions.push(item);
Domain.ApplicationPermission.addUsers(item, $this);
}
static addRangePermissions = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.ApplicationUser.addPermissions($this, item));
            }
static removePermissions = ($this, item) => {
                if ($this == null || $this.Permissions == null || $this.Permissions.indexOf(item) === -1) return;
                var _index = $this.Permissions.indexOf(item);
                $this.Permissions.splice(_index, 1);
Domain.ApplicationPermission.removeUsers(item, $this);
}
static clearPermissions = ($this) => { $this.Permissions = null; }
static setRoles = ($this, items) => {
                if (items == null) items = [];
                if ($this.Roles == null) $this.Roles = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Roles.length > 0) {
                    Domain.ApplicationUser.removeRoles($this, $this.Roles[0]);
                }

                Domain.ApplicationUser.addManyRoles($this, copyItems);
            }
static addManyRoles = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.ApplicationUser.addRoles($this, items[i]);
                }
            }
static addRoles = ($this, item) => {
                if ($this.Roles == null) $this.Roles = [];
                if (item == null || $this.Roles.indexOf(item) > -1) return;
                $this.Roles.push(item);
Domain.ApplicationRole.addUsers(item, $this);
}
static addRangeRoles = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.ApplicationUser.addRoles($this, item));
            }
static removeRoles = ($this, item) => {
                if ($this == null || $this.Roles == null || $this.Roles.indexOf(item) === -1) return;
                var _index = $this.Roles.indexOf(item);
                $this.Roles.splice(_index, 1);
Domain.ApplicationRole.removeUsers(item, $this);
}
static clearRoles = ($this) => { $this.Roles = null; }
static setClients = ($this, items) => {
                if (items == null) items = [];
                if ($this.Clients == null) $this.Clients = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Clients.length > 0) {
                    Domain.ApplicationUser.removeClients($this, $this.Clients[0]);
                }

                Domain.ApplicationUser.addManyClients($this, copyItems);
            }
static addManyClients = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.ApplicationUser.addClients($this, items[i]);
                }
            }
static addClients = ($this, item) => {
                if ($this.Clients == null) $this.Clients = [];
                if (item == null || $this.Clients.indexOf(item) > -1) return;
                $this.Clients.push(item);
if (item.User != $this) {
Domain.ApplicationClient.setUser(item, $this);
}
}
static addRangeClients = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.ApplicationUser.addClients($this, item));
            }
static removeClients = ($this, item) => {
                if ($this == null || $this.Clients == null || $this.Clients.indexOf(item) === -1) return;
                var _index = $this.Clients.indexOf(item);
                $this.Clients.splice(_index, 1);
item.User = null;
}
static clearClients = ($this) => { $this.Clients = null; }
static setLogins = ($this, items) => {
                if (items == null) items = [];
                if ($this.Logins == null) $this.Logins = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Logins.length > 0) {
                    Domain.ApplicationUser.removeLogins($this, $this.Logins[0]);
                }

                Domain.ApplicationUser.addManyLogins($this, copyItems);
            }
static addManyLogins = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.ApplicationUser.addLogins($this, items[i]);
                }
            }
static addLogins = ($this, item) => {
                if ($this.Logins == null) $this.Logins = [];
                if (item == null || $this.Logins.indexOf(item) > -1) return;
                $this.Logins.push(item);
if (item.User != $this) {
Domain.ApplicationUserLogin.setUser(item, $this);
}
}
static addRangeLogins = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.ApplicationUser.addLogins($this, item));
            }
static removeLogins = ($this, item) => {
                if ($this == null || $this.Logins == null || $this.Logins.indexOf(item) === -1) return;
                var _index = $this.Logins.indexOf(item);
                $this.Logins.splice(_index, 1);
item.User = null;
}
static clearLogins = ($this) => { $this.Logins = null; }
static setClaims = ($this, items) => {
                if (items == null) items = [];
                if ($this.Claims == null) $this.Claims = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Claims.length > 0) {
                    Domain.ApplicationUser.removeClaims($this, $this.Claims[0]);
                }

                Domain.ApplicationUser.addManyClaims($this, copyItems);
            }
static addManyClaims = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.ApplicationUser.addClaims($this, items[i]);
                }
            }
static addClaims = ($this, item) => {
                if ($this.Claims == null) $this.Claims = [];
                if (item == null || $this.Claims.indexOf(item) > -1) return;
                $this.Claims.push(item);
if (item.User != $this) {
Domain.ApplicationUserClaim.setUser(item, $this);
}
}
static addRangeClaims = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.ApplicationUser.addClaims($this, item));
            }
static removeClaims = ($this, item) => {
                if ($this == null || $this.Claims == null || $this.Claims.indexOf(item) === -1) return;
                var _index = $this.Claims.indexOf(item);
                $this.Claims.splice(_index, 1);
item.User = null;
}
static clearClaims = ($this) => { $this.Claims = null; }
static setProfile = ($this, value) => {
var oldValue = $this.Profile;
$this.Profile = value;
if (value != null) {
}
}

static IsInRole = ($this: any, item: string): boolean => {
    return item == null || ($this.Roles?.map(r => r.Name) ?? []).includes(item);
  }

  static HasPermission = ($this: any, item: string): boolean => {
    return item == null || ($this.Permissions?.map(r => r.Name) ?? []).includes(item);
  }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.UserName == null || $this.UserName == "";
            }


}