import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class Profile  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.Profile";
constructor() {
this.Settings = [];
this.Id = null;
this.LanguageLCID = null;
this.LocaleLCID = null;
this.TimezoneInfoID = null;
this.Theme = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
LanguageLCID:any;
LocaleLCID:any;
TimezoneInfoID:any;
Theme:any;
Settings:any;
static setSettings = ($this, items) => {
                if (items == null) items = [];
                if ($this.Settings == null) $this.Settings = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Settings.length > 0) {
                    Domain.Profile.removeSettings($this, $this.Settings[0]);
                }

                Domain.Profile.addManySettings($this, copyItems);
            }
static addManySettings = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Profile.addSettings($this, items[i]);
                }
            }
static addSettings = ($this, item) => {
                if ($this.Settings == null) $this.Settings = [];
                if (item == null || $this.Settings.indexOf(item) > -1) return;
                $this.Settings.push(item);
if (item.ParentProfile != $this) {
Domain.ProfileSetting.setParentProfile(item, $this);
}
}
static addRangeSettings = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Profile.addSettings($this, item));
            }
static removeSettings = ($this, item) => {
                if ($this == null || $this.Settings == null || $this.Settings.indexOf(item) === -1) return;
                var _index = $this.Settings.indexOf(item);
                $this.Settings.splice(_index, 1);
item.ParentProfile = null;
}
static clearSettings = ($this) => { $this.Settings = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}