import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { zAppDevProgressBarComponent } from "./ProgressBar.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [zAppDevProgressBarComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [zAppDevProgressBarComponent],
})
export class zAppDevProgressBarModule {
  static forRoot(): ModuleWithProviders<zAppDevProgressBarModule> {
    return {
      ngModule: zAppDevProgressBarModule,
      providers: [],
    };
  }
}
