import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class Dashobard  {
    _originalTypeClassName = "zPMO.BO.Dashobard";
constructor() {
this.Data = [];
this.CompanyData = [];
this.Licences = [];
this.PaidInvoices = null;
this.UnpaidInvoices = null;
this.ToBeIssuedInvoices = null;
this.Income = null;
this.Expenses = null;
this.Profit = null;
this.Leads = null;
this.Tax = null;
this.DashobardKey = null;
this._key = this.DashobardKey = 0; 
}

_key: any;
PaidInvoices:any;
UnpaidInvoices:any;
ToBeIssuedInvoices:any;
Income:any;
Expenses:any;
Profit:any;
Leads:any;
Tax:any;
FiscalYear:any;
Data:any;
CompanyData:any;
Licences:any;
private _DashobardKey:any;
get DashobardKey(): any {
    return this._DashobardKey;
  }
set DashobardKey(value: any) {
    this._DashobardKey = value;
    this._key = value;
  }
static setFiscalYear = ($this, value) => {
var oldValue = $this.FiscalYear;
$this.FiscalYear = value;
if (value != null) {
}
}
static setData = ($this, items) => {
                if (items == null) items = [];
                if ($this.Data == null) $this.Data = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Data.length > 0) {
                    Domain.Dashobard.removeData($this, $this.Data[0]);
                }

                Domain.Dashobard.addManyData($this, copyItems);
            }
static addManyData = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Dashobard.addData($this, items[i]);
                }
            }
static addData = ($this, item) => {
                if ($this.Data == null) $this.Data = [];
                if (item == null || $this.Data.indexOf(item) > -1) return;
                $this.Data.push(item);
}
static addRangeData = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Dashobard.addData($this, item));
            }
static removeData = ($this, item) => {
                if ($this == null || $this.Data == null || $this.Data.indexOf(item) === -1) return;
                var _index = $this.Data.indexOf(item);
                $this.Data.splice(_index, 1);
}
static clearData = ($this) => { $this.Data = null; }
static setCompanyData = ($this, items) => {
                if (items == null) items = [];
                if ($this.CompanyData == null) $this.CompanyData = [];

                const copyItems = Common.cloneObject(items);

                while ($this.CompanyData.length > 0) {
                    Domain.Dashobard.removeCompanyData($this, $this.CompanyData[0]);
                }

                Domain.Dashobard.addManyCompanyData($this, copyItems);
            }
static addManyCompanyData = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Dashobard.addCompanyData($this, items[i]);
                }
            }
static addCompanyData = ($this, item) => {
                if ($this.CompanyData == null) $this.CompanyData = [];
                if (item == null || $this.CompanyData.indexOf(item) > -1) return;
                $this.CompanyData.push(item);
}
static addRangeCompanyData = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Dashobard.addCompanyData($this, item));
            }
static removeCompanyData = ($this, item) => {
                if ($this == null || $this.CompanyData == null || $this.CompanyData.indexOf(item) === -1) return;
                var _index = $this.CompanyData.indexOf(item);
                $this.CompanyData.splice(_index, 1);
}
static clearCompanyData = ($this) => { $this.CompanyData = null; }
static setLicences = ($this, items) => {
                if (items == null) items = [];
                if ($this.Licences == null) $this.Licences = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Licences.length > 0) {
                    Domain.Dashobard.removeLicences($this, $this.Licences[0]);
                }

                Domain.Dashobard.addManyLicences($this, copyItems);
            }
static addManyLicences = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.Dashobard.addLicences($this, items[i]);
                }
            }
static addLicences = ($this, item) => {
                if ($this.Licences == null) $this.Licences = [];
                if (item == null || $this.Licences.indexOf(item) > -1) return;
                $this.Licences.push(item);
}
static addRangeLicences = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.Dashobard.addLicences($this, item));
            }
static removeLicences = ($this, item) => {
                if ($this == null || $this.Licences == null || $this.Licences.indexOf(item) === -1) return;
                var _index = $this.Licences.indexOf(item);
                $this.Licences.splice(_index, 1);
}
static clearLicences = ($this) => { $this.Licences = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.DashobardKey == null || $this.DashobardKey == 0;
            }


}