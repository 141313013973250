import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class Payment  {
    _originalTypeClassName = "zPMO.BO.Payment";
constructor() {
this.Id = null;
this.Amount = null;
this.Date = null;
this.InvoiceNumber = null;
this.IsPaid = false;
this.PaidDate = null;
this.Comment = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Amount:any;
Date:any;
InvoiceNumber:any;
IsPaid:any;
PaidDate:any;
Comment:any;
Company:any;
Invoice:any;
static setCompany = ($this, value) => {
var oldValue = $this.Company;
$this.Company = value;
if (value != null) {
}
}
static setInvoice = ($this, value) => {
var oldValue = $this.Invoice;
$this.Invoice = value;
if (value != null) {
if (oldValue != null) {
Domain.Invoice.removePayments(oldValue, $this);
}
Domain.Invoice.addPayments(value, $this);
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}