<zapp-modal #Modal [body]="bodyModal" [header]="headerModal" [footer]="footerModal">
  <ng-template #headerModal>
    Search
  </ng-template>
  <ng-template #bodyModal>
    <div>
      <zappTextbox type="string" [(value)]="query" (change)="search()"></zappTextbox>
      <zappCheckbox [(value)]="matchCase" (valueChange)="search()">Match Case</zappCheckbox>
      <zappCheckbox [(value)]="partialMatch" (valueChange)="search()">Partial Match</zappCheckbox>
    </div>
    <zapp-tabView>
      <!-- Index results -->
      <zapp-tabPanel [header]="resIndexResults" [contentTemplate]="IndexesContent">
        <ng-template #IndexesContent>
          <zappDataTable [model]="indexSearchResults" [header]="IndexesGridHeader" [body]="IndexesGridBody">
            <ng-template #IndexesGridHeader>
              <tr>
                <th>Icon</th>
                <th>Primary Label</th>
                <th>Secondary Label</th>
                <th>Class Name - Id</th>
                <th>Router Link</th>
              </tr>
            </ng-template>
            <ng-template #IndexesGridBody let-IndexesGridItem>
              <tr>
                <td>{{ IndexesGridItem.Icon }}</td>
                <td>{{ IndexesGridItem.PrimaryLabel }}</td>
                <td>{{ IndexesGridItem.SecondaryLabel }}</td>
                <td>{{ IndexesGridItem.ClassName }} - {{ IndexesGridItem.Id }}</td>
                <td [routerLink]="IndexesGridItem.RouterLink">{{ IndexesGridItem.RouterLink }}</td>
              </tr>
            </ng-template>
          </zappDataTable>
        </ng-template>
      </zapp-tabPanel>
      <!-- Menu results -->
      <zapp-tabPanel [header]="resSearchResults" [contentTemplate]="MenuContent">
        <ng-template #MenuContent>
          <zappDataTable [model]="menuSearchResults" [header]="MenuGridHeader" [body]="MenuGridBody">
            <ng-template #MenuGridHeader>
              <tr>
                <th>Add to favorites</th>
                <th>Text</th>
                <th>Path</th>
                <th>Remove from favorites</th>
                <th>Router link</th>
              </tr>
            </ng-template>
            <ng-template #MenuGridBody let-MenuGridItem>
              <tr>
                <td (click)="addToFavorites(MenuGridItem.routerLink)">Add to favorites</td>
                <td>{{ MenuGridItem.text }}</td>
                <td>{{ MenuGridItem.path }}</td>
                <td (click)="removeFromFavorites(MenuGridItem.routerLink)">Remove from favorites</td>
                <td [routerLink]="MenuGridItem.routerLink">{{ MenuGridItem.routerLink }}</td>
              </tr>
            </ng-template>
          </zappDataTable>
        </ng-template>
      </zapp-tabPanel>
      <!-- Favorites -->
      <zapp-tabPanel [header]="resFavorites" [contentTemplate]="FavoritesContent">
        <ng-template #FavoritesContent>
          <zappDataTable [model]="favoriteItems" [header]="FavoritesGridHeader" [body]="FavoritesGridBody">
            <ng-template #FavoritesGridHeader>
              <tr>
                <th></th>
                <th>{{ resName }}</th>
                <th>{{ resPath }}</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template #FavoritesGridBody let-FavoritesGridItem>
              <tr>
                <td class="clickable-icon" (click)="removeFromFavorites(FavoritesGridItem.route)">
                  <i class="pi pi-star-fill"></i>
                </td>
                <td>{{ FavoritesGridItem.primary }}</td>
                <td>{{ FavoritesGridItem.secondary }}</td>
                <td class="clickable-icon" [routerLink]="FavoritesGridItem.route">
                  <i class="pi pi-arrow-circle-right"></i>
                </td>
              </tr>
            </ng-template>
          </zappDataTable>
        </ng-template>
      </zapp-tabPanel>
      <!-- Most used -->
      <zapp-tabPanel [header]="resMostUsed" [contentTemplate]="MostUsedContent">
        <ng-template #MostUsedContent>
          <zappDataTable [model]="mostUsedItems" [header]="MostUsedGridHeader" [body]="MostUsedGridBody">
            <ng-template #MostUsedGridHeader>
              <tr>
                <th></th>
                <th>{{ resName }}</th>
                <th>{{ resPath }}</th>
                <th></th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template #MostUsedGridBody let-MostUsedGridItem>
              <tr>
                <td *ngIf="!MostUsedGridItem.isFavorite" (click)="addToFavorites(MostUsedGridItem.route)">
                  <i class="pi pi-star"></i>
                </td>
                <td *ngIf="MostUsedGridItem.isFavorite" (click)="removeFromFavorites(MostUsedGridItem.route)">
                  <i class="pi pi-star-fill"></i>
                </td>
                <td>{{ MostUsedGridItem.primary }}</td>
                <td>{{ MostUsedGridItem.secondary }}</td>
                <td class="clickable-icon" (click)="removeFromMostUsed(MostUsedGridItem.route)">
                  <i class="pi pi-trash"></i>
                </td>
                <td class="clickable-icon" [routerLink]="MostUsedGridItem.route">
                  <i class="pi pi-arrow-circle-right"></i>
                </td>
              </tr>
            </ng-template>
          </zappDataTable>
        </ng-template>
      </zapp-tabPanel>
    </zapp-tabView>
  </ng-template>
  <ng-template #footerModal>
    <zapp-button (click)="closeModal()">
      Close
    </zapp-button>
  </ng-template>
</zapp-modal>
