import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class MonthyReport  {
    _originalTypeClassName = "zPMO.BO.MonthyReport";
constructor() {
this.Client = new Domain.Client();
this.AdHocEntries = [];
this.ApprovedEntries = [];
this.Id = null;
this.Date = null;
this.DateFrom = null;
this.DateTo = null;
this.AdHocQueryId = null;
this.ApprovedQueryId = null;
this.TotalAdHocHours = null;
this.TotalAdHocManDays = null;
this.TotalApprovedHours = null;
this.TotalApprevedManDays = null;
this.PreviousDayBalance = null;
this.NewDayBalance = null;
this.IsAccepted = false;
this.ReportName = null;
this.CreatedBy = null;
this.ApprovedBy = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Date:any;
DateFrom:any;
DateTo:any;
AdHocQueryId:any;
ApprovedQueryId:any;
TotalAdHocHours:any;
TotalAdHocManDays:any;
TotalApprovedHours:any;
TotalApprevedManDays:any;
PreviousDayBalance:any;
NewDayBalance:any;
IsAccepted:any;
ReportName:any;
CreatedBy:any;
ApprovedBy:any;
Client:any;
Project:any;
AdHocEntries:any;
ApprovedEntries:any;
static setClient = ($this, value) => {
var oldValue = $this.Client;
$this.Client = value;
if (value != null) {
}
}
static setProject = ($this, value) => {
var oldValue = $this.Project;
$this.Project = value;
if (value != null) {
}
}
static setAdHocEntries = ($this, items) => {
                if (items == null) items = [];
                if ($this.AdHocEntries == null) $this.AdHocEntries = [];

                const copyItems = Common.cloneObject(items);

                while ($this.AdHocEntries.length > 0) {
                    Domain.MonthyReport.removeAdHocEntries($this, $this.AdHocEntries[0]);
                }

                Domain.MonthyReport.addManyAdHocEntries($this, copyItems);
            }
static addManyAdHocEntries = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.MonthyReport.addAdHocEntries($this, items[i]);
                }
            }
static addAdHocEntries = ($this, item) => {
                if ($this.AdHocEntries == null) $this.AdHocEntries = [];
                if (item == null || $this.AdHocEntries.indexOf(item) > -1) return;
                $this.AdHocEntries.push(item);
}
static addRangeAdHocEntries = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.MonthyReport.addAdHocEntries($this, item));
            }
static removeAdHocEntries = ($this, item) => {
                if ($this == null || $this.AdHocEntries == null || $this.AdHocEntries.indexOf(item) === -1) return;
                var _index = $this.AdHocEntries.indexOf(item);
                $this.AdHocEntries.splice(_index, 1);
}
static clearAdHocEntries = ($this) => { $this.AdHocEntries = null; }
static setApprovedEntries = ($this, items) => {
                if (items == null) items = [];
                if ($this.ApprovedEntries == null) $this.ApprovedEntries = [];

                const copyItems = Common.cloneObject(items);

                while ($this.ApprovedEntries.length > 0) {
                    Domain.MonthyReport.removeApprovedEntries($this, $this.ApprovedEntries[0]);
                }

                Domain.MonthyReport.addManyApprovedEntries($this, copyItems);
            }
static addManyApprovedEntries = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.MonthyReport.addApprovedEntries($this, items[i]);
                }
            }
static addApprovedEntries = ($this, item) => {
                if ($this.ApprovedEntries == null) $this.ApprovedEntries = [];
                if (item == null || $this.ApprovedEntries.indexOf(item) > -1) return;
                $this.ApprovedEntries.push(item);
}
static addRangeApprovedEntries = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.MonthyReport.addApprovedEntries($this, item));
            }
static removeApprovedEntries = ($this, item) => {
                if ($this == null || $this.ApprovedEntries == null || $this.ApprovedEntries.indexOf(item) === -1) return;
                var _index = $this.ApprovedEntries.indexOf(item);
                $this.ApprovedEntries.splice(_index, 1);
}
static clearApprovedEntries = ($this) => { $this.ApprovedEntries = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}