import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class CRMStatsItem  {
    _originalTypeClassName = "zPMO.BO.CRMStatsItem";
constructor() {
this.Group = null;
this.Value = null;
this.CRMStatsItemKey = null;
this._key = this.CRMStatsItemKey = 0; 
}

_key: any;
Group:any;
Value:any;
private _CRMStatsItemKey:any;
get CRMStatsItemKey(): any {
    return this._CRMStatsItemKey;
  }
set CRMStatsItemKey(value: any) {
    this._CRMStatsItemKey = value;
    this._key = value;
  }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.CRMStatsItemKey == null || $this.CRMStatsItemKey == 0;
            }


}