import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { zAppDevDateTimeBoxComponent } from './DateTimeBox.component';
import { CalendarNavComponent } from './calendar-nav/calendar-nav.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DatePickerDirective } from './date-picker/date-picker.directive';
import { DayCalendarComponent } from './day-calendar/day-calendar.component';
import { MonthCalendarComponent } from './month-calendar/month-calendar.component';
import { TimeSelectComponent } from './time-select/time-select.component';
import { DayTimeCalendarComponent } from './day-time-calendar/day-time-calendar.component';
import { CalendarModule } from 'primeng/calendar';

//https://www.npmjs.com/package/ng2-date-picker



export const zAppDevDateTimeBox_DEPENDENCIES = [

];

@NgModule({
  declarations: [zAppDevDateTimeBoxComponent,
    DatePickerComponent,
    DatePickerDirective,
    DayCalendarComponent,
    MonthCalendarComponent,
    CalendarNavComponent,
    TimeSelectComponent,
    DayTimeCalendarComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule
  ],
  exports: [zAppDevDateTimeBoxComponent,
    DatePickerComponent,
    DatePickerDirective,
    MonthCalendarComponent,
    DayCalendarComponent,
    TimeSelectComponent,
    DayTimeCalendarComponent]
})
export class zAppDevDateTimeBoxModule { }
