import { Pipe, PipeTransform } from "@angular/core";
import { ValueFormat } from "@framework/core/clms.fw.angular";
import { DataListColumnInfo } from "./helpers/List.helper.classes";
import * as Domain from '@core/Domain';
import * as CommonUtilities from "@framework/core/clms.fw.common";
import { TranslateService } from "@ngx-translate/core";
import { Joove } from "../../core/Joove";
import { environment } from "src/environments/environment";
import { ColumnInfo } from "../../datasource/models/infos/column-info";

export const resolveProperty = (obj: any, prop: string) => {
    var parts = prop.split(".");
    let current = obj;
    for (let part of parts) {
        const clean = part.replace("?", "");
        current = current[clean];
        if (current == null) {
            return null;
        }
    }
    return current;
}

export const toMambaEnumStringValue = (mambaEnumDataType: string, value: number, translate: TranslateService): string => {
    const enumClass = Domain[mambaEnumDataType];

    if (enumClass == undefined || enumClass[value] == undefined) {
        return `${value}`;
    }
    
    const resKey = `Res_ENUM_${mambaEnumDataType}_${enumClass[value]}`;
    let translation = translate.instant(resKey);
    if (CommonUtilities.CLMS.Framework.String.IsNullOrEmpty(translation) || translation.indexOf('Res_ENUM_') == 0) {
        translation = CommonUtilities.CLMS.Framework.String.SplitCamelCase(enumClass[value] ?? "", /([a-z])([A-Z])|([A-Z])([A-Z][a-z])/g, "$1 $2")
    }

    return translation;
}

export const getColumnValue = (row: any, columnInfo: DataListColumnInfo, translate: TranslateService) => {
    const data = resolveProperty(row, columnInfo.name);

    //progress bar, return raw data
    if (columnInfo.itemType == 6) {
        return data;
    }

    // HTML
    if (columnInfo.itemType == 5) {
      return getHtml(data);
    }

    let outputData: string;
    const type = "";
    const decimalDatatypes = ["float", "decimal", "double"];
    const numericDataTypes = ["byte", "int", "long", "float", "decimal", "double"];
    var valueFormat = new ValueFormat(columnInfo.formatting as any);

    //Safely handle empty fields
    if (data == null) {
        outputData = "";
    }
    else if (columnInfo.dataType == "FileData" && data != null) {
        //outputData = `${window._context.siteRoot}${window._context.currentController}/Download_${this.name}_${columnInfo.name}?key=${row._key}`;
    }
    else if (columnInfo.formatting != null ||
        (valueFormat != null && decimalDatatypes.indexOf(columnInfo.mambaDataType) > -1)
    ) {
        outputData = valueFormat.format(data);
    }
    else if (columnInfo.mambaDataTypeIsEnum === true) {
        //Try to find the enum in order to display string info instead of numbers
        outputData = toMambaEnumStringValue(columnInfo.mambaDataType, data, translate);
    }
    else {
        outputData = data;
    }


    return outputData;
}

const getHtml = (value: any) => {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = value;
  const html = textArea.value;
  textArea.remove();
  return html;
}

@Pipe({
    name: 'getColumnValue'
})
export class GetColumnValuePipe implements PipeTransform {


    constructor(protected readonly translate: TranslateService) {

    }

    transform(row: any, columnInfo: DataListColumnInfo) {
        return getColumnValue(row, columnInfo, this.translate);
    }
}

@Pipe({
  name: 'getListImageUrl'
})
export class GetListImageUrlPipe implements PipeTransform {

  transform(id: number, col: ColumnInfo, controllerName: string, listControlName: string, thumbnail: boolean = true) {

    return Joove.Common.pathJoin(environment.appUrl, controllerName, `Download_${listControlName}_${col.name}?key=${id}&thumbnail=${thumbnail}`);
  }
}
