import { ColumnInfo } from './column-info';
export class GroupByInfo {
    column: ColumnInfo;
    state;
    inactive;
    getGroupsClosed;
    constructor(columnInfo: ColumnInfo, state, inactive, getGroupsClosed) {
        this.column = columnInfo;
        this.state = state;
        this.inactive = inactive;
        this.getGroupsClosed = getGroupsClosed;
    }
}
