import { Directive, Input, HostBinding, OnInit } from '@angular/core';
import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';

@Directive({
  selector: '[zappDescriptionContainer]'
})
export class zappDescriptionContainerDirective implements OnInit {
  @HostBinding('class') descriptionContainerClass: string;

  constructor(private themeService: IThemeSevice) {
  }

  ngOnInit(): void {
    this.descriptionContainerClass = 'form-group-description zapp-formgroupdescriptioncontainer';
  }
}
