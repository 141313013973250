import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ApplicationUserClaim  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.ApplicationUserClaim";
constructor() {
this.User = new Domain.ApplicationUser();
this.Id = null;
this.ClaimType = null;
this.ClaimValue = null;
this.ClaimValueType = null;
this.Issuer = null;
this.OriginalIssuer = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
ClaimType:any;
ClaimValue:any;
ClaimValueType:any;
Issuer:any;
OriginalIssuer:any;
User:any;
static setUser = ($this, value) => {
var oldValue = $this.User;
$this.User = value;
if (value != null) {
if (oldValue != null) {
Domain.ApplicationUser.removeClaims(oldValue, $this);
}
Domain.ApplicationUser.addClaims(value, $this);
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}