<ng-container *ngIf="isMultiSelect != true">
  <p-dropdown *ngIf="!hidden"
              #dropdown
              [autoDisplayFirst]="false"
              [placeholder]="placeholder"
              [filterPlaceholder]="filterPlaceholder"
              [showClear]="showClear && !(disabled || readonly)"
              [styleClass]="globalClass"
              [style]="_style"
              [options]="items"
              [optionLabel]="optionLabel"
              [optionValue]="optionValue"
              [ngModel]="selectedValue"
              [filter]="isSearchable"
              [readonly]="_readonly"
              (onFilter)="onFilter($event)"
              (onFocus)="onFocus($event)"
              (onShow)="onShow($event)"
              (onChange)="onValueChange($event)"
              [panelStyleClass]="panelStyleClass"
              appendTo="body"
              [disabled]="disabled || readonly"
              [dropdownIcon]="iconClasses">
  </p-dropdown>
</ng-container>

<ng-container *ngIf="isMultiSelect == true">
  <p-multiSelect *ngIf="!hidden"
                 #dropdown
                 [options]="items"
                 [(ngModel)]="selectedValue"
                 [optionLabel]="optionLabel"
                 [optionValue]="optionValue"
                 [filter]="isSearchable"
                 (onChange)="onValueChange($event)"
                 [attr.display]="displayChips ? 'chip' : undefined"
                 [disabled]="disabled || readonly"
                 [placeholder]="placeholder"
                 appendTo="body">
  </p-multiSelect>
</ng-container>

