import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class MarketSegment  {
    _originalTypeClassName = "zPMO.BO.MarketSegment";
constructor() {
this.Accounts = [];
this.Conditions = [];
this.Id = null;
this.Name = null;
this.Description = null;
this.TotalAccounts = null;
this.RuleBased = false;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Description:any;
TotalAccounts:any;
RuleBased:any;
Accounts:any;
Conditions:any;
static setAccounts = ($this, items) => {
                if (items == null) items = [];
                if ($this.Accounts == null) $this.Accounts = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Accounts.length > 0) {
                    Domain.MarketSegment.removeAccounts($this, $this.Accounts[0]);
                }

                Domain.MarketSegment.addManyAccounts($this, copyItems);
            }
static addManyAccounts = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.MarketSegment.addAccounts($this, items[i]);
                }
            }
static addAccounts = ($this, item) => {
                if ($this.Accounts == null) $this.Accounts = [];
                if (item == null || $this.Accounts.indexOf(item) > -1) return;
                $this.Accounts.push(item);
Domain.Client.addMarketSegments(item, $this);
}
static addRangeAccounts = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.MarketSegment.addAccounts($this, item));
            }
static removeAccounts = ($this, item) => {
                if ($this == null || $this.Accounts == null || $this.Accounts.indexOf(item) === -1) return;
                var _index = $this.Accounts.indexOf(item);
                $this.Accounts.splice(_index, 1);
Domain.Client.removeMarketSegments(item, $this);
}
static clearAccounts = ($this) => { $this.Accounts = null; }
static setConditions = ($this, items) => {
                if (items == null) items = [];
                if ($this.Conditions == null) $this.Conditions = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Conditions.length > 0) {
                    Domain.MarketSegment.removeConditions($this, $this.Conditions[0]);
                }

                Domain.MarketSegment.addManyConditions($this, copyItems);
            }
static addManyConditions = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.MarketSegment.addConditions($this, items[i]);
                }
            }
static addConditions = ($this, item) => {
                if ($this.Conditions == null) $this.Conditions = [];
                if (item == null || $this.Conditions.indexOf(item) > -1) return;
                $this.Conditions.push(item);
}
static addRangeConditions = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.MarketSegment.addConditions($this, item));
            }
static removeConditions = ($this, item) => {
                if ($this == null || $this.Conditions == null || $this.Conditions.indexOf(item) === -1) return;
                var _index = $this.Conditions.indexOf(item);
                $this.Conditions.splice(_index, 1);
}
static clearConditions = ($this) => { $this.Conditions = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}