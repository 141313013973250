import {
  Component,
  Input,
  ElementRef, ViewChild,
} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

import {IThemeSevice} from '../../theme/interfaces/theme.interface';
import {CF_COMPONENT} from '@framework/rule-engine/directives/condition-formatting.directive';
import {zAppDevBaseComponent} from '../BaseComponent/base.component';
import {PermissionService} from '../../security/services/permission.service';
import {zAppDevMenuItem} from "@framework/components/MenuControl/menu-item";
import {AuthService} from "@services/Auth.service";
import {ZAppDevModalComponent} from "@framework/components/Modal/modal.component";
import {IndexSearchResult, IndexSearchService} from "@services/IndexSearch.service";
import {MenuSearch, MenuSearchItem} from "@framework/components/SearchModal/menu_search";
import {MenuFavorites} from "@framework/components/SearchModal/menu_favorites";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'zapp-searchmodal',
  styleUrls: ['./SearchModal.component.less'],
  templateUrl: './SearchModal.component.html',
  providers: [{provide: CF_COMPONENT, useExisting: zAppSearchModalComponent}]
})
export class zAppSearchModalComponent extends zAppDevBaseComponent {
  protected destroy$ = new Subject<void>();

  @ViewChild('Modal')
  modal: ZAppDevModalComponent;

  private _menuItems: zAppDevMenuItem[];
  @Input() set menuItems(value: any) {
    this._menuItems = value;
    this.menuSearch.setMenuItems(value);
  }

  @Input() indexes: string[];

  @Input() menuName: string;

  query: string = '';
  matchCase: boolean = false;
  partialMatch: boolean = true;

  menuSearch: MenuSearch = new MenuSearch();

  resSearchResults = '';
  resIndexResults = '';
  resMostUsed = '';
  resFavorites = '';
  resName = '';
  resPath = '';

  indexSearchResults: IndexSearchResult[] = [];
  menuSearchResults: MenuSearchItem[] = [];
  favoriteItems: FavoriteItem[] = [];
  mostUsedItems: MostUsedItem[] = [];

  constructor(
    protected readonly translate: TranslateService,
    protected themeService: IThemeSevice,
    protected readonly permissionsService: PermissionService,
    protected readonly authService: AuthService,
    protected readonly indexSearchService: IndexSearchService,
    protected readonly menuFavorites: MenuFavorites,
    protected elementRef: ElementRef
  ) {
    super(elementRef);
    this.options = themeService.getBreadcrumbThemeOptions();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.menuFavorites.fetchMenu(this.menuName).then(r => {
      this.updateFavorites();
      this.updateMostUsedItems();
    });
    this.translate
      .onLangChange
      .pipe()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.resSearchResults = this.translate.instant('RES_MENU_SEARCH_SEARCH_RESULTS');
        this.resIndexResults = this.translate.instant('RES_MENU_SEARCH_INDEX_RESULTS');
        this.resMostUsed = this.translate.instant('RES_MENU_SEARCH_MOST_USED');
        this.resFavorites = this.translate.instant('RES_MENU_SEARCH_FAVORITES');
        this.resName = this.translate.instant('RES_SEARCH_MODAL_NAME');
        this.resPath = this.translate.instant('RES_SEARCH_MODAL_PATH');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModal() {
    this.modal.showDialog();
  }

  closeModal() {
    this.modal.hideDialog();
  }

  async search() {
    await this.searchIndexes();
    await this.searchMenu();
  }

  async searchIndexes() {
    if (!this.query) {
      return;
    }
    const results: IndexSearchResult[] = [];
    for (let index of this.indexes) {
      const indexResults =
        await this.indexSearchService.search(index, this.query, this.matchCase, this.partialMatch);
      results.push(...indexResults);
    }
    this.indexSearchResults = results;
  }

  async searchMenu() {
    this.menuSearchResults = this.menuSearch.search(this.query);
  }

  /**
   * Search the menu recursively for an item that matches the given predicate.
   * Optionally specify the root item to start the search from (used for recursion).
   * Returns the tree path to the matched item from the top to bottom.
   * This method also exists in the breadcrumb component.
   */
  findMenuItem(predicate: (item: zAppDevMenuItem) => boolean, root?: zAppDevMenuItem): zAppDevMenuItem[] | null {
    if (!root) {
      root = {name: undefined, children: this._menuItems};
    }
    if (predicate(root)) {
      return [root];
    }
    if (!root.children) {
      return null;
    }
    for (let child of root.children) {
      const path = this.findMenuItem(predicate, child);
      if (path != null) {
        return [root, ...path];
      }
    }
    return null;
  }

  async addToFavorites(route: string) {
    await this.menuFavorites.addToFavorites(this.menuName, route);
    this.updateFavorites();
    this.updateMostUsedItems();
  }

  async removeFromFavorites(route: string) {
    await this.menuFavorites.removeFromFavorites(this.menuName, route);
    this.updateFavorites();
    this.updateMostUsedItems();
  }

  async removeFromMostUsed(route: string) {
    await this.menuFavorites.removeMostUsed(this.menuName, route);
    this.updateMostUsedItems();
  }

  updateFavorites() {
    this.favoriteItems =
      this.menuFavorites.getFavorites(this.menuName)
        .map(route => {
          const path = this.findMenuItem(item => item.route === route);
          if (!path) {
            return null;
          }
          const primary = path[path.length - 1].label();
          const secondary = path.slice(1).map(item => item.label()).join(' / ');
          return {primary, secondary, route};
        })
        .filter(item => !!item);
  }

  updateMostUsedItems() {
    this.mostUsedItems =
      this.menuFavorites.getTopMostUsed(this.menuName)
        .map(route => {
          const path = this.findMenuItem(item => item.route === route);
          if (!path) {
            return null;
          }
          const primary = path[path.length - 1].label();
          const secondary = path.slice(1).map(item => item.label()).join(' / ');
          const isFavorite = this.favoriteItems.some(item => item.route === route);
          return {primary, secondary, route, isFavorite};
        })
        .filter(item => !!item);
  }
}

interface FavoriteItem {
  primary: string;
  secondary: string;
  route: string;
}

interface MostUsedItem {
  primary: string;
  secondary: string;
  route: string;
  isFavorite: boolean;
}
