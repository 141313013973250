import { RowOperators } from '../enums/row-operators';
import { FilterOperators } from '../enums/filter-operators';
import { ColumnInfo } from './column-info';

export class FilterInfo {
    column: ColumnInfo;
    value: any;
    rowOperator: RowOperators;
    operator: FilterOperators; // FilterOperator
    secondValue: any;

    constructor(columnInfo: ColumnInfo,
                value: any,
                rowOp: RowOperators,
                op: FilterOperators,
                secondValue?: any) {
        this.column = columnInfo;
        this.value = value;
        this.rowOperator = rowOp;
        this.operator = op;
        this.secondValue = secondValue;
    }
}
