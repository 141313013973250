import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';
import { environment } from "src/environments/environment";
import { zAppDevDataListComponent } from "./DataList.component";


const headers = new HttpHeaders()
  .set('Content-Type', 'application/zappdev;');

export class ExportHelper {
  constructor(httpClient: HttpClient,
    listRef: zAppDevDataListComponent, protected readonly translate: TranslateService) {
    this._httpClient = httpClient;
    this.listReference = listRef;
  }

  private listReference: zAppDevDataListComponent;
  private _httpClient: HttpClient;

  public okCallback(options: {
    type: string,
    range: string,
    fileName: string,
    exportTitle: string,
    includeGridLines: boolean,
    portraitOrientation: boolean,
    headerColor: string,
    evenColor: string,
    oddColor: string,
    groupColor: string,
    aggregateColor: string
  }) {
    //Column Info
    var columns = [];
    var allGroups = this.listReference.state.groupBy || [];
    for (let columnInfo of this.listReference.state.columnInfo) {

      if (columnInfo.visible !== true && allGroups.filter(x => x.column.name === columnInfo.name).length === 0) {
        continue;
      }

      //Handle back end formatting
      const formatting = (columnInfo.formatting as any) instanceof Object
        ? (columnInfo.formatting as any).backEndFormatting
        : columnInfo.formatting;

      const excelFormat = (columnInfo.formatting as any) instanceof Object
        ? (columnInfo.formatting as any).excelFormat
        : columnInfo.formatting;

      //Handle width
      /* NOTE: The width value is only used in PDF export and only as an analogy to the list column widths. Since the column widths may
       *       have null values (no predefined with or the user hasn't resized them) we get the actual width of the header element itself
       */
      var columnWidth = columnInfo.width;
      columns.push({
        name: columnInfo.name,
        caption: this.translate.instant(columnInfo.caption),
        formatting: formatting,
        width: columnWidth ?? 100,
        excelFormat: excelFormat
      });
    }

    //Group Info
    var groups = [];
    for (var i = 0; i < this.listReference.state.groupBy.length; i++) {
      var groupBy = this.listReference.state.groupBy[i];
      groups.push(groupBy.column.name.toLowerCase());
    }

    var reservedChars = /^[^\\/:\*\?"<>\|]+$/;
    var forbiddenCharIndex = /^\./;

    if (options.fileName == "") {
      alert("Error - Empty FileName");
      return;
    }

    if (!reservedChars.test(options.fileName)) {
      alert("Error - FileName can not contain any of the following characters \/:*?\"<>| ");
      return;
    }

    if (!reservedChars.test(options.fileName)) {
      alert("Error - FileName can not start with char .");
      return;
    }

    this.listReference.state.exportSettings = {
      type: options.type,
      range: options.range,
      fileName: options.fileName,
      exportTitle: options.exportTitle,
      includeGridLines: options.includeGridLines,
      portraitOrientation: options.portraitOrientation,
      columnInfo: columns,
      groupInfo: groups,
      headerColor: options.headerColor,
      evenColor: options.evenColor,
      oddColor: options.oddColor,
      groupColor: options.groupColor,
      aggregateColor: options.aggregateColor,
    };

    //TODO: what is this?
    //this.listReference.dataTableInstance.state.save();

    this.export(this.listReference.state.exportSettings);
  }

  private export(opts: any) {
    const activeAggregators = this.listReference.state.aggregators.filter(item => item.enabled);

    return this._httpClient
      .post(`${environment.appUrl}${this.listReference.dataset.controllerName}/${this.listReference.name}_ExportV2`,
        {
          model: this.listReference.modelToProjection(),
          exportData: opts,
          datasourceRequest: this.listReference.prepareDatasourceRequestInfo(null),
          aggregatorsRequest: JSON.parse(JSON.stringify(activeAggregators || []))
        },
        { headers, observe: 'response' })
      .subscribe(response => {
        let downloadInfo = response.body as any;
        if (downloadInfo == null || downloadInfo.Data == null) {
          console.error("Could not prepare list export file for download!");
          return;
        }
        this.downloadFile(downloadInfo.Data);
      });
  }

  private downloadFile(data: any) {
    this._httpClient
      .get(`${environment.appUrl}${this.listReference.dataset.controllerName}/DownloadFile`, {
        params: {
          id: data,
        },
        responseType: 'blob'
      })
      .subscribe((response) => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(response)
        a.href = objectUrl
        a.download = `${this.listReference.state.exportSettings.fileName}.${this.getFileExtension(this.listReference.state.exportSettings.type)}`;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  private getFileExtension(type: string): string {
    if (type === "EXCEL") {
      return "xlsx";
    } else if (type === "PDF") {
      return "pdf";
    } else {
      return "csv";
    }
  }
}
