import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class ProfileSetting  {
    _originalTypeClassName = "zAppDev.DotNet.Framework.Identity.Model.ProfileSetting";
constructor() {
this.Id = null;
this.Key = null;
this.Value = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Key:any;
Value:any;
ParentProfile:any;
static setParentProfile = ($this, value) => {
var oldValue = $this.ParentProfile;
$this.ParentProfile = value;
if (value != null) {
if (oldValue != null) {
Domain.Profile.removeSettings(oldValue, $this);
}
Domain.Profile.addSettings(value, $this);
}
}


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}