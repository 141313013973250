import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevButtonModule } from '../Button/button.module';
import { zAppDevIconismModule } from '../Iconism/iconism.module';
import { zAppDevModalModule } from '../Modal/modal.module';
import { zAppDevDropDownBoxModule } from '../DropDownBox/dropdownbox.module';
import { zAppDevCheckBoxModule } from '../CheckBox/checkbox.module';
import { zAppDevLabelModule } from '../Label/label.module';
import { zAppDevTextBoxModule } from '../TextBox/textbox.module';
import { zAppDevDateTimeBoxModule } from '../DateTimeBox/datetimebox.module';
import { zAppDevFormGroupModule } from '../FormGroup/formgroup.module';
import { zAppDevColorPickerModule } from '../ColorPicker/colorpicker.module';
import { zAppDevResponsiveTableModule } from '../ResponsiveTable/responsivetable.module';
import { zAppDevFileAttachmentModule } from '../FileAttachment/fileattachment.module';
import { zAppDevMultiSelectModule } from '../MultiSelect/multiselect.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ContextMenuModule } from 'primeng/contextmenu';
import { zAppDevDataListComponent } from './DataList.component';
import { TableModule } from 'primeng/table';
import { VisibleColumnsPipe } from './column.filter';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { SecurityModule } from '../../security/security.module';
import { RuleEngineModule } from '@framework/rule-engine/rule-engine.module';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { CheckboxModule } from 'primeng/checkbox';
import { GetColumnValuePipe, GetListImageUrlPipe } from './getCellValue.pipe';
import { zAppDevProgressBarModule } from '../ProgressBar/progressbar.module';
import { ImageModule } from 'primeng/image';

@NgModule({
  declarations: [VisibleColumnsPipe, GetColumnValuePipe, zAppDevDataListComponent, GetListImageUrlPipe],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TableModule,
    ButtonModule,
    RippleModule,
    SecurityModule,
    zAppDevButtonModule,
    zAppDevIconismModule,
    zAppDevModalModule,
    zAppDevDropDownBoxModule,
    zAppDevCheckBoxModule,
    zAppDevLabelModule,
    zAppDevTextBoxModule,
    zAppDevDateTimeBoxModule,
    zAppDevFormGroupModule,
    zAppDevColorPickerModule,
    zAppDevResponsiveTableModule,
    zAppDevFileAttachmentModule,
    zAppDevMultiSelectModule,
    ContextMenuModule,
    RuleEngineModule,
    InputTextModule,
    SkeletonModule,
    CheckboxModule,
    zAppDevProgressBarModule,
    ImageModule,
  ],
  exports: [zAppDevDataListComponent],
})
export class zAppDevDataListModule { }
