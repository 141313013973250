import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { Cycles } from '@framework/common/cycles';
import * as CommonUtilities from "@framework/core/clms.fw.common";
import { IThemeSevice } from '../../theme/interfaces/theme.interface';
import * as ThemeOptions from '../../theme/interfaces/options';
import { zAppDevComponentStatus } from '../component-status';
import { zAppDevComponentSize } from '../component-size';
import { project } from '../../common/projection';
import { takeUntil } from 'rxjs/operators';
import { zAppDevBaseComponent } from '../BaseComponent/base.component';
import { CF_COMPONENT } from '@framework/rule-engine/directives/condition-formatting.directive';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { AuthService } from '@services/Auth.service';
import { ZAppDevModalComponent } from '@framework/components/Modal/modal.component';
import * as moment from "moment";
import { ManuallyEnteredDatasource } from '@framework/datasource/types/manuallyentered-datasource';
import { FilterOperators } from '@framework/datasource/models/enums/filter-operators';
import { RowOperators } from '@framework/datasource/models/enums/row-operators';
import { DateTimeBoxOptions } from '@framework/components/DateTimeBox/DateTimeBox.model';
import { environment } from "src/environments/environment";
import { ListImportResult } from '@framework/components/DataList/DataList.import';
import { convertToRGB } from '../../common/utils';
import { ConditionalFormatting } from '../../rule-engine/models/conditional-formatting';
import { CodeHelper } from '../../../@core/services/CodeHelper.service';
import { ContextMenu } from 'primeng/contextmenu';
import { AggregatorTypes } from '../../datasource/models/enums/aggregator-types';
import { DataListAggregatorInfo, DataListColumnInfo, DataListColumnItemType, DataListFilterInfo, DataListFilterType, DataListGroupByInfo, IDataListOptions, IDataListStatus, ListExportColorsConfiguration } from '@framework/components/DataList/helpers/List.helper.classes';
import { DataListAction } from '@framework/components/DataList/helpers/actions';
import { ListControlResourcesService } from '@framework/components/DataList/helpers/resources.service';
import { DatasourceRequest } from '../../datasource/models/infos/dataSource-request';
import { LazyLoadEvent } from 'primeng/api';
import { ValueFormat } from '../../core/clms.fw.angular';
import * as Domain from '@core/Domain';
import { Joove } from '../../core/Joove';
import { ColumnInfo } from '../../datasource/models/infos/column-info';
import { ExportHelper } from './DataList.export';
import * as Common from '@framework/common';
import { RuleAction } from '../../rule-engine/models/interfaces/irule-options';
import { RuleActions } from '../../rule-engine/models/enums/actions';
import * as _ from 'lodash';
import { ActionAuthorization } from '../../security/directives/permissions-only.directive';
import { PermissionService } from '../../security/services/permission.service';
import { getColumnValue, resolveProperty, toMambaEnumStringValue } from './getCellValue.pipe';
import { Table } from 'primeng/table';
import { zAppDevDropDownBoxComponent } from '../DropDownBox/DropDownBox.component';
import { ViewModelDatasource } from '../../datasource/types/viewmodel-datasource';

export enum GroupState {
  EXPANDED,
  COLLAPSED
}

export type AggregatorInfo = {
  Type: AggregatorTypes,
  Column: string,
  Value: number,
  ValueFormatted: string,
  Formatting: string,
}

export type GroupColumnInfo = {
  Name: string,
  MambaDataType: string,
  Formatting: ValueFormat,
  IsEncrypted: boolean
}

export type AggregationResult = {
  Data: any,
  Groups: GroupTree,
  RuleEvaluations: any,
  TotalRows: number
};

export type GroupTree = {
  Aggregates: AggregatorInfo[],
  Parent: GroupTree,
  Items: any[],
  Column: GroupColumnInfo,
  Identifier: string,
  Key: string,
  KeyFormatted: string,
  StateType: GroupState,
  SubGroups: GroupTree[],
  UniqueItemKeys: any[]
}

export class ListView {
  public ViewName: string;
  public SerializedStatus: any;
  public IsDefault: boolean;
}

@Component({
  selector: 'zapp-datalist-new',
  templateUrl: './DataList.component.html',
  styleUrls: ["./DataList.component.less"],
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevDataListComponent }]
})
export class zAppDevDataListComponent extends zAppDevBaseComponent implements AfterViewInit {
  protected destroy$: Subject<void> = new Subject<void>();

  public static readonly MAX_ROWS: number = 1000;
  public static readonly DOUBLE_CLICK_THRESHOLD: number = 250;
  public static readonly STANDALONE_OUTER_ELEMENTS_DUMMY_CLASS: string = "data-list-standalone-outer-static-elements-dummy";
  public static readonly PICKLIST_POPUP_MODAL_CLASS: string = "p-dialog-content";
  public static readonly DEFAULT_DATATABLES_MIN_HEIGHT: string = "50vh";
  public static readonly GROUP_PATH_SEPARATOR: string = " /*+!+*/ ";   //IMPORTANT: The group separator must contain at least one space in order to work with the jQuery selectors
  public static readonly GROUP_VALUE_SEPARATOR: string = "/++***++/";
  public static readonly SANITIZE_COLUMN_REPLACEMENT_VALUE: string = ""; //IMPORTANT: This should match the replacement value that the function SanitizeCSharpIdentifier uses in LinqRuntimeTypeBuilder.cs
  public static readonly DEFAULT_PAGE_SIZE: number = 25;
  public static readonly DEFAULT_DATETIME_FORMAT = "DD/MM/YYYY";

  protected themeOptions: ThemeOptions.ListThemeOptions;

  dt: any;

  data: any[] = [];
  public state: IDataListStatus;
  loading: boolean;
  totalRecords: number = 0;
  pageSizes: number[];
  selection: any | any[] = [];
  quickFilters: boolean = false;

  protected exportHelper: ExportHelper;

  @Input()
  changeCounter: any;
  
  @Input()
  model: any;

  @Input()
  indexes: any;

  @Input()
  selectedItems: any[];

  @Input()
  projectionSchema: any;

  @Input()
  size: zAppDevComponentSize = '';

  @Input()
  status: zAppDevComponentStatus = 'default';

  @Input()
  variation: string = 'Standard';

  @Input()
  class: string = '';

  @Input()
  options: IDataListOptions = null;

  @Input()
  cols: Array<DataListColumnInfo> = [];

  @Input()
  actions: Array<DataListAction> = [];

  @Input()
  dataset: any;

  @Input() name: string = "";

  @Input() excludedItemKeys: string[] = [];

  @Input() dtoName: string = '';

  @Input() compactLines: boolean = false;

  @Input()
  editable: boolean = false;

  @Input()
  editRowAuthorizationInfo: ActionAuthorization;

  @Output()
  valueChange = new EventEmitter<any>();

  @Output()
  selectedItemsChange = new EventEmitter<any>();

  @Output()
  defaultActionExecuted = new EventEmitter<any>();

  @Output()
  onFetchStart = new EventEmitter<any>();

  @Output()
  onFetchFinish = new EventEmitter<any>();

  @Output()
  onRowEditSave = new EventEmitter<any>();

  @ViewChild('listContainer')
  listContainerRef: ElementRef;

  @ViewChild('dataTableElement')
  el: Table;

  @ViewChild('GroupingModal')
  GroupingModal: ZAppDevModalComponent;

  @ViewChild('ExportModal')
  ExportModal: ZAppDevModalComponent;

  @ViewChild('FiltersModal')
  FiltersModal: ZAppDevModalComponent;

  @ViewChild('ViewsModal')
  ViewsModal: ZAppDevModalComponent;

  @ViewChild('ImportModal')
  ImportModal: ZAppDevModalComponent;

  @ViewChild('csv_file')
  csv_file: ElementRef;

  @ViewChild('headCellsContextMenu')
  headCellsContextMenu: ContextMenu;

  @ViewChild('cellsContextMenu')
  cellsContextMenu: ContextMenu;

  @ViewChild('columnsVisibility')
  columnsVisibility: zAppDevDropDownBoxComponent;
  
  leftGroupingColumns: DataListColumnInfo[] = [];
  rightGroupingColumns: DataListColumnInfo[] = [];
  leftSelectedGroupingColumns: string[] = [];
  rightSelectedGroupingColumns: string[] = [];

  getGroupsClosed: boolean = false;
  mergeGroupLevels: boolean = false;

  ExportTypes = [
    { _text: "Excel", _value: "EXCEL", selected: true },
    { _text: "PDF", _value: "PDF", selected: false },
    { _text: "CSV", _value: "CSV", selected: false }];
  ExportTypeDataset = new ManuallyEnteredDatasource(this.ExportTypes);

  PrintRanges = [
    { _text: "Current Page", _value: "CURRENT", selected: true },
    { _text: "Top 100 Records", _value: "TOP100", selected: false },
    { _text: "All Pages", _value: "ALL", selected: false }];
  PrintRangeDataset = new ManuallyEnteredDatasource(this.PrintRanges);

  _resources: ListControlResourcesService;

  selectedExportType: string = "EXCEL";
  ExportTypeDropdown_ProjectionScema = {};

  selectedPrintRange: string = "CURRENT";
  PrintRangeDropdown_ProjectionScema = {};

  PageSizeOptions: { _text: number | string, _value: number }[];
  PageSizeDataset: ManuallyEnteredDatasource<any>;
  PageSizeDropdown_ProjectionScema = {};

  pdfPortraitOrientation: boolean = false;
  includeGridLines: boolean = true;
  exportFileName: string;
  exportTitle: string;
  headerLinesColor: string = '#FFFFFF';
  evenLinesColor: string = '#FFFFFF';
  oddLinesColor: string = '#FFFFFF';
  aggregateLinesColor: string = '#FFFFFF';
  groupLinesColor: string = '#FFFFFF';

  searchInput: string = '';

  ColumnsVisibility_ProjectionScema = {};
  visibleColumnNames: any[] = [];
  virtualScrollItemSize: number = 50;

  @HostBinding('class.stripped') alternateRowsColor: boolean;

  @HostBinding('class.hidden')
  get hiddenClass() {
    return this.hidden ? "hidden" : "";
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateRowHeight();
  }

  // $selectedColumnInfoHeader: JQuery;

  headCellItems: { label: string, icon: string, disabled: boolean, escape: boolean, styleClass: string, command: () => void }[] = [];

  cellItems = [];
  conditionalFormattings: ConditionalFormatting[] = [];

  lazyLoaded: boolean = false;
  initialized: boolean = false;

  first: number = 0;
  last: number = 0;

  styleClass: string;

  selectionMode: 'single' | 'multiple' | undefined;

  hideRowEditButton: boolean = false;

  get allowColumnsFreeze() {
    return this.options.allowColumnsFreeze === true && (this.state?.groupBy ?? []).length <= 0;
  }

  constructor(
    protected readonly _httpClient: HttpClient,
    protected readonly translate: TranslateService,
    protected readonly themeservice: IThemeSevice,
    protected readonly resources: ListControlResourcesService,
    protected elementRef: ElementRef,
    protected readonly authService: AuthService,
    protected readonly codeHelper: CodeHelper,
    private permissionsService: PermissionService,
  ) {
    super(elementRef);
    this.isList = true;
    this.themeOptions = themeservice.getListThemeOptions();
    this._resources = resources;

    this.exportHelper = new ExportHelper(this._httpClient, this, this.translate);
  }

  refreshHostClasses() {
    this.hostClasses = [
      this.class,
      this.statusClass(),
      this.sizeClass(),
      this.rootClass()
    ].join(' ');
  }

  statusClass() {
    const extraClasses = this.themeOptions[this.variation].Classes.Roles[this.status];
    return extraClasses;
  }

  sizeClass() {
    const extraClasses = this.themeOptions[this.variation].Classes.Roles[this.size];
    return extraClasses;
  }

  rootClass() {
    return (this.themeOptions ?? [])[this.variation]?.Classes?.Global ?? '';
  }

  async ngOnInit() {
    super.ngOnInit();
    this.refreshHostClasses();

    const styleClasses = [];
    if (this.options.alternateRowsColor) {
      styleClasses.push('p-datatable-striped');
    }
    if (this.compactLines) {
      styleClasses.push('p-datatable-sm');
    }
    if (this.options.selectUsingCheckbox) {
      styleClasses.push('p-datatable-checkbox-select');
    }
    this.styleClass = styleClasses.join(' ');

    // hide inline edit button if no permission to execute action
    this.permissionsService.permissions.pipe(takeUntil(this.destroy$)).subscribe((permissions) => {
      if (this.editRowAuthorizationInfo == null) {
        return;
      }

      if ((this.editRowAuthorizationInfo.allowAnonymous && !this.authService.isLoggedIn())
        || (this.editRowAuthorizationInfo.allowAuthenticated && this.authService.isLoggedIn())
        || this.editRowAuthorizationInfo.permissions?.some(p => permissions?.some(per => per === p))) {

        this.hideRowEditButton = false;
      } else {
        this.hideRowEditButton = true;
      }
    });
  }

  private enrichColumnData(columns: DataListColumnInfo[], reinit = true) {
    for (let c of columns) {

      if (reinit) {
        c.quickFilterData = {
          search: "",
        }
      }

      if (c.mambaDataTypeIsEnum) {
        const enumClass = Domain[c.mambaDataType];
        let values = [];
        for (let item in enumClass) {
          if (!isNaN(parseInt(item))) {
            continue;
          }
          const resKey = `Res_ENUM_${c.mambaDataType}_${item}`;
          let translation = this.translate.instant(resKey);
          if (CommonUtilities.CLMS.Framework.String.IsNullOrEmpty(translation) || translation.indexOf('Res_ENUM_') == 0) {
            translation = CommonUtilities.CLMS.Framework.String.SplitCamelCase(item ?? "", /([a-z])([A-Z])|([A-Z])([A-Z][a-z])/g, "$1 $2")
          }

          values.push({
            _text: translation,
            _value: item,
            _id: enumClass[item]
          });
        }
        c.quickFilterData.dataset = new ManuallyEnteredDatasource(values);
      } else if (c.dataType == "DateTime") {
        c.quickFilterData.dateConfig = new DateTimeBoxOptions({
          readonly: false,
          required: false,
          doesNotMakeFormDirty: false,
          asMonthPicker: false,
          datePicker: true,
          timePicker: false,
          dateFormat: "",
          timeFormat: "",
          allowtyping: true,
        })
      }
    }
  }

  private resetState() {
    const userPageSize = this.pageSizes?.length > 0 ? this.pageSizes[0] : zAppDevDataListComponent.DEFAULT_PAGE_SIZE;

    this.searchInput = "";

    this.filters = [];

    this.state = {
      startRow: 0,
      pageSize: this.options.hasPaging ? userPageSize : zAppDevDataListComponent.MAX_ROWS,
      columnInfo: this.cols.map(c => ({ ...c })),
      filters: [],
      orderBy: [],
      aggregators: [],
      groupBy: [],
      getGroupsClosed: false,
      mergeGroupLevels: false,
      excludedKeys: [],
      selectedKeys: [],
      selectedItems: [],
      allKeysSelected: false,
      indexes: this.indexes ?? [],
      projectionSchemaCallback: null,
      exportSettings: {
        type: "Excel",
        range: "",
        fileName: "",
        exportTitle: "",
        includeGridLines: true,
        portraitOrientation: false,
        columnInfo: [],
        groupInfo: [],
        headerColor: "",
        evenColor: "",
        oddColor: "",
        groupColor: "",
        aggregateColor: ""
      }
    };

    this.state.columnInfo.forEach(c => {
      if (c.quickFilterData != null) c.quickFilterData.search = "";
    });

    this.rightGroupingColumns = [];

    this.visibleColumnNames = this.FilterColumns.filter(c => this.state.columnInfo.find(ci => ci.name === c._value).visible).map(c => c._value);

    this.initAggregators();
  }

  initAggregators() {
    for (let i = 0; i < this.state.columnInfo.length; i++) {
      const columnInfo = this.state.columnInfo[i];

      if (!columnInfo.supportsAggregators) continue;

      const predefinedAggregators = this.options.predefinedAggregators?.filter(predefinedAggregator => predefinedAggregator.column === columnInfo.name) ?? [];

      this.state.aggregators.push(new DataListAggregatorInfo(
        this.state.columnInfo[i].name,
        AggregatorTypes.COUNT,
        this.state.columnInfo[i].formatting,
        predefinedAggregators.some(predefinedAggregator => predefinedAggregator.type === 'COUNT'))
      );

      if (Joove.Common.getMambaDataType(columnInfo.mambaDataType) === Joove.MambaDataType.NUMBER) {
        const sumAggregator = new DataListAggregatorInfo(
          this.state.columnInfo[i].name,
          AggregatorTypes.SUM,
          this.state.columnInfo[i].formatting ?? '#.00;\'-\'#.00;\'0\'',
          predefinedAggregators.some(predefinedAggregator => predefinedAggregator.type === 'SUM'));
        const avgAggregator = new DataListAggregatorInfo(
          this.state.columnInfo[i].name,
          AggregatorTypes.AVERAGE,
          this.state.columnInfo[i].formatting ?? '#.00;\'-\'#.00;\'0\'',
          predefinedAggregators.some(predefinedAggregator => predefinedAggregator.type === 'AVERAGE'));
        sumAggregator.encrypted = this.state.columnInfo[i].isEncrypted;
        avgAggregator.encrypted = this.state.columnInfo[i].isEncrypted;
        this.state.aggregators.push(sumAggregator);
        this.state.aggregators.push(avgAggregator);
      }
    }
  }

  async ngAfterViewInit() {
    //const resources = new DataListControlResources(this.translate,
    //                                               this.themeservice);

    this.selectionMode = this.options.selectUsingCheckbox
      ? undefined
      : (this.options.hasMultiSelect ? 'multiple' : 'single');

    this.enrichColumnData(this.cols);

    this.cellItems = this.actions.map((action: DataListAction) => {
      return { label: this.translate.instant(action.label), icon: "pi pi-arrow-circle-right", command: () => { this.actionButtonOnClick(action); } };
    });

    this.pageSizes = this.options.pageSizes.filter((size) => { return size > 0 });

    if (this.options.userCanSelectPageSize !== false) {
      if (!this.pageSizes.includes(zAppDevDataListComponent.MAX_ROWS)) {
        this.pageSizes.push(zAppDevDataListComponent.MAX_ROWS);
      }
    }

    this.FilterColumns = this.cols.map((c) => {
      return { _text: this.translate.instant(c.caption), _value: c.name, selected: false };
    })

    if (this.options.canHideShowColumns) {
      this.VisibleColumnDataset = new ManuallyEnteredDatasource(this.FilterColumns);
    }

    if (this.options.structuredFiltering) {
      this.FiltersColumnDataset = new ManuallyEnteredDatasource(this.cols.filter(c => c.searchable).map((c) => {
        return { _text: this.translate.instant(c.caption), _value: c.name, selected: false };
      }));
    }

    this.resetState();

    let defaultView: ListView;
    if (this.views == null) {
      await this.fetchAllAvailableViews();

      defaultView = this.views.find(view => view.IsDefault);

      if (defaultView != null) {
        this.loadView(defaultView);
      }
    }

    // restore state
    if (this.options.rememberLastState == true) {
      const view = this.getStateFromLocalStorage();

      if (view != null) {
        this.loadView(view);
      }
    }

    if (defaultView == null && this.options.predefinedGroups != null && this.options.predefinedGroups.length > 0) {
      this.rightGroupingColumns = this.cols
        .filter(column => this.options.predefinedGroups.some(predefinedGroup => predefinedGroup.column === column.name))
        .sort((a, b) => {

          const aIndex = this.options.predefinedGroups.findIndex(predefinedGroup => predefinedGroup.column === a.name);
          const bIndex = this.options.predefinedGroups.findIndex(predefinedGroup => predefinedGroup.column === b.name);

          if (aIndex > bIndex) {
            return 1;
          }

          if (aIndex < bIndex) {
            return -1;
          }

          return 0;
        });
      this.applyGrouping({
        getGroupsClosed: this.getGroupsClosed,
        mergeGroupLevels: this.mergeGroupLevels,
        groupingColumns: this.rightGroupingColumns
      });
    }

    this.loading = false;

    this.leftGroupingColumns = this.cols;

    if (this.options.isStandAlone) {
      this.calculcateStandaloneListContainerHeight();
    } else {
      this.initialized = true;
    }

    this.calculateRowHeight();

    /*this.instance =
      await new DataListControl(this.options, this.cols, this.dataset, this.actions,
        this.translate, this.themeservice, this.resources, $(this.el.nativeElement),
        () => this.modelToProjection(this), [], [], this.name, this._httpClient, this.codeHelper, this.model, this.authService, (settings, data) => { this.onStateSave(this, settings, data) },
        () => {
          that.FilterColumns = that.cols.map((c) => {
            return { _text: that.translate.instant(c.caption), _value: c.name, selected: false };
          })
          that.VisibleColumnDataset = new ManuallyEnteredDatasource(that.FilterColumns);
          that.visibleColumnNames = that.FilterColumns.filter(c => that.instance.status.columnInfo.find(ci => ci.name === c._value).visible);

          that.PageSizeOptions = that.instance.options.pageSizes.map((s) => {
            const text = s < 0 ? that.resources.textResources.All : s;
            return { _text: text, _value: s };
          });

          that.PageSizeDataset = new ManuallyEnteredDatasource(that.PageSizeOptions);

          that.alternateRowsColor = that.instance?.options?.alternateRowsColor == true;

          that.cellItems = that.instance.actions.map((action: DataListAction) => {
            return { label: that.translate.instant(action.label), icon: "pi pi-arrow-circle-right", command: () => { that.actionButtonOnClick(action); } };
          });
        },
        (event) => {
          event.stopPropagation();
          this.cellsContextMenu.hide();
          this.headCellsContextMenu.show(event);
          this.$selectedColumnInfoHeader = $(event.target).closest('th');
          this.refreshHeadCellItems();
        },
        (event) => {
          event.stopPropagation();
          this.headCellsContextMenu.hide();
          this.cellsContextMenu.show(event);
        }
      );

    this.instance.selectedItems.pipe(takeUntil(this.destroy$)).subscribe((selectedItems: any[]) => {
      if (selectedItems == null) {
        return;
      }

      this.selectedItemsChange.emit(selectedItems);
      this.valueChange.emit(selectedItems);
    });*/

  }

  private loadingTimeout = null;
  private _loading: boolean = false;

  async fetchData($event?: LazyLoadEvent) {

    // reject requests before the initial one made by the PrimeNG p-table component
    // this lazy load request is unavoidable and any request before this is useless
    if (!this.lazyLoaded && $event == null) {
      return;
    }

    if (!this.lazyLoaded && this.state.startRow > 0) {
      $event.first = this.state.startRow;
      this.first = this.state.startRow;
      this.updateLast();
    }

    // if it is picklist and the request is triggered by the PrimeNG p-table component
    // reject it, the initial request will occure when the picklist appears
    // commented out because after the change to the Modal component (render body template) this is probably useless
    // if (this.options.isPickList && $event != null && this.lazyLoaded == false) {
    //   this.lazyLoaded = true;
    //   return;
    // }

    if (this._loading == true) {
      return;
    }

    this._loading = true;
    this.loadingTimeout = setTimeout(() => {
      this.loading = true;
    }, 250);

    const datasourceRequestInfo = this.prepareDatasourceRequestInfo(this.lazyLoaded ? $event : null);
    const model = this.modelToProjection();

    this.onFetchStart.emit();

    if (this.options.rememberLastState == true) {
      this.saveStateToLocalStorage();
    }

    this.dataset && this.dataset.fetchData(datasourceRequestInfo, model, this.indexes, this.name).then((response: any) => {

      if ($event != null) {
        this.lazyLoaded = true;
      }

      clearTimeout(this.loadingTimeout);
      this._loading = false;
      this.loading = false;

      if (response?.error != null) {
        if ($event != null) {
          this.resetState();
          if (this.options.rememberLastState == true) {
            this.saveStateToLocalStorage();
          }
        }
        return;
      }

      if (this.options.infiniteScrolling && this.data.length != response.TotalRows) {
        this.data = Array.from({ length: response.TotalRows });
      }

      const page = Cycles.reconstructObject(response.Data);
      if (this.options.infiniteScrolling == true) {

        let first;
        let rows;
        if ($event != null) {
          first = $event.first;
          rows = $event.rows;
        } else {
          first = this.state.startRow;
          rows = this.state.pageSize;
        }

        Array.prototype.splice.apply(this.data, [
          ...[first, rows],
          ...page,
        ]);
      } else {
        this.data = page;
      }

      // add grouping metadata
      this.data = this.processDataGrouping(this.data, response.Groups);

      this.makeAggregatorRequest();

      this.totalRecords = response.TotalRows;
      this.updateLast();

      this.applyConditionalFormattings();

      this.refreshSelection();

      this.onFetchFinish.emit();

      if (this.options.isPickList && this.options.infiniteScrolling && this.dataset instanceof ViewModelDatasource) {
        const pScroller = this.el.el.nativeElement.querySelector('.p-scroller');
        const scrollTop = pScroller?.scrollTop ?? 0;

        this.el.scrollTo({ top: scrollTop + 1 });

        setTimeout(() => {
          this.el.scrollTo({ top: scrollTop });
          this.calculateRowHeight();
        }, 10);

      } else {
        this.calculateRowHeight();
      }

    });
  }

  public async fetchFullRecordset() {

    if (this.dataset == null) {
      return;
    }

    const datasourceRequest = this.prepareDatasourceRequestInfo();
    const model = this.modelToProjection();

    await this.dataset.fetchFullRecordset({
      datasourceRequest,
      model,
      dataType: this.dtoName,
      keys: this.state.excludedKeys.join(','),
      indexes: this.state.indexes.join(',')
    }).then((response: any) => {

      const data = [].concat(response);

      clearTimeout(this.loadingTimeout);
      this._loading = false;
      this.loading = false;
      this.data = Cycles.reconstructObject(data);

      // add grouping metadata
      this.data = this.processDataGrouping(this.data, response.Groups);

      this.makeAggregatorRequest();

      this.totalRecords = this.data.length;
      this.updateLast();

      this.applyConditionalFormattings();
    });
  }

  public prepareDatasourceRequestInfo($event?: LazyLoadEvent): DatasourceRequest {
    if ($event != null) {
      //inform state
      this.state.startRow = $event.first;
      if ($event.last == null) {
        this.state.pageSize = $event.rows;
      }

      // console.log($event);
      if ($event.sortField == null || $event.sortField.length == 0) {
        this.state.orderBy = [];
      } else {
        const sortColumn = this.cols.find(c => c.name == $event.sortField);
        if (sortColumn == null) {
          this.state.orderBy = [];
        } else {
          this.state.orderBy = [{
            column: sortColumn,
            direction: $event.sortOrder == -1 ? 1 : 0
          }];
        }
      }
    }

    if (this.excludedItemKeys != null) {
      this.state.excludedKeys = this.excludedItemKeys;
    }

    const clonedFilters = JSON.parse(JSON.stringify(this.state.filters ?? []));

    for (let filter of clonedFilters) {

      if (filter.value == null) {
        filter.value = "";
      }

      var colDataType = Joove.Common.getMambaDataType(filter.column.mambaDataType);
      if (colDataType === Joove.MambaDataType.DATETIME) {

        const serverWaitingFormat = 'D/M/YYYY';

        // first attempt to parse a value previously parsed
        let parsedDate = moment(filter.value, serverWaitingFormat);
        if (!parsedDate.isValid()) {
          // if invalid, parse value without specific format
          parsedDate = moment(new Date(filter.value));
        }

        // first attempt to parse a value previously parsed
        let secondParsedDate = moment(filter.secondValue, serverWaitingFormat);
        if (!secondParsedDate.isValid()) {
          // if invalid, parse value without specific format
          secondParsedDate = moment(new Date(filter.secondValue));
        }

        filter.value = parsedDate.format(serverWaitingFormat); // hard coded format, same as the format that the server is waiting (RuntimePredicateBuilder);
        filter.secondValue = secondParsedDate.format(serverWaitingFormat); // hard coded format, same as the format that the server is waiting (RuntimePredicateBuilder);
      }
    }

    const request = new DatasourceRequest(
      this.state.startRow,
      this.options.infiniteScrolling ? 100 : this.state.pageSize,
      clonedFilters,
      this.state.orderBy,
      this.state.excludedKeys,
      this.state.groupBy,
      null,
      this.state.indexes ?? []
    );

    return request;
  }

  public modelToProjection(): any {
    let model = this.model;
    if (this.projectionSchema != null) {
      model = project(this.model, this.projectionSchema);
    }
    if (model == null) {
      return {}
    }
    return model;
  }

  renderCellValue(row: any, columnInfo: DataListColumnInfo) {
    return getColumnValue(row, columnInfo, this.translate);
  }

  renderMambaEnumCellValue(row: any, columnInfo: DataListColumnInfo) {
    return resolveProperty(row, columnInfo.name);
  }

  setCellValue(value: any, row: any, columnInfo: DataListColumnInfo) {
    var parts = columnInfo.name.split(".");
    var lastPart = parts.pop();

    let current = row;
    for (let part of parts) {
      const clean = part.replace("?", "");
      current = current[clean];
      if (current == null) {
        return;
      }
    }

    if (current == null) {
      return;
    }

    current[lastPart] = value;
  }

  private getSelectedItems() {
    return this.options.hasMultiSelect ? this.selection : (this.selection == null ? [] : [this.selection]) ?? [];
  }

  onHeadRowRightClick(event: MouseEvent, columnInfo: DataListColumnInfo) {
    this.refreshHeadCellItems(columnInfo);
    this.cellsContextMenu.hide();
    this.headCellsContextMenu.show(event);
  }

  onDataRowRightClick(event: MouseEvent, data: any) {
    if (this.actions.length == 0) {
      return;
    }

    this.selection = this.options.hasMultiSelect ? [data] : data;
    const selectedItems = this.getSelectedItems();
    this.selectedItemsChange.emit(selectedItems ?? []);
    this.valueChange.emit(selectedItems ?? []);

    this.headCellsContextMenu.hide();
    this.cellsContextMenu.show(event);
  }

  onRowDoubleClick(rowData: any) {

    if (!this.options.selectUsingCheckbox) {
      return;
    }

    const defaultAction = this.actions.find(a => a.isDefault);

    if (defaultAction == null) {
      return;
    }

    this.selection = this.options.hasMultiSelect ? [rowData] : rowData;
    this.selectedItemsChange.emit([rowData] ?? []);
    this.valueChange.emit([rowData] ?? []);
    
    this.actionButtonOnClick(defaultAction);

    // auto select and close picklist modal
    if (this.options.isPickList && !this.options.hasMultiSelect) {
      this.defaultActionExecuted.emit();
    }
  }

  selectionChanged($event, selected: boolean) {
    let selectedItems = this.getSelectedItems();
    const defaultAction = this.actions.find(a => a.isDefault);

    const lineDoubleClicked = $event?.originalEvent?.type === 'dblclick';
    const executeDefaultAction = lineDoubleClicked && defaultAction != null && (selected || lineDoubleClicked);

    if (executeDefaultAction || (lineDoubleClicked && this.options.isPickList)) {
      if (selectedItems.length == 0) {
        this.selection = this.options.hasMultiSelect ? [$event.data] : $event.data;
        selectedItems = this.getSelectedItems();
      }
    }

    this.selectedItemsChange.emit(selectedItems ?? []);
    this.valueChange.emit(selectedItems ?? []);

    if (executeDefaultAction) {
      this.actionButtonOnClick(defaultAction);
    }

    if (selected || lineDoubleClicked) {
      if (this.options.hasMultiSelect) {
        this.state.selectedKeys = _.union(this.state.selectedKeys, [].concat($event.data).map(d => d._key));
      } else {
        this.state.selectedKeys = $event.data._key;
      }
    } else {
      if (this.options.hasMultiSelect) {
        this.state.selectedKeys = this.state.selectedKeys.filter(key => key !== $event.data._key);
        this.state.allKeysSelected = this.state.selectedKeys.length === this.totalRecords;
      } else {
        this.state.selectedKeys = null;
      }
    }

    // auto select and close picklist modal
    if (this.options.isPickList && !this.options.hasMultiSelect && lineDoubleClicked) {
      this.defaultActionExecuted.emit();
    }

    if (this.options.rememberSelectedItems) {
      this.saveStateToLocalStorage();
    }
  }

  refreshHeadCellItems(columnInfo: DataListColumnInfo) {

    const columnAggregators = (this.state?.aggregators ?? []).filter(aggregator => aggregator.column === columnInfo.name);

    const countAggregator = columnAggregators.find(aggregator => aggregator.type === AggregatorTypes.COUNT);
    const averageAggregator = columnAggregators.find(aggregator => aggregator.type === AggregatorTypes.AVERAGE);
    const sumAggregator = columnAggregators.find(aggregator => aggregator.type === AggregatorTypes.SUM);

    this.headCellItems = [
      {
        label: countAggregator?.enabled ? `<span>${this.resources.textResources.CalculateCount}</span><span class="p-menuitem-icon pi pi-check"></span>` : this.resources.textResources.CalculateCount,
        icon: "pi pi-arrow-circle-right",
        disabled: countAggregator == null,
        escape: false,
        styleClass: 'aggregations',
        command: () => {
          countAggregator.enabled = !countAggregator.enabled;
          this.makeAggregatorRequest();
        }
      },
      {
        label: sumAggregator?.enabled ? `<span>${this.resources.textResources.CalculateSum}</span><span class="p-menuitem-icon pi pi-check"></span>` : this.resources.textResources.CalculateSum,
        icon: "pi pi-arrow-circle-right",
        disabled: averageAggregator == null,
        escape: false,
        styleClass: 'aggregations',
        command: () => {
          sumAggregator.enabled = !sumAggregator.enabled;
          this.makeAggregatorRequest();
        }
      },
      {
        label: averageAggregator?.enabled ? `<span>${this.resources.textResources.CalculateAverage}</span><span class="p-menuitem-icon pi pi-check"></span>` : this.resources.textResources.CalculateAverage,
        icon: "pi pi-arrow-circle-right",
        disabled: sumAggregator == null,
        escape: false,
        styleClass: 'aggregations',
        command: () => {
          averageAggregator.enabled = !averageAggregator.enabled;
          this.makeAggregatorRequest();
        }
      }
    ];

    if (this.options.allowColumnsFreeze) {
      this.headCellItems = this.headCellItems.concat([
        {
          label: `Lock Left`,
          icon: "pi pi-lock",
          disabled: !this.allowColumnsFreeze || columnInfo.frozen == 'left',
          escape: false,
          styleClass: 'aggregations',
          command: () => {
            columnInfo.frozen = 'left';
            if (this.options.rememberLastState == true) {
              this.saveStateToLocalStorage();
            }
          }
        },
        {
          label: `Lock Right`,
          icon: "pi pi-lock",
          disabled: !this.allowColumnsFreeze || columnInfo.frozen == 'right',
          escape: false,
          styleClass: 'aggregations',
          command: () => {
            columnInfo.frozen = 'right';
            if (this.options.rememberLastState == true) {
              this.saveStateToLocalStorage();
            }
          }
        },
        {
          label: `Unlock`,
          icon: "pi pi-unlock",
          disabled: columnInfo.frozen == null || columnInfo.frozen == '',
          escape: false,
          styleClass: 'aggregations',
          command: () => {
            columnInfo.frozen = null;
            if (this.options.rememberLastState == true) {
              this.saveStateToLocalStorage();
            }
          }
        }
      ]);
    }

  }

  runConditionalFormattingRule(rule: ConditionalFormatting) {

    if (this.conditionalFormattings.some(cf => cf.name === rule.name)) {
      return;
    }

    this.conditionalFormattings.push(rule);
  }

  onStateSave(self: zAppDevDataListComponent, settings, data) {
    this.currentViewSerializedStatus = data;
  }

  showActionButton(action: DataListAction) {
    if (action.show === 'always') {
      return true;
    }

    const selectedItemsCount = this.options.hasMultiSelect
      ? this.selection.length
      : (this.selection == null || this.selection.length <= 0 ? 0 : 1);

    if (action.show === 'single') {
      return selectedItemsCount === 1;
    }

    if (action.show === 'multi') {
      return selectedItemsCount >= 1;
    }
  }

  /*********************** [BEGIN] Search ***********************/

  async clearButtonOnClick(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.addGlobalSearch('', true);
  }

  async searchButtonOnClick(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.addGlobalSearch(this.searchInput, true);
  }

  async filterButtonOnClick(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    if (_evt != null && _evt instanceof PointerEvent) {
      _evt.stopPropagation();
      _evt.preventDefault();
    }
    this.quickFilters = !this.quickFilters;
  }

  addGlobalSearch(globalSearchTerm: string, autoApply: boolean) {
    this.searchInput = globalSearchTerm;

    this.removeFiltersFromGlobalSearch();

    if (globalSearchTerm != null && globalSearchTerm.trim() != "") {
      const guessedSearchTermType = Joove.Common.guessStringMambaDataType(globalSearchTerm);

      for (let i = 0; i < this.state.columnInfo.length; i++) {
        const columnInfo = this.state.columnInfo[i];

        if (columnInfo.searchable === false) { continue; }

        const columnInfoMambaDataType = Joove.Common.getMambaDataType(columnInfo.mambaDataType);
        //send undefined for back-end handling. Propably enumerators
        if (columnInfoMambaDataType !== undefined) {
          //Skip non compatible columns.
          switch (guessedSearchTermType) {
            case Joove.MambaDataType.BOOL:
              if (!(columnInfoMambaDataType === Joove.MambaDataType.BOOL ||
                columnInfoMambaDataType === Joove.MambaDataType.STRING))
                continue;
              break;
            case Joove.MambaDataType.NUMBER:
              if (!(columnInfoMambaDataType === Joove.MambaDataType.NUMBER ||
                columnInfoMambaDataType === Joove.MambaDataType.STRING))
                continue;
              break;
            case Joove.MambaDataType.DATETIME:
              if (!(columnInfoMambaDataType === Joove.MambaDataType.DATETIME ||
                columnInfoMambaDataType === Joove.MambaDataType.STRING))
                continue;
              break;
            case Joove.MambaDataType.STRING:
              if (columnInfoMambaDataType !== Joove.MambaDataType.STRING)
                continue;
              break;
          }
        }

        let operator = this.getFilterOperatorBasedOnColumnDataType(columnInfo);

        var filter =
          new DataListFilterInfo(columnInfo, globalSearchTerm, RowOperators.OR, operator, undefined, DataListFilterType.Global);

        this.state.filters.push(filter);
      }
    }

    if (autoApply === true) {
      this.goToFirst();
      this.fetchData();
    }

  }

  getFilterOperatorBasedOnColumnDataType(col: DataListColumnInfo): FilterOperators {
    var colDataType = Joove.Common.getMambaDataType(col.mambaDataType);
    var useLikeOperator = colDataType === Joove.MambaDataType.STRING
      || colDataType === Joove.MambaDataType.DATETIME
      || colDataType === Joove.MambaDataType.NUMBER
      || colDataType === Joove.MambaDataType.INT
      || colDataType === undefined;

    return useLikeOperator
      ? FilterOperators.LIKE
      : FilterOperators.EQUAL_TO;
  }

  removeFiltersFromGlobalSearch() {
    // reverse loop when removing, for keeping indexes valid!
    for (let i = this.state.filters.length - 1; i > -1; i--) {
      var current = this.state.filters[i];

      if (current.type != DataListFilterType.Global) continue;

      this.state.filters.splice(i, 1);
    }
  }

  addQuickFilter(columnInfo: DataListColumnInfo, newValue: string = undefined) {
    if (newValue !== undefined) {
      columnInfo.quickFilterData.search = newValue;
    }

    const existing = this.state.filters.find(f => f.type == DataListFilterType.Quick && f.column.name == columnInfo.name);
    if (existing != null && existing.value == columnInfo.quickFilterData.search) {
      return;
    }

    if (existing != null) {
      this.removeQuickFilterFromColumn(columnInfo, false);
    }

    if (columnInfo.quickFilterData.search == null ||
      (columnInfo.quickFilterData.search.trim && columnInfo.quickFilterData.search.trim() === "")) {
      this.fetchData();
      return;
    }

    var operator = this.getFilterOperatorBasedOnColumnDataType(columnInfo);
    var filter = new DataListFilterInfo(columnInfo, columnInfo.quickFilterData.search, RowOperators.AND, operator, undefined, DataListFilterType.Quick);
    this.state.filters.push(filter);

    this.goToFirst();
    this.fetchData();
  }

  removeQuickFilterFromColumn(col: DataListColumnInfo, refresh: boolean = false) {
    // reverse loop when removing, for keeping indexes valid!
    for (let i = this.state.filters.length - 1; i > -1; i--) {
      var current = this.state.filters[i];

      if (current.type != DataListFilterType.Quick || current.column.name != col.name) continue;

      this.state.filters.splice(i, 1);
    }

    if (refresh) {
      col.quickFilterData.search = "";
      this.fetchData();
    }
  }

  addCustomFilter(term: string, columnName: string, controlId: string, filterOp: string, rowOp: string, overwriteExisting: boolean, autoApply: boolean) {
    var columnInfo = this.getColumnInfoByName(columnName);

    if (overwriteExisting === true) {
      this.removeCustomFilterFromColumn(columnInfo);
    }

    if (term != null && (term.trim == null || (term.trim() != "" && term.trim() != "?"))) {
      var operator: FilterOperators = FilterOperators[filterOp];
      var rowOperator: RowOperators = RowOperators[rowOp];

      var filter =
        new DataListFilterInfo(columnInfo, term.toString(), rowOperator, operator, undefined, DataListFilterType.Custom, controlId);

      this.state.filters.push(filter);
    }
    else {
      this.removeCustomFilterByControlId(controlId);
    }

    if (autoApply === true) {
      this.goToFirst();
      this.fetchData();
    }
  }

  removeCustomFilterByControlId(id: string) {
    // reverse loop when removing, for keeping indexes valid!
    for (let i = this.state.filters.length - 1; i > -1; i--) {
      var current = this.state.filters[i];

      if (current.controlId != id) continue;

      this.state.filters.splice(i, 1);
    }
  }

  removeCustomFilterFromColumn(col: ColumnInfo) {
    // reverse loop when removing, for keeping indexes valid!
    for (let i = this.state.filters.length - 1; i > -1; i--) {
      var current = this.state.filters[i];

      if (current.type != DataListFilterType.Custom || current.column.name != col.name) continue;

      this.state.filters.splice(i, 1);
    }
  }

  removeAllCustomFilters() {
    // reverse loop when removing, for keeping indexes valid!
    for (let i = this.state.filters.length - 1; i > -1; i--) {
      var current = this.state.filters[i];

      if (current.type != DataListFilterType.Custom) continue;

      this.state.filters.splice(i, 1);
    }
  }

  getColumnInfoByName(name: string) {
    for (let i = 0; i < this.state.columnInfo.length; i++) {
      var current = this.state.columnInfo[i];

      if (current.name == name) return current;
    }
  }
  /*********************** Search [END] ***********************/

  async showGroupingModal(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.GroupingModal.showDialog();
  }

  async showExportModal(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.exportTitle = this.name;
    this.exportFileName = this.getExportFilename();

    const defaultColor = '#FFFFFF';
    const exportColors = this.getExportColorsFromLocalStorage();
    this.headerLinesColor = exportColors?.headerColor ?? defaultColor;
    this.evenLinesColor = exportColors?.evenColor ?? defaultColor;
    this.oddLinesColor = exportColors?.oddColor ?? defaultColor;
    this.aggregateLinesColor = exportColors?.aggregateColor ?? defaultColor;
    this.groupLinesColor = exportColors?.groupColor ?? defaultColor;

    this.ExportModal.showDialog();
  }

  async showFiltersModal(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.FiltersModal.showDialog();
  }

  async showViewsModal(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    await this.fetchAllAvailableViews();

    this.ViewsModal.showDialog();
  }

  async showImportModal(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    await this.fetchAllAvailableViews();
    this.listImportResults = null;
    this.ImportModal.showDialog();
  }

  async refresh(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.fetchData(null);
  }

  async reset(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.resetState();
    this.fetchData();
  }

  async actionButtonOnClick(action: DataListAction, event?: MouseEvent) {

    if (action.show === 'single' && this.options.hasMultiSelect && this.selection.length > 1) {
      return;
    }

    action.OnClick(this.getSelectedItems(), event);
  }

  async GroupingModalOK(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.applyGrouping({
      getGroupsClosed: this.getGroupsClosed,
      mergeGroupLevels: this.mergeGroupLevels,
      groupingColumns: this.rightGroupingColumns
    });

    this.GroupingModal.hideDialog();
  }

  applyGrouping(options: {
    getGroupsClosed: boolean,
    mergeGroupLevels: boolean,
    groupingColumns: DataListColumnInfo[]
  }) {
    if (options.getGroupsClosed && this.options.hasPaging) {
      //  this.$wrapperElement.find(".dataTables_length select").prop("disabled", true);
      //  this.dataTableInstance.page.len(zAppDevDataListComponent.MAX_ROWS);
      this.state.pageSize = zAppDevDataListComponent.MAX_ROWS;
      //  this.$wrapperElement.find(".dataTables_length select").val(-1);
    } else {
      //  this.$wrapperElement.find(".dataTables_length select").prop("disabled", false);
      this.state.pageSize = this.options.pageSizes[0];
      //  this.dataTableInstance.page.len(this.state.pageSize);
      //  this.$wrapperElement.find(".dataTables_length select").val(this.state.pageSize);
    }

    const groupBy = [];
    for (let groupingColumn of options.groupingColumns) {
      const state = /*options.getGroupsClosed === true && i === groupsArray.length - 1 ? "COLLAPSED" :*/ "EXPANDED";
      groupBy.push(new DataListGroupByInfo(groupingColumn, state, options.getGroupsClosed));
    }

    this.state.groupBy = groupBy;
    this.state.getGroupsClosed = options.getGroupsClosed;
    this.state.mergeGroupLevels = options.mergeGroupLevels;
    this.state.startRow = 0;  //Reset paging
    this.fetchData();

    //If aggregators are enabled then request them again
    if (this.state.aggregators != null) {
      this.makeAggregatorRequest();
    }

  }

  saveStateToLocalStorage() {

    if (!this.initialized) {
      return;
    }

    if (Joove.Common.stringIsNullOrEmpty(window._context.currentUsername)) return;
    var view = new ListView();
    view.SerializedStatus = JSON.stringify(this.state);
    view.ViewName = this.lastStateName;
    view.IsDefault = false;
    var key = this.createKeyForLocalStorageState();
    try {
      localStorage.setItem(key, JSON.stringify(view));
    } catch (error) {
      var statusToSave = JSON.parse(view.SerializedStatus);
      //Remove the selected item objects as they most probably be the cause for exceeding the quota exception
      statusToSave.status.selectedItems = [];
      view.SerializedStatus = JSON.stringify(statusToSave);
      //...and retry to save the view
      localStorage.setItem(key, JSON.stringify(view));
    }
  }

  private getStateFromLocalStorage(): ListView {
    var key = this.createKeyForLocalStorageState();
    var viewSerialized = localStorage.getItem(key);
    if (viewSerialized == null) return null;
    var view: ListView = JSON.parse(viewSerialized);
    return view;
  }

  makeAggregatorRequest() {

    // reject requests before the initial lazy load fetch data request made by the PrimeNG p-table component
    if (!this.lazyLoaded) {
      return;
    }

    const activeAggregators = this.state.aggregators.filter(aggregator => aggregator.enabled);

    if (activeAggregators.length <= 0) {
      //this.state.aggregators = null;
      //this.renderAggregators(null, null, null, null, null);
      //if (this.cache.groups != null) {
      //  this.renderGroups();
      //}
      //this.dataTableInstance.state.save();
      return;
    }

    const datasourceRequestInfo = this.prepareDatasourceRequestInfo();
    const model = this.modelToProjection();

    this.dataset && this.dataset.fetchAggregators(datasourceRequestInfo, activeAggregators, model).then((data: any) => {
      // this.state.aggregators = data;
      this.processColumnAggregators(data);

      //if (this.cache.groups != null) {
      //  this.renderGroups();
      //}
      //this.renderAggregators(null, null, null, null, null);
      //this.dataTableInstance.state.save();

      if (this.options.rememberLastState == true) {
        this.saveStateToLocalStorage();
      }
    });

  }

  async GroupingModalCancel(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.GroupingModal.hideDialog();
  }

  async ExportModalOK(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {

    const options = {
      type: this.selectedExportType,
      range: this.selectedPrintRange,
      fileName: this.exportFileName,
      exportTitle: this.exportTitle,
      includeGridLines: this.includeGridLines,
      portraitOrientation: this.pdfPortraitOrientation,
      headerColor: convertToRGB(this.headerLinesColor),
      evenColor: convertToRGB(this.evenLinesColor),
      oddColor: convertToRGB(this.oddLinesColor),
      groupColor: convertToRGB(this.groupLinesColor),
      aggregateColor: convertToRGB(this.aggregateLinesColor)
    };

    this.exportHelper.okCallback(options);
    this.ExportModal.hideDialog();

    this.setExportColorsToLocalStorage({
      headerColor: this.headerLinesColor,
      evenColor: this.evenLinesColor,
      oddColor: this.oddLinesColor,
      groupColor: this.groupLinesColor,
      aggregateColor: this.aggregateLinesColor
    });
  }

  setExportColorsToLocalStorage(colors: ListExportColorsConfiguration) {
    var key = this.createKeyForLocalStorageExportColors();
    localStorage.setItem(key, JSON.stringify(colors));
  }

  getExportColorsFromLocalStorage(): ListExportColorsConfiguration {
    var key = this.createKeyForLocalStorageExportColors();
    return JSON.parse(localStorage.getItem(key) ?? '{}');
  }

  async ExportModalCancel(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.ExportModal.hideDialog();
  }

  async FitersModalOK(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {

    this.validateFilters();

    if (this.filtersHaveErrors) {
      return;
    }

    this.FiltersModal.hideDialog();

    this.state.filters = this.filters;

    this.goToFirst();
    this.fetchData();
  }

  async FiltersModalCancel(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.FiltersModal.hideDialog();
  }

  async ViewsModalOK(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.ViewsModal.hideDialog();
  }

  async ViewsModalCancel(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.ViewsModal.hideDialog();
  }

  async ImportModalOK(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.ImportModal.hideDialog();
  }

  async ImportModalCancel(_evt: any = null, indexes: Array<number> = [], listItems: Array<any> = []) {
    this.ImportModal.hideDialog();
  }

  async addGroups(_evt: any) {
    this.rightGroupingColumns = this.rightGroupingColumns.concat(this.cols.filter(c => this.leftSelectedGroupingColumns.indexOf(c.name) >= 0));
    this.leftGroupingColumns = this.leftGroupingColumns.filter(c => this.leftSelectedGroupingColumns.indexOf(c.name) < 0);

    this.leftSelectedGroupingColumns = [];
  }

  async removeGroups(_evt: any) {
    this.leftGroupingColumns = this.leftGroupingColumns.concat(this.cols.filter(c => this.rightSelectedGroupingColumns.indexOf(c.name) >= 0));
    this.rightGroupingColumns = this.rightGroupingColumns.filter(c => this.rightSelectedGroupingColumns.indexOf(c.name) < 0);

    this.rightSelectedGroupingColumns = [];
  }

  async moveGroupUp(_evt: any) {

    const columnsToMove = this.rightGroupingColumns.filter(c => this.rightSelectedGroupingColumns.indexOf(c.name) >= 0);

    for (let column of columnsToMove) {
      const index = this.rightGroupingColumns.indexOf(column);

      if (index <= 0) {
        continue;
      }

      [this.rightGroupingColumns[index], this.rightGroupingColumns[index - 1]] = [this.rightGroupingColumns[index - 1], this.rightGroupingColumns[index]];
    }
  }

  async moveGroupDown(_evt: any) {
    const columnsToMove = this.rightGroupingColumns.filter(c => this.rightSelectedGroupingColumns.indexOf(c.name) >= 0);

    for (let column of columnsToMove.slice().reverse()) {
      const index = this.rightGroupingColumns.indexOf(column);

      if (index >= this.rightGroupingColumns.length - 1) {
        continue;
      }

      [this.rightGroupingColumns[index], this.rightGroupingColumns[index + 1]] = [this.rightGroupingColumns[index + 1], this.rightGroupingColumns[index]];
    }
  }

  exportTypeChanged(event, path, root, makeDirty) {
    this.selectedExportType = event;

    if (this.selectedExportType !== 'PDF') {
      this.pdfPortraitOrientation = false;
    }
  }

  printRangeChanged(event, path, root, makeDirty) {
    this.selectedPrintRange = event;
  }

  pdfPortraitOrientationChanged(event, path, root, makeDirty) {
    this.pdfPortraitOrientation = event;
  }

  includeGridLinesChanged(event, path, root, makeDirty) {
    this.includeGridLines = event;
  }

  exportFilenameChanged(event, path, root, makeDirty) {
    this.exportFileName = event;
  }

  exportPageTitleChanged(event, path, root, makeDirty) {
    this.exportTitle = event;
  }

  mergeGroupLevelsChanged(event, path, root, makeDirty) {
    this.mergeGroupLevels = event;
  }

  getGroupsClosedChanged(event, path, root, makeDirty) {
    this.getGroupsClosed = event;
  }

  pageSizeChanged(event, path, root, makeDirty) {
    /*this.instance.status.pageSize = event._value;
    this.instance.dataTableInstance.page.len(event._value);
    this.instance.dataTableInstance.draw();*/
  }

  visibleColumnsChanged(event, path, root, makeDirty) {
    const visibleColumnsIndexes = [];
    const hiddenColumnsIndexes = [];
    for (let i = 0; i < this.state.columnInfo.length; i++) {
      const col = this.state.columnInfo[i];
      col.visible = event.some(c => c === col.name);
      col.isVisible = col.visible;

      if (col.visible) {
        visibleColumnsIndexes.push(i + 1);
      } else {
        hiddenColumnsIndexes.push(i + 1);
      }
    }

    this.visibleColumnNames = this.FilterColumns.filter(c => this.state.columnInfo.find(ci => ci.name === c._value).visible).map(c => c._value);

    if (this.options.rememberLastState) {
      this.saveStateToLocalStorage();
    }

    this.makeAggregatorRequest();
  }

  getExportFilename(): string {
    return `${this.name}_${moment().format(' DD-MM-YYYY (HH mm)')}`;
  }

  getPageSize() {

    if (this.PageSizeOptions == null) {
      return;
    }

    /*if (this.instance.status.pageSize === 1000) {
      return this.PageSizeOptions.find(o => o._value === -1);
    }

    return this.PageSizeOptions.find(o => o._value === this.instance.status.pageSize);*/
  }

  getPageSizeLabel() {
    //return this.getPageSize()?._text;
  }

  /** Filters Modal */

  filtersModalStyle = { width: '75vw' };
  FilterColumnDropdown_ProjectionScema = {};
  FilterOperatorDropdown_ProjectionScema = {};
  FilterRowOperatorDropdown_ProjectionScema = {};
  VisibleColumnDataset: ManuallyEnteredDatasource<any>;
  FiltersColumnDataset: ManuallyEnteredDatasource<any>;
  FilterColumns: { _text: string, _value: string, selected: boolean }[];
  FilterBooleanDropdown_ProjectionScema = {};

  FilterOperators = Object.keys(FilterOperators)
    .filter(v => isNaN(parseInt(v, 10)) && FilterOperators[v] !== FilterOperators.NONE)
    .map(v => {

      let text = '';
      switch (FilterOperators[v]) {
        case FilterOperators.EQUAL_TO:
          text = this.resources.textResources.EqualTo;
          break;
        case FilterOperators.GREATER_THAN:
          text = this.resources.textResources.GreaterThan;
          break;
        case FilterOperators.GREATER_THAN_OR_EQUAL_TO:
          text = this.resources.textResources.GreaterThanOrEqualTo;
          break;
        case FilterOperators.HAS_NO_VALUE:
          text = this.resources.textResources.HasNoValue;
          break;
        case FilterOperators.HAS_VALUE:
          text = this.resources.textResources.HasValue;
          break;
        case FilterOperators.LESS_THAN:
          text = this.resources.textResources.LessThan;
          break;
        case FilterOperators.LESS_THAN_OR_EQUAL_TO:
          text = this.resources.textResources.LessThanOrEqualTo;
          break;
        case FilterOperators.LIKE:
          text = this.resources.textResources.Like;
          break;
        case FilterOperators.NOT_EQUAL_TO:
          text = this.resources.textResources.NotEqualTo;
          break;
        case FilterOperators.RANGE:
          text = this.resources.textResources.Range;
          break;
      }

      return { _text: text, _value: FilterOperators[v], selected: false };
    });
  FiltersOperatorDataset = new ManuallyEnteredDatasource(this.FilterOperators);

  FilterRowOperators = Object.keys(RowOperators)
    .filter(v => isNaN(parseInt(v, 10)) && RowOperators[v] !== RowOperators.NONE)
    .map(v => {
      return { _text: v, _value: RowOperators[v], selected: false };
    });
  FiltersRowOperatorDataset = new ManuallyEnteredDatasource(this.FilterRowOperators);

  BooleanOperators = [
    { _text: 'True', _value: 'true', selected: false },
    { _text: 'False', _value: 'false', selected: false },
  ];
  BooleanDataset = new ManuallyEnteredDatasource(this.BooleanOperators);

  filters: DataListFilterInfo[] = [];
  filtersHaveErrors: boolean;

  filtersDatetimeOptions: DateTimeBoxOptions = new DateTimeBoxOptions({
    readonly: false,
    required: false,
    doesNotMakeFormDirty: false,
    asMonthPicker: false,
    datePicker: true,
    timePicker: false,
    dateFormat: "",
    timeFormat: "",
    allowtyping: true
  });

  findCompatibleOperators = function (filter: DataListFilterInfo) {
    return function (item: { _text: string, _value: FilterOperators }) {
      if (filter == null || filter.column == null) return false;

      const common = [
        FilterOperators.NONE,
        FilterOperators.EQUAL_TO,
        FilterOperators.NOT_EQUAL_TO,
        FilterOperators.HAS_VALUE,
        FilterOperators.HAS_NO_VALUE,
      ];

      if (common.indexOf(item._value) > -1) return true;

      if (filter.column.dataType != "bool" && item._value == FilterOperators.LIKE) return true;

      const ordinableTypes = ["DateTime", "int", "long", "float", "decimal", "double"];

      return ordinableTypes.indexOf(filter.column.dataType) > -1;
    };
  };

  // Events
  addFilter() {
    this.filters.push(new DataListFilterInfo(
      null,
      null,
      RowOperators.OR,
      null,
      null,
      null
    ));
  }

  removeFilter(filter: DataListFilterInfo) {
    this.filters.splice(this.filters.indexOf(filter), 1);
  }

  clearAllFilter() {
    this.filters = [];
  }

  getFilterColumn(filter: DataListFilterInfo) {

    if (filter.column == null) {
      return null;
    }

    return this.FilterColumns.find(c => c._value === filter.column.name);
  }

  getFilterOperator(filter: DataListFilterInfo) {

    if (filter.operator == null) {
      return null;
    }

    return this.FilterOperators.find(f => f._value === filter.operator);
  }

  getRowFilterOperator(filter: DataListFilterInfo) {

    if (filter.rowOperator == null) {
      return null;
    }

    return this.FilterRowOperators.find(f => f._value === filter.rowOperator);
  }

  getBooleanOperator(filter: DataListFilterInfo) {

    if (filter.value == null) {
      return null;
    }

    return this.BooleanOperators.find(f => f._value === filter.value);
  }

  rowValueChange(event, path, row: DataListFilterInfo, makeDirty) {
    row.value = event;
  }

  rowSecondValueChange(event, path, row: DataListFilterInfo, makeDirty) {
    row.secondValue = event;
  }

  filtersColumnChanged(event, path, row: DataListFilterInfo, makeDirty) {
    row.column = this.cols.find(c => c.name === event._value);
    row.operator = null;
    row.value = '';
    row.secondValue = '';
  }

  filtersOperatorChanged(event, path, row: DataListFilterInfo, makeDirty) {
    row.operator = event._value;
  }

  filtersRowOperatorChanged(event, path, row: DataListFilterInfo, makeDirty) {
    row.rowOperator = event._value;
  }

  filtersBooleanOperatorChanged(event, path, row: DataListFilterInfo, makeDirty) {
    row.value = event._value;
  }

  filterDateTimeChanged(event, path, row: DataListFilterInfo, makeDirty) {
    row.value = event;
    //row.value = moment(new Date(event)).format('D/M/YYYY'); // hard coded format, same as the format that the server is waiting (RuntimePredicateBuilder)
  }

  filterDateTimeSecondChanged(event, path, row: DataListFilterInfo, makeDirty) {
    row.secondValue = event;
    //row.secondValue = moment(event).format('D/M/YYYY'); // hard coded format, same as the format that the server is waiting (RuntimePredicateBuilder)
  }

  validateFilters() {
    this.filtersHaveErrors = false;

    for (let filter of this.filters) {

      // Basic Fields are empty (ALL)
      if (filter.column == null ||
        filter.rowOperator == null ||
        filter.rowOperator == RowOperators.NONE ||
        filter.operator == null ||
        filter.operator == FilterOperators.NONE) {
        this.filtersHaveErrors = true;
        break;
      }

      // Filter Value is empty (ALL except from HAS_VALUE / HAS_NO_VALUE)
      if (filter.operator != FilterOperators.HAS_VALUE &&
        filter.operator != FilterOperators.HAS_NO_VALUE &&
        (filter.value == null || filter.value.trim().length <= 0)) {
        this.filtersHaveErrors = true;
        break;
      }

      // Second Filter Value is empty (RANGE only)
      if (filter.operator == FilterOperators.RANGE &&
        (filter.secondValue == null || filter.secondValue.trim().length <= 0)) {
        this.filtersHaveErrors = true;
        break;
      }
    }
  }

  /** Filters Modal End */

  /** Views Modal */

  views: ListView[];
  currentView: ListView;
  viewName: string = '';
  isDefaultView: boolean = false;
  currentViewSerializedStatus: any = {};
  lastStateName: string = "LastState";

  async fetchAllAvailableViews() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/zappdev;');

    return new Promise<void>((res, rej) => {
      this._httpClient
        .post(`${environment.appUrl}${this.dataset.controllerName}/LoadListViews`,
          {
            ControlName: this.name // this.instance.serversideElementId,
          },
          { headers, observe: 'response' })
        .subscribe(response => {

          var responseData;
          if (response.body == null) {
            responseData = { Data: { Views: [] } };
          } else {
            responseData = response.body as any;
          }

          this.views = response == null ? [] : responseData.Data.Views;

          for (let i = 0; i < this.views.length; i++) {
            var current = this.views[i];

            if (current.ViewName == responseData.Data.DefaultView) {
              current.IsDefault = true;
              this.currentView = current;
            }
            else {
              current.IsDefault = false;
            }
          }

          res();
        });
    })

  }

  viewNameChange(event, path, row: DataListFilterInfo, makeDirty) {
    this.viewName = event;
  }

  isDefaultViewChanged(event, path, row: DataListFilterInfo, makeDirty) {
    this.isDefaultView = event;
  }

  saveCurrentView() {

    var view = new ListView();
    view.SerializedStatus = JSON.stringify(this.state);
    view.ViewName = this.viewName;
    view.IsDefault = this.isDefaultView;

    this.saveView(view);
  }

  public saveView(view: ListView) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/zappdev;');

    return this._httpClient
      .post(`${environment.appUrl}${this.dataset.controllerName}/SaveListView`,
        {
          ControlName: this.name,// this.state.serversideElementId,
          SerializedStatus: view.SerializedStatus,
          ViewName: view.ViewName,
          SetAsDefault: view.IsDefault
        },
        { headers, observe: 'response' })
      .subscribe(response => {
        var index = -1;
        for (let i = 0; i < this.views.length; i++) {
          var current = this.views[i];

          if (current.ViewName == view.ViewName) {
            index = i;
            break;
          }
        }

        if (index > -1) {
          this.views.splice(index, 1);
        }

        this.views.push(view);

        if (view.IsDefault === true) {
          this.currentView = view;
        }

      });
  }

  deleteView(view: ListView) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/zappdev;');

    return this._httpClient
      .post(`${environment.appUrl}${this.dataset.controllerName}/DeleteListView`,
        {
          ControlName: this.name, // this.instance.serversideElementId,
          ViewName: view.ViewName,
        },
        { headers, observe: 'response' })
      .subscribe(response => {
        var index = this.views.indexOf(view);

        if (index == -1) return;

        this.views.splice(index, 1);

        // this.viewsHelper._options.loadCb && this.viewsHelper._options.loadCb();
      });
  }

  loadView(view: ListView) {

    this.ViewsModal.hideDialog();

    this.currentView = view;
    this.currentViewSerializedStatus = view.SerializedStatus;

    this.state = JSON.parse(view.SerializedStatus);
    this.state.indexes = this.indexes ?? [];

    //check for new columns
    let index = 0;
    for (let col of this.cols) {
      const stateCol = this.state.columnInfo.find(c => c.name == col.name);
      if (stateCol == null) {
        const colToAdd = { ...col };
        this.state.columnInfo.splice(index, 0, colToAdd);
      }
      index++;
    }
    //check for removed columns
    for (let col of this.state.columnInfo) {
      const defColumn = this.cols.find(c => c.name == col.name);
      if (defColumn == null) {
        index = this.state.columnInfo.indexOf(col);
        this.state.columnInfo.splice(index, 1);
      }
    }

    this.enrichColumnData(this.state.columnInfo, false);

    //grouping columns
    this.rightGroupingColumns = this.state.groupBy == null ? [] :
      this.state.groupBy.map(g => this.cols.find(c => c.name == g.column.name)).filter(c => c != null);
    this.initAggregators();

    //customer filters
    this.filters = this.state.filters;

    if ((this.state?.filters ?? []).length > 0 && this.state.filters[0].column?.mambaDataType != "DateTime") {
      this.searchInput = this.state?.filters[0].value;
    }

    for (let filterItem of this.filters) {
      var colDataType = Joove.Common.getMambaDataType(filterItem.column.mambaDataType);
      if (colDataType === Joove.MambaDataType.DATETIME) {
        const formater = new ValueFormat(filterItem.column?.formatting as any);
        filterItem.value = formater.tryParseDate(filterItem.value) + "";
        filterItem.secondValue = formater.tryParseDate(filterItem.secondValue) + "";
      }
    }

    this.visibleColumnNames = this.FilterColumns.filter(c => this.state.columnInfo.find(ci => ci.name === c._value).visible).map(c => c._value);

    this.fetchData();
  }

  getFilterFormatValues(filter: DataListFilterInfo) {
    return { ...this.filtersDatetimeOptions, dateFormat: filter.column.formatting?.dateFormat }
  }

  /** Views Modal End */

  /** Import Modal */
  filesToUpload: any[];
  listImportResults: ListImportResult;

  downloadCsvTemplate() {
    window.location.href = `${environment.appUrl}${this.dataset.controllerName}/${this.name}_ImportTemplate`;
  }

  onSelectFile(event) {
    var files = this.csv_file.nativeElement.files;
    this.uploadCsvForImport(files);
  }

  onFileDrop(event) {
    event.stopPropagation();
    event.preventDefault();

    if (!event.dataTransfer) {
      return;
    }
    const droppedFiles = event.dataTransfer.files; // the files that were dropped

    this.uploadCsvForImport(droppedFiles);
  }

  onFileDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  uploadCsvForImport(files: Array<any>) {
    if (files == null || files.length == 0) return;

    // this.$labelContent.html(window._resourcesManager.getFileUploadLoading());

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }

    this._httpClient
      .post(`${environment.appUrl}${this.dataset.controllerName}/${this.name}_ImportData`, formData, {
        reportProgress: true,
        observe: "events",
      })
      .subscribe((events) => {
        if (events.type === HttpEventType.UploadProgress) {
        } else if (events.type === HttpEventType.Response) {
          var data = events.body as any;
          this.listImportResults = data;
          this.fetchData();
        }
      });
  }

  /** Import Modal End */

  processColumnAggregators(data: AggregatorInfo[] | AggregationResult) {

    const grouped = (data as AggregationResult).Groups != null;

    for (let columnInfo of this.state.columnInfo) {

      const columnAggregators = grouped
        ? (data as AggregationResult).Groups.Aggregates.filter(d => d.Column.toLowerCase() === columnInfo.name.replace(/\./g, '').toLowerCase())
        : (data as AggregatorInfo[]).filter(d => d.Column === columnInfo.name);

      columnInfo.countAggregator = columnAggregators.find(aggregator => parseInt(aggregator.Type as any) === AggregatorTypes.COUNT);
      columnInfo.sumAggregator = columnAggregators.find(aggregator => parseInt(aggregator.Type as any) === AggregatorTypes.SUM);
      columnInfo.averageAggregator = columnAggregators.find(aggregator => parseInt(aggregator.Type as any) === AggregatorTypes.AVERAGE);
    }

    if (!grouped) {
      return;
    }

    const groupData = data as AggregationResult;

    for (let dataItem of this.data) {

      if (dataItem.$groupingInfo == null) {
        continue;
      }

      for (let group of dataItem.$groupingInfo.groups) {

        group.columnAggregators = this.state.columnInfo.map(column => {
          return { column: column.name, visible: column.visible };
        });

        const subgroups = this.getSubgroupsByIdentifier(group.identifier.replace(/\./g, '').toLowerCase(), groupData.Groups);

        if (subgroups == null || subgroups.length <= 0) {
          continue;
        }

        for (let subgroup of subgroups) {

          for (let aggregator of subgroup.Aggregates) {

            const columnAggregator = group.columnAggregators.find(columnAggregator => columnAggregator.column.replace(/\./g, '').toLowerCase() === aggregator.Column.replace(/\./g, '').toLowerCase());

            if (columnAggregator == null) {
              continue;
            }

            const aggregatorType = parseInt(aggregator.Type as any);
            if (aggregatorType === AggregatorTypes.COUNT) {
              columnAggregator.countAggregator = aggregator;
            } else if (aggregatorType === AggregatorTypes.AVERAGE) {
              columnAggregator.averageAggregator = aggregator;
            } else if (aggregatorType === AggregatorTypes.SUM) {
              columnAggregator.sumAggregator = aggregator;
            }

          }
        }

      }
    }

  }

  processDataGrouping(data: any[], grouping: GroupTree): any[] {

    const mergeGroupLevels = this.state.mergeGroupLevels;

    if (grouping == null) {
      return data;
    }

    let groupDataOnly = false;
    if (data == null || data.length <= 0) {
      data = grouping.SubGroups.map(subgroup => {
        return { $helperRow: true };
      });

      data = this.createGroupingFakeDataRows(grouping);
      groupDataOnly = true;
    }

    const processedSubgroups: GroupTree[] = [];
    for (let dataItem of data) {

      const subgroups = groupDataOnly
        ? this.getSubgroupsByIdentifier(dataItem.identifier, grouping)
        : this.getSubgroupsByKey(dataItem._key, grouping);

      if (subgroups == null || subgroups.length <= 0) {
        continue;
      }

      const groupingInfo: {
        groups: {
          columnName: string,
          value: string,
          level: number,
          identifier: string,
          collapsed: boolean,
          hidden: boolean,
          columnAggregators: any[],
          mergedColumns: { columnName: string, value: string }[],
        }[],
        hidden: boolean,
        identifiers: string[]
      } = { groups: [], hidden: false, identifiers: subgroups.map(subgroup => subgroup.Identifier) };

      let level = 0;
      for (let subgroup of subgroups) {
        level++;

        if (processedSubgroups.includes(subgroup) && !mergeGroupLevels) {
          continue;
        }

        processedSubgroups.push(subgroup);

        const columnName = subgroup.Column.Name;
        let value = groupDataOnly ? subgroup.KeyFormatted : Common.safeDeepPropertyAccess(dataItem, columnName, null);

        const columnInfo = this.state.columnInfo.find(ci => ci.name === columnName);
        if (columnInfo != null && columnInfo.mambaDataTypeIsEnum === true) {
          value = toMambaEnumStringValue(columnInfo.mambaDataType, value, this.translate);
        }

        if (mergeGroupLevels && groupingInfo.groups.length > 0) {
          const group = groupingInfo.groups[0];
          group.mergedColumns.push({ columnName: columnName.replace(/\./g, ' '), value });
          group.identifier = subgroup.Identifier;
        } else {

          const group = {
            columnName: columnName.replace(/\./g, ' '),
            value,
            level,
            identifier: subgroup.Identifier,
            collapsed: false,
            hidden: false,
            columnAggregators: [],
            mergedColumns: [],
          };

          groupingInfo.groups.push(group);

          if (mergeGroupLevels) {
            group.mergedColumns.push({ columnName: columnName.replace(/\./g, ' '), value });
          }
        }
      }

      dataItem['$groupingInfo'] = groupingInfo;
    }

    return data;
  }

  createGroupingFakeDataRows(grouping: GroupTree) {
    const mergeGroupLevels = this.state.mergeGroupLevels;

    let data = [];
    for (let subgroup of grouping.SubGroups) {

      // if merged groups, push only deepest level rows
      if (mergeGroupLevels !== true || subgroup.SubGroups.length <= 0) {
        data.push({ $helperRow: true, identifier: subgroup.Identifier, value: subgroup.Key, valueFormatted: subgroup.KeyFormatted });
      }

      if (subgroup.SubGroups.length > 0) {
        data = [...data, ...this.createGroupingFakeDataRows(subgroup)]
      } else {

      }
    }
    return data;
  }

  toggleGroup(group: { columnName: string, value: string, level: number, identifier: string, collapsed: boolean, hidden: boolean }) {

    group.collapsed = !group.collapsed;

    for (let dataItem of this.data) {

      if (!(dataItem.$groupingInfo?.identifiers ?? []).includes(group.identifier)) {
        continue;
      }

      const subgroups = dataItem.$groupingInfo.groups.filter(subgroup => subgroup.level > group.level);

      let subgroupCollapsed = false;
      for (let subgroup of subgroups) {
        subgroup.hidden = group.collapsed;
        subgroupCollapsed = subgroup.collapsed;
      }

      dataItem.$groupingInfo.hidden = group.collapsed || subgroupCollapsed;
    }
  }

  getSubgroupsByKey(key: number, group: GroupTree): GroupTree[] {

    const rowSubgroup = group.SubGroups.find(subgroup => (subgroup.UniqueItemKeys ?? []).includes(key));

    if (rowSubgroup != null) {
      return [rowSubgroup];
    }

    for (let subgroup of group.SubGroups) {
      const rowSubgroups = this.getSubgroupsByKey(key, subgroup);

      if (rowSubgroups != null && rowSubgroups.length > 0) {
        return [subgroup, ...rowSubgroups];
      }
    }

    return null;
  }

  getSubgroupsByIdentifier(identifier: string, group: GroupTree): GroupTree[] {

    const columnSubgroup = group.SubGroups.find(subgroup => (subgroup.Identifier.toLowerCase() === identifier.toLowerCase()));

    if (columnSubgroup != null) {
      return [columnSubgroup];
    }

    for (let subgroup of group.SubGroups) {
      const columnSubgroups = this.getSubgroupsByIdentifier(identifier, subgroup);

      if (columnSubgroups != null && columnSubgroups.length > 0) {
        return [subgroup, ...columnSubgroups];
      }
    }

    return null;
  }

  private createKeyForLocalStorageState(): string {
    return `LIST_STATE_${window._context.currentController}|${this.name}|${window._context.currentUsername}`;
  }

  private createKeyForLocalStorageExportColors(): string {
    return `LIST_EXPORT_COLORS_${window._context.currentController}|${this.name}|${window._context.currentUsername}`;
  }

  /***************************************** Conditional Formattings ***********************************************/

  public async applyConditionalFormattings() {

    for (let rule of this.conditionalFormattings) {

      for (let i = 0; i < this.data.length; i++) {
        const rowData = this.data[i];

        if (await rule.condition(this.codeHelper, this.model, [rowData], this._httpClient)) {
          for (let action of rule.whenTrueActions.filter(a => a.listData != null)) {
            this.dispatchConditionalFormattingAction(action, i, rowData);
          }
        } else {
          for (let action of rule.whenFalseActions.filter(a => a.listData != null)) {
            this.dispatchConditionalFormattingAction(action, i, rowData);
          }
        }

      }
    }
  }

  dispatchConditionalFormattingAction(action: RuleAction, rowIndex: number, rowData: any) {

    switch (action.type) {
      case RuleActions.ApplyCssClass: {
        if (action.listData?.appliesToListRow) {
          this.applyClassToRow(rowIndex, rowData, action.data, RuleActions.ApplyCssClass);
        }

        if (action.listData?.appliesToListCell) {
          this.applyClassToCell(rowIndex, rowData, action.listData.listCellsAffected, action.data, RuleActions.ApplyCssClass);
        }

        if (action.listData?.appliesToListColumn) {
          this.applyClassToColumn(rowIndex, action.data, RuleActions.ApplyCssClass);
        }
        break;
      }
      case RuleActions.RemoveCssClass: {
        if (action.listData?.appliesToListRow) {
          this.applyClassToRow(rowIndex, rowData, action.data, RuleActions.RemoveCssClass);
        }

        if (action.listData?.appliesToListCell) {
          this.applyClassToCell(rowIndex, rowData, action.listData.listCellsAffected, action.data, RuleActions.RemoveCssClass);
        }

        if (action.listData?.appliesToListColumn) {
          this.applyClassToColumn(rowIndex, action.data, RuleActions.RemoveCssClass);
        }
        break;
      }
      case RuleActions.ChangeStyle: {
        if (action.listData?.appliesToListRow) {
          this.applyChangeStyleToRow(rowIndex, rowData, action.data);
        }

        if (action.listData?.appliesToListCell) {
          this.applyChangeStyleToCell(rowIndex, rowData, action.listData.listCellsAffected, action.data);
        }

        //if (action.listData?.appliesToListColumn) {
        //  this.applyChangeStyleToColumn(rowIndex, action.data, RuleActions.RemoveCssClass);
        //}
        break;
      }
    }

  }

  applyClassToRow(rowIndex: number, rowData: any, cls: string, actionType: RuleActions.ApplyCssClass | RuleActions.RemoveCssClass) {

    if (rowData.$cfInfo == null) {
      rowData.$cfInfo = {};
    }

    if (rowData.$cfInfo.rowClasses == null) {
      rowData.$cfInfo.rowClasses = '';
    }

    const classes = rowData.$cfInfo.rowClasses.split(' ');
    if (actionType == RuleActions.ApplyCssClass) {
      if (!classes.includes(cls)) {
        classes.push(cls);
      }
    } else {
      if (classes.includes(cls)) {
        classes.pop(cls);
      }
    }
    rowData.$cfInfo.rowClasses = classes.join(' ');
  }

  applyClassToCell(rowIndex: number, rowData: any, listCellsAffected: string[], cls: string, actionType: RuleActions.ApplyCssClass | RuleActions.RemoveCssClass) {

    this.initCfCellMetadataOnRow(rowData);

    for (let cell of listCellsAffected) {
      const classes = rowData.$cfInfo.cells[cell].classes.split(' ');
      if (actionType == RuleActions.ApplyCssClass) {
        if (!classes.includes(cls)) {
          classes.push(cls);
        }
      } else {
        if (classes.includes(cls)) {
          classes.pop(cls);
        }
      }

      rowData.$cfInfo.cells[cell].classes = classes.join(' ');
    }
  }

  applyClassToColumn(rowIndex: number, cls: string, actionType: RuleActions.ApplyCssClass | RuleActions.RemoveCssClass) {
    // todo: implement
  }

  applyChangeStyleToRow(rowIndex: number, rowData: any, data: string[]) {

    if (rowData.$cfInfo == null) {
      rowData.$cfInfo = {};
    }

    if (rowData.$cfInfo.rowClasses == null) {
      rowData.$cfInfo.rowClasses = '';
    }

    const classToAdd = data[0];
    const classToRemove = data[1];

    const classes = rowData.$cfInfo.rowClasses.split(' ');
    if (!classes.includes(classToAdd)) {
      classes.push(classToAdd);
    }
    if (classes.includes(classToRemove)) {
      classes.pop(classToRemove);
    }

    rowData.$cfInfo.rowClasses = classes.join(' ');
  }

  applyChangeStyleToCell(rowIndex: number, rowData: any, listCellsAffected: string[], data: string[]) {

    this.initCfCellMetadataOnRow(rowData);

    const classToAdd = data[0];
    const classToRemove = data[1];

    for (let cell of listCellsAffected) {
      const classes = rowData.$cfInfo.cells[cell].classes.split(' ');
      if (!classes.includes(classToAdd)) {
        classes.push(classToAdd);
      }
      if (classes.includes(classToRemove)) {
        classes.pop(classToRemove);
      }

      rowData.$cfInfo.cells[cell].classes = classes.join(' ');
    }
  }

  initCfCellMetadataOnRow(rowData: any) {
    let initialized = true;

    if (rowData.$cfInfo == null) {
      rowData.$cfInfo = {};
      initialized = false;
    }

    if (rowData.$cfInfo.cells == null) {
      rowData.$cfInfo.cells = {};
      initialized = false;
    }

    if (initialized) {
      return;
    }

    for (let columnInfo of this.cols) {
      rowData.$cfInfo.cells[columnInfo.name] = {
        classes: ''
      };
    }
  }


  /***************************************** Conditional Formattings [END] *****************************************/

  async selectAll() {
    await this.fetchFullRecordset();
    this.state.allKeysSelected = true;
    this.state.selectedKeys = _.union(this.state.selectedKeys, this.data.map(dataItem => dataItem._key));
    this.selection = _.uniqBy([...this.selection, ...this.data], '_key');
    this.selectedItemsChange.emit(this.getSelectedItems());
    if (this.options.rememberSelectedItems) {
      this.saveStateToLocalStorage();
    }

    if (this.options.hasPaging) {
      this.data = this.data.slice(this.first, this.first + this.el.rows);
    }
  }

  deselectAll() {
    this.state.allKeysSelected = false;
    this.state.selectedKeys = [];
    this.selection = [];
    this.selectedItemsChange.emit(this.getSelectedItems());
    if (this.options.rememberSelectedItems) {
      this.saveStateToLocalStorage();
    }
  }

  // add current page to selection
  selectCurrentPage() {

    let currentPageData = this.data;
    if (this.data.length != this.el.rows) {
      currentPageData = this.data.slice(this.first, this.first + this.el.rows);
    }

    this.state.selectedKeys = _.union(this.state.selectedKeys, currentPageData.map(dataItem => dataItem._key));
    this.selection = _.uniqBy([...this.selection, ...currentPageData], '_key');
    this.selectedItemsChange.emit(this.getSelectedItems());
    if (this.options.rememberSelectedItems) {
      this.saveStateToLocalStorage();
    }
  }

  deselectCurrentPage() {
    this.state.allKeysSelected = false;

    let currentPageData = this.data;
    if (this.data.length != this.el.rows) {
      currentPageData = this.data.slice(this.first, this.first + this.el.rows);
    }

    this.state.selectedKeys = this.state.selectedKeys.filter(key => !(currentPageData.map(dataItem => dataItem._key).includes(key)));
    const currentPageKeys = currentPageData.map(dataItem => dataItem._key);
    this.selection = this.selection.filter(item => !currentPageKeys.includes(item._key));
    this.selectedItemsChange.emit(this.getSelectedItems());
    if (this.options.rememberSelectedItems) {
      this.saveStateToLocalStorage();
    }
  }

  // including already selected (excluded) items (picklist)
  deselectEverything() {
    this.state.allKeysSelected = false;
    this.excludedItemKeys = [];
    this.state.selectedKeys = [];
    this.state.excludedKeys = [];
    this.fetchData();
    this.selection = [];
    this.deselectAll();
  }

  get allSelected() {
    return this.options.hasMultiSelect && this.state.selectedKeys.length === this.totalRecords;
  }

  get nothingSelected() {
    return this.state.selectedKeys.length <= 0;
  }

  get currentPageAllSelected() {
    let selection = this.selection.filter(d => this.data.map(d => d._key).includes(d._key));
    return selection.length >= this.state.pageSize || selection.length >= this.data.length;
  }

  get currentPageNothingSelected() {
    let selection = this.selection.filter(d => this.data.map(d => d._key).includes(d._key));
    return selection.length <= 0;
  }

  get moreThanOnePageExist() {
    return this.options.hasPaging && !this.virtualScroll && this.data.length < this.totalRecords;
  }

  get virtualScroll() {
    if (!this.options.isStandAlone && !this.options.isPickList) {
      return false;
    }

    return this.options.infiniteScrolling === true
      || (!this.hidden
        && this.state.pageSize > 100
        && this.totalRecords > 100
        && this.state.groupBy.length == 0);
  }

  // restore selection from page to page
  refreshSelection() {

    if (this.options.hasMultiSelect) {

      if (this.state.allKeysSelected) {
        this.selectAll();
        return;
      }

      if (this.state.selectedKeys == null) {
        return;
      }

      this.selection = this.data.filter(d => d != null).filter(dataItem => this.state.selectedKeys.includes(dataItem._key));
    } else {
      this.selection = this.data.filter(d => d != null).find(dataItem => this.state.selectedKeys === dataItem._key);
    }

    this.selectedItemsChange.emit(this.getSelectedItems());
  }

  selectByKeys(keys: string[]) {
    this.state.selectedKeys = keys;
    this.refreshSelection();
  }

  calculcateStandaloneListContainerHeight() {

    // const windowHeight = window.innerHeight;
    // const windowHeight = window.screen.height;
    const windowHeight = window.visualViewport.height;
    // const windowHeight = document.documentElement.clientHeight;

    const maxHeight = 5000;
    this.listContainerRef.nativeElement.style.height = `${maxHeight}px`;

    const modalElement = Joove.Common.findAncestor(this.listContainerRef.nativeElement, '.p-dialog-content');

    const rootElement = modalElement != null
      ? modalElement
      : document.documentElement;

    setTimeout(() => {
      const diff = rootElement.scrollHeight - rootElement.clientHeight;
      const minusPixels = windowHeight - (maxHeight - diff);

      if (this.listContainerRef?.nativeElement != null) {
        this.listContainerRef.nativeElement.style.height = `calc(100dvh - ${minusPixels}px)`;
      }
      this.initialized = true;
    }, 10)
  }

  calculateRowHeight() {
    const row = this.listContainerRef?.nativeElement?.querySelector("tr.p-element.p-selectable-row");

    if (row == null) {
      return;
    }

    const rect = row.getBoundingClientRect();
    this.virtualScrollItemSize = rect.height ?? 50;
  }

  next() {
    this.first = this.first + this.state.pageSize;
    this.updateLast();
    this.state.startRow = this.first;
  }

  prev() {
    this.first = this.first - this.state.pageSize;
    this.updateLast();
    this.state.startRow = this.first;
  }

  goToFirst() {
    this.first = 0;
    this.updateLast();
    this.state.startRow = this.first;
  }

  goToLast() {
    this.first = Math.floor(this.model.length / this.state.pageSize) * this.state.pageSize;
    this.updateLast();
    this.state.startRow = this.first;
  }

  onPage(event) {
    this.updateLast();
  }

  isLastPage(): boolean {
    return this.model ? this.first === (this.model.length - this.state.pageSize) : true;
  }

  isFirstPage(): boolean {
    return this.model ? this.first === 0 : true;
  }

  updateLast() {
    let last = this.first + this.state.pageSize;
    if (last > this.totalRecords) {
      last = this.totalRecords;
    }

    this.last = last;
  }

  displayAggregationsFooter() {
    return !this.options.isPickList
      //&& this.state.groupBy != null
      //&& this.state.groupBy.length > 0
      && this.state.aggregators != null
      && this.state.aggregators.some(aggregator => aggregator.enabled);
  }


  clonedRows: { [s: string]: any; } = {};

  rowEditInit(row: any) {
    this.clonedRows[row._key] = _.cloneDeep(row);
  }

  rowEditSave(row: any) {
    this.onRowEditSave.emit(row);
  }

  rowEditCancel(row: any, index: number) {
    this.data[index] = this.clonedRows[row._key];
    delete this.clonedRows[row._key];
  }

  onCellEditInit(event) {
    const row = event.data;
    this.clonedRows[row._key] = { ...row };
  }

  onCellEditComplete(event) {
    this.onRowEditSave.emit(event.data);
  }

  onCellEditCancel(event) {
    const row = event.data;
    const index = event.index;
    this.data[index] = this.clonedRows[row._key];
    delete this.clonedRows[row._key];
  }

  onColumnReorder(event) {
    if (this.options.rememberLastState == true) {
      this.saveStateToLocalStorage();
    }
  }

  columnHasFilter(col: ColumnInfo) {
    return col.searchable && this.state.filters.some(c => c.column != null && c.column.name === col.name);
  }

  toggleSelectAll(event) {
    if (!this.allSelected) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }

  showColumnsVisibility() {
    this.columnsVisibility.show();
  }

  ngOnDestroy(): void {
    //if (this.instance) {
    //  this.instance.destroy(true);
    // }
    super.ngOnDestroy()
  }

}
