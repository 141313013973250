import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class LeadProductLine  {
    _originalTypeClassName = "zPMO.BO.LeadProductLine";
constructor() {
this.Id = null;
this.Quantity = 1;
this.TotalPrice = null;
this.ItemPrice = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Quantity:any;
TotalPrice:any;
ItemPrice:any;
Product:any;
static setProduct = ($this, value) => {
var oldValue = $this.Product;
$this.Product = value;
if (value != null) {
}
}

static UpdateTotalPrice($this: any): any{$this.TotalPrice = Joove.Common.safeNumber($this?.ItemPrice) * Joove.Common.safeNumber($this?.Quantity);
}

static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}