import { System } from 'src/app/@framework/core/clms.fw.dotnet';
import * as Domain from '../Domain';
import * as Common from '../../@framework/common';
import { CLMS  } from 'src/app/@framework/core/clms.fw.common';
import { Joove } from 'src/app/@framework/core/Joove';
import * as moment from 'moment';
var numeral = require('numeral');
const VerEx = require('verbal-expressions');


export class LeadType  {
    _originalTypeClassName = "zPMO.BO.LeadType";
constructor() {
this.Statuses = [];
this.DynamicFields = [];
this.StatusActivationValidations = [];
this.TerminalActions = [];
this.Automations = [];
this.Id = null;
this.Name = null;
this._key = this.Id = 0; 
}

_key: any;
private _Id:any;
get Id(): any {
    return this._Id;
  }
set Id(value: any) {
    this._Id = value;
    this._key = value;
  }
Name:any;
Statuses:any;
DynamicFields:any;
StatusActivationValidations:any;
TerminalActions:any;
Automations:any;
static setStatuses = ($this, items) => {
                if (items == null) items = [];
                if ($this.Statuses == null) $this.Statuses = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Statuses.length > 0) {
                    Domain.LeadType.removeStatuses($this, $this.Statuses[0]);
                }

                Domain.LeadType.addManyStatuses($this, copyItems);
            }
static addManyStatuses = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.LeadType.addStatuses($this, items[i]);
                }
            }
static addStatuses = ($this, item) => {
                if ($this.Statuses == null) $this.Statuses = [];
                if (item == null || $this.Statuses.indexOf(item) > -1) return;
                $this.Statuses.push(item);
if (item.LeadType != $this) {
Domain.LeadStatus.setLeadType(item, $this);
}
}
static addRangeStatuses = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.LeadType.addStatuses($this, item));
            }
static removeStatuses = ($this, item) => {
                if ($this == null || $this.Statuses == null || $this.Statuses.indexOf(item) === -1) return;
                var _index = $this.Statuses.indexOf(item);
                $this.Statuses.splice(_index, 1);
item.LeadType = null;
}
static clearStatuses = ($this) => { $this.Statuses = null; }
static setDynamicFields = ($this, items) => {
                if (items == null) items = [];
                if ($this.DynamicFields == null) $this.DynamicFields = [];

                const copyItems = Common.cloneObject(items);

                while ($this.DynamicFields.length > 0) {
                    Domain.LeadType.removeDynamicFields($this, $this.DynamicFields[0]);
                }

                Domain.LeadType.addManyDynamicFields($this, copyItems);
            }
static addManyDynamicFields = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.LeadType.addDynamicFields($this, items[i]);
                }
            }
static addDynamicFields = ($this, item) => {
                if ($this.DynamicFields == null) $this.DynamicFields = [];
                if (item == null || $this.DynamicFields.indexOf(item) > -1) return;
                $this.DynamicFields.push(item);
}
static addRangeDynamicFields = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.LeadType.addDynamicFields($this, item));
            }
static removeDynamicFields = ($this, item) => {
                if ($this == null || $this.DynamicFields == null || $this.DynamicFields.indexOf(item) === -1) return;
                var _index = $this.DynamicFields.indexOf(item);
                $this.DynamicFields.splice(_index, 1);
}
static clearDynamicFields = ($this) => { $this.DynamicFields = null; }
static setStatusActivationValidations = ($this, items) => {
                if (items == null) items = [];
                if ($this.StatusActivationValidations == null) $this.StatusActivationValidations = [];

                const copyItems = Common.cloneObject(items);

                while ($this.StatusActivationValidations.length > 0) {
                    Domain.LeadType.removeStatusActivationValidations($this, $this.StatusActivationValidations[0]);
                }

                Domain.LeadType.addManyStatusActivationValidations($this, copyItems);
            }
static addManyStatusActivationValidations = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.LeadType.addStatusActivationValidations($this, items[i]);
                }
            }
static addStatusActivationValidations = ($this, item) => {
                if ($this.StatusActivationValidations == null) $this.StatusActivationValidations = [];
                if (item == null || $this.StatusActivationValidations.indexOf(item) > -1) return;
                $this.StatusActivationValidations.push(item);
if (item.LeadType != $this) {
Domain.LeadStatusActivationValidation.setLeadType(item, $this);
}
}
static addRangeStatusActivationValidations = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.LeadType.addStatusActivationValidations($this, item));
            }
static removeStatusActivationValidations = ($this, item) => {
                if ($this == null || $this.StatusActivationValidations == null || $this.StatusActivationValidations.indexOf(item) === -1) return;
                var _index = $this.StatusActivationValidations.indexOf(item);
                $this.StatusActivationValidations.splice(_index, 1);
item.LeadType = null;
}
static clearStatusActivationValidations = ($this) => { $this.StatusActivationValidations = null; }
static setTerminalActions = ($this, items) => {
                if (items == null) items = [];
                if ($this.TerminalActions == null) $this.TerminalActions = [];

                const copyItems = Common.cloneObject(items);

                while ($this.TerminalActions.length > 0) {
                    Domain.LeadType.removeTerminalActions($this, $this.TerminalActions[0]);
                }

                Domain.LeadType.addManyTerminalActions($this, copyItems);
            }
static addManyTerminalActions = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.LeadType.addTerminalActions($this, items[i]);
                }
            }
static addTerminalActions = ($this, item) => {
                if ($this.TerminalActions == null) $this.TerminalActions = [];
                if (item == null || $this.TerminalActions.indexOf(item) > -1) return;
                $this.TerminalActions.push(item);
if (item.LeadType != $this) {
Domain.LeadTerminalAction.setLeadType(item, $this);
}
}
static addRangeTerminalActions = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.LeadType.addTerminalActions($this, item));
            }
static removeTerminalActions = ($this, item) => {
                if ($this == null || $this.TerminalActions == null || $this.TerminalActions.indexOf(item) === -1) return;
                var _index = $this.TerminalActions.indexOf(item);
                $this.TerminalActions.splice(_index, 1);
item.LeadType = null;
}
static clearTerminalActions = ($this) => { $this.TerminalActions = null; }
static setAutomations = ($this, items) => {
                if (items == null) items = [];
                if ($this.Automations == null) $this.Automations = [];

                const copyItems = Common.cloneObject(items);

                while ($this.Automations.length > 0) {
                    Domain.LeadType.removeAutomations($this, $this.Automations[0]);
                }

                Domain.LeadType.addManyAutomations($this, copyItems);
            }
static addManyAutomations = ($this, items) => {
                if (items == null) return;
                for (let i = 0; i < items.length; i++) {
                    Domain.LeadType.addAutomations($this, items[i]);
                }
            }
static addAutomations = ($this, item) => {
                if ($this.Automations == null) $this.Automations = [];
                if (item == null || $this.Automations.indexOf(item) > -1) return;
                $this.Automations.push(item);
if (item.LeadType != $this) {
Domain.LeadAutomation.setLeadType(item, $this);
}
}
static addRangeAutomations = ($this, items: System.Collections.List<any>) => { 
                items.forEach(item => Domain.LeadType.addAutomations($this, item));
            }
static removeAutomations = ($this, item) => {
                if ($this == null || $this.Automations == null || $this.Automations.indexOf(item) === -1) return;
                var _index = $this.Automations.indexOf(item);
                $this.Automations.splice(_index, 1);
item.LeadType = null;
}
static clearAutomations = ($this) => { $this.Automations = null; }


static IsNew($this: any): boolean {
                if ($this == null) return true;
                return $this.Id == null || $this.Id == 0;
            }


}